import React, { useState, useContext } from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';

import { GuidanceTipsContext } from '../../../utils/contexts/guidanceTipsContext';
import Util from '../../../util';
import './styles.scss';

const GuidanceTip = ({
  tipId,
  toolTipPosition,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [, setTimeoutId] = useState(null);

  const tipsData = useContext(GuidanceTipsContext);

  const matchedTip = tipsData?.guidanceTips?.find(guidanceTip => guidanceTip.tipId === tipId);

  const showTip = Util.isDESelectFreeUser() && matchedTip?.isEnabled && tipsData?.preferences?.showTips;

  /**
   * Helps to keep the tooltip displayed 400 after the mouse leaves
   * @returns {void}
   */
  const tipTimeout = () => {
    const id = setTimeout(() => setShowTooltip(false), 400);

    setTimeoutId((oldId) => {
      if (oldId) clearTimeout(oldId);

      return id;
    });
  };

  /**
   * Handle event when Mouse leaves the tip icon
   * @returns {void}
   */
  const handleIconOnMouseEnter = () => {
    setShowTooltip(() => {
      setTimeoutId((oldId) => {
        if (oldId) clearTimeout(oldId);
      });

      return true;
    });
  };

  /**
   * Handle event when Mouse leaves the tip content
   * @returns {void}
   */
  const handleContentOnMouseEnter = () => {
    setTimeoutId((oldId) => {
      if (oldId) clearTimeout(oldId);

      return null;
    });
  };

  return (
    <div
      className="guidance-tip-container"
    >
      {showTip ?
        (<div
        className="tip-icon-container"
        onMouseEnter={handleIconOnMouseEnter}
        onMouseLeave={tipTimeout}
        >
        <span
          className="slds-icon_container slds-icon_container_circle tip-light-bulb"
          >
          <svg className="slds-icon slds-icon_x-small" aria-hidden="true">
            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#light_bulb" />
          </svg>
        </span>
         </div>) :
        null}
      {showTooltip && (
        <div
          className={`slds-popover slds-popover_tooltip slds-nubbin_top-${toolTipPosition} wp-break-spaces`}
          role="tooltip"
          id="tooltip-content"
          onMouseEnter={handleContentOnMouseEnter}
          onMouseLeave={tipTimeout}
        >
          <div className="slds-popover__body">
            <p className="guidance-tip-title">{matchedTip?.title}</p>
            <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(matchedTip?.text) }} />
          </div>
        </div>
      )}
    </div>
  );
};

GuidanceTip.propTypes = {
  /**
   * The id of the tip to display
   */
  tipId: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
  /**
   * Optional positioning of the Tooltip
   */
  toolTipPosition: PropTypes.oneOf(['left', 'right']),
};

GuidanceTip.defaultProps = {
  toolTipPosition: 'left',
};

export default GuidanceTip;

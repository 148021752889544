import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import SelectionListColumn from './SelectionListColumn/SelectionListColumn';
import './styles.scss';
// eslint-disable-next-line import/no-named-as-default
import SelectionFolders from '../../../Overview/SelectionFolders/SelectionFolders';
import { setSelectedFolderId } from '../../../../redux/actions/waterfallSelection/folderActions';
import { handleChangePaginationIndex } from '../../../../redux/actions/waterfallSelection/globalActions';
import Constants from '../../../../constants/constants';
import Features from '../../../../features';

const AvailableSelections = ({
  getNestedSelectionFolders,
  axiosCancelToken,
  handleSetAppState,
  folderSettings,
}) => {
  const dispatch = useDispatch();

  // get state of properties from folder reducer
  const {
    selectedFolderId, selectionFolders, loadingFolders, searchValue,
  } = useSelector(({ folderReducer, globalReducer }) => ({
    selectedFolderId: folderReducer.selectedFolderId,
    selectionFolders: folderReducer.selectionFolders,
    loadingFolders: folderReducer.loadingFolders,
    searchValue: globalReducer.searchValue,
  }), shallowEqual);

  const [hideFolders, setHideFolders] = useState(false);
  const [nestedFolders, setNestedFolders] = useState([]);

  useEffect(() => {
    // set nested folders for selections
    setNestedFolders(getNestedSelectionFolders(selectionFolders, '0'));
    // eslint-disable-next-line
  }, [selectionFolders]);

  /**
   * OnClick event handler for when a folder is clicked
   * @param {object|string} folder - clicked folder object or its Id
   * @returns {void}
   */
  const handleFolderClicked = (folder) => {
    // set selected folder
    dispatch(setSelectedFolderId(folder?._id || folder));

    // change to pagination on 1 and fetch all selections
    dispatch(handleChangePaginationIndex(null, 1, axiosCancelToken.token, folder?._id || folder, searchValue));
  };

  const featureSelectionFolders = Features.isFeatureEnabled(Constants.FEATURE__FOLDERS);

  return (
    <div className="available-selection">
      <div className="stickyAE">
        <h4 className="section-title">
          Available Selections
        </h4>
        <div className="available-selection-section">
          <SelectionFolders
            hideFolders={hideFolders}
            isWaterfall
            hideArchivedSelections
            openAllFolders
            nestedFolders={nestedFolders}
            folderId=""
            handleHideFolders={() => setHideFolders(!hideFolders)}
            handleFolderClicked={handleFolderClicked}
            filterFolderId={selectedFolderId}
            loadingFolders={loadingFolders}
            showLoadingModal={false}
            isReadOnly
            handleSetAppState={handleSetAppState}
            folderSettings={folderSettings}
            allFolders={selectionFolders}
            featureSelectionFolders={featureSelectionFolders}
          />
          <SelectionListColumn
            axiosCancelToken={axiosCancelToken.token}
          />
        </div>
      </div>
    </div>
  );
};

AvailableSelections.propTypes = {
  /**
   * connect parent+children folders
   */
  getNestedSelectionFolders: PropTypes.func.isRequired,
  /*
   * function for changing the state of the selection search field
   */
  axiosCancelToken: PropTypes.instanceOf(Object).isRequired,
  /**
   * Object with a saved folder settings
   */
  folderSettings: PropTypes.instanceOf(Object),
  /**
   * it sets the App component`s state
   * This prop will be passed from App.js component through Overview.js
   */
  handleSetAppState: PropTypes.func,
};

export default AvailableSelections;

import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import Constants from '../../../constants/constants';
import AdminHeader from '../../shared/AdminHeader/AdminHeader';
import Table from '../../shared/Table/Table';
import Util from '../../../util';
import './styles.scss';
import GlobalCustomValuesAPI from '../../../api/global-custom-values';
import SwalUtil from '../../../utils/swal/swalUtil';

const GlobalCustomValues = ({
  globalCustomValues = [],
  handleSetAdminPanelState,
  isLoading,
  handleSort,
  sortedProperty,
  sortDirection,
  openPanel,
}) => {
  const userDateTimeFormat = Util.getUserDateTimeFormat();

  // data for table header
  const tableHeadersData = [
    {
      title: 'Name',
      propertyName: 'name',
      sortIconId: 'nameSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__SMALL,
    },
    {
      title: 'Last Modified Date',
      propertyName: 'updatedAt',
      sortIconId: 'updatedAtSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Action',
    },
  ];

  /**
   * Deletes the provided Shared Custom Value
   * @param {Object} event - onClick event
   * @param {Object} globalCustomValue - Shared Custom Value to delete
   * @returns {void}
   */
  const handleRemove = async (event, globalCustomValue) => {
    event.preventDefault();

    const result = await SwalUtil.fire({
      type: 'warning',
      title: 'Remove Shared Custom Value',
      messageHTML: `<p class="width_swal">
      Are you sure you want to remove &apos;<strong>${globalCustomValue?.name}</strong>&apos;?
      </p>`,
      options: {
        showCancelButton: true,
        confirmButtonText: 'Remove',
        footer: '<div></div>',
        buttonsStyling: false,
      },
    });

    if (result.value) {
      // Delete filterSet
      await GlobalCustomValuesAPI.deleteGlobalCustomValue(globalCustomValue?._id, axios.CancelToken.source().token);

      // Reload data
      openPanel(Constants.ADMIN_PANEL__MENU__GLOBAL_CUSTOM_VALUES__PANEL);
    }
  };

  const handleSelect = (e, customValue) => {
    handleSetAdminPanelState({
      activePanel: Constants.ADMIN_PANEL__MENU__GLOBAL_CUSTOM_VALUE,
      selectedCustomValue: customValue,
    });
  };

  const renderTable = () => {
    if (!globalCustomValues?.length && !isLoading) {
      return (
        <tr className="row-data">
          <td
            colSpan="5"
            className="slds-text-align_center"
          >
            <div id="no-picklists-defined">
              No Shared Custom Values have been defined yet.
               Hit the &apos;New Shared Custom Value&apos; button to get started.
            </div>
          </td>
        </tr>
      );
    }

    if (globalCustomValues?.length && !isLoading) {
      return (globalCustomValues.map(globalCustomValue => (
        <tr key={globalCustomValue._id} className="slds-hint-parent row-data">
          <td data-label="GlobalCustomValue">
            <div className="slds-truncate">
              <a
                href="#!"
                title={globalCustomValue.name}
                onClick={async e => handleSelect(e, globalCustomValue)}
              >
                {globalCustomValue.name}
              </a>
            </div>
          </td>
          <td data-label="LastModifiedDate">
            <div className="slds-truncate slds-m-left_x-small">
              {globalCustomValue.updatedAt ? Util.formatDate(globalCustomValue.updatedAt, userDateTimeFormat) : null}
            </div>
          </td>
          <td data-label="Action">
            <div className="slds-truncate slds-text-align_left">
              <button
                type="button"
                className="slds-button slds-button_icon slds-button_icon-border-filled"
                title="Remove"
                onClick={async e => handleRemove(e, globalCustomValue)}
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#delete" />
                </svg>
              </button>
            </div>
          </td>
        </tr>
      )));
    }

    return false;
  };

  return (
    <>
      {/* Picklists header */}
      <AdminHeader
        title="Shared Custom Values"
        buttonId="new-global-custom-value"
        onClick={handleSelect}
        buttonLabel="New Shared Custom Value"
        iconLink="/assets/icons/standard-sprite/svg/symbols.svg#picklist_type"
      />
      <Table
        isLoading={isLoading}
        tableHeaders={tableHeadersData}
        bodyContent={renderTable()}
        id="picklists-panel"
        handleSort={handleSort}
        sortedProperty={sortedProperty}
        sortDirection={sortDirection}
      />
    </>
  );
};

GlobalCustomValues.propTypes = {
  /**
   * Array containing defined Shared Custom Values
   */
  globalCustomValues: PropTypes.instanceOf(Array),
  /**
   * Variable that holds whether the panel state is loading or not
   */
  isLoading: PropTypes.bool,
  /**
   * Function that handles sorting of columns
   */
  handleSort: PropTypes.func.isRequired,
  /**
   * Function that updates the state in admin panel
   */
  handleSetAdminPanelState: PropTypes.func.isRequired,
  /**
   * Function to open a certain panel
   */
  openPanel: PropTypes.func.isRequired,
  /**
   * Variable that indicates which is the property that sorts the data in the panel
   */
  sortedProperty: PropTypes.string,
  /**
   * Variable that indicates how are the data sorted in the panel
   */
  sortDirection: PropTypes.string,
};

export default GlobalCustomValues;

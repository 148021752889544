import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
// import * as serviceWorker from "./serviceWorker";
import { unregister } from './serviceWorker';
import App from './App';
import ErrorBoundary from './ErrorBoundary';

unregister();

ReactDOM.render(
  <ErrorBoundary>
      <App />
  </ErrorBoundary>,
  document.getElementById('root') || document.createElement('div'),
);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: http://bit.ly/CRA-PWA
 * serviceWorker.unregister();
 */


import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import Swal from 'sweetalert2';
import classNames from 'classnames';
import _ from 'lodash';

import 'react-datepicker/dist/react-datepicker.css';
import AuditLogAPI from '../../../api/audit-logs';
import Constants from '../../../constants/constants';
import Util from '../../../util';
import timeUtil from '../../../utils/time/timeUtil';
import setIntervalAsync from './useIntervalAsync';
import Table from '../../shared/Table/Table';
import AdminHeader from '../../shared/AdminHeader/AdminHeader';
import Button from '../../shared/Button/Button';
import Tooltip from '../../shared/Tooltip/Tooltip';

import './styles.scss';

function AuditLogs() {
  const axiosCancelToken = axios.CancelToken.source();
  const DATEFORMAT = timeUtil.getUserDateTimeFormat();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedAuditLogs, setGeneratedAuditLogs] = useState([]);

  // Custom datepicker header year and month properties
  const minimumYear = moment().subtract(1, 'years').year();
  const years = timeUtil.returnYears(minimumYear);
  const months = timeUtil.returnMonthsOfYear();

  const userLocale = timeUtil.getUserLocale();
  // note: the datetime format here is different from what util.getUserDateTimeFormat returns
  const datePickerDateFormat = timeUtil.getDatePickerDateFormat(userLocale);

  // Sorting
  const [sortingData, setSortingData] = useState({
    sortedProperty: 'retrievalDate',
    sortDirection: Constants.SORT_DIRECTION__DESCENDING,
  });

  /**
   * Sort the audit logs based on the defined sort direction
   * @param {Array<Object>} logs - Audit logs array
   * @param {String} direction - Sort direction
   * @returns {Array} - Sorted audit logs array
   */
  const sortAuditLogs = (logs, direction) => {
    const order = direction === Constants.SORT_DIRECTION__ASCENDING ? 'asc' : 'desc';

    return _.orderBy(logs, ['createdAt'], [order]);
  };

  // Sort logs whenever sort direction changes
  useEffect(() => {
    setGeneratedAuditLogs(prevState => sortAuditLogs(prevState, sortingData.sortDirection));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingData.sortDirection]);

  const loggedInUser = Util.userInfo();

  /**
   * Calculate maximum start date
   * @returns {Date} - The maximum start date that can be selected in the datepicker
   */
  const maxStartDate = () => {
    const dayBeforeEndDate = moment(endDate).subtract(1, 'days').toDate();
    const dateToday = moment().toDate();

    if (endDate) {
      return dayBeforeEndDate;
    }

    return dateToday;
  };

  /**
   * Calculate minimum start date
   * @returns {Date} - The minimum start date that can be selected in the datepicker
   */
  const minStartDate = () => {
    const yearBeforeEndDate = moment(endDate).subtract(1, 'years').toDate();
    const yearBeforeToday = moment().subtract(1, 'years').toDate();

    const minYearIsOutOfRange = moment(yearBeforeEndDate).isBefore(moment().toDate());

    if (endDate && !minYearIsOutOfRange) {
      return yearBeforeEndDate;
    }

    return yearBeforeToday;
  };

  /**
   * Calculate maximum end date
   * @returns {Date} - The maximum end date that can be selected in the datepicker
   */
  const maxEndDate = () => {
    const yearFromNow = moment(startDate).add(1, 'years').toDate();
    const dateToday = moment().toDate();

    const maxYearIsGreaterThanToday = moment(yearFromNow).isAfter(dateToday);

    if (startDate && !maxYearIsGreaterThanToday) {
      return yearFromNow;
    }

    return dateToday;
  };

  /**
   * Calculate minimum end date
   * @returns {Date} - The minimum end date that can be selected in the datepicker
   */
  const minEndDate = () => {
    const yearBeforeToday = moment().subtract(1, 'years').toDate();

    if (startDate) {
      return startDate;
    }

    return yearBeforeToday;
  };

  /**
   * @param {date} date  date to be formatted
   * @returns {date}  formatted date to be displayed in the date picker
   */
  const formatDate = (date) => {
    // assign a date only if it is selected
    if (date) {
      return moment(date)
        .add(moment(date).utcOffset(), 'm')
        .utc()._d;
    }

    return null;
  };

  /**
   * @param {string} typeOfSwal - type of swal
   * @param {string} titleOfSwal - title of swal
   * @param {string} message - message of swal
   * @returns {object} - return swal
   */
  const throwSwal = async (typeOfSwal, titleOfSwal, message) => {
    return Swal.fire({
      type: typeOfSwal,
      title: `<div class="${classNames({
        'error-title': typeOfSwal === Constants.SWAL__TYPE__ERROR,
      })}">${titleOfSwal}</div>`,
      html: `<p class="width_swal">${message}</p>`,
      showCancelButton: false,
      confirmButtonText: 'OK',
      footer: '<div></div>',
      buttonsStyling: false,
      allowOutsideClick: false,
    });
  };

  /**
   * Picks date from date picker and formats it to be sent to the API
   * Validation is done here to ensure that the date is not in the future
   * @param {Date} date - start date to be formatted
   * @returns {void}
   */
  const handleStartDateChange = (date) => {
    const selectedStartDate = formatDate(date);

    setStartDate(selectedStartDate);
  };

  /**
   * Handle sorting of audit logs
   * @returns {void}
   */
  const handleSortAuditLogs = () => {
    if(sortingData.sortDirection === Constants.SORT_DIRECTION__DESCENDING) {
      setSortingData(prevState => ({ ...prevState, sortDirection: Constants.SORT_DIRECTION__ASCENDING }));
    } else {
      setSortingData(prevState => ({ ...prevState, sortDirection: Constants.SORT_DIRECTION__DESCENDING }));
    }
  };

  /**
   * Picks date from date picker and formats it to be sent to the API
   * Validations are done here to ensure that the end date is not before the start date
   * @param {Date} date - end date to be formatted
   * @returns {void}
   */
  const handleEndDateChange = (date) => {
    const selectedEndDate = formatDate(date);

    setEndDate(selectedEndDate);
  };

  /**
   * Triggers the generation of the audit log CSV
   * @returns {void}
   */
  const receiveAuditLogs = async () => {
    const startDateFormatted = moment(startDate).format(Constants.DATE_FORMAT);
    const endDateFormatted = moment(endDate).format(Constants.DATE_FORMAT);

    const postData = {
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      userId: loggedInUser.id,
      orgId: loggedInUser.orgId,
      businessUnitId: loggedInUser.loggedInBusinessUnitId,
    };

    try {
      await AuditLogAPI.generateAuditLogCSV(postData, axiosCancelToken.token);
      throwSwal(
        'info',
        'Retrieving Audit Logs',
        `The Audit Logs are being retrieved. A notification will be sent to your email once
        they are ready for download.`,
      );
      setStartDate(null);
      setEndDate(null);
    } catch (error) {
      if (!axios.isCancel(error)) throwSwal('error', 'Error', error);
      setStartDate(null);
      setEndDate(null);
    }
  };

  /**
   * Returns formatted date range for the requested audit logs
   * @param {Date} startDate - start date to be formatted
   * @param {Date} endDate - end date to be formatted
   * @returns {String}  returns formatted range from start date to end date
   */
  const getDateRange = (startDate, endDate) => moment(startDate).format('ll') +
    ' - ' + moment(endDate).format('ll');

  const tableData = generatedAuditLogs.map(auditLog => (
    <tr key={auditLog._id} className="slds-hint-parent users_row">
      <td data-label="Date Requested">
        <div className="slds-truncate">{timeUtil.formatDate(auditLog.createdAt, DATEFORMAT)}</div>
      </td>
      <td data-label="Date Range">
        <div className="slds-truncate slds-m-left_x-small">{getDateRange(auditLog.startDate, auditLog.endDate)}</div>
      </td>
      <td data-label="Link">
        <div className="slds-truncate slds-text-align_left slds-m-left_small">
          <a href={auditLog.fileUrl} target="_blank" rel="noopener noreferrer">
            Download
          </a>
        </div>
      </td>

    </tr>
  ));

  const tableHeadersData = [
    {
      title: 'Retrieval Date',
      propertyName: 'retrievalDate',
      sortIconId: 'retrievalDateSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Date Range of Audit Logs',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Download Link',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
  ];

  /**
   * Renders custom header for calendar date picker
   * @param {Date} param.date - date to be formatted
   * @param {Function} param.changeYear - function to change year
   * @param {Function} param.changeMonth - function to change month
   * @param {Function} param.decreaseMonth - function to decrease month
   * @param {Function} param.increaseMonth - function to increase month
   * @param {Boolean} param.prevMonthButtonDisabled - boolean to check if previous month button is disabled
   * @param {Boolean} param.nextMonthButtonDisabled - boolean to check if next month button is disabled
   * @returns {JSX} - returns date picker
   */
  const renderCalendarCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="custom-calendar-header">
      <div className="custom-calendar-header__month">
        <button
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          type="button">
          <svg
            className="slds-icon slds-icon-text-default slds-icon_x-small status-error"
            aria-hidden="true"
          >
            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#left" />
          </svg>
        </button>
        <select
          value={months[getMonth(date)]}
          onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
        >
          {months.map((option, key) => (
            <option key={key} value={option}>
              {option}
            </option>
          ))}
        </select>
        <button
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          type="button">
          <svg
            className="slds-icon slds-icon-text-default slds-icon_x-small status-error"
            aria-hidden="true"
          >
            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#right" />
          </svg>
        </button>
      </div>
      <div className="custom-calendar-header__year">
        <select
          value={getYear(date)}
          onChange={({ target: { value } }) => changeYear(value)}
        >
          {years.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  const updateState = useCallback(async () => {
    const postData = {
      userId: loggedInUser.id,
      orgId: loggedInUser.orgId,
      businessUnitId: loggedInUser.loggedInBusinessUnitId,
    };

    const validLogs = await AuditLogAPI.getGeneratedCSVLogs(postData, axiosCancelToken.token);

    setGeneratedAuditLogs(sortAuditLogs(validLogs?.generatedCSVLogs, sortingData.sortDirection));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingData.sortDirection]);

  setIntervalAsync(updateState, 5000);

  return (
    <>
      <AdminHeader
        title="Audit Logs"
        iconLink="/assets/icons/standard-sprite/svg/symbols.svg#user"
        iconTitle="Audit Logs Menu"
      />
      <div className="audit-logs">
        <div className="audit-logs__title">
          <h3 className="settings-title">Retrieve Audit Logs</h3>
          <div className="info-text">
            As an admin, you can view the Audit Logs for your current Business Unit
            {' '}
            <span className="bold-text">{loggedInUser?.loggedInBusinessUnitId}</span>
            .
            Choose a date range below and click on the
            {' '}
            <span className="bold-text">Retrieve Audit Logs</span>
            {' '}
            button to generate a link to download a CSV of the Audit Logs.
            {' '}
            <p>
              Once the CSV is ready for download, you will receive a notification in your email
              <span className="bold-text">
                {' '}
                {loggedInUser?.email}
                .
              </span>
            </p>
          </div>
        </div>

        <div className="audit-logs__actions">
          <div className="audit-logs__actions_header">
            <span>Choose Date Range</span>
            <Tooltip
              nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
              tooltipText="You can only view Audit Logs for a maximum range of 1 year from today.
              Date and time are in UTC."
            />
          </div>
          <div className="date-range">
            <div className="date-range__actions date-range__from">
              <p className="range-title">Start Date</p>
              <DatePicker
                className="datepicker-filterline"
                id="startDate"
                type="text"
                dateFormat={datePickerDateFormat}
                selected={startDate}
                onChange={date => handleStartDateChange(date)}
                onKeyDown={e => Util.preventTextInputOnDatePicker(e)}
                maxDate={maxStartDate()}
                autoComplete="off"
                selectsStart
                startDate={startDate}
                endDate={endDate}
                minDate={minStartDate()}
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => renderCalendarCustomHeader({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                })}
              />
            </div>
            <div className="date-range__actions date-range__to">
              <p className="range-title">End Date</p>
              <DatePicker
                className="datepicker-filterline"
                id="endDate"
                type="text"
                dateFormat={datePickerDateFormat}
                selected={endDate}
                onChange={date => handleEndDateChange(date)}
                onKeyDown={e => Util.preventTextInputOnDatePicker(e)}
                maxDate={maxEndDate()}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={minEndDate()}
                autoComplete="off"
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => renderCalendarCustomHeader({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                })}
              />
            </div>
            <div className="audit-logs__actions_button">
              <Button
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                className="receive-logs-button"
                id="receiveAuditLogs"
                title="Receive Audit Logs"
                onClick={receiveAuditLogs}
                disabled={!startDate || !endDate}
              >
                Retrieve Audit Logs
              </Button>
            </div>
          </div>
        </div>

      { generatedAuditLogs && (
          <div className="audit-logs__generated">
            <div className="audit-logs__generated_header">
              <span>Generated Audit Logs</span>
              <Tooltip
                nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                tooltipText="Audit logs are only available for download for 7 days, after which
                they are automatically deleted from this list."
              />
            </div>

            <Table
              tableHeaders={tableHeadersData}
              bodyContent={tableData}
              sortedProperty={sortingData.sortedProperty}
              sortDirection={sortingData.sortDirection}
              handleSort={handleSortAuditLogs}
              id="picklists-panel"
              className="contact-relations-table"
            />
            {
              generatedAuditLogs.length === 0 && (
                <div className="audit-logs__generated_list_item">
                  <p className="audit-logs__generated_list_item_empty">
                    No Audit Logs available yet. You will be able to see Audit Logs here
                    once they are available for download.
                  </p>
                </div>
              )
            }
          </div>
      )}
      </div>
    </>
  );
}

export default AuditLogs;

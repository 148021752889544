import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';

import Select from '../../../../shared/Select/Select';
import Constants from '../../../../../constants/constants';
import Input from '../../../../shared/Input/Input';
import timeUtil from '../../../../../utils/time/timeUtil';
import DropdownOptions from '../../../../../constants/dropdownOptions';
import Util from '../../../../../util';
import Alert from '../../../../shared/Alert/Alert';

/**
 * @returns {void}
 */
const FixedValueModal = ({
  fixedValueData,
  handleSetCustomValuesState,
  disabled,
}) => {
  const userLocale = timeUtil.getUserLocale();

  /**
   * This function helps with the date value for date picker
   * @param {object} e - event
   * @returns {void}
   */
  const handlePeriodDateChange = (e) => {
    let newDate;

    // if user clears date input field, event e will be null so set the date to the current date
    if (e === null) {
      // Set date value
      const date = timeUtil.formatDateForDatePicker();

      newDate = moment(date).set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      })._d;
    } else {
      newDate = timeUtil.formatDateForDatePicker(e)._d;
    }

    handleSetCustomValuesState({ fixedValueData: { ...fixedValueData, value: newDate } });
  };

  const handleFieldTypeChange = (e) => {
    const defaultValue = e.target.value === Constants.FILTERLINE__FIELDTYPE__DATE ?
      moment(timeUtil.formatDateForDatePicker()).set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      })._d :
      Util.getDefaultValueByFieldType(e.target.value);

    handleSetCustomValuesState({ fixedValueData: { fieldType: e.target.value, value: defaultValue } });
  };

  /**
   * This function helps with changes to the value
   * @param {object} value - New value
   * @returns {void}
   */
  const handleChange = (value) => {
    handleSetCustomValuesState({
      fixedValueData: { ...fixedValueData, value },
    });
  };

  /**
   * This function helps with changes in number input
   * @param {object} e - event
   * @returns {void}
   */
  const handleNumberInputChange = (e) => {
    if (fixedValueData.fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER) {
      if (!/^[0-9]*$/.test(e.target.value)) {
        return;
      }
    }
    handleChange(e.target.value);
  };

  return (
    <div className="custom-values-input">
      <div
        id="field-type-selection"
        className="field fixed-value"
      >
        <div className="item-label"><b>Field Type:</b></div>
        <Select
          containerClassName="custom-values-select"
          id="select-field-type"
          onChange={e => handleFieldTypeChange(e)}
          value={fixedValueData.fieldType}
          options={DropdownOptions.FIELD_TYPE__OPTIONS}
          disabled={disabled}
        />
      </div>
      <br />
      <div className="field fixed-value">
        <div className="item-label"><b>Value:</b></div>
        {fixedValueData.fieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN ?
          (
            <Select
              containerClassName="custom-values-select boolean-filter-value"
              id="select-boolean"
              onChange={e => handleChange(e.target.value)}
              value={fixedValueData.value}
              options={DropdownOptions.BOOLEAN__OPTIONS}
              disabled={disabled}
            />
          ) :
          null}
        {fixedValueData.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE ?
          (
            <DatePicker
              fixedHeight
              popperPlacement="top-start"
              className="form-control custom-value-date"
              name="customValueDate"
              type="text"
              dateFormat={timeUtil.getDatePickerDateTimeFormat(userLocale)}
              timeFormat="HH:mm"
              showTimeInput
              selected={timeUtil.formatDatePickerSelectedDate(fixedValueData.value)}
              onChange={e => handlePeriodDateChange(e)}
              data-placement="top-start"
              disabled={disabled}
            />
          ) :
          null}
        {fixedValueData.fieldType !== Constants.FILTERLINE__FIELDTYPE__BOOLEAN &&
          fixedValueData.fieldType !== Constants.FILTERLINE__FIELDTYPE__DATE ?
          (
            <div className="field-value">
              <Input
                name="value"
                type={fixedValueData.fieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL ?
                  Constants.FILTERLINE__FIELDTYPE__NUMBER :
                  fixedValueData.fieldType}
                pattern={fixedValueData.fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ?
                  '[0-9]*' :
                  null}
                inputMode={fixedValueData.fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ?
                  'numeric' :
                  null}
                id="field-value-text"
                min="1"
                max="2550"
                className="custom-values-radio"
                placeholder="Enter a value here"
                disabled={disabled}
                value={fixedValueData.value || ''}
                onChange={e => handleNumberInputChange(e)}
              />
              {(fixedValueData.fieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS &&
              !Util.validateEmail(fixedValueData.value)) &&
                <Alert
                  id="invalid email"
                  title="Invalid Email Address"
                  className="invalid-email"
                />}
            </div>
          ) :
          null}
      </div>
    </div>
  );
};

FixedValueModal.propTypes = {
  /**
   * Fixed custom value data
   */
  fixedValueData: PropTypes.instanceOf(Object),
  /**
   * Function that handles changes in data.
   */
  handleSetCustomValuesState: PropTypes.func,
  /**
   * If all inputs should be disabled or not.
   */
  disabled: PropTypes.bool,
};

export default FixedValueModal;

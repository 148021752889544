import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Constants from '../../../constants/constants';
import Util from '../../../util';
import AdminHeader from '../../shared/AdminHeader/AdminHeader';
import Table from '../../shared/Table/Table';
import SwalUtil from '../../../utils/swal/swalUtil';
import timeUtil from '../../../utils/time/timeUtil';
import './styles.scss';
import UserPanel from './UserPanel/UserPanel';

const Users = ({
  usersList,
  licensesInfo,
  handleSetAdminPanelState,
  handleUpdateAdminPanelState,
  isLoading,
}) => {
  const DATEFORMAT = timeUtil.getUserDateTimeFormat();

  // create a copy of usersList
  const userPrevList = [...usersList];

  const [openPanelID, setOpenPanelID] = useState(null);
  const [userListCopy, setUserListCopy] = useState(userPrevList);

  /**
   * Open userPanel, sets the ID of the open panel
   * @param {string} id - id of the user
   * @returns {void}
   */
  const handleClick = (id) => {
    setOpenPanelID(id);
  };

  /**
   * Close userPanel
   * @returns {void}
   */
  const closePanel = () => {
    // returns the previous state of the userList
    setUserListCopy(userPrevList);

    // close the panel
    setOpenPanelID(null);
  };

  /**
   * Updates the list of users after clicking on the checkbox
   * @param {string} id - id of the user
   * @param {string} isAdminOrActive - parameter to change - isActive or isAdmin
   * @returns {void}
   */
  const handleCheckboxChange = (id, isAdminOrActive) => {
    // create newUserList with changed parameter
    const newUserList = userListCopy.map((ul) => {
      /**
       * search for user by id and check if the selected user id is not equal to the logged in admin id
       * logged in admin user can't change own isAdmin and isActive values
       */
      if (ul._id === id && id !== Util.loggedInUserId()) {
        // if the passed parameter is isAdmin, change its value to the opposite
        if (isAdminOrActive === 'isAdmin') {
          return {
            ...ul,
            isAdmin: !ul.isAdmin,
          };
        } if (isAdminOrActive === 'isActive') {
          // if the passed parameter is isActive, change its value to the opposite
          return {
            ...ul,
            isActive: !ul.isActive,
          };
        }
      }

      return ul;
    });

    // change the user list state to a new list
    setUserListCopy(newUserList);
  };

  /**
   * Saves the list of users after clicking on the save button
   * @param {object} user - user data object
   * @returns {void}
   */
  const handleUserPanelSave = async (user) => {
    try {
      // update the user in UsersAPI
      await handleUpdateAdminPanelState(user);

      // set new user list state in PanelAdmin
      handleSetAdminPanelState({ usersList: userListCopy });
    } catch (error) {
      if (error?.request?.status === 422) {
        const errorMessage = 'There are no more DESelect Segment licenses available ' +
        'for activating this user.<br>Please deactivate other users, or' +
        ' <a href="https://deselect.com/contact/" target="_blank">contact your DESelect Account Executive</a>' +
        ' to purchase additional licenses.';

        SwalUtil.fire({
          title: 'Out of licenses',
          message: errorMessage,
          options: {
            showCancelButton: false,
            confirmButtonText: 'OK',
          },
        });
      } else {
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Error',
          message: error,
          options: {
            showCancelButton: false,
            confirmButtonText: 'OK',
          },
        });
      }
      // set previous state of users list
      setUserListCopy(userPrevList);
    }
    // close the panel
    setOpenPanelID(null);
  };

  const tableData = usersList.map(user => (
    <tr key={user._id} className="slds-hint-parent users_row">
      <td data-label="Full name">
        <div className="slds-truncate fullName" onClick={() => handleClick(user._id)}>{user.name}</div>
      </td>
      <td data-label="Username">
        <div className="slds-truncate slds-m-left_x-small">{user.username}</div>
      </td>
      <td data-label="Administrator">
        <div className="slds-truncate slds-text-align_left slds-m-left_small">
          {user.isAdmin ?
            <i className="far fa-check-square" /> :
            <i className="far fa-square" />}
        </div>
      </td>
      <td data-label="Active">
        <div className="slds-truncate slds-text-align_left slds-m-left_small">
          {user.isActive ?
            <i className="far fa-check-square" /> :
            <i className="far fa-square" />}
        </div>
      </td>
      <td data-label="Last Active">
        {timeUtil.formatDate(user.lastActive, DATEFORMAT)}
      </td>
    </tr>
  ));

  /**
   * Renders HTML object with information about purchased licenses
   * @returns {object} HTML Object
   */
  const renderPurchasedLicenses = () => licensesInfo?.purchased > 0 && (
    <div className="user-licenses">
      <h6>
        Licenses purchased:
        {' '}
        <span className="user-licenses-purchased">
          {licensesInfo?.purchased}
        </span>
      </h6>
      <h6>
        Licenses in use:
        {' '}
        <span className="user-licenses-in-use">
          {licensesInfo?.inUse}
        </span>
      </h6>
    </div>
  );

  // data for table header
  const tableHeadersData = [
    {
      title: 'Full Name',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__SMALL,
    },
    {
      title: 'Username',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Administrator',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Active',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Last Active',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
  ];

  return (
    <>
      <AdminHeader
        title="Users"
        headerRightContent={renderPurchasedLicenses()}
        iconLink="/assets/icons/standard-sprite/svg/symbols.svg#user"
        iconTitle="Users Menu"
      />
      <Table
        tableHeaders={tableHeadersData}
        className="usersTable"
        bodyContent={tableData}
        tableClassName="slds-table_fixed-layout"
        rowClassName="userPanel"
        isLoading={isLoading}
        >
          {userListCopy && (
          <UserPanel
            user={userListCopy.find(user => user._id === openPanelID)}
            openPanelID={openPanelID}
            closePanel={closePanel}
            handleCheckboxChange={handleCheckboxChange}
            handleUserPanelSave={handleUserPanelSave}
          />
          )}
      </Table>
    </>
  );
};

Users.propTypes = {
  /**
   * The list of users in AdminPanel
   */
  usersList: PropTypes.instanceOf(Array).isRequired,
  /**
   * Number of purchased licenses and number of licenses in use in AdminPanel
   */
  licensesInfo: PropTypes.instanceOf(Object).isRequired,
  /**
   * changes state in AdminPanel
   */
  handleSetAdminPanelState: PropTypes.func.isRequired,
  /**
   * changes and updates user data
   */
  handleUpdateAdminPanelState: PropTypes.func.isRequired,
  /**
   * Responsible for showing/hiding loader
   */
  isLoading: PropTypes.bool,
};

export default Users;

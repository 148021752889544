import axios from 'axios';

import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const SelectionFoldersAPI = {
  /**
   * Get all selection folders
   * @param {object} cancelToken - Axios token
   * @param {boolean} isWaterfall - defines whether the folder type is waterfall selection
   * @returns {array<object>} Array of selection folders
   */
  getSelectionFolders: async (cancelToken, isWaterfall) => {
    // define request URL depending on the type of folder
    const requestUrl = `${apiUrl}/selection-folders/?folderType=${isWaterfall ?
      'waterfallSelection' :
      'selection'}`;

    const res = await Util.catch418And403Error(
      axios.get,
      requestUrl,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data;
  },
  /**
   * Create new selection folder
   * @param {object} cancelToken - Axios token
   * @param {object} name - Name of selection folder
   * @param {object} parentFolderId - ParentFolderId of selection folder
   * @param {boolean} isWaterfall - defines whether the folder type is waterfall selection
   * @returns {object} Object of newly created folder
   */
  createSelectionFolder: async (cancelToken, name, parentFolderId, isWaterfall) => {
    // define request URL depending on the type of folder
    const requestUrl = `${apiUrl}/selection-folders/?folderType=${isWaterfall ?
      'waterfallSelection' :
      'selection'}`;

    const res = await Util.catch418And403Error(
      axios.post,
      requestUrl,
      { name, parentFolderId },
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },
  /**
   * Update selection folder
   * @param {object} cancelToken - Axios token
   * @param {string} folderId - Id of folder
   * @param {object} name - Name of selection folder
   * @param {object} parentFolderId - ParentFolderId of selection folder
   * @returns {object} Object of updated folder
   */
  updateSelectionFolder: async (cancelToken, folderId, name, parentFolderId) => {
    const res = await Util.catch418And403Error(
      axios.put,
      `${apiUrl}/selection-folders/${folderId}`,
      { name, parentFolderId },
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },
  /**
   * Delete selection folder
   * @param {object} cancelToken - Axios token
   * @param {string} folderId - Id of folder
   * @returns {object} Object of deleted folder
   */
  deleteSelectionFolder: async (cancelToken, folderId) => {
    const res = await Util.catch418And403Error(
      axios.post,
      `${apiUrl}/selection-folders/${folderId}/delete`,
      {},
      Util.apiPostCallSettings(cancelToken),
    );

    return res.data;
  },
};

export default SelectionFoldersAPI;

import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import {
  arrayMove,
} from '@dnd-kit/sortable';
import moment from 'moment';

import SortableCriteriaContainer from '../SortableCriteria/SortableCriteriaContainer';
import InputTypeSelect from '../InputTypeSelect';
import Select from '../../../../shared/Select/Select';
import DropdownOptions from '../../../../../constants/dropdownOptions';
import Alert from '../../../../shared/Alert/Alert';
import Constants from '../../../../../constants/constants';
import Button from '../../../../shared/Button/Button';
import filtersUtil from '../../../../../utils/filters/filtersUtil';
import timeUtil from '../../../../../utils/time/timeUtil';
import Util from '../../../../../util';
import SwalUtil from '../../../../../utils/swal/swalUtil';
import Input from '../../../../shared/Input/Input';
import Checkbox from '../../../../shared/Checkbox/Checkbox';
import DynamicValuesCriteriaModal from './DynamicValuesCriteriaModal';
import './styles.scss';

const DynamicValueModal = ({
  selectedDataExtensions,
  noAvailableFieldsRef,
  showAllNullErrorRef,
  dynamicValueData,
  handleSetCustomValuesState,
  handleSetSelectionState,
  pickLists,
  showNoAvailableFieldsError,
  dynamicCustomValuesFilterID,
  dynamicCustomValuesFilters,
  getDataExtensionOrDataViewFields,
  DEBorderMouseOver,
  filterBorderMouseOver,
  closeCriteriaModal,
  handleFiltersSave,
  handleSetDynamicValueFilterState,
  handlePickListOptions,
  handleRemoveFilterLine,
  applyTimezoneSettingsToAllDateFields,
  handleSetTimezoneToAllDateFields,
  timezoneSettingsForAllDateFields,
  criteriaModal,
  disabled,
}) => {
  const userLocale = timeUtil.getUserLocale();
  const nullValues = dynamicValueData?.criteria?.map(criterion => criterion?.then === null);
  const isDefaultValueNull = dynamicValueData?.defaultValue?.defaultValue === null;
  const thenValues = dynamicValueData?.criteria?.map(criterion => criterion?.then?.dataExtensionObjectId);
  const filters = dynamicValueData?.criteria?.map(criterion => criterion.when) || [];
  const criteriaText =
    filters?.map(filter => filtersUtil.getFilterTextForFilters(filter?.filters, filter?.operator, true, pickLists));
  const selectDefaultValue = !!(dynamicValueData?.defaultValue?.defaultDataExtensionObjectId?.length);
  const showInvalidEmailMessage = [];

  /**
   * This function helps to get fields from picked selected DE
   * @param {object} selectedDEObjectIdOrAlias - Name of the selected DE we want get fields from
   * @param {string} fieldType - Picked dynamic field type
   * @returns {array} fields - array with available fields for certain field type
   */
  const getSelectedDEFields = (selectedDEObjectIdOrAlias, fieldType) => {
    const fields = [];

    // get all available fields
    if (selectedDataExtensions && selectedDataExtensions.length > 0) {
      selectedDataExtensions.forEach((de) => {
        /*
         * check if selected DE or dataview contains fields then add fields into
         * fields array (deAlias check for dataview)
         */
        if ((selectedDEObjectIdOrAlias === de.ObjectID || selectedDEObjectIdOrAlias === de.deAlias) &&
          de.fields && de.fields.length > 0) {
          for (let i = 0; i < de.fields.length; i += 1) {
            if (de.fields[i].FieldType === fieldType ||
              (de.fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS &&
                fieldType === Constants.FILTERLINE__FIELDTYPE__TEXT)) {
              fields.push(de.fields[i]);
            }
          }
        }
      });
    }

    // returns distinct values when having same multiple DE with same fields
    return fields.filter((v, i, a) => a.findIndex(t => (t.ObjectID === v.ObjectID)) === i);
  };

  /**
   * This function helps to set Then clause (dynamic type) on blur
   * @param {object} e - event
   * @param {number} index - index of the criteria we want to change
   * @returns {void}
   */
  const handleBlur = (e, index) => {
    // if target field value is empty then populate default value otherwise set value
    if (e.target.value === '') {
      if (index > -1) {
        if (e.target.id === `${index}`) {
          const criteriaValuesCopy = [...(dynamicValueData?.criteria || [])];

          criteriaValuesCopy[index] = { when: filters[index], then: '0' };
          handleSetCustomValuesState({
            dynamicValueData: { ...dynamicValueData, criteria: criteriaValuesCopy },
          });
        }
      } else {
        handleSetCustomValuesState({ value: e.target.value });
      }
    }
  };

  /**
   * This function helps with changing default value
   * @param {object} e - event
   * @returns {void}
   */
  const handleDefaultValueChange = (e) => {
    handleSetCustomValuesState({
      showNoAvailableFieldsError: false,
      dynamicValueData: {
        ...dynamicValueData,
        defaultValue: {
          ...dynamicValueData?.defaultValue,
          defaultValue: e.target.value,
        },
      },
    });
  };

  /**
   * Toggles the input type of a then or default criteria (from select to input and vice-versa)
   * @param {number} idx - Index of the criteria
   * @returns {void}
   */
  const toggleInputType = (idx) => {
    let valueToChange;

    let dynamicCriteriaValue;

    // If idx is -1, we're changing default criteria's input type
    if (idx === -1) {
      // Set value to change
      valueToChange = selectDefaultValue;
    } else {
      // We're changing a then criteria's input type at idx
      valueToChange = thenValues[idx];
      dynamicCriteriaValue = dynamicValueData?.criteria[idx];
    }

    let fieldObjectId = '';

    let dataExtensionObjectId = '';

    let dataExtensionAlias = '';

    let value = '';

    let selectedFields = [];

    // If the input type is a select
    if (valueToChange) {
      value = dynamicValueData?.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE ?
        moment(timeUtil.formatDateForDatePicker()).set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        })._d :
        Util.getDefaultValueByFieldType(dynamicValueData?.fieldType);
    } else {
      // If we're changing to a select element
      dataExtensionObjectId = selectedDataExtensions[0].ObjectID;
      dataExtensionAlias = selectedDataExtensions[0].deAlias;
      // get available fields
      selectedFields = getSelectedDEFields(dataExtensionObjectId, dynamicValueData?.fieldType);
      // if there is at least one fields set defaultFieldObjectId to that field
      if (selectedFields && selectedFields.length > 0) {
        fieldObjectId = selectedFields[0].ObjectID;
        // otherwise set fieldObjectId to the empty string
      } else {
        fieldObjectId = '';
      }
    }

    // If idx is -1, we're changing default criteria's input type
    if (idx === -1) {
      // Set state
      handleSetCustomValuesState({
        showNoAvailableFieldsError: false,
        selectDefaultValue: !selectDefaultValue,
        dynamicValueData: {
          ...dynamicValueData,
          defaultValue: {
            defaultValue: value,
            defaultDataExtensionObjectId: dataExtensionObjectId,
            defaultFieldObjectId: fieldObjectId,
            defaultDataExtensionAlias: dataExtensionAlias,
          },
        },
      });
    } else {
      // We're changing a then criteria's input type at idx

      // Update thenValues
      const updatedThenValues = [
        ...thenValues.slice(0, idx),
        !thenValues[idx],
        ...thenValues.slice(idx + 1),
      ];

      // Update DynamicCriteriaValues
      const dynamicValueDataCriteria = dynamicValueData?.criteria || [];
      const updatedDynamicCriteriaValues = [
        ...dynamicValueDataCriteria.slice(0, idx),
        {
          ...dynamicCriteriaValue,
          then: updatedThenValues[idx] ?
            {
              fieldObjectId,
              dataExtensionObjectId,
              dataExtensionAlias,
            } :
            value,
        },
        ...dynamicValueDataCriteria.slice(idx + 1),
      ];

      // Set state
      handleSetCustomValuesState({
        dynamicValueData: {
          ...dynamicValueData,
          criteria: updatedDynamicCriteriaValues,
        },
      });
    }
  };

  /**
   * Sets the default values of then criteria
   * @param {string} fieldType - The field type selected by the user
   * @param {object} dynamicCriteriaValue - The dynamicCriteriaValue object
   * @param {object} dynamicValuesFilter - The dynamicCriteriaValue object
   * @return {object} - The updated dynamicCriteriaValue
   */
  const setDefaultThenValue = (
    fieldType,
    dynamicCriteriaValue,
    dynamicValuesFilter,
  ) => {
    let dynamicCriteriaValueCopy = { ...dynamicCriteriaValue };
    // Set default value for dataExtensionObjectId and fieldObjectId

    // Get available fields
    const selectedFields = getSelectedDEFields(
      dynamicCriteriaValueCopy.then.dataExtensionObjectId,
      fieldType,
    );

    // if there is at least one field set defaultFieldObjectId to that fields ObjectID
    if (selectedFields?.length > 0) {
      dynamicCriteriaValueCopy = {
        then: {
          ...dynamicCriteriaValueCopy.then,
          fieldObjectId: selectedFields[0].ObjectID,
        },
        when: dynamicValuesFilter,
      };
    } else {
      dynamicCriteriaValueCopy = {
        then: {
          ...dynamicCriteriaValueCopy.then,
          fieldObjectId: '',
        },
        when: dynamicValuesFilter,
      };
    }

    return dynamicCriteriaValueCopy;
  };

  /**
   * This function helps to custom value field name, Then clause (dynamic type) or value (fixed type)
   * @param {object} e - event
   * @param {number} index - index of the criteria we want to change
   * @returns {void}
   */
  const handleChange = (e, index) => {
    let showInvalidEmailMessageCopy = [...showInvalidEmailMessage];

    // validate if an email address is in the right format
    if (dynamicValueData?.fieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS) {
      // if email is valid
      if (Util.validateEmail(e.target.value) || e.target.value.length === 0) {
        // filter the showInvalidEmailMessageCopy array to get Then index values where emails are invalid
        if (index > -1) {
          if (showInvalidEmailMessageCopy.includes(index)) {
            showInvalidEmailMessageCopy = showInvalidEmailMessageCopy.filter(item => item !== index);
          } // check for the Fixed email values
        } else {
          // throw out -1 if email is valid
          if (showInvalidEmailMessageCopy.includes(-1)) {
            showInvalidEmailMessageCopy.pop();
          }
        }
        // if email is not valid
      } else {
        if (index > -1) {
          // check if in the showInvalidEmailMessageCopy already exists index
          if (!showInvalidEmailMessageCopy.includes(index)) {
            // if the index doesn't exist push it into the showInvalidEmailMessageCopy array
            showInvalidEmailMessageCopy.push(index);
          }
        } else {
          /*
           * this is for Fixed email values
           * if -1 is not already in the showInvalidEmailMessageCopy array push it in
           */
          if (!showInvalidEmailMessageCopy.includes(-1)) {
            showInvalidEmailMessageCopy.push(-1);
          }
        }
      }
    }

    handleSetCustomValuesState({
      showInvalidEmailMessage: showInvalidEmailMessageCopy,
      showNoAvailableFieldsError: false,
    });

    if (e.target.id === `${index}`) {
      const criteriaValuesCopy = [...dynamicValueData?.criteria || []];

      criteriaValuesCopy[index] = { when: filters[index], then: e.target.value };
      handleSetCustomValuesState({
        dynamicValueData: {
          ...dynamicValueData,
          criteria: criteriaValuesCopy,
        },
      });
    }
  };

  /**
   * Function for changing field type
   * @param {object} e - event
   * @returns {void}
   */
  const handleOnChangeFieldType = (e) => {
    const valueDataCopy = JSON.parse(JSON.stringify(dynamicValueData));

    // when we change field type to the Boolean set corresponding values to the default boolean value
    if (e.target.value === Constants.FILTERLINE__FIELDTYPE__BOOLEAN) {
      const criteriaValuesCopy = [...(valueDataCopy?.criteria || [])];

      if (filters?.length) {
        for (let i = 0; i < filters.length; i += 1) {
          if (thenValues[i]) {
            // Set default then value
            criteriaValuesCopy[i] = setDefaultThenValue(
              e.target.value,
              criteriaValuesCopy[i],
              filters[i],
            );
          } else {
            // Set default boolean value if we're not showing a dropdown
            criteriaValuesCopy[i] = {
              then: Constants.FILTERLINE__VALUE__TRUE,
              when: filters[i],
            };
          }
        }
      }

      if (!selectDefaultValue) valueDataCopy.defaultValue.defaultValue = Constants.FILTERLINE__VALUE__TRUE;

      valueDataCopy.criteria = criteriaValuesCopy;

      // when we change field type to the Date set corresponding values to the current date
    } else if (e.target.value === Constants.FILTERLINE__FIELDTYPE__DATE) {
      // Set date value
      const dateValue = timeUtil.formatDateForDatePicker();

      const date = moment(dateValue).set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      })._d;

      const criteriaValuesCopy = [...(dynamicValueData?.criteria || [])];

      if (filters?.length) {
        for (let i = 0; i < filters.length; i += 1) {
          // If we're showing a dropdown
          if (thenValues[i]) {
            // Set default then value
            criteriaValuesCopy[i] = setDefaultThenValue(
              e.target.value,
              criteriaValuesCopy[i],
              filters[i],
            );
          } else {
            // Set default then value if we're not showing a dropdown
            criteriaValuesCopy[i] = {
              then: moment(date)._d,
              when: filters[i],
            };
          }
        }
      }
      if (!selectDefaultValue) valueDataCopy.defaultValue.defaultValue = moment(date)._d;

      valueDataCopy.criteria = criteriaValuesCopy;

      // when we change field type to the Number or Decimal set corresponding values default numeric value
    } else if (filtersUtil.isNumberOrDecimal(e.target.value)) {
      /*
       * set each dynamic criteria and its respected filters and set default to 0
       */
      const criteriaValuesCopy = [...(dynamicValueData?.criteria || [])];

      // check if dynamicValuesFilters is defined and has elements
      if (filters?.length) {
        for (let i = 0; i < filters.length; i += 1) {
          if (thenValues[i]) {
            // Set default then value
            criteriaValuesCopy[i] = setDefaultThenValue(
              e.target.value,
              criteriaValuesCopy[i],
              filters[i],
            );
          } else {
            // Set default then value if we're not showing a dropdown
            criteriaValuesCopy[i] = {
              then: '0',
              when: filters[i],
            };
          }
        }
      }

      // if no default value is defined then set default value to 0
      if (!selectDefaultValue) valueDataCopy.defaultValue.defaultValue = '0';

      valueDataCopy.criteria = criteriaValuesCopy;

      // otherwise change all corresponding values
    } else {
      const criteriaValuesCopy = [...(dynamicValueData?.criteria || [])];

      if (filters?.length) {
        for (let i = 0; i < filters.length; i += 1) {
          // If we're showing a dropdown
          if (thenValues[i]) {
            // Set default then value
            criteriaValuesCopy[i] = setDefaultThenValue(
              e.target.value,
              criteriaValuesCopy[i],
              filters[i],
            );
          } else {
            // Set default value if we're not showing a dropdown
            criteriaValuesCopy[i] = {
              then: '',
              when: filters[i],
            };
          }
        }
      }

      if (!selectDefaultValue) valueDataCopy.defaultValue.defaultValue = '';

      valueDataCopy.criteria = criteriaValuesCopy;
    }

    // if selectDefaultValue means that we can choose fields from dropdown
    if (selectDefaultValue) {
      // get available fields
      const selectedFields = getSelectedDEFields(
        dynamicValueData?.defaultValue?.defaultDataExtensionObjectId,
        e.target.value,
      );

      valueDataCopy.defaultValue.defaultFieldObjectId = selectedFields.length ?
        selectedFields[0].ObjectID :
        '';
    }

    valueDataCopy.fieldType = e.target.value;

    let showInvalidEmailMessageCopy = [...showInvalidEmailMessage];

    /*
     * reset showInvalidEmailMessage array and defaultValueInvalidEmail state
     * get only -1 value since there can still be an invalid email for Fixed value type
     */
    showInvalidEmailMessageCopy = showInvalidEmailMessage.filter(index => index === -1);

    handleSetCustomValuesState({
      showNoAvailableFieldsError: false,
      showInvalidEmailMessage: showInvalidEmailMessageCopy,
      dynamicValueData: valueDataCopy,
    });
  };

  /**
   * This function helps with the date value for date picker
   * @param {object} e - event
   * @param {Integer} index - index
   * @returns {void}
   */
  const handlePeriodDateChange = (e, index) => {
    let newDate;

    // if user clears date input field, event e will be null so set the date to the current date
    if (e === null) {
      // Set date value
      const date = timeUtil.formatDateForDatePicker();

      newDate = moment(date).set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      })._d;
    } else {
      newDate = timeUtil.formatDateForDatePicker(e)._d;
    }

    // if index is different then undefined means we are changing date for criteria (then)
    if (index === undefined) {
      handleSetCustomValuesState({
        dynamicValueData: {
          ...dynamicValueData,
          defaultValue: {
            ...dynamicValueData.defaultValue,
            defaultValue: newDate,
          },
        },
      });
    } else {
      const criteriaValuesCopy = [...(dynamicValueData?.criteria || [])];

      criteriaValuesCopy[index] = { when: filters[index], then: newDate };
      handleSetCustomValuesState({
        dynamicValueData: {
          ...dynamicValueData,
          criteria: criteriaValuesCopy,
        },
      });
    }
  };

  /**
   * This function helps with changing default value on blur
   * @param {object} e - event
   * @returns {void}
   */
  const handleDefaultValueBlur = (e) => {
    // if field is empty set default to '0'
    if (e.target.value === '') {
      handleSetCustomValuesState({
        dynamicValueData: {
          ...dynamicValueData,
          defaultValue: {
            ...dynamicValueData.defaultValue,
            defaultValue: '0',
          },
        },
      });
    }
  };

  /**
   * This function helps with changing default field
   * @param {object} e - event
   * @param {number} idx - Index of the value to be changed
   * @returns {void}
   */
  const handleSelectedFieldChange = (e, idx) => {
    // If idx is -1, we're dealing with a default value
    if (idx === -1) {
      handleSetCustomValuesState({
        dynamicValueData: {
          ...dynamicValueData,
          defaultValue: {
            ...dynamicValueData.defaultValue,
            defaultFieldObjectId: e.target.value,
          },
        },
      });
    } else {
      // We're dealing with a then value at idx
      const dynamicCriteriaValue = dynamicValueData?.criteria[idx];

      // Update DynamicCriteriaValues
      const dynamicValueDataCriteria = dynamicValueData?.criteria || [];
      const updatedDynamicCriteriaValues = [
        ...dynamicValueDataCriteria.slice(0, idx),
        {
          ...dynamicCriteriaValue,
          then:
          {
            ...dynamicCriteriaValue.then,
            fieldObjectId: e.target.value,
          },
        },
        ...dynamicValueDataCriteria.slice(idx + 1),
      ];

      // Set state
      handleSetCustomValuesState({
        dynamicValueData: {
          ...dynamicValueData,
          criteria: updatedDynamicCriteriaValues,
        },
      });
    }
  };

  /**
   * This function helps with changing selected DE
   * @param {object} e - event
   * @param {number} idx - Index of the value to be changed
   * @returns {void}
   */
  const handleSelectedDEChange = (e, idx) => {
    let fieldObjectId;

    let selectedFields = [];

    // get available fields
    selectedFields = getSelectedDEFields(e.target.value, dynamicValueData?.fieldType);

    // Get selectedDE
    const selectedDE = Util.getDataExtensionByAlias(e.target.value, selectedDataExtensions) || {};

    // Set dataExtensionObjectId
    const dataExtensionObjectId = selectedDE.ObjectID || '';

    // if there is at least one field set fieldObjectId to that field
    if (selectedFields && selectedFields.length > 0) {
      fieldObjectId = selectedFields[0].ObjectID;
      // otherwise set fieldObjectId to the empty string
    } else {
      fieldObjectId = '';
    }

    // If idx is -1, we're dealing with a default value
    if (idx === -1) {
      handleSetCustomValuesState({
        dynamicValueData: {
          ...dynamicValueData,
          defaultValue: {
            ...dynamicValueData?.defaultValue,
            defaultDataExtensionObjectId: dataExtensionObjectId,
            defaultFieldObjectId: fieldObjectId,
            defaultDataExtensionAlias: e.target.value,
          },
        },
      });
    } else {
      // We're dealing with a then value at idx
      const dynamicCriteriaValue = dynamicValueData?.criteria[idx];

      // Update DynamicCriteriaValues
      const dynamicValueDataCriteria = dynamicValueData?.criteria || [];
      const updatedDynamicCriteriaValues = [
        ...dynamicValueDataCriteria.slice(0, idx),
        {
          ...dynamicCriteriaValue,
          then:
          {
            dataExtensionAlias: e.target.value,
            dataExtensionObjectId,
            fieldObjectId,
          },
        },
        ...dynamicValueDataCriteria.slice(idx + 1),
      ];

      // Set state
      handleSetCustomValuesState({
        dynamicValueData: {
          ...dynamicValueData,
          criteria: updatedDynamicCriteriaValues,
        },
      });
    }
  };

  /**
   * Event handler for null checkbox
   * @param {number} index - index of the criteria
   * @returns {void}
   */
  const handleChangeNullValue = (index) => {
    if (index === -1) {
      handleSetCustomValuesState({
        showNoAvailableFieldsError: false,
        dynamicValueData: {
          ...dynamicValueData,
          defaultValue: {
            ...dynamicValueData?.defaultValue,
            defaultValue: isDefaultValueNull ? Util.getDefaultValueByFieldType(dynamicValueData?.fieldType) : null,
          },
        },
      });
    } else {
      const dynamicValueDataCriteria = dynamicValueData?.criteria || [];

      handleSetCustomValuesState({
        showNoAvailableFieldsError: false,
        dynamicValueData: {
          ...dynamicValueData,
          criteria: [
            ...dynamicValueDataCriteria.slice(0, index),
            {
              ...dynamicValueDataCriteria[index],
              then: nullValues[index] ? Util.getDefaultValueByFieldType(dynamicValueData?.fieldType) : null,
            },
            ...dynamicValueDataCriteria.slice(index + 1),
          ],
        },
      });
    }
  };

  /**
   * Renders the null checkbox inside dynamic
   * @param {number} index - index of the criteria
   * @param {array} nullValues - The array that keeps track of which checkbox is checked
   * @param {boolean} isDefaultValueNull - Determines whether default value is null or not
   * @returns {object} The HTML of the checkbox
   */
  const renderNullCheckbox = (index, nullValues, isDefaultValueNull) => (
    <Checkbox
      id={`value-null-${index}`}
      name="value-null"
      onChange={() => handleChangeNullValue(index)}
      checked={index === -1 ? isDefaultValueNull : nullValues[index]}
      label="Null"
      disabled={disabled}
    />
  );

  /**
   * Function for rendering an invalid email error message
   * @returns {object} HTML for the message
   */
  const renderInvalidEmailAddressMessage = () => (
    <Alert
      id="invalid email"
      title="Invalid Email Address"
      className="invalid-email"
    />
  );

  /**
   * to sort the criteria list, move an item from a position to another in the criteria list
   * @param {number} oldIndex - old item index in the criteria list
   * @param {number} newIndex - new item index in the criteria list
   * @returns {void}
   */
  const sortCriteria = async (oldIndex, newIndex) => {
    const sortedFilters = arrayMove(filters, oldIndex, newIndex);
    const sortedCriteria = arrayMove(dynamicValueData?.criteria, oldIndex, newIndex);

    handleSetCustomValuesState({
      dynamicValuesFilters: sortedFilters,
      dynamicValueData: {
        ...dynamicValueData,
        criteria: sortedCriteria,
      },
    });
  };

  // Function for opening a criteria filters modal
  const addCriteria = () => {
    handleSetCustomValuesState({
      criteriaModal: true,
      dynamicCustomValuesFilterID: null,
    });
    handleSetSelectionState({ dynamicCustomValuesFilters: {} });
  };

  /**
   * This function helps to edit criteria
   * @param {number} index - index of criteria which will be edited
   * @returns {void}
   */
  const editCriteria = (index) => {
    const copyDynamicValuesFilters = [...filters];

    const updatedFilters = JSON.parse(JSON.stringify({
      filters: copyDynamicValuesFilters[index]?.filters,
      operator: copyDynamicValuesFilters[index]?.operator,
    }));

    handleSetCustomValuesState({ criteriaModal: true, dynamicCustomValuesFilterID: index });
    handleSetSelectionState({ dynamicCustomValuesFilters: updatedFilters });
  };

  /**
   * This function deletes criteria
   * @param {number} index - index of criteria which will be deleted
   * @returns {null} - it returns null
   */
  const deleteCriteria = async (index) => {
    const removeFilter = [];
    const removeCriteria = [];

    // if there is only one criteria throw swal and don't delete criteria
    if (filters?.length === 1) {
      return SwalUtil.fire({
        title: 'Cannot delete this criteria',
        message: 'You need to have at least one criteria.',
      });
    }
    const result = await SwalUtil.fire({
      title: 'Delete Criteria',
      message: 'This criteria will be removed.',
      options: {
        showCancelButton: true,
      },
    });

    if (result.value) {
      filters.forEach((filter, id) => {
        if (id !== index) {
          removeFilter.push(filter);
        }
      });

      dynamicValueData?.criteria?.forEach((value, id) => {
        if (id !== index) {
          removeCriteria.push(value);
        }
      });

      handleSetCustomValuesState({
        dynamicValuesFilters: removeFilter,
        dynamicValueData: {
          ...dynamicValueData,
          criteria: removeCriteria,
        },
      });
    }

    return null;
  };

  /**
   * Renders the delete criteria button for the dynamic modal
   * @param {number} index - The index of the criteria to delete
   * @returns {object} JSX to render the button
   */
  const renderDeleteCriteriaDynamicModal = index => (
    <Button
      buttonIcon
      className="delete-criteria-button"
      title="Delete Criteria"
      id="delete-criteria"
      onClick={() => deleteCriteria(index)}
      disabled={disabled}
    >
      <svg className="slds-button__icon mr-11" aria-hidden="true">
        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#recycle_bin_empty" />
      </svg>
    </Button>
  );

  return (
    <>
      <div className="custom-values-input dynamic">
        <div id="field-value-boolean" className="field dynamic-value">
          <div className="item-label"><b>Field Type:</b></div>
          <Select
            id="select-field-type-dynamic"
            containerClassName="custom-values-select"
            onChange={e => handleOnChangeFieldType(e)}
            value={dynamicValueData?.fieldType}
            options={DropdownOptions.FIELD_TYPE__OPTIONS}
            disabled={disabled}
          />
        </div>
        <br />
        {criteriaText?.length ?
            <SortableCriteriaContainer
              criteriaText={criteriaText}
              sortCriteria={sortCriteria}
              editCriteria={editCriteria}
              toggleInputType={toggleInputType}
              thenValues={thenValues}
              fieldTypeDynamicValue={dynamicValueData?.fieldType}
              selectedDataExtensions={selectedDataExtensions}
              dynamicCriteriaValues={dynamicValueData?.criteria}
              showInvalidEmailMessage={showInvalidEmailMessage}
              renderDeleteCriteriaDynamicModal={renderDeleteCriteriaDynamicModal}
              renderNullCheckbox={renderNullCheckbox}
              isDefaultValueNull={isDefaultValueNull}
              nullValues={nullValues}
              showNoAvailableFieldsError={showNoAvailableFieldsError}
              getSelectedDEFields={getSelectedDEFields}
              noAvailableFieldsRef={noAvailableFieldsRef}
              handleSelectedDEChange={handleSelectedDEChange}
              handleSelectedFieldChange={handleSelectedFieldChange}
              handleChange={handleChange}
              handlePeriodDateChange={handlePeriodDateChange}
              handleBlur={handleBlur}
              renderInvalidEmailAddressMessage={renderInvalidEmailAddressMessage}
              disabled={disabled}
              /> :
          (
            <div className="custom-values-dynamic-when">
              <b>When:&nbsp;</b>
              <Button
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                className="add-criteria-btn"
                onClick={() => addCriteria()}
                disabled={disabled}
              >
                Add Criteria
              </Button>
            </div>
          )}
        {criteriaText?.length > 0 ?
          (
            <div>
              <Button
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                className="add-criteria-button"
                onClick={() => addCriteria()}
                disabled={disabled}
              >
                Add Criteria
              </Button>
            </div>
          ) :
          null}
        <div className="field default-values">
          <div><b>Default:&nbsp;</b></div>
          <div className="default-value-inputs-container">
            {selectedDataExtensions && selectedDataExtensions.length > 0 ?
              (
                <div className="compare-fields-filter-toggle">
                  <Button
                    buttonLook={Constants.BUTTON__TYPE__BRAND}
                    className="default-value-button"
                    title="Pick available fields"
                    onClick={() => toggleInputType(-1)}
                    disabled={isDefaultValueNull && !selectDefaultValue || disabled}
                  >
                    <i className={`fas fa-${selectDefaultValue ?
                      'font' :
                      'table'}`}
                    />
                  </Button>
                </div>
              ) :
              null}
            {selectDefaultValue ?
              (
                <InputTypeSelect
                  selectedDataExtensions={selectedDataExtensions}
                  dataExtensionAlias={dynamicValueData?.defaultValue?.defaultDataExtensionAlias}
                  fieldObjectId={dynamicValueData?.defaultValue?.defaultFieldObjectId}
                  isDefaultValueNull={isDefaultValueNull}
                  renderNullCheckbox={renderNullCheckbox}
                  index={-1}
                  showNoAvailableFieldsError={showNoAvailableFieldsError}
                  fields={getSelectedDEFields(
                    dynamicValueData?.defaultValue?.defaultDataExtensionObjectId,
                    dynamicValueData?.fieldType,
                  )}
                  noAvailableFieldsRef={noAvailableFieldsRef}
                  handleSelectedDEChange={handleSelectedDEChange}
                  handleSelectedFieldChange={handleSelectedFieldChange}
                  nullValues={nullValues}
                  disabled={disabled}
                />
              ) :
              (
                <div className="default-value-container">
                  {dynamicValueData?.fieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN ?
                    (
                      <>
                        <Select
                          containerClassName="default-value-boolean"
                          value={dynamicValueData?.defaultValue?.defaultValue || ''}
                          onChange={e => handleDefaultValueChange(e)}
                          disabled={isDefaultValueNull || disabled}
                          options={DropdownOptions.BOOLEAN__OPTIONS}
                        />
                        <div className="null-checkbox">
                          {renderNullCheckbox(-1, null, isDefaultValueNull)}
                        </div>
                      </>
                    ) :
                    (
                      <div className="default-value-input">
                        {dynamicValueData?.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE ?
                          (
                            <DatePicker
                              fixedHeight
                              popperPlacement="top-start"
                              className={classNames(
                                `form-control filter-value
                                  dynamic-value-date default-value-date`,
                                { 'disabled-date-picker': isDefaultValueNull },
                              )}
                              name="customValueDate"
                              type="text"
                              dateFormat={timeUtil.getDatePickerDateTimeFormat(userLocale)}
                              timeFormat="HH:mm"
                              showTimeInput
                              selected={timeUtil.formatDateInDatePicker(
                                dynamicValueData?.defaultValue?.defaultValue || new Date().setHours(0, 0),
                              )}
                              onChange={e => handlePeriodDateChange(e)}
                              data-placement="top-start"
                              disabled={isDefaultValueNull || disabled}
                            />
                          ) :
                          (
                            <Input
                              name="value"
                              type={
                                dynamicValueData?.fieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL ?
                                  Constants.FILTERLINE__FIELDTYPE__NUMBER :
                                  dynamicValueData?.fieldType
                              }
                              pattern={
                                dynamicValueData?.fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ?
                                  '[0-9]*' :
                                  null
                              }
                              inputMode={
                                dynamicValueData?.fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ?
                                  'numeric' :
                                  null
                              }
                              id="default-values"
                              min="1"
                              max="2550"
                              className="dynamic-values-input"
                              value={dynamicValueData?.defaultValue?.defaultValue || ''}
                              placeholder="Enter a value here"
                              disabled={isDefaultValueNull || disabled}
                              onChange={(e) => {
                                if (dynamicValueData?.fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER) {
                                  if (!/^[0-9]*$/.test(e.target.value)) {
                                    return;
                                  }
                                }
                                handleDefaultValueChange(e);
                              }}
                              onBlur={(e) => {
                                if (filtersUtil.isNumberOrDecimal(dynamicValueData?.fieldType)) {
                                  handleDefaultValueBlur(e);
                                }
                              }}
                            />
                          )}
                        {dynamicValueData?.fieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS &&
                          !Util.validateEmail(dynamicValueData?.defaultValue?.defaultValue) &&
                          dynamicValueData?.defaultValue?.defaultValue?.length > 0 &&
                          renderInvalidEmailAddressMessage()}
                        <div className="null-checkbox">
                          {renderNullCheckbox(-1, null, isDefaultValueNull)}
                        </div>
                      </div>
                    )}
                </div>
              )}
          </div>
        </div>
        {isDefaultValueNull && dynamicValueData?.criteria?.every(criterion => criterion?.then === null) && (
          <Alert
            type={Constants.ALERT__TYPE__WARNING}
            id="all-null-warning"
            forwardRef={showAllNullErrorRef}
            title="Please make sure at least one value is not null."
            headerId="header-all-null-warning"
          />
        )}
      </div>
      {criteriaModal ?
        <DynamicValuesCriteriaModal
          show
          closeCriteriaModal={closeCriteriaModal}
          selectedDataExtensions={selectedDataExtensions}
          handleSetSelectionState={handleSetSelectionState}
          handleFiltersSave={handleFiltersSave}
          dynamicCustomValuesFilterID={dynamicCustomValuesFilterID}
          handleSetDynamicValueFilterState={handleSetDynamicValueFilterState}
          dynamicCustomValuesFilters={dynamicCustomValuesFilters}
          getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
          DEBorderMouseOver={DEBorderMouseOver}
          filterBorderMouseOver={filterBorderMouseOver}
          pickLists={pickLists}
          handlePickListOptions={handlePickListOptions}
          handleRemoveFilterLine={handleRemoveFilterLine}
          applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
          timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
          handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
          disabled={disabled}
        /> :
        null}
    </>
  );
};

DynamicValueModal.propTypes = {
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.array,
  /**
   * Ref for the noAvailableFields error
   */
  noAvailableFieldsRef: PropTypes.object,
  /**
   * Ref for the allNullError
   */
  showAllNullErrorRef: PropTypes.object,
  /**
   * It keeps the dynamic value data
   */
  dynamicValueData: PropTypes.object,
  /**
   * Function to handle the change/update custom values state
   */
  handleSetCustomValuesState: PropTypes.func,
  /**
   * Function to handle the change/update selection state
   */
  handleSetSelectionState: PropTypes.func,
  /**
   * Function to close the criteria modal
   */
  closeCriteriaModal: PropTypes.func,
  /**
   * It handles saving of the filters
   */
  handleFiltersSave: PropTypes.func,
  /**
   * It keeps the dynamic custom values filter id
   */
  dynamicCustomValuesFilterID: PropTypes.number,
  /**
   * Function to handle the change/update dynamic custom values filter state
   */
  handleSetDynamicValueFilterState: PropTypes.func,
  /**
   * It keeps the dynamic custom values filters
   */
  dynamicCustomValuesFilters: PropTypes.object,
  /**
   * Function to get the data extension or data view fields
   */
  getDataExtensionOrDataViewFields: PropTypes.func,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.array,
  /**
   * showNoAvailableFieldsError
   */
  showNoAvailableFieldsError: PropTypes.bool,
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   * it will be passed from Selection.js
   */
  handlePickListOptions: PropTypes.func,
  /**
   * It Removes a given filterLine
   * It is passed from Selection.js
   */
  handleRemoveFilterLine: PropTypes.func,
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * An object containing timezone details
   */
  timezoneSettingsForAllDateFields: PropTypes.object,
  /**
   * Handles the setting of timezone settings to all date filters
   */
  handleSetTimezoneToAllDateFields: PropTypes.func,
  /**
   * Indicates whether the criteria modal is open
   */
  criteriaModal: PropTypes.bool,
  /**
   * Indicates disabled state of the modal
   */
  disabled: PropTypes.bool,
};

export default DynamicValueModal;

import moment from 'moment-timezone';
import jstz from 'jstz';
import { getYear } from 'date-fns';
import range from 'lodash/range';

import Constants from '../../constants/constants';
import Util from '../../util';

const timeUtil = {
  /**
   * Helps to set the selected days of the week in the array for the checkbox
   * @param {object} e - onChange event object
   * @param {array} daysOfWeek - array with days of week
   * @returns {array} array with selected days of the week, sorted
   */
  setDaysOfWeekInArray: (e, daysOfWeek) => {
    let daysOfWeekCopy = [...daysOfWeek];

    const { checked } = e.target;

    let { id } = e.target;

    id = parseInt(id);
    // if checkbox is checked push id into the daysOfWeekCopy array
    if (checked) {
      daysOfWeekCopy.push(id);
    } else {
      // if checkbox is unchecked delete id for that checkbox from the daysOfWeekCopy array
      daysOfWeekCopy = daysOfWeekCopy.filter(item => item !== id);
    }

    // return sorted array
    return daysOfWeekCopy.sort();
  },

  /**
   * Returns array with days of week
   * @returns {array} array with the names of the days of the week
   */
  returnDaysOfWeek: () => {
    return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  },

  /**
   * Returns array with months of year
   * @returns {array} array with the names of the months of the year
   */
  returnMonthsOfYear: () => {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
      'October', 'November', 'December'];
  },

  /**
   * Returns array with years from range
   * @param {number} startYear - year to start from
   * @returns {array} array with the years from range
   */
  returnYears: (startYear) => {
    const yearToStartFrom = startYear || 2010;
    const years = range(yearToStartFrom, getYear(moment().toDate()) + 1, 1);

    return years;
  },

  /**
   * This function returns currentTime in selected timezone
   * @param {string} timezone - timezone name
   * @returns {date} current date and time for selected timezone
   */
  currentTimeInTimezone: timezone => moment().add(-moment().utcOffset(), 'm').utc().tz(timezone)._d,

  /**
   * This function returns date and time in readable format
   * @param {object} date - object with not formatted date
   * @param {boolean} onlyDate - indicates whether function should return only date parts (year, month, day)
   * @param {boolean} onlyTime - indicates whether function should return only time parts (hours, minutes, seconds)
   * @returns {string} formatted date
   */
  formatFullDate: (date, onlyDate, onlyTime) => {
    if (onlyDate) { return moment(date).format('YYYY-MM-DD'); }
    if (onlyTime) { return moment(date).format('HH:mm:ss'); }

    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  },

  /**
   * This function helps to check if all fields are filled, it is used for disable schedule button
   * @param {object} scheduledRun - scheduledRun object
   * @returns {boolean} returns true if not all required fields are filled, in another case - returns false
   */
  handleValidationForSchedule: (scheduledRun) => {
    const {
      mode, runOnce, enabled, timezone,
      hoursValue, minutesValue, repeatMode, daysValue, daysOfWeek, monthsValue,
    } = scheduledRun;

    if (enabled) {
      // required fields for mode: 'once'
      if (mode === Constants.SCHEDULE_SELECTION__MODE__ONCE) {
        if (!runOnce.dateValue || !runOnce.timeValue) return true;

        return timeUtil.hasPassed(runOnce, timezone);
      }

      // required fields for mode: 'repeat'
      if (mode === Constants.SCHEDULE_SELECTION__MODE__REPEAT) {
        // there are values for hour select in hourly repeatMode
        const hoursValueForHourlySelect = ['1', '2', '3', '4', '6', '12'];

        // there are required fields for repeatMode views
        if (repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__HOURLY) {
          if (!hoursValue || !minutesValue || !hoursValueForHourlySelect.includes(hoursValue)) return true;
        }

        if (repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__DAILY) {
          if (!daysValue || !hoursValue || !minutesValue) return true;
        }

        if (repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__WEEKLY) {
          if (!daysOfWeek.length || !hoursValue || !minutesValue) return true;
        }

        if (repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__MONTHLY) {
          if (!monthsValue || !daysValue || !hoursValue || !minutesValue) return true;
        }
      }
    }

    return false;
  },

  /**
   * This function shows when next run will be executed for chosen mode
   * @param {object} scheduledRun - scheduledRun object
   * @returns {string} returns string with the next run information
   */
  nextRunInformation: (scheduledRun) => {
    const {
      mode, enabled, timezone,
      hoursValue, minutesValue, repeatMode, daysValue, daysOfWeek, monthsValue,
    } = scheduledRun;

    const currentTime = timeUtil.currentTimeInTimezone(timezone);

    if (mode === Constants.SCHEDULE_SELECTION__MODE__REPEAT && enabled) {
      // declare today in selected timezone using moment
      const now = moment().add(-moment().utcOffset(), 'm').utc().tz(timezone);

      // if we are in hourly repeatMode
      if (repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__HOURLY && hoursValue && minutesValue &&
        !timeUtil.handleValidationForSchedule(scheduledRun)) {
        // create an array with hours, increase the number in the array by a selected number of hours
        const arrayWithHours = Util.getArrayWithNumbers(0, 25, hoursValue);

        // select the next hour for execution
        const nextHourValue = arrayWithHours.find((hour) => {
          if (hour === currentTime.getHours() && minutesValue > currentTime.getMinutes()) {
            // return current hour if the select minutes are greater than the current
            return hour === currentTime.getHours();
          }

          // in another case return the nearest possible hour
          return hour > currentTime.getHours();
        });

        // show the next run date in proper format
        const nextRunDate = nextHourValue === 24 ?
          moment(currentTime).add(1, 'days').format(timeUtil.getUserDateTimeFormat().split(' ')[0]) :
          moment(currentTime).format(timeUtil.getUserDateTimeFormat().split(' ')[0]);

        // return information about next run schedule for hourly repeatMode
        return {
          firstRunEachDay: '00:' + moment(minutesValue, 'minutes').format('mm'),
          nextRunDate,
          nextRunHour: moment(nextHourValue, 'hours').format('HH'),
          nextRunMinutes: moment(minutesValue, 'minutes').format('mm'),
        };
      }

      // if we are in daily repeatMode
      if (repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__DAILY && daysValue && hoursValue && minutesValue &&
          !timeUtil.handleValidationForSchedule(scheduledRun)) {
        // the date of the next run schedule
        let runDate;

        // call the function, find the next possible day
        const nextDayInTheMonth = timeUtil.findNextDay(Util.getArrayWithNumbers(
          1,
          now.daysInMonth(),
          daysValue,
        ), currentTime, hoursValue, minutesValue);

        if (nextDayInTheMonth) {
          // if the day was found, set the date of the next run schedule in the proper format (without time)
          runDate = now.set(Constants.SCHEDULE_SELECTION__DATE_VALUE__DATE, nextDayInTheMonth)
            .format(timeUtil.getUserDateTimeFormat().split(' ')[0]);
        }

        if (!nextDayInTheMonth) {
          /*
           * if the next possible day in this month was not found,
           * the next schedule will be run at the beginning of the next month
           */
          const newMonth = now.add(1, Constants.SCHEDULE_SELECTION__DATE_VALUE__MONTHS);
          const newMonthWithFirstDay = moment(newMonth).set(Constants.SCHEDULE_SELECTION__DATE_VALUE__DATE, 1);
          const setMonthWithFirstHour = moment(newMonthWithFirstDay).set({
            hour: 0, minute: 0, second: 0, millisecond: 0,
          });

          // set the date of the next run schedule in the proper format (without time)
          runDate = setMonthWithFirstHour.format(timeUtil.getUserDateTimeFormat().split(' ')[0]);
        }

        // return information about next run schedule for daily repeatMode
        return {
          nextRunDate: runDate,
          nextRunHour: moment(hoursValue, 'hours').format('HH'),
          nextRunMinutes: moment(minutesValue, 'minutes').format('mm'),
        };
      }

      // if we are in weekly repeatMode
      if (repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__WEEKLY && daysOfWeek &&
          hoursValue && minutesValue && !timeUtil.handleValidationForSchedule(scheduledRun)) {
        // return information about next run schedule for weekly repeatMode
        return {
          nextRunDate: timeUtil.nextRunForDay(daysOfWeek, hoursValue, minutesValue, timezone),
          nextRunHour: moment(hoursValue, 'hours').format('HH'),
          nextRunMinutes: moment(minutesValue, 'minutes').format('mm'),
        };
      }

      // if we are in monthly repeatMode
      if (repeatMode === Constants.SCHEDULE_SELECTION__REPEAT_MODE__MONTHLY && monthsValue &&
          daysValue && hoursValue && minutesValue && !timeUtil.handleValidationForSchedule(scheduledRun)) {
        // the date of the next run schedule for monthly repeatMode
        let runDateForMonthly;

        // call the function, find the next possible month
        const nextMonthInTheYear = moment(`${daysValue} ${hoursValue}:${minutesValue}`, 'DD HH:mm').isAfter(moment()) ?
          moment(currentTime).format('MM') :
          moment(currentTime, 'MM').add(monthsValue, 'months').format('MM');

        if (nextMonthInTheYear) {
          /*
           * if the month was found, set the date of the next run schedule in the proper format (without time)
           * we subtract the month by one because the moment counts months from 0 and cron expression from 1
           */
          const dateWithProperMonth = now.set(Constants.SCHEDULE_SELECTION__DATE_VALUE__MONTHS, nextMonthInTheYear - 1);

          const todayDate = moment(currentTime).format('DD');
          const todayMonth = moment(currentTime).format('MM');

          /**
           * TEMPORARY FIX FOR THE ISSUE WITH THE MONTHLY SCHEDULE THAT ARE SCHEDULED AND
           * DATE HAS PASSED OF THE MONTH (DECEMBER)
           * In this case the next schedule should run at the beginning of the next year but
           * currently the year is not changing
           */
          if(daysValue < todayDate && todayMonth === '12') {
            runDateForMonthly = timeUtil.setFirstDayInNextYear(now);
          }

          // if the user selects the number of days that does not occur in this month
          if (daysValue > dateWithProperMonth.daysInMonth()) {
            // find the next month from the array
            const findNextMonthFromArray = Util.getArrayWithNumbers(1, 12, monthsValue)
              .find(nextMonth => nextMonth > nextMonthInTheYear);

            if (findNextMonthFromArray) {
              // get the next possible month from the array, and set the date for this month
              const newMonth = now.set(Constants.SCHEDULE_SELECTION__DATE_VALUE__MONTHS, findNextMonthFromArray - 1);

              const newMonthWithFirstDay = moment(newMonth).set(
                Constants.SCHEDULE_SELECTION__DATE_VALUE__DATE,
                daysValue,
              );
              const setMonthWithFirstHour = moment(newMonthWithFirstDay).set({
                hour: 0, minute: 0, second: 0, millisecond: 0,
              });

              // set the date of the next run schedule in the proper format (without time)
              runDateForMonthly = setMonthWithFirstHour.format(timeUtil.getUserDateTimeFormat().split(' ')[0]);
            } else {
              /*
               * if the next possible month in the array was not found,
               * the next schedule will be run at the beginning of the next year
               */
              runDateForMonthly = timeUtil.setFirstDayInNextYear(now);
            }
          } else {
            runDateForMonthly = dateWithProperMonth.set(Constants.SCHEDULE_SELECTION__DATE_VALUE__DATE, daysValue)
              .format(timeUtil.getUserDateTimeFormat()
                .split(' ')[0]);
          }
        }

        if (!nextMonthInTheYear) {
          /*
           * if the next possible month in this year was not found,
           * the next schedule will be run at the beginning of the next year
           */
          runDateForMonthly = timeUtil.setFirstDayInNextYear(now);
        }

        // return information about next run schedule for monthly repeatMode
        return {
          nextRunDate: runDateForMonthly,
          nextRunHour: moment(hoursValue, 'hours').format('HH'),
          nextRunMinutes: moment(minutesValue, 'minutes').format('mm'),
        };
      }
    }

    return {
      nextRunDate: null,
      nextRunHour: null,
      nextRunMinutes: null,
    };
  },

  /**
   * This function shows if the user has selected today's date for timezone in DatePicker
   * @param {object} runOnce - The date part of the date
   * @param {string} timezone - The time part of the date
   * @returns {bool} returns true if the selected date is today
   */
  isTodaySelected: (runOnce, timezone) => {
    // get converted date in selected timezone
    const convertedDateValueForSelectedTimezone = timeUtil.formatFullDate(
      moment(new Date(runOnce.dateValue))
        .utc().utcOffset(timezone),
    );

    const currentTime = timeUtil.currentTimeInTimezone(timezone);

    // if selected date in dateValue in runOnce is today, then return true
    if (convertedDateValueForSelectedTimezone &&
          moment(convertedDateValueForSelectedTimezone)._d.getDate() === currentTime.getDate() &&
          moment(convertedDateValueForSelectedTimezone)._d.getMonth() === currentTime.getMonth() &&
          moment(convertedDateValueForSelectedTimezone)._d.getFullYear() === currentTime.getFullYear()) {
      return true;
    }

    return false;
  },

  /**
   * Checks if the selected time has passed
   * @param {object} runOnce - The date part of the date
   * @param {string} timezone - The time part of the date
   * @param {boolean} isTimeFormatted - tells us if the time is formatted (eg. HH:MM:SS)
   * @returns {boolean} - True if the date has passed, false otherwise
   */
  hasPassed: (runOnce, timezone, isTimeFormatted) => {
    // get the min time for the given timezone
    const minTimeForTimezone = timeUtil.currentTimeInTimezone(timezone);

    let selectedTime;

    let selectedHours;

    let selectedMinutes;

    // if time is formatted (will be if it's coming from Selection.js)
    if (isTimeFormatted) {
      selectedTime = runOnce.timeValue;

      // split the time by the ':' and parse the hours into number
      selectedHours = parseInt(selectedTime.split(':')[0]);

      // split the time by the ':' and parse the minutes into number
      selectedMinutes = parseInt(selectedTime.split(':')[1]);
      // otherwise the time and date are coming from the Scheduling modal
    } else {
      // get the selected time
      selectedTime = moment(runOnce.timeValue).add(-moment(runOnce.timeValue).utcOffset(), 'm').utc()._d;

      selectedHours = selectedTime.getHours();
      selectedMinutes = selectedTime.getMinutes();
    }

    // check if the chosen date is today
    if (timeUtil.isTodaySelected(runOnce, timezone)) {
      /*
       * check if the min hours that could be selected for the given timezone are
       * greater than the hours of the selected time
       */
      if (minTimeForTimezone.getHours() > selectedHours) {
        // if so return true (means that the Schedule button will be disabled)
        return true;
      }

      /*
       * check if the min minutes that could be selected for the given timezone are
       * greater than the minutes  of the selected time
       */
      if (minTimeForTimezone.getHours() === selectedHours &&
          minTimeForTimezone.getMinutes() >= selectedMinutes) {
        // if so return true (means that the Schedule button will be disabled)
        return true;
      }
    } else {
      // Chosen date is not today
      const dateToday = moment();
      const scheduledDate = moment(runOnce.dateValue);

      return scheduledDate.isBefore(dateToday);
    }

    return false;
  },

  /**
   * It gets the date from different timezone and then calculate to the local date and time
   * @param {string} timezone - The timezone for the given date
   * @param {string} date - The date in the given timezone
   * @returns {string} The local datetime based on the given date and timezone
   */
  formatDateInDifferentTimezoneToTheLocal: (timezone, date) => {
    return moment(date).add(-moment(date).tz(timezone)
      .utcOffset(), 'm').utc()._d;
  },

  /**
   * It gets the date from datePicker and then calculate the time difference and date
   * @param {string} date - The date from the date picker
   * @returns {object} moment object with date
   */
  formatDateForDatePicker: date => moment(date)
    .add(moment(date).utcOffset(), 'm')
    .utc(),

  /**
   * It gets the time and date and calculate the time for datePicker. It's not the same as above - look at .add moment
   * @param {string} date - The date to date picker
   * @returns {object} moment object with date
   */
  formatDateInDatePicker: date => moment(date)
    .add(-moment(date).utcOffset(), 'm')
    .utc()._d,

  /**
   * It formats the date for html date input
   * @param {string} date - The date to format
   * @returns {string} The formatted date
   */
  formatDateForHtmlDateInput: (date) => {
    if (timeUtil.getUserLocale() === Constants.LOCALE__EN_US) {
      return moment(date, 'DD/MM/YY').format('YY-DD-MM');
    }

    return moment(date, 'DD/MM/YY').format('YY-MM-DD');
  },

  /**
   * This function helps to return the first day in the next year
   * @param {date} date - current date
   * @returns {string} the first day in the next year '01/01/YYYY(next year)'
   */
  setFirstDayInNextYear: (date) => {
    // set the moment object - add one year and set the first day and month
    const newYear = date.add(1, Constants.SCHEDULE_SELECTION__DATE_VALUE__YEAR);
    const newYearWithFirstDay = moment(newYear).set({ month: 0, date: 1 });
    const setMonthWithFirstHour = moment(newYearWithFirstDay).set({
      hour: 0, minute: 0, second: 0, millisecond: 0,
    });

    // Set the first day in the next year in the proper format (without time)
    const firstDayInNextYear = setMonthWithFirstHour.format(timeUtil.getUserDateTimeFormat().split(' ')[0]);

    return firstDayInNextYear;
  },

  /**
   * This function returns an identifier for a time offset from UTC
   * @returns {string} time offset from UTC
   */
  formatUserUTC: () => {
    return moment().tz(moment.tz.guess()).format('Z');
  },

  /**
   * Function for convert hours, minutes and seconds into milliseconds
   * @param {number} h - hours
   * @param {number} m - minutes
   * @param {number} s - seconds
   * @returns {number} - time in milliseconds
   */
  convertTimeToMilliseconds: (h, m, s) => (h * 60 * 60 + m * 60 + s) * 1000,

  /**
   * Formats a date into DD/MM/YYYY HH:MM or MM/DD/YYY HH:MM format depending on users locale
   * @param {string} date - The date to format
   * @param {string} userLocale - The desired date format
   * @returns {string} The formatted date
   */
  formatDateFilterText: (date, userLocale) => {
    const formattedDate = moment(date)
      .add(-moment(date).utcOffset(), 'm')
      .utc()
      ._d;

    const day = Util.addLeadingZero(formattedDate.getDate());
    const month = Util.addLeadingZero(formattedDate.getMonth() + 1);
    const hour = Util.addLeadingZero(formattedDate.getHours());
    const minute = Util.addLeadingZero(formattedDate.getMinutes());

    let value;

    if (userLocale === Constants.LOCALE__EN_US) {
      value = `${month}/${day}/${date.getFullYear()} ${hour}:${minute}`;
    } else {
      value = `${day}/${month}/${date.getFullYear()} ${hour}:${minute}`;
    }

    return value;
  },

  /**
   * Formats a date into DD/MM/YYYY HH:MM or MM/DD/YYY HH:MM format depending on users locale
   * @param {string} date - The date to format
   * @param {string} dateFormat - The desired date format
   * @returns {string} The formatted date
   */
  formatDate: (date, dateFormat) => moment(date).format(dateFormat),

  /**
   * Get the user locale, return 'en-US' if undefined
   * @returns {string} The user locale
   */
  getUserLocale: () => {
    let userLocale;
    const userInfo = Util.userInfo();

    // get the user locale
    userLocale = userInfo ? userInfo.locale : Constants.LOCALE__EN_US;
    try {
      // try to use function for number formatting with user locale
      new Intl.NumberFormat(userLocale);
      // if we catch an error that means user locale is not correct so set locale to en-US
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Wrong locale');
      userLocale = Constants.LOCALE__EN_US;
    }

    return userLocale;
  },

  /**
   * Get the user timezone, return timezone name
   * @param {array} arrayWithTimezones - array with timezone objects (look at TimezoneList.json in Schedule Selection)
   * @param {boolean} timezoneValue - defines that timezone value, not name, must be returned
   * @returns {string} The user timezone name
   */
  getCurrentUserTimezone: (arrayWithTimezones, timezoneValue) => {
    let findTimezoneFromList;

    // get user local timezone
    const userTimezone = moment.tz.guess();

    // get user local timezone offset
    const userTimezoneOffset = moment().tz(userTimezone).format('Z');

    // check if local user timezone is found
    if (userTimezone) {
      // find timezone name in the arrayWithTimezones (search for each object in the utc table)
      findTimezoneFromList = arrayWithTimezones.find(tz => tz.utc
        .find(zoneInUtc => zoneInUtc === userTimezone ||
        zoneInUtc.match(userTimezone)));
    } else if (userTimezoneOffset) {
      // if a timezone name is not found in the list, look for the name using offset value
      findTimezoneFromList = arrayWithTimezones.find(tz => tz.utc
        .find(zoneInUtc => moment().tz(zoneInUtc).format('Z') === userTimezoneOffset));
    }

    if (findTimezoneFromList && !Util.objectIsEmpty(findTimezoneFromList) &&
    findTimezoneFromList.utc && findTimezoneFromList.utc.length) {
      /*
       * If it finds a timezone name in object, it will return the first timezone name for that object
       * (or value if timezoneValue is passed).
       * We take the first name to select initial timezone in timezoneSelect
       */
      return timezoneValue ? findTimezoneFromList.value : findTimezoneFromList.utc[0];
    }

    const utcTimezone = arrayWithTimezones.find(timezone => timezone.value === 'UTC');

    // if it doesn't find timezone, select the default timezone name for utc
    return timezoneValue ? utcTimezone.value : utcTimezone.utc[0];
  },

  /**
   * Show CST Date time in local time
   * @param {string} dateTime - The date time
   * @param {string} dateFormat - Format of the date
   * @returns {string|null} A date time in local format
   */
  showCSTDateTimeInLocale: (dateTime, dateFormat) => {
    if (dateTime) {
      // determine user timezone
      if (!sessionStorage.getItem('timezone')) {
        const tz = jstz.determine() || 'UTC';

        sessionStorage.setItem('timezone', tz.name());
      }
      const timezone = sessionStorage.getItem('timezone');

      const momentTime = moment(dateTime);

      // convert to users locale
      const tzTime = momentTime.tz(timezone);

      return tzTime.format(dateFormat);
    }

    return null;
  },

  /**
   * Get the user dateTimeFormat
   * @returns {string} The user date time format
   */
  getUserDateTimeFormat: () => {
    const userLocale = timeUtil.getUserLocale();

    return (userLocale === Constants.LOCALE__EN_US ? 'MM/DD/YYYY HH:mm' : 'DD/MM/YYYY HH:mm');
  },

  /**
   * Get the date format for datepicker
   * @param {string} userLocale - The user locale
   * @returns {string} The date format for datepicker
   */
  getDatePickerDateFormat: userLocale => (userLocale === Constants.LOCALE__EN_US ? 'MM/dd/yyyy' : 'dd/MM/yyyy'),

  /**
   * Format date picker selected Date
   * @param {string} value - Value to format
   * @returns {Date} - Formatted date
   */
  formatDatePickerSelectedDate: (value) => {
    return moment(value)
      .add(-moment(value).utcOffset(), 'm')
      .utc()._d;
  },

  /**
   * Get the datetime format for datepicker
   * @param {string} userLocale - The user locale
   * @returns {string} The date format for datepicker
   */
  getDatePickerDateTimeFormat: userLocale => (userLocale === Constants.LOCALE__EN_US ?
    'MM/dd/yyyy HH:mm' :
    'dd/MM/yyyy HH:mm'),

  /**
   * This function returns label with schedule date for selection depending on user locale
   * @param {object} date - object with date parts (year, month, date...)
   * @param {string} date.scheduledMonth - month in 'MM' format
   * @param {string} date.scheduledDay - date in 'DD' format
   * @param {string} date.scheduledYear - year in 'YYYY' format
   * @param {number} date.scheduledHour - number of hours
   * @param {number} date.scheduledMinute - number of minutes
   * @returns {string} label with scheduled date info
   */
  returnScheduledDateInUserLocaleFormat: ({
    scheduledMonth, scheduledDay, scheduledYear, scheduledHour, scheduledMinute,
  }) => {
    const dateTime = moment(scheduledDay + '-' + scheduledMonth + '-' + scheduledYear +
     ' ' + scheduledHour + ':' + scheduledMinute, 'DD-MM-YYYY HH:mm A');

    if (timeUtil.getUserLocale() === Constants.LOCALE__EN_US) {
      return 'This selection is scheduled to run next at: ' + dateTime.format('MM/DD/YYYY HH:mm') + ':00';
    }

    return 'This selection is scheduled to run next at: ' + dateTime.format('DD/MM/YYYY HH:mm') + ':00';
  },

  /**
   * This function returns scheduled time in format YYYY-MM-DD HH:MM:SS
   * @param {object} scheduledRun - object with necessary values for scheduling
   * @returns {string}  scheduled time in format YYYY-MM-DD HH:MM:SS
   */
  calculateNextRunDate: (scheduledRun) => {
    // get user timezone
    const currentUserTimeUTCOffset = moment().tz(moment.tz.guess()).format('Z');

    // get the difference in timezone (user and scheduled)
    const scheduledTimeUTCOffset = moment().tz(scheduledRun.timezone).format('Z');

    // check if schedule time is in user timezone
    const scheduleTimeInUserTimezone = currentUserTimeUTCOffset === scheduledTimeUTCOffset;

    // get the data from schedule repeat mode
    const { nextRunDate, nextRunHour, nextRunMinutes } =
    timeUtil.nextRunInformation({ ...scheduledRun, ...scheduledRun.runRepeat });

    /**
     * Formats next run date to 'YYYY-MM-DD' depending on the user locale
     * @param {string} date - date to format
     * @returns {string} formatted date 'YYYY-MM-DD'
     */
    const formatNextRunDate = (date) => {
      let formattedDate;

      if(timeUtil.getUserLocale() === Constants.LOCALE__EN_US) {
        // format date from 'MM/DD/YYYY' to 'YYYY-MM-DD'
        formattedDate = timeUtil.formatFullDate(date, true);
      } else {
        /*
         * for other local settings we need to format from 'DD/MM/YYYY' to 'YYYY-MM-DD'
         * moment library returns Invalid Date, so we need to split received date and return the proper one
         */
        const [day, month, year] = date.split('/');

        formattedDate = `${year}-${month}-${day}`;
      }

      return formattedDate;
    };

    // define date value and time value depending on selected mode
    const dateValue = scheduledRun.mode === Constants.SCHEDULE_SELECTION__MODE__ONCE ?
      scheduledRun?.runOnce?.dateValue :
      formatNextRunDate(nextRunDate);
    const timeValue = scheduledRun.mode === Constants.SCHEDULE_SELECTION__MODE__ONCE ?
      scheduledRun?.runOnce?.timeValue :
      `${nextRunHour}:${nextRunMinutes}`;

    // get formatted date and time value with selected timezone
    const dateTimeValueInSelectedTimezone = moment.tz(`${dateValue} ${timeValue}`, scheduledRun.timezone).format();

    // get converted date and time to local time in a readable format
    const convertedDateTimeValueForCurrentTimezone = timeUtil.formatFullDate(
      moment(new Date(dateTimeValueInSelectedTimezone))
        .utc().utcOffset(currentUserTimeUTCOffset),
    );

    // when schedule is enabled
    if (scheduledRun.enabled) {
      const scheduledPast = 'Schedule has passed';

      // get scheduled date depending on the timezone set
      const scheduledDateValue = scheduleTimeInUserTimezone ?
        dateValue :
        timeUtil.formatFullDate(convertedDateTimeValueForCurrentTimezone, true);

      // get year, month, day of scheduled date using moment
      const scheduledYear = moment(scheduledDateValue).format('YYYY');

      const scheduledMonth = moment(scheduledDateValue).format('MM');

      const scheduledDay = moment(scheduledDateValue).format('DD');

      // get current date in the user's local time
      const currentDate = moment()._d;

      // get year, month and date of current date using moment
      const currentYear = moment(currentDate).format('YYYY');
      const currentMonth = moment(currentDate).format('MM');
      const currentDay = moment(currentDate).format('DD');

      /*
       * check if the scheduled year has passed (scheduled year smaller than current year)
       * if that is the case, then return the message 'Schedule has passed'
       */
      if (scheduledYear < currentYear) return scheduledPast;

      // if the scheduled year hasn't passed, check if month has passed
      if (scheduledYear === currentYear) {
        // if scheduled month has passed return the message 'Schedule has passed'
        if (scheduledMonth < currentMonth) return scheduledPast;
        // if the scheduled month hasn't passed, check if day has passed
        if (scheduledMonth === currentMonth) {
          // if scheduled day has passed return the message 'Schedule has passed'
          if (scheduledDay < currentDay) return scheduledPast;
        }
      }

      // if scheduled date has not passed get the time value
      const splitTimeValue = scheduleTimeInUserTimezone ?
        timeValue.split(':') :
        timeUtil.formatFullDate(convertedDateTimeValueForCurrentTimezone, false, true).split(':');

      // get hours and minutes from timeValue
      const scheduledHour = Number(splitTimeValue[0]);
      const scheduledMinute = Number(splitTimeValue[1]);

      // if the scheduled date is the same as the current date
      if (scheduledDateValue === timeUtil.formatFullDate(currentDate, true)) {
        // check if hours have passed. If so return the message 'Schedule has passed'
        if (scheduledHour < currentDate.getHours()) return scheduledPast;
        // if hours have not passed, check if minutes have passed
        if (scheduledHour === currentDate.getHours() &&
        scheduledMinute < currentDate.getMinutes()) return scheduledPast;
      }

      // return scheduled date and time depending on user locale
      return timeUtil.returnScheduledDateInUserLocaleFormat({
        scheduledMonth, scheduledDay, scheduledYear, scheduledHour, scheduledMinute,
      });
    }

    // if there is no scheduled selection return an empty string
    return '';
  },

  /**
   * This function helps to return the next possible day in current month for run schedule
   * @param {array} arrayWithDays - an array with selected daysValue in the month
   * @param {date} date - date to select the next possible day
   * @param {number} hoursValue - next hours value
   * @param {number} minutesValue - next minutes value
   * @returns {string|null} the next possible day in current month or null
   */
  findNextDay: (arrayWithDays, date, hoursValue, minutesValue) => {
    const nextDay = arrayWithDays.find((day) => {
      // return today if the selected time has not passed yet
      if ((day === date.getDate() && parseInt(hoursValue) > date.getHours()) || (
        parseInt(hoursValue) === date.getHours() && parseInt(minutesValue) > date.getMinutes())
      ) {
        return day === date.getDate();
      }
      if (day > date.getDate()) {
        // return next day in the array
        return day > date.getDate();
      }
      if (day === date.getDate() && date.getHours() === 0 && date.getMinutes() === 0) {
        // in the case of 00:00, return that day
        return day === date.getDate();
      }

      return null;
    });

    // if the day in the array is found, return it, otherwise return null
    return nextDay || null;
  },

  /**
   * This function helps to check whether the selected day has already passed this week
   * @param {number} targetDayNum - number of selected day
   * @param {object} day - the day we are checking (in moment format)
   * @param {string} timezone - timezone name
   * @returns {string|bool} day of the week or false if not found
   */
  isThisInFuture: (targetDayNum, day, timezone) => {
    // integer value corresponding to the day of the week
    const todayNum = moment().tz(timezone).isoWeekday();

    // check if the day we have selected is after the day we check
    if (todayNum <= targetDayNum) {
      if (todayNum === targetDayNum && moment().tz(timezone).isAfter(day)) {
        return false;
      }

      // return this week's instance of that day
      return moment().tz(timezone).isoWeekday(targetDayNum);
    }

    return false;
  },

  /**
   * This function helps to return the first valid day from the array with selected days
   * @param {object} day - the day we are checking (in moment format)
   * @param {array} daysArray - array with numbers of selected days
   * @param {string} timezone - timezone name
   * @returns {date} return the date with first valid day of next week
   */
  findNextInstanceInDaysArray: (day, daysArray, timezone) => {
    // iterate the array of days and find all possible matches
    const tests = daysArray.map(d => timeUtil.isThisInFuture(d, day, timezone));

    // select the first matching day of this week, ignoring subsequent ones, by finding the first moment object
    const thisWeek = tests.find(sample => sample instanceof moment);

    // but if there are none, we'll return the date with first valid day of next week
    return thisWeek || day.add(1, Constants.SCHEDULE_SELECTION__DATE_VALUE__WEEKS)
      .isoWeekday(daysArray[0]);
  },

  /**
   * @param {array} daysOfWeek - array with selected days of week
   * @param {string} hoursValue - the value of the selected hour
   * @param {string} minutesValue - the value of the selected minutes
   * @param {string} timezone - the timezone name
   * @returns {date} return the date with first valid day of next week
   */
  nextRunForDay: (daysOfWeek, hoursValue, minutesValue, timezone) => {
    // set the date with selected hour and minutes
    const dayWithSelectedTime = moment().tz(timezone)
      .set({
        hour: hoursValue, minute: minutesValue, second: 0, millisecond: 0,
      });

    // for cron expression the week numbers are incremented by 1. Change them to the correct date format
    const dayOfWeekInDateFormat = daysOfWeek.map(week => week);

    // return the date with the first valid day from the array with selected days
    return timeUtil.findNextInstanceInDaysArray(dayWithSelectedTime, dayOfWeekInDateFormat, timezone)
      .format(timeUtil.getUserDateTimeFormat().split(' ')[0]);
  },

  /**
   * This function helps to return the next possible month from selected month
   * @param {array} arrayWithMonths - an array with number of selected months
   * @param {date} date - current date to select the next possible month for run schedule
   * @param {number} day - the number of the current date
   * @param {string} daysValue - the value of the selected day
   * @param {string} hoursValue - the value of the selected hour
   * @param {string} minutesValue - the value of the selected minutes
   * @returns {string|null} the next possible month in selected month or null
   */
  findNextMonth: (arrayWithMonths, date, day, daysValue, hoursValue, minutesValue) => {
    const findMonth = arrayWithMonths.find((month) => {
      /*
       * return this month if forward date is selected
       * we increment the month by one because the moment counts months from 0 and cron expression from 1
       */
      if (month === date.getMonth() + 1 && day < parseInt(daysValue)) {
        return month === date.getMonth() + 1;
      }

      // return this month if today is selected with later execution time
      if (month === date.getMonth() + 1 && day === parseInt(daysValue) &&
              (parseInt(hoursValue) > date.getHours() || (
                parseInt(hoursValue) === date.getHours() && parseInt(minutesValue) > date.getMinutes()))
      ) {
        return month === date.getMonth() + 1;
      }

      // return next month in the array
      if (month > date.getMonth() + 1) {
        return month > date.getMonth() + 1;
      }

      // in the case of 00:00, return that month
      if (month === date.getMonth() + 1 && day === parseInt(daysValue) && date.getHours() === 0 &&
              date.getMinutes() === 0) {
        return month === date.getMonth();
      }

      return null;
    });

    // if the month in the array is found, return it, otherwise return null
    if (findMonth) { return findMonth; }

    return null;
  },

  /**
   * This function returns the rounded minutes to a specified 15-minute interval
   * @param {object} time - time value from DatePicker
   * @param {number} interval - the number of minutes for the interval
   * @returns {number} rounded minutes
   */
  roundUpMinutes: (time, interval) => Math.ceil(time.getMinutes() / interval) * interval,

  /**
   * This function returns the date offset to local user timezone
   * @param {object} date - date value
   * @param {string} utcOffset - utc offset value
   * @returns {date} the date offset to user local timezone
   */
  getOffsetDate: (date, utcOffset) => {
    const newDate = timeUtil.formatFullDate(date, true, false);
    const newTime = timeUtil.formatFullDate(date, false, true);

    const dateObject = Util.buildDateTime(
      newDate,
      newTime,
      utcOffset,
    );

    return dateObject;
  },
};

export default timeUtil;

import Util from './util';

const Features = {
  // Read the features object from the cookie
  getFeaturesFromCookie: () => Util.readObjectFromCookies('features'),

  // Return true if enabled, false if disabled, undefined if features is not defined for this org
  isFeatureEnabled: (featureKey) => {
    const features = Features.getFeaturesFromCookie();

    if (features) return features[featureKey];

    return false;
  },
};

export default Features;

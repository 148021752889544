import axios from 'axios';

import Util from '../util';
import Constants from '../constants/constants';

const apiUrl = process.env.REACT_APP_API_URL;

const UsersAPI = {
  /**
   * retrieves users with the same orgId
   * @param {object} cancelToken - Axios token
   * @returns {array<object>} List of the users
   */
  getUsers: async (cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/users?productPackage=${Constants.DESELECT__SEGMENTATION}`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data.users;
  },

  /**
   * update an org's user with the specified id
   * @param {string} id - user ID
   * @param {object} userData - object with updated userData
   * @param {object} cancelToken - Axios token
   * @returns {object} Updated object with user data
   */
  updateUser: async (id, userData, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.put,
      `${apiUrl}/users/${id}`,
      userData,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data;
  },

  /**
   * update an org's user with the specified id
   * @param {object} preferences - object with updated userData
   * @param {object} cancelToken - Axios token
   * @returns {object} Updated object with user data
   */
  updatePreferences: async (preferences, cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.put,
      `${apiUrl}/users/preferences`,
      { preferences },
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data?.preferences;
  },

  /**
   * update an org's user with the specified id
   * @param {object} cancelToken - Axios token
   * @returns {object} Updated object with user data
   */
  getPreferences: async (cancelToken) => {
    const res = await Util.catch418And403Error(
      axios.get,
      `${apiUrl}/users/preferences`,
      Util.apiGetCallSettings(cancelToken),
    );

    return res.data;
  },
};

export default UsersAPI;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import DOMPurify from 'dompurify';
import LogRocket from 'logrocket';

import Util from './util';
import Constants from './constants/constants';
import NetworkErrorModal from './NetworkErrorModal';
import Button from './components/shared/Button/Button';

const user = Util.readObjectFromCookies(process.env.REACT_APP_SEGMENT__SESSION_USER_KEY);
const org = Util.readObjectFromCookies('org');

if (process.env.REACT_APP_SENTRY_ENV && process.env.REACT_APP_SENTRY_ENV !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENV,
  });
}

if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test' && user) {
  // Crash Reporting integrations add a LogRocket session to every crash report and exception
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => { scope.setExtra('sessionURL', sessionURL); });
  });

  // if user and org exists, set respective properties for pendo
  if (user && org) {
    LogRocket.identify(user.id, {
      name: user.name,
      username: user.username,
    });

    // eslint-disable-next-line no-undef
    pendo.initialize({
      visitor: {
        id: user.id,
        name: user.name,
        username: user.username,
        isAdmin: user.isAdmin,
        loggedInBusinessUnitId: user.loggedInBusinessUnitId,
        email: user.email,
        full_name: user.name,
        createdAt: user.createdAt,
      },

      account: {
        id: user.orgId,
        name: org.name,
        edition: org.edition,
        enterpriseBusinessUnitId: org.enterpriseBusinessUnitId,
        type: org.type,
      },
    });
  }

  LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY);
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      showErrorModal: false,
      eventId: null,
    };
  }

  // Catches errors and sets the error states
  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test' && user) {
      this.setState({
        error,
        showErrorModal: true,
      });
      Sentry.withScope((scope) => {
        const { currentSelectionId } = this.state;
        // send current selection's id along with the captured error if it exists

        if (currentSelectionId) {
          // eslint-disable-next-line no-param-reassign
          errorInfo.selectionId = currentSelectionId;
        }
        scope.setExtras(errorInfo);
        if (user) {
          scope.setUser({
            id: user._id,
            userName: user.username,
          });
        }
        const eventId = Sentry.captureException(error);

        this.setState({ eventId });
      });
    } else {
      this.setState({
        showErrorModal: true,
        error,
        eventId: errorInfo,
      });
    }
  }

  /**
   * Reset error states
   * @returns {void} Will setState for error, eventId and sentryModalFeedbackMessage
   */
  cancelError = () => {
    this.setState({
      error: false,
      eventId: null,
      currentSelectionId: '',
      sentryModalFeedbackMessage: '',
      showErrorModal: false,
    });
  };

  /**
   * Runs when send feedback button on the error modal is clicked
   * @returns {void}
   */
  handleReportFeedBack = () => {
    // check the environment is not development and test
    if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
      const { eventId } = this.state;

      // close the error modal
      this.setState({
        showErrorModal: false,
        sentryModalFeedbackMessage: '',
      });

      // open the feedback modal of the Sentry
      Sentry.showReportDialog({ eventId });
      const { showErrorModal } = this.state;

      /**
       * Start interval
       * Interval is used here to check if form is submitted or closed
       */
      this.interval = setInterval(() => {
        // get the Sentry modal as html element
        const sentryModal = document.getElementsByClassName('sentry-error-embed');

        /**
         * Get the feedback message
         * It will not be undefined only when form is filled and submitted
         */
        const sentryModalFeedbackMessage = document.getElementsByClassName('message-success');

        if (sentryModalFeedbackMessage) {
          this.setState({
            sentryModalFeedbackMessage: 'Your feedback has been sent. Thank you!',
          });
        }

        // if the Sentry modal is closed then open the error modal again with success feedback message
        if ((!sentryModal || sentryModal.length === 0) && showErrorModal) {
          this.setState({
            showErrorModal: true,
          });
          clearInterval(this.interval);
        }
      }, 1000);
    }
  };

  render() {
    const { error, sentryModalFeedbackMessage, showErrorModal } = this.state;
    const { children } = this.props;

    // Check if error is a 'network error'
    if (error && error.toString() === Constants.NETWORK_ERROR) {
      return (
        <NetworkErrorModal error cancelError={this.cancelError} />
      );
    }

    // For other type of error
    if (error) {
      return (
        <>
          <section
            role="dialog"
            tabIndex="-1"
            className="slds-modal slds-fade-in-open slds-modal_small other-than-network-error-modal"
            aria-labelledby="modal-heading-01"
            aria-modal="true"
            aria-hidden={showErrorModal}
          >
            <div className="slds-modal__container">
              <header className="slds-modal__header">
                <h2 id="modal-heading-01" className="slds-modal__title slds-hyphenate">Oops</h2>
              </header>
              <div className="slds-modal__content slds-p-around_medium" style={{ textAlign: 'center' }}>
                <p>{sentryModalFeedbackMessage || 'Something didn\'t quite go as planned'}</p>
                <img alt="Penguin" src="./img/sad-penguin.png" height="250px" width="250px" />
                {/* eslint-disable-next-line react/no-danger */}
                <p dangerouslySetInnerHTML={({
                  __html: DOMPurify.sanitize(error?.response?.data?.error || error).replace('<a', '<a target="_blank"'),
                })}
                />
              </div>
              <footer className="slds-modal__footer">
                <Button
                  submitButton
                  buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                  onClick={() => this.cancelError()}
                >
                  Go back
                </Button>
                <Button
                  submitButton
                  buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                  onClick={() => this.handleReportFeedBack()}
                >
                  Report Feedback
                </Button>
              </footer>
            </div>
          </section>
          <div className="slds-backdrop slds-backdrop_open" />
        </>
      );
    }

    // if there is no error, return children
    return children;
  }
}

ErrorBoundary.propTypes = {
  /**
   * Children nodes for this component
   */
  children: PropTypes.node,
};

export default ErrorBoundary;

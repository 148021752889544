import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import Features from '../../../../features';
import Constants from '../../../../constants/constants';

const classNames = require('classnames');

const FilterSetCard = ({
  filterSearchField = '',
  handleSetSelectionState,
  DEBorderMouseOver,
  selectedDataExtensions,
  filterSetsToDisplay,
  filterSets,
  toggleFilterSetsVisibility,
  positionIndex,
}) => {
  const featureCustomValuesIsEnabled = Features.isFeatureEnabled(
    Constants.FEATURE__CUSTOM_VALUES,
  );

  const filteredFilterSets = filterSetsToDisplay?.filters?.filter((f) => {
    return f.name?.toString()?.toLowerCase().includes(filterSearchField.toString().toLowerCase()) ? f : '';
  }) || [];

  /**
   * set filter set id or add DE to filterset
   * @param {Object} e - filter set object
   * @returns {void}
   */
  const handleSetFilterSetIdandType = (e) => {
    if (e.target.dataset?.filterSetId) {
      e.dataTransfer.setData('filterSetId', e.target.dataset?.filterSetId);
    } else {
      e.dataTransfer.setData('filterSetType', 'behavioral');
    }
  };

  /**
   * Returns the relevant description for the filter set
   * @param {Object} filterSet - filter set object
   * @returns {string} description depending on the filter set has description or not
   */
  const getFilterSetDescription = filterSet => filterSet.description || 'No description available.';

  return (
    <div>
      <div
        className={classNames(
          'collection-label-container',
          { 'disabled-cv': !featureCustomValuesIsEnabled },
        )}
        onClick={() => {
          toggleFilterSetsVisibility(
            filterSetsToDisplay?.category?.name,
            filterSetsToDisplay?.showFilterSetFields,
            positionIndex,
          );
        }}
        style={{ cursor: 'pointer' }}
        >
          <div
          className="chevron-icons-container"
          >
          <svg
          className="slds-icon-size"
          aria-hidden="true"
          id="right-icon"
          >
            {
            filterSetsToDisplay?.showFilterSetFields ?
              (
              <use xlinkHref="assets/icons/utility-sprite/svg/symbols.svg#down" />
              ) :
              (
                <use xlinkHref="assets/icons/utility-sprite/svg/symbols.svg#right" />
              )
            }
          </svg>
          </div>
        <span
          className="collection-name slds-truncate"
        >
          <p className="slds-truncate">
          {
              filterSetsToDisplay?.category?.name
          }
          </p>
        </span>
      </div>
      {filterSetsToDisplay?.filters && filterSetsToDisplay?.showFilterSetFields && filteredFilterSets?.map(f => (
          <div
            title={getFilterSetDescription(f)}
            data-filter-set-id={f._id}
            className="drag-field available-field"
            onDragStart={(e) => {
              handleSetFilterSetIdandType(e);

              return e;
            }}
            onDragEnd={() => handleSetSelectionState({ filterBorderMouseOver: false, DEBorderMouseOver: false })}
            onDragOver={e => e.preventDefault()}
            draggable
            key={f._id}
            style={{ pointerEvents: DEBorderMouseOver ? 'none' : '' }}
          >
            <span>{f.name}</span>
          </div>
      ))}

      {
        filterSets?.length === 0 && (
          <span className="filter-sets-message">
            You do not have any predefined Filter Sets. You can set them up from the admin panel.
          </span>
        )
      }

      {
        filterSetsToDisplay?.filters?.filters?.length === 0 && (selectedDataExtensions?.length !== 0) &&
        (filterSets?.length !== 0) && (
          <span className="filter-sets-message">
            You do not have any predefined Filter Sets for this data source.
            Please select a data source where filter sets are defined.
          </span>
        )
      }

      {
        selectedDataExtensions?.length === 0 && (filterSets?.length !== 0) && (
          <span className="filter-sets-message">
            Please select a Data Source to view existing Filter Sets.
          </span>
        )
      }

    </div>
  );
};

FilterSetCard.propTypes = {
  /**
   * it keeps the given value of the search input on available fields
   */
  filterSearchField: PropTypes.string,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /*
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * An array containing filter sets to display
   */
  filterSetsToDisplay: PropTypes.instanceOf(Array),
  /**
   * An array containing all filter sets
   */
  filterSets: PropTypes.instanceOf(Array),
  /**
   * selected data extensions
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * a function to toggle filterSet visibility
   */
  toggleFilterSetsVisibility: PropTypes.func.isRequired,
  /**
   * Index position of filter set category
   */
  positionIndex: PropTypes.number,
};

export default FilterSetCard;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './styles.scss';
import axios from 'axios';
import _ from 'lodash';

import FilterContainer from '../FilterContainer/FilterContainer';
import Constants from '../../../../../constants/constants';
import Features from '../../../../../features';
import Util from '../../../../../util';
import filtersUtil from '../../../../../utils/filters/filtersUtil';
// eslint-disable-next-line import/no-cycle
import SubQueryModal from '../SubqueryModal/SubQueryModal';
import DataExtensionsAPI from '../../../../../api/data-extensions';
import SwalUtil from '../../../../../utils/swal/swalUtil';
import TimezoneModal from '../TimezoneModal/TimezoneModal';
import TimezoneList from '../../../ScheduleSelectionModal/TimezoneList.json';
import timeUtil from '../../../../../utils/time/timeUtil';
import FilterSetsModal from '../../FilterSetModal/FilterSetModal';
import DataViewsAPI from '../../../../../api/data-views';

/*
 * we tried PureComponent and the issue we got is
 * after changing an alias of a selectedExtension
 * on the filterLine it wasn't changed
 */
class SelectedFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      operator: Constants.FILTERLINE__OPERATOR__AND,
      showSubQueryModal: false,
      subQueryModalSelectedDataExtensions: [],
      subQueryModalSelectedFilters: [],
      subQueryModalFilterLineId: '0',
      showText: false,
      isSubQueryRelation: false,
      tags: {},
      showTimezoneModal: false,
      convertTimezone: false,
      convertToTimezone: '',
      convertFromTimezone: Constants.TIME_ZONE__CENTRAL_STANDARD_TIME__VALUE,
      filterId: '',
      savedApplyTimezoneSettingsToAllDateFields: false,
      collapseFormulas: false,
      selectDataExtension: false,
      filterSetSelectedSourceDE: '',
      selectedDataExtensionFieldId: '',
      selectDataExtensionFieldName: '',
      selectDataExtensionName: '',
      selectedFilterSetId: '',
      selectedSubscription: 'SubscriberKey',
      loadingDataExtensions: false,
      dataViewName: '',
      behavioralFilterSetCriteria: '',
      hasSendable: true,
      filterSetSelectedSourceDEObjectID: '',
    };

    this.featureDynamicDateFilterIsEnabled = Features.isFeatureEnabled(
      Constants.FEATURE__DYNAMIC_DATE_FILTERS,
    );
    this.regexForSearchingComma = /\s*,\s*/;
    this.axiosCancelToken = axios.CancelToken.source();
  }

  componentDidMount() {
    const { selectedDataExtensions } = this.props;

    this.checkSendableDataExtension(selectedDataExtensions);
  }

  componentDidUpdate(prevProps) {
    const { selectedDataExtensions } = this.props;

    if(prevProps?.selectedDataExtensions !== selectedDataExtensions) {
      this.checkSendableDataExtension(selectedDataExtensions);
    }
  }

  /* React function called every time the props have changed, including during mounting */
  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};

    if (
      nextProps.selectedFilters !== prevState.selectedFilters &&
      nextProps.selectedFilters.filters &&
      nextProps.selectedFilters.operator
    ) {
      newState.filters = nextProps.selectedFilters.filters;

      newState.operator = nextProps.selectedFilters.operator;
      // DO NOT DELETE THE LINE BELOW, IT WILL BREAK THE TOP LEVEL AND-OR LOGIC.
      newState.selectedFilters = nextProps.selectedFilters;

      return newState;
    }

    return null;
  }

  /**
   * Function that checks if there is sendable data extensions
   * @param {*} selectedDataExtensions - selected data extensions
   * @returns {void}
   */
  checkSendableDataExtension = (selectedDataExtensions) => {
    // check if there is sendable data extension, populate it in filterSetModal.js
    const res = selectedDataExtensions?.find((obj) => {
      if(obj.IsSendable === true) {
        const fieldData = obj?.fields?.find(de => de.Name === obj?.SendableDataExtensionField.Name);

        this.setState({
          filterSetSelectedSourceDE: obj.deAlias,
          filterSetSelectedSourceDEObjectID: obj.ObjectID,
          selectDataExtensionFieldName: obj?.SendableDataExtensionField.Name,
          selectDataExtensionName: obj.deAlias,
          selectedDataExtensionFieldId: fieldData?.ObjectID,
          selectedSubscription:
          obj?.SendableSubscriberField?.Name === '_SubscriberKey' ? 'SubscriberKey' : 'SubscriberID',
          hasSendable: true,
        });

        return obj;
      }

      return null;
    });

    if(!res) {
      selectedDataExtensions?.find((obj) => {
        this.setState({
          filterSetSelectedSourceDE: obj.deAlias,
          filterSetSelectedSourceDEObjectID: obj.ObjectID,
          selectDataExtensionFieldName: obj?.fields[0]?.Name,
          selectDataExtensionName: obj.deAlias,
          selectedDataExtensionFieldId: obj?.fields[0]?.ObjectID,
          hasSendable: true,
        });

        return obj;
      });
    }
  };

  /**
   * Gets the current value of filters and updates the object with tags
   * @returns {void}
   */
  updateTags = () => {
    const { tags, filters } = this.state;

    const arrayWithFilterKeyValuePair = [];

    // get an array with the IDs of the filters using tags
    const tagsIds = Object.keys(tags) || [];

    const newTags = tags;

    // get an array with all selected filters
    const allFilters = filtersUtil.getAllFilters({ filters }, []);

    allFilters.forEach((filter) => {
      // Set isUsingTagInput if fieldType is decimal/number and criteria is in/not in
      const isUsingTagInput = filtersUtil.isFilterUsingTagInput(filter.fieldType, filter.criteria);

      if (isUsingTagInput) {
        // for a new filter, add its id to the array with tagsIds
        if (!tags[filter.id]) {
          tagsIds.push(filter.id);
        }

        // for each filter, update its value in the object with tags
        newTags[filter.id] = filter.value;
      }

      tagsIds.forEach((id) => {
        if (id.toString() === filter.id.toString() && isUsingTagInput) {
          // create an array with arrays [key: value]
          const keyValueInArray = [id, newTags[id]];

          arrayWithFilterKeyValuePair.push(keyValueInArray);
        }
      });
    });

    // save tags as {key: value} objects
    this.setState({ tags: _.fromPairs(arrayWithFilterKeyValuePair) });
  };

  /**
   * Handles the showing of timezone modal
   * @param {string} id - id of the filterline
   * @returns {void}
   */
  handleOpenTimezoneModal = (id) => {
    const { filters } = this.state;
    const { applyTimezoneSettingsToAllDateFields } = this.props;

    // Find filter element
    const filter = this.findFilterElement(id, filters).element;

    const {
      convertTimezone, convertToTimezone, convertFromTimezone,
    } = filter;

    const valuesToUpdate = {
      ...convertTimezone && { convertTimezone },
      ...convertToTimezone && { convertToTimezone },
      ...convertFromTimezone && { convertFromTimezone },
      savedApplyTimezoneSettingsToAllDateFields: applyTimezoneSettingsToAllDateFields,
    };

    this.setState({ showTimezoneModal: true, filterId: id, ...valuesToUpdate });
  };

  /**
   * Handles the showing of timezone modal
   * @returns {void}
   */
  handleCloseTimezoneModal = () => {
    const { savedApplyTimezoneSettingsToAllDateFields } = this.state;
    const { applyTimezoneSettingsToAllDateFields, handleSetSelectionState } = this.props;

    if (savedApplyTimezoneSettingsToAllDateFields !== applyTimezoneSettingsToAllDateFields) {
      handleSetSelectionState({ applyTimezoneSettingsToAllDateFields: savedApplyTimezoneSettingsToAllDateFields });
    }

    this.setState({
      showTimezoneModal: false,
      filterId: '',
    });
  };

  handleCollapseAllFormulas = (collapseState) => {
    this.setState({ collapseFormulas: collapseState });
  };

  /**
   * Event handler for when convert timezone's value is changed
   * @returns {void}
   */
  handleConvertTimezone = () => {
    this.setState(prevState => ({
      convertTimezone: !prevState.convertTimezone,
      ...!prevState.convertToTimezone && {
        convertToTimezone: timeUtil.getCurrentUserTimezone(TimezoneList, true),
      },
      ...!prevState.convertFromTimezone && {
        convertFromTimezone: Constants.TIME_ZONE__CENTRAL_STANDARD_TIME__VALUE,
      },
    }));
  };

  /**
   * Event handler for when form elements' values are changed
   * Most inputs' and selections' onChange events are handled using this function
   * @param {object} e - onChange event object
   * @returns {void}
   */
  handleFormElementChanged = (e) => {
    const { value, name } = e.target;

    this.setState({ [name]: value });
  };

  /**
   * Event handler for when applyTimezoneSettingsToAllDateFields values is changed
   * @returns {void}
   */
  handleChangeApplyTimezoneToAllDateFields = () => {
    const { handleSetSelectionState } = this.props;

    handleSetSelectionState(prevState => ({
      applyTimezoneSettingsToAllDateFields: !prevState.applyTimezoneSettingsToAllDateFields,
    }));
  };

  /**
   * Saves a filterline's timezone settings
   * @returns {void}
   */
  handleSaveTimezoneSettings = () => {
    const {
      filterId,
      convertTimezone,
      convertToTimezone,
      convertFromTimezone,
    } = this.state;

    let { filters } = this.state;

    const {
      handleFiltersSave, applyTimezoneSettingsToAllDateFields, handleSetTimezoneToAllDateFields, isRegularFilter,
    } = this.props;

    const settings = {
      convertTimezone, convertToTimezone, convertFromTimezone,
    };

    if (applyTimezoneSettingsToAllDateFields) {
      filtersUtil.updateTimezoneSettingsForDateFilter({ filters }, settings);
    } else {
      // Find filter element
      const filter = this.findFilterElement(filterId, filters).element;

      filtersUtil.updateTimezoneSettingsForDateFilter(filter, settings);
    }

    this.setState(
      {
        filters,
        convertTimezone: false,
        convertToTimezone: '',
        convertFromTimezone: Constants.TIME_ZONE__CENTRAL_STANDARD_TIME__VALUE,
        showTimezoneModal: false,
        filterId: '',
      },
      () => {
        const { operator } = this.state;

        ({ filters } = this.state);
        handleFiltersSave({ filters, operator });

        if (applyTimezoneSettingsToAllDateFields && isRegularFilter) {
          handleSetTimezoneToAllDateFields(convertTimezone, convertToTimezone, convertFromTimezone);
        }
      },
    );
  };

  /**
   * Find a filterline or filtercontainer in the filters array. Filter space container.
   * @param {string} id - id of a filter
   * @param {object} filtersArray - array of filters
   * @returns {string} - element
   */
  findFilterElement = (id, filtersArray) => {
    // Loop through filtersArray to find filterLine
    let element;

    let parent;

    for (let f = 0; f < filtersArray.length; f += 1) {
      // Find filterLine
      if (filtersArray[f].id === id) {
        element = filtersArray[f];
        parent = filtersArray;
        break;
      } else if (filtersArray[f].filters) {
        // If the array element contains a filters array, execute this function on this branch
        const e = this.findFilterElement(id, filtersArray[f].filters);

        if (e) ({ element } = e);
      }
    }

    return element && { element, parent };
  };

  /**
   * Function for moving a filter line to a filter zone in a filter container
   * @param {string} filterLineId - id of filter
   * @param {string} filterContainerId - id of container where it is dropped
   * @param {string} toIndex - index to move filterLine to
   * @param {bool} isInResultsFormula - check if criteria in filterline is in results formula
   * @param {string} parentId - filter's parent id
   * @returns {void}
   */
  moveFilterToFilterContainer = async (filterLineId, filterContainerId, toIndex, isInResultsFormula, parentId) => {
    let { filters } = this.state;
    const {
      handleRemoveFilterLine, handleFiltersSave, filterType, isSubQuery, subQueryModalFilterLineId,
    } = this.props;

    let originFilterContainer;

    let destinationFilterContainer;

    let idx;

    let destinationIndex;

    let filtersArray = filters;

    let parentFilter;

    if (isInResultsFormula) {
      parentFilter = this.findFilterElement(parentId, filters)?.element;

      filtersArray = parentFilter?.subQuery?.formulas?.filters;
    }

    const delimeter = isInResultsFormula ? '-' : ',';
    // to know if filter is nested
    const indexOfDelimeter = filterLineId.lastIndexOf(delimeter);
    const id = indexOfDelimeter === -1 ?
      null :
      filterLineId.substring(0, indexOfDelimeter);

    // If filter is in the highest level 'container'
    if (indexOfDelimeter === -1 || (isInResultsFormula && id === parentId)) {
      originFilterContainer = { filters: filtersArray, id: null };
    } else {
      // filter is in a nested container
      const searchFilterContainer = this.findFilterElement(id, filtersArray);

      originFilterContainer = searchFilterContainer.element;
    }

    // check if destination container is nested
    if (filterContainerId === undefined) {
      // highest level 'container'
      destinationFilterContainer = { filters: filtersArray, id: null };
    } else {
      // container is nested, so find the actual container
      destinationFilterContainer = this.findFilterElement(filterContainerId, filtersArray).element;
    }

    // find filterLine
    const searchFilterLine = this.findFilterElement(filterLineId, filtersArray);
    const filterLine = searchFilterLine && { ...searchFilterLine.element };

    if (searchFilterLine?.element) {
      // Check if filter is being moved within its container
      if (destinationFilterContainer.id === originFilterContainer.id) {
      /*
       * filters are in the same container
       * get the index of the filter
       */
        idx = indexOfDelimeter === -1 ?
          filterLineId :
          filterLineId.substring(indexOfDelimeter + 1);
        /*
         * remove the filter from the filters array
         * No need to use the handleRemoveFilterLine function since filter is being moved within its container
         */
        originFilterContainer.filters.splice(idx, 1);

        // make sure toIndex is not undefined
        destinationIndex = toIndex === undefined ?
          originFilterContainer.filters.length :
          toIndex;
        let offset = 0;

        // If we're moving a filterline from top to bottom
        if (+idx < toIndex) {
          offset = -1;
        }

        // Insert filter into the filters array
        originFilterContainer.filters.splice(destinationIndex + offset, 0, filterLine);

        this.recreateIds(filtersArray, parentId, isInResultsFormula);
      } else {
      /*
       * filter is in a different container from its own
       * make sure toIndex is not undefined
       */
        destinationIndex = toIndex === undefined ?
          destinationFilterContainer.filters.length :
          toIndex;
        // temporarily change the id so that the moved filterline doesn't get deleted
        filterLine.id = 'temp';
        // insert filter into the filters array
        destinationFilterContainer.filters.splice(destinationIndex, 0, filterLine);

        /*
         *  this function is used to make sure we rearrange filterlines in the
         * case where the filter to be [re]moved is the last in its container
         */
        handleRemoveFilterLine({
          filterLineId,
          fieldExists: true,
          toIgnore: true,
          subQueryModalFilterLineId,
          isSubQuery,
          handleFiltersSave,
          filterType,
          subQueryFilters: filters,
          parentId,
          isInResultsFormula,
        });
      }
    }

    this.setState(
      {
        filters,
      },
      () => {
        const { operator } = this.state;

        ({ filters } = this.state);
        handleFiltersSave({ filters, operator });
      },
    );
  };

  /**
   * On Drop - gets data and updates "selectedFilters" state
   * @param {object} e - event
   * @param {string} containerId - id of a container (parent)
   * @param {string} toIndex - index to move filterLine to
   * @param {bool} isInResultsFormula - check if criteria in filterline is in results formula
   * @param {string} parentId - filter's parent id
   * @returns {void}
   */
  dropFieldOnFilters = async (e, containerId, toIndex, isInResultsFormula, parentId) => {
    e.preventDefault();

    // Checks retrieve filterSetId (mark it as null if not defined)
    const filterSetId = e?.dataTransfer?.getData('filterSetId')?.length > 0 ?
      e.dataTransfer.getData('filterSetId') :
      null;

    if (filterSetId) {
      this.addFilterSetFilters(filterSetId, containerId);

      return;
    }
    const type = e.dataTransfer.getData('type');

    if (type === 'filter') {
      const filterLineId = e.dataTransfer.getData('filterLineId');

      this.moveFilterToFilterContainer(filterLineId, containerId, toIndex, isInResultsFormula, parentId);
      this.updateTags();
    } else {
      let deObjectID = e.dataTransfer.getData('deObjectID');
      const deAlias = e.dataTransfer.getData('alias');
      const fieldObjectID = e.dataTransfer.getData('fieldObjectID');

      // relation ID for relation type of dragged filter
      const relationId = e.dataTransfer.getData('relationId');

      /*
       * if deObjectID is 'undefined' then we are dealing with data views,
       * in this case assign customerKey as ObjectID to this filter
       */
      if (deObjectID === 'undefined') {
        deObjectID = e.dataTransfer.getData('customerKey');
      }

      this.addNewFilterline(fieldObjectID, deAlias, containerId, deObjectID, null, type, relationId);
    }
  };

  /**
   * Id generator for filters
   * @param {string} parentId - if of a parent
   * @param {number} id - id
   * @param {bool} isInResultsFormula - check if criteria in filterline is in results formula
   * @returns {string} - newId
   */
  createId = (parentId, id, isInResultsFormula) => {
    if (isInResultsFormula) {
      return parentId + '-' + id;
    }

    return parentId == null ? '' + id : parentId + ',' + id;
  };

  /**
   * Recreate all ids for filters
   * Needs to be called after updated filters has been saved as state
   * @param {object} filtersArray - filters
   * @param {string} parentId - id of a parent
   * @param {bool} isInResultsFormula - check if criteria in filterline is in results formula
   * @returns {object} - filters
   */
  recreateIds = (filtersArray, parentId, isInResultsFormula) => {
    // Loop through filtersArray
    for (let f = 0; f < filtersArray.length; f += 1) {
      // Update id for this element
      // eslint-disable-next-line no-param-reassign
      filtersArray[f].id = this.createId(
        parentId,
        f,
        isInResultsFormula,
      );

      if (filtersArray[f].subQuery?.formulas?.filters?.length) {
        this.recreateIds(filtersArray[f].subQuery.formulas.filters, filtersArray[f].id, true);
      }

      /*
       * If the array element contains a filters array, execute this function on this branch
       * (executes when you join filters)
       */
      if (filtersArray[f].filters) {
        this.recreateIds(filtersArray[f].filters, filtersArray[f].id, isInResultsFormula);
      }
    }

    return filtersArray;
  };

  /**
   * Create a new FilterLine (with field, operator, value) to filtercontainer in filters
   * @param {string} fieldObjectID - field Object ID
   * @param {string} collectionAlias - DE alias
   * @param {string} filterContainerId - id of filter's container
   * @param {string} deId - data extension Object ID (or Customer Key in case of Data Views)
   * @param {object} filtersBranch - branch with filters
   * @param {object} type - type of dragged filter
   * @param {object} relationId - Id of predefined relations for relation type
   * @returns {void}
   */
  addNewFilterline = (fieldObjectID, collectionAlias, filterContainerId, deId, filtersBranch, type, relationId) => {
    const { filters, operator } = this.state;
    const {
      hideCollectionAlias,
      selectedDataExtensions,
      handleFiltersSave,
      pickLists,
      applyTimezoneSettingsToAllDateFields,
      timezoneSettingsForAllDateFields,
    } = this.props;

    const copySelectedCollectionFields = JSON.parse(JSON.stringify(selectedDataExtensions));

    // Filter based on selected DE - get proper DE
    let filteredFields;

    if (collectionAlias.toString() === 'undefined' && type !== Constants.FILTERLINE__FILTER_TYPE__RELATION) {
      filteredFields = [...selectedDataExtensions];
      // eslint-disable-next-line no-param-reassign
      collectionAlias = selectedDataExtensions[0].Name.toString();
    } else {
      // search data extension by ID and deAlias
      filteredFields = copySelectedCollectionFields.filter(dataExtension => (
        dataExtension.ObjectID === deId ||
        // for dataViews we will use CustomerKey
        dataExtension.CustomerKey === deId) &&
        dataExtension.deAlias === collectionAlias);
    }

    if (filteredFields?.length && filteredFields[0]?.fields) {
      // Get the filter, which is dragged, from list of filters - from the proper DE
      const updatedFields = filteredFields[0].fields
        .filter(f => f.ObjectID === fieldObjectID);

      if (filteredFields[0]) {
        const updatedField = updatedFields[0];

        /*
         * This is the filters array we need to find a matching element on:
         * works on the filters state unless a specific branch is passed
         */
        let filtersArray = filtersBranch || filters;

        // when adding new filterline find options from the picklist for the field we are adding
        const optionForPicklist = filtersUtil.returnActiveOptionsForPicklist(pickLists, updatedField.ObjectID);

        /**
         * Retrieves the new filter line value
         * @param {array} optForPicklist - Options from the picklist for the field we are adding
         * @param {object} updatedFieldFromDE - Get the filter from list of filters - from the proper DE
         * @returns {string} The new filter line value
         */
        const newFilterLineValue = (optForPicklist, updatedFieldFromDE) => {
          if (optForPicklist.length === 0 &&
            (updatedFieldFromDE.FieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ||
              updatedFieldFromDE.FieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL)) {
            return '0';
          }
          if (optForPicklist.length > 0) return optForPicklist[0].value;

          return '';
        };

        // Loop through filtersArray
        let foundContainer = false;

        let timezoneSettingsToApply = timezoneSettingsForAllDateFields;

        if (applyTimezoneSettingsToAllDateFields) {
          // Find first available date filter
          const filtersParam = Array.isArray(filters) ? filters : [filters];

          const firstAvailableFilter = filtersUtil.findDateTypeFilter(filtersParam);

          if (firstAvailableFilter) {
            const {
              convertToTimezone, convertTimezone, convertFromTimezone,
            } = firstAvailableFilter;

            timezoneSettingsToApply = {
              convertToTimezone, convertTimezone, convertFromTimezone,
            };
          }
        }

        // Create new filter line
        const newFilterLine = {
          fieldObjectID: updatedField?.ObjectID ? updatedField.ObjectID : null,
          field: updatedField?.Name ? updatedField.Name.toString() : null,
          collectionAlias,
          deId,
          hideCollectionAlias: !!hideCollectionAlias,
          fieldType: updatedField.FieldType,
          criteria: '',
          value: type === Constants.FILTERLINE__FILTER_TYPE__RELATION ?
            '0' :
            newFilterLineValue(optionForPicklist, updatedField),
          includesSubQuery: false,
          subQuery: {
            fields: [],
            filters: [],
            formulas: {
              filters: [],
              operator: Constants.FILTERLINE__OPERATOR__AND,
            },
            relations: [],
            relationData: {},
            collections: [],
            dataExtensionFields: [],
          },
          isCompareFieldsFilter: false,
          isPicklistOptions: true,
          comparedDataExtension: {},
          comparableFields: [],
          comparedField: {},
          relation: type === Constants.FILTERLINE__FILTER_TYPE__RELATION,
          relationId,
          ...applyTimezoneSettingsToAllDateFields && updatedField.FieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
          timezoneSettingsToApply,
        };

        if (updatedField.FieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
          type !== Constants.FILTERLINE__FILTER_TYPE__RELATION) {
          newFilterLine.criteria = Constants.FILTERLINE__CRITERIA__SMALLER_THAN;
          newFilterLine[
            Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_FILTER_TYPE
          ] = Constants.FILTERLINE__DATE_TYPE__CALENDAR;
          newFilterLine.dateValueStart = Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY;
          newFilterLine.filterInterval = Constants.FILTERLINE__DATE_VALUE__INTERVAL_DAYS;
          newFilterLine.dateValue = 1;
          // if there is not option from the picklists handle dates normally
          if (optionForPicklist.length === 0) {
            const date = moment(new Date().setHours(0, 0));

            newFilterLine.value = date.add(date.utcOffset(), 'm').utc();
            // otherwise assign fist option to the filterline value
          } else {
            newFilterLine.value = optionForPicklist[0].label;
          }
        }

        // Adding the filter
        for (let f = 0; f < filtersArray.length; f += 1) {
          // If filterContainer is found add new filter to next level
          if (filtersArray[f].id === filterContainerId) {
            if (filtersArray[f].filters) {
              filtersArray[f].filters.push(newFilterLine);
            }

            // Stop looping through filtersArray
            foundContainer = true;
            break;
          } else if (filtersArray[f].filters) {
            // If the array element contains a filters array, execute this function on this branch
            this.addNewFilterline(
              fieldObjectID,
              collectionAlias,
              filterContainerId,
              deId,
              filtersArray[f].filters,
              type,
              relationId,
            );
          }
        }
        if (!foundContainer && !filtersBranch && !filterContainerId) {
          // If no container found - add new filter to top level
          filtersArray.push(newFilterLine);
        }
        const { isSubQuery, handleUpdateSubQuery, subQueryModalFilterLineId } = this.props;

        // If we're working with a subquery, update the subquery property of the filterline ondrop
        if (isSubQuery) {
          const subQueryFilters = { filters: filtersArray, operator };

          // Update filterline
          handleUpdateSubQuery(subQueryModalFilterLineId, subQueryFilters);
        }

        filtersArray = filtersUtil.recreateIds(filtersArray);

        this.setState({ filters: filtersArray }, () => {
          handleFiltersSave({ filters: filtersArray, operator });
        });
      }
    } else {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        message: 'You can only drop filters here.',
      });
    }
  };

  /**
   * Updates the container IDs of the filters from filter set
   * @param {Object} params - params
   * @param {Array} params.filters - filters from filter set
   * @param {Object} params.filterContainer - filters' container
   * @param {Boolean} params.isUpdateIndexNeeded - tells if filters index should be updated or not
   * @param {number} params.filtersStateLength - length of filters on the current state
   * @returns {Array} array of updated filters
   */
  updateFilterContainerIds = ({
    filters, filterContainer, isUpdateIndexNeeded = true, filtersStateLength = null,
  }) => {
    return filters?.map((filter, index) => {
      const updatedFilter = { ...filter };
      const updatedId = this.updateFilterContainerIds({
        filterContainer, isUpdateIndexNeeded, filtersStateLength, index,
      });

      updatedFilter.id = updatedId;

      if (filter.filters) {
        updatedFilter.filters = this.updateFilterContainerIds({
          filters: filter.filters,
          filterContainer: updatedFilter,
          isUpdateIndexNeeded: false,
        });
      }

      return updatedFilter;
    }, []);
  };

  /**
   * Finds a filter based on the filter id
   * @param {Array} filters - filters to search from
   * @param {Object} filterContainerId - filter container id
   * @returns {object | null} filter found or null if not found
   */
  findFiltersContainer = (filters, filterContainerId) => {
    for (let i = 0; i < filters?.length; i += 1) {
      if (filters[i].id === filterContainerId) {
        return filters[i];
      }

      if (filters[i]?.filters) {
        const filterFoundInChild = this.findFiltersContainer(filters[i]?.filters, filterContainerId);

        if (filterFoundInChild?.id === filterContainerId) {
          return filterFoundInChild;
        }
      }
    }

    return null;
  };

  /**
   * Format and return DE aliases options
   * @param {String} dataExtensionObjectId - DE object ID
   * @returns {Array} - DE aliases options
   */
  getDEAliasesOptions = (dataExtensionObjectId) => {
    const { selectedDataExtensions } = this.props;

    return selectedDataExtensions?.filter(de => de.ObjectID === dataExtensionObjectId)
      ?.reduce((options, currentDE) => {
        return { ...options, [currentDE.deAlias]: currentDE.deAlias };
      }, {});
  };

  /**
   * Merge current filters state with the filters from filter set
   * @param {Array} filters - current filters state
   * @param {Array} filterSetFilters - filters from filter set
   * @param {String} filterSetOperator - filter set operator
   * @param {Object} filterContainerId - filter container id
   * @returns {Array} new filters state
   */
  mergeFiltersWithFilterSet = (filters, filterSetFilters, filterSetOperator, filterContainerId) => {
    const filtersState = JSON.parse(JSON.stringify(filters));

    if (!filterContainerId) {
      if (filterSetFilters.length === 1) {
        filtersState[filtersState.length] = {
          ...filterSetFilters[0],
          id: `${filtersState.length}`,
        };
      } else {
        filtersState[filtersState.length] = {
          filters: filterSetFilters,
          operator: filterSetOperator,
          id: `${filtersState.length}`,
        };
      }

      return filtersState;
    }

    const newFilterState = filtersState.reduce((updatedFiltersState, currentFilter, i) => {
      if (currentFilter.id === filterContainerId) {
        if (filterSetFilters.length === 1) {
          filtersState[i].filters[currentFilter.filters?.length] = {
            ...filterSetFilters[0],
            id: `${filterContainerId},${currentFilter.filters?.length}`,
          };
        } else {
          filtersState[i].filters[currentFilter.filters?.length] = {
            filters: filterSetFilters,
            operator: filterSetOperator,
            id: `${filterContainerId},${currentFilter.filters?.length}`,
          };
        }

        return filtersState;
      }

      if (currentFilter?.filters) {
        const filtersStateFromChild = this.mergeFiltersWithFilterSet(
          currentFilter?.filters,
          filterSetFilters,
          filterSetOperator,
          filterContainerId,
        );

        if (filtersStateFromChild) {
          filtersState[i].filters = filtersStateFromChild;

          return filtersState;
        }
      }

      return updatedFiltersState;
    }, filtersState);

    return newFilterState;
  };

  /**
   * Update DE alias in the filter in case filter set is based on data extension
   * @param {Object} filterSetToUpdate - current filters state
   * @returns {Object} Updated filter set
   */
  updateFilterSetDEAliases = async (filterSetToUpdate) => {
    const { selectedDataExtensions } = this.props;

    // If filterSet is behavioral show the modal to add DE and fields
    if (
      filterSetToUpdate?.category?.name === 'Behavioral' && !filterSetToUpdate?.filters) {
      this.setState({
        selectDataExtension: true,
        selectedFilterSetId: filterSetToUpdate?._id,
        dataViewName: filterSetToUpdate?.dataViewName,
        behavioralFilterSetCriteria: filterSetToUpdate?.filterType,
      });
    } else {
      const sourceDEInstances = selectedDataExtensions?.filter(
        de => de.ObjectID === filterSetToUpdate?.dataExtensionObjectId,
      ) || [];

      const sourceDEName = sourceDEInstances?.[0]?.Name?.toString();

      if (sourceDEInstances.length === 1) {
        filterSetToUpdate.filters = filtersUtil.updateFiltersDEAliases(
          filterSetToUpdate?.filters,
          sourceDEInstances[0].deAlias,
        );

        return filterSetToUpdate;
      }

      const result = await SwalUtil.fire({
        title: 'Select the Alias Name for Data Extension',
        options: {
          showCancelButton: true,
          confirmButtonText: 'Confirm',
          allowOutsideClick: false,
          input: 'select',
          inputOptions: this.getDEAliasesOptions(filterSetToUpdate?.dataExtensionObjectId),
          inputPlaceholder: 'Choose an alias',
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value) {
                resolve();
              } else {
                resolve('You need to choose an alias');
              }
            });
          },
        },
        messageHTML: `<div style="text-align: left">
          <p> The Data Extension <span style="font-weight: bold">(${sourceDEName})</span> linked to this filter set is
          being used more than once in the Selected Data Extensions section above.
          </p>
          <p style="margin-top: 0.5em">
          Please choose the alias name for which you would like to use this Filter Set:
          </p>
          </div>`,
      }).then((result) => {
        if (result.isConfirmed) {
          filterSetToUpdate.filters = filtersUtil.updateFiltersDEAliases(filterSetToUpdate?.filters, result.value);

          return filterSetToUpdate;
        }

        return null;
      });

      return result;
    }
  };

  /**
   * Create a new FilterLines using filters from filter set
   * @param {string} filterSetId - id of the filter set
   * @param {string} filterContainerId - id of filter's container
   * @returns {void}
   */
  addFilterSetFilters = async (filterSetId, filterContainerId) => {
    const { filterSets } = this.props;
    const { filters } = this.state;
    const filterSetToAdd = filterSets?.find(f => f._id === filterSetId);
    const filterContainer = this.findFiltersContainer(filters, filterContainerId);

    if (filterSetToAdd) {
      const copyOfFilterSet = JSON.parse(JSON.stringify(filterSetToAdd));

      // Update aliases in case source type in Data Extension
      const updatedCopyOfFilterSet =
      copyOfFilterSet?.sourceType === Constants.FILTER_SET__SOURCE_TYPE__DATA_EXTENSION &&
     !(copyOfFilterSet?.category?.name === 'Behavioral' &&
      copyOfFilterSet?.filters) ?
        await this.updateFilterSetDEAliases(copyOfFilterSet) :
        copyOfFilterSet;

      if (filters.length === 0 && !filterContainerId) {
        this.setState({
          filters: updatedCopyOfFilterSet?.filters.filters || [],
          operator: updatedCopyOfFilterSet?.filters.operator,
        }, () => {
          const { filters, operator } = this.state;
          const { handleFiltersSave } = this.props;

          handleFiltersSave({ filters, operator });
        });
      } else {
        const updatedFilterSetFilters = this.updateFilterContainerIds({
          filters: updatedCopyOfFilterSet?.filters.filters,
          filterContainer,
          isUpdateIndexNeeded: true,
          filtersStateLength: filters.length,
        });

        if(updatedFilterSetFilters) {
          const filtersArray = this.mergeFiltersWithFilterSet(
            filters,
            updatedFilterSetFilters,
            updatedCopyOfFilterSet?.filters.operator,
            filterContainerId,
          );

          this.setState({
            filters: filtersUtil.recreateIds(filtersArray),
          }, () => {
            const { filters, operator } = this.state;
            const { handleFiltersSave } = this.props;

            handleFiltersSave({ filters, operator });
          });
        }
      }
    }
  };

  /**
   * Merge filterline1 and filterline2 into a new filtercontainer
   * @param {string} filterLine1Id - id of first filter
   * @param {string} filterLine2Id - id of second filter
   * @param {string} parentId - filter's parent id
   * @param {object} filtersBranch - branch with filters
   * @param {bool} isInResultsFormula - check if criteria in filterline is in results formula
   * @returns {void}
   */
  handleMergeFilterLines = (filterLine1Id, filterLine2Id, parentId, filtersBranch, isInResultsFormula) => {
    const { filters } = this.state;
    const { handleFiltersSave } = this.props;
    /*
     * This is the filters array we need to find a matching element on:
     * works on the filters state unless a specific branch is passed
     */

    let filtersArray = filtersBranch || filters;

    let parentFilter;

    if (isInResultsFormula) {
      parentFilter = this.findFilterElement(parentId, filters)?.element;

      filtersArray = parentFilter?.subQuery?.formulas?.filters;
    }

    let filterLine1;

    let filterLine2;

    // Loop through filtersArray
    for (let f = 0; f < filtersArray.length; f += 1) {
      // If the array element contains a filters array, execute this function on this branch
      if (filtersArray[f].filters) {
        this.handleMergeFilterLines(filterLine1Id, filterLine2Id, filtersArray[f].id, filtersArray[f].filters);
      } else if (filtersArray[f].id === filterLine1Id) {
        filtersArray[f].showGroupButton = false;
        filterLine1 = filtersArray[f];
      } else if (filtersArray[f].id === filterLine2Id) {
        filtersArray[f].showGroupButton = false;
        filterLine2 = filtersArray[f];
      }

      if (filterLine1 && filterLine2) {
        // Replace first element with the new FilterContainer with the 2 filters that are merged
        const newFilterContainer = {
          operator: Constants.FILTERLINE__OPERATOR__AND,
          filters: [filterLine1, filterLine2],
        };

        filtersArray[f - 1] = newFilterContainer;

        // Remove 2nd element from array
        filtersArray.splice(f, 1);

        // Stop looping through filtersArray
        break;
      }
    }

    filtersArray = this.recreateIds(filtersArray, parentId, isInResultsFormula);

    if (isInResultsFormula) {
      Util.handleUpdateFormulaFiltersParentId({ filters });
    }

    this.setState({ filters: isInResultsFormula ? filters : filtersArray }, () => {
      const { filters: f, operator } = this.state;

      handleFiltersSave({ filters: f, operator });
    });
  };

  /**
   * Update collectionAlias on filterLines in filters
   * @param {string} oldCollectionAlias - old alias
   * @param {string} newCollectionAlias - new alias
   * @param {object} filtersBranch - branch with filters
   * @returns {void}
   */
  handleUpdateCollectionAliasInFilters = (oldCollectionAlias, newCollectionAlias, filtersBranch) => {
    const { filters } = this.state;

    /*
     * This is the filters array we need to find a matching element on:
     * works on the filters state unless a specific branch is passed
     */
    const filtersArray = filtersBranch || filters;

    // Loop through filtersArray
    filtersArray.forEach((filterElement) => {
      // If the array element contains a filters array, execute this function on this branch
      if (filterElement.filters) {
        this.handleUpdateCollectionAliasInFilters(oldCollectionAlias, newCollectionAlias, filterElement.filters);
      } else {
        if (filterElement.collectionAlias === oldCollectionAlias) {
          // replace old alias with a new one
          // eslint-disable-next-line no-param-reassign
          filterElement.collectionAlias = newCollectionAlias;
        }
      }
    });

    // Update collection alias
    const updatedFilters = filtersUtil.handleUpdateCollectionAliasInFilters(
      oldCollectionAlias,
      newCollectionAlias,
      filtersArray,
    );

    this.setState({
      filters: updatedFilters,
    });
  };

  /**
   * Color changes on hovering
   * Function can't be moved into another component. It causes state update delay
   * @param {string} id - id of hovered filter
   * @param {object} filtersBranch - branch with filters
   * @param {bool} isInResultsFormula - check if criteria in filterline is in results formula
   * @param {string} parentId - filter's parent id
   * @returns {void}
   */
  handleHoverFilterMerging = (id, filtersBranch, isInResultsFormula, parentId) => {
    const { filters } = this.state;
    const { handleFiltersSave } = this.props;

    let filtersArray = filtersBranch || filters;

    let parentFilter;

    if (isInResultsFormula) {
      // Find the parent filter
      parentFilter = this.findFilterElement(parentId, filters)?.element;

      if (parentFilter) {
        filtersArray = parentFilter.subQuery?.formulas?.filters;
      }
    }

    for (let i = 0; i < filtersArray.length; i += 1) {
      // If the array element contains a filters array, execute this function on this branch
      if (filtersArray[i].filters) {
        this.handleHoverFilterMerging(id, filtersArray[i].filters);
      } else {
        // If ids are matched update the property
        if (filtersArray[i].id === id) {
          // If it indicates last element
          if (filtersArray[i].id === filtersArray[filtersArray.length - 1].id) {
            filtersArray[i].showGroupButton = true;
            filtersArray[filtersArray.length - 2].showGroupButton = true;
            // Other situations
          } else {
            /*
             * If the filter next to it is a filter container and
             * it's not the first in its container
             */
            if (filtersArray[i + 1].filters && i !== 0) {
              filtersArray[i - 1].showGroupButton = true;
            } else {
              // Other cases
              filtersArray[i + 1].showGroupButton = true;
            }
            filtersArray[i].showGroupButton = true;
          }
        }
      }
    }

    if (isInResultsFormula) {
      parentFilter.subQuery.formulas.filters = filtersArray;
    }
    this.setState(
      {
        filters: isInResultsFormula ?
          filters :
          filtersArray,
      },
      () => {
        const { filters: f, operator } = this.state;

        handleFiltersSave({ filters: f, operator });
      },
    );
  };

  /**
   * Retrieve the previous min / max value from a filters array or from picklist options
   * @param {array} filtersArray - is the array of filters
   * @param {number} filterIndex - is the index we look at in filtersArray
   * @param {array} optionForPicklist - is the options from the picklist for the field we are adding
   * @param {string} minOrMax - 'min' or 'max', controls whether we retrieve from previous min or previous max field
   * @returns {string} - it returns min/max value from a filtersArray or optionForPicklist
   */
  _retrieveMinOrMaxValue = (filtersArray, filterIndex, optionForPicklist, minOrMax) => {
    /**
     * As the key name is important ('max' != 'Max'), we lower case everything then upper case the first letter.
     * It gives 'previousMaxValue' or 'previousMinValue'.
     */
    const key = `previous${minOrMax.toString().toLowerCase().charAt(0).toUpperCase() + minOrMax.slice(1)}Value`;

    if (filtersArray[filterIndex][key]) return filtersArray[filterIndex][key];
    if (optionForPicklist && optionForPicklist.length > 0) return optionForPicklist[0].label;

    return '0';
  };

  /**
   * Update formula filterline criteria
   * @param {string} _parentId - id of parent filter
   * @param {string} id - id of hovered filter
   * @param {string} criteria - type of filter criteria
   * @param {string} subQuery - this is the subquery object
   * @returns {void}
   */

  handleUpdateFormulaFilterLineCriteria = (_parentId, id, criteria, subQuery) => {
    const { filters } = this.state;
    const { handleFiltersSave } = this.props;

    const filtersArray = filters;

    let filter;

    // eslint-disable-next-line prefer-const
    filter = this.findFilterElement(id, subQuery.formulas.filters);

    if (filter && filter.element) {
      filter.element.criteria = criteria;
    }

    this.setState(
      {
        filters: filtersArray,
      },
      () => {
        const { filters: f, operator } = this.state;

        handleFiltersSave({ filters: f, operator });
      },
    );
  };

  /**
   * Update values on filterline
   * @param {string} id - id of hovered filter
   * @param {string} criteria - type of filter criteria
   * @param {string} previousCriteria - type of previous criteria
   * @param {object} filtersBranch - branch with filters
   * @param {object} optionForPicklist - branch with options
   * @param {boolean} updateInSubQuery - defines whether to update data in subQuery
   * @returns {void}
   */
  handleUpdateFilterLineCriteria = (
    id,
    criteria,
    previousCriteria,
    filtersBranch,
    optionForPicklist,
    updateInSubQuery,
  ) => {
    const { filters } = this.state;
    const { handleFiltersSave } = this.props;

    /**
     * Short-hand for knowing if a criteria is EMPTY or NOT EMPTY
     * @param {string} ctr - A criteria
     * @returns {boolean} True if the criteria is EMPTY or NOT EMPTY, false otherwise
     */
    const isEmptyOrNotEmpty =
    ctr => ctr === Constants.FILTERLINE__CRITERIA__IS_EMPTY || ctr === Constants.FILTERLINE__CRITERIA__IS_NOT_EMPTY;
    /**
     * Short-hand for knowing if a criteria is EQUALS or NOT EQUAL TO
     * @param {string} ctr - A criteria
     * @returns {boolean} True if the criteria is EQUALS or NOT EQUAL TO, false otherwise
     */
    const isEqualOrNotEqual =
    ctr => ctr === Constants.FILTERLINE__CRITERIA__EQUALS || ctr === Constants.FILTERLINE__CRITERIA__NOT_EQUAL_TO;
    /**
     * Short-hand for knowing if a filter field type is a NUMBER or a DECIMAL
     * @param {string} filter - A filter
     * @returns {boolean} True if the field type is a NUMBER or a DECIMAL, false otherwise
     */
    const isNumberOrDecimal = ({ fieldType }) => (
      fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ||
    fieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL
    );
    /**
     * Short-hand for knowing if optionForPicklist is defined
     * @returns {boolean} True if optionForPicklist is defined
     */
    const hasPicklist = () => optionForPicklist && optionForPicklist.length;
    /**
     * Short-hand for knowing if a criteria is Contains or Does Not Contain
     * @param {string} ctr - A criteria
     * @returns {boolean} True if the criteria is Contains or Does Not Contain, false otherwise
     */
    const isContainsOrNotContains =
    ctr => ctr === Constants.FILTERLINE__CRITERIA__CONTAINS ||
      ctr === Constants.FILTERLINE__CRITERIA__DOES_NOT_CONTAIN;

    /**
     * This is the filters array we need to find a matching element on:
     * works on the filters state unless a specific branch is passed
     */
    const filtersArray = filtersBranch || filters;

    // Loop through filtersArray
    for (let f = 0; f < filtersArray.length; f += 1) {
      const currentFilter = filtersArray[f];

      // If the array element contains a filters array, execute this function on this branch
      if (currentFilter.filters) {
        this.handleUpdateFilterLineCriteria(
          id,
          criteria,
          previousCriteria,
          currentFilter.filters,
          optionForPicklist,
          updateInSubQuery,
        );
      } else {
        if (currentFilter.id === id) {
        // If previous criteria is "is empty" or "is not empty" and field type is number: Set initial value
          if (isEmptyOrNotEmpty(previousCriteria)) {
          // Set 0 as initial value if fieldType is an instance of Constants.FILTERLINE__FIELDTYPE__NUMBER
            if (isNumberOrDecimal(currentFilter)) {
              if (criteria === Constants.FILTERLINE__CRITERIA__BETWEEN) {
                currentFilter.value = {
                  minValue: this._retrieveMinOrMaxValue(filtersArray, f, optionForPicklist, 'min'),
                  maxValue: this._retrieveMinOrMaxValue(filtersArray, f, optionForPicklist, 'max'),
                };
              } else if (filtersUtil.isInOrNotInOrOneOf(criteria)) {
                currentFilter.value = [];
              } else currentFilter.value = currentFilter.previousValue ? currentFilter.previousValue : '0';
            } else {
              if (filtersUtil.isInOrNotInOrOneOf(criteria)) {
              /**
               * If the value is an array type of object then assign it as is but the value is not array type
               * of object then split the value by comma (,) to an array so we can use them for the in filter
               */
                if (currentFilter.previousValue) {
                  if (typeof currentFilter.previousValue === 'object') {
                    currentFilter.value = '';
                  } else currentFilter.value = currentFilter.previousValue.split(this.regexForSearchingComma);
                } else currentFilter.value = '';
              } else currentFilter.value = currentFilter.previousValue ? currentFilter.previousValue : '';
            }
          } else {
            if (isEmptyOrNotEmpty(criteria)) {
            // Save previousValue and reset value
              if (previousCriteria === Constants.FILTERLINE__CRITERIA__BETWEEN) {
                currentFilter.previousMinValue = currentFilter.value.minValue;
                currentFilter.previousMaxValue = currentFilter.value.maxValue;
              } else {
                currentFilter.previousValue = currentFilter.value;
                currentFilter.value = '';
              }
            } else {
            // If criteria is 'between', set value to an object with two key value pairs minValue and maxValue
              if (criteria === Constants.FILTERLINE__CRITERIA__BETWEEN) {
              // Save value
                currentFilter.previousValue = currentFilter.value;
                // Set new value
                currentFilter.value = {
                  minValue: this._retrieveMinOrMaxValue(filtersArray, f, optionForPicklist, 'min'),
                  maxValue: this._retrieveMinOrMaxValue(filtersArray, f, optionForPicklist, 'max'),
                };
              } else {
                if (
                  currentFilter.fieldType !== Constants.FILTERLINE__FIELDTYPE__DATE &&
                !isNumberOrDecimal(currentFilter) &&
                isEqualOrNotEqual(criteria) &&
                !isEqualOrNotEqual(previousCriteria)
                ) {
                // Save previously saved value
                  currentFilter.previousValue = filtersUtil.returnArrayOrStringTypeOfValue(currentFilter.value);
                  if (hasPicklist()) {
                  // If the current value is in the picklist, select it directly
                    currentFilter.value =
                    optionForPicklist.some(o => currentFilter.previousValue === o.value) ?
                      currentFilter.previousValue :
                      optionForPicklist[0].value;
                  } else {
                    currentFilter.value = currentFilter.previousValue ? currentFilter.previousValue : '';
                  }
                } else if (previousCriteria === Constants.FILTERLINE__CRITERIA__BETWEEN &&
                currentFilter.fieldType !== Constants.FILTERLINE__FIELDTYPE__DATE) {
                // Save minValue and maxValue
                  currentFilter.previousMinValue = currentFilter.value.minValue;
                  currentFilter.previousMaxValue = currentFilter.value.maxValue;
                  if (isNumberOrDecimal(currentFilter) && !filtersUtil.isInOrNotInOrOneOf(criteria)) {
                    currentFilter.value = currentFilter.previousValue ? currentFilter.previousValue : '0';
                  // Other situations
                  } else {
                    currentFilter.value = currentFilter.previousValue ? currentFilter.previousValue : '';
                  }
                } else if (filtersUtil.isInOrNotInOrOneOf(previousCriteria)) {
                  if (filtersUtil.isInOrNotInOrOneOf(criteria)) {
                    currentFilter.previousValue = currentFilter.value;
                  } else {
                    if (isContainsOrNotContains(criteria)) {
                      if (currentFilter.isPicklistOptions) {
                        currentFilter.previousValue = currentFilter.value;
                      } else {
                        currentFilter.previousValue = filtersUtil.returnArrayOrStringTypeOfValue(currentFilter.value);
                      }
                    } else {
                      currentFilter.previousValue = filtersUtil.returnArrayOrStringTypeOfValue(currentFilter.value);
                    }
                  }

                  if ((currentFilter.fieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL ||
                  currentFilter.fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER) &&
                   !filtersUtil.isInOrNotInOrOneOf(criteria)
                  ) {
                  // Reset value when fieldType is number or decimal and current criteria is not IN or NOT IN
                    currentFilter.value = '';
                  } else currentFilter.value = currentFilter.previousValue ? currentFilter.previousValue : '';
                } else if (filtersUtil.isInOrNotInOrOneOf(criteria)) {
                // Save previously saved value
                  currentFilter.previousValue = filtersUtil.returnArrayOrStringTypeOfValue(currentFilter.value);

                  // Set value to format that is appropriate for an in/not in criteria
                  if ((isEqualOrNotEqual(previousCriteria) ||
                  previousCriteria === Constants.FILTERLINE__CRITERIA__BEGINS_WITH ||
                  previousCriteria === Constants.FILTERLINE__CRITERIA__ENDS_WITH) && !hasPicklist()) {
                    if (currentFilter.fieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL ||
                    currentFilter.fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER
                    ) {
                    // Reset value when fieldType is number or decimal
                      currentFilter.value = [];
                    } else {
                      currentFilter.value = currentFilter.value ?
                        currentFilter.value.split(',').map(el => el.trim()) :
                        '';
                    }
                  } else if (!isContainsOrNotContains(previousCriteria)) {
                  // Reset value
                    currentFilter.value = (currentFilter.fieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL ||
                    currentFilter.fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER) ?
                      [] :
                      '';
                  }
                }
              }
            }
          }

          // if fieldType is date then set default value to current date
          if (currentFilter.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE && !currentFilter.relation) {
            const defaultDate = new Date(new Date().setSeconds(0)).setMilliseconds(0);

            currentFilter.value = new Date(defaultDate).toISOString();
          }

          // when we update criteria in SubQuery in Relation Data
          if (updateInSubQuery) {
            if (currentFilter.subQuery?.relationData) {
            // update criteria in RelationData
              currentFilter.subQuery.relationData.criteria = criteria;

              // switch between in results and not in results statements
              if (
                ((currentFilter.subQuery.relationData.criteria === Constants.FILTERLINE__CRITERIA__EQUALS &&
                Number(currentFilter.subQuery.relationData.value) === 0) ||
                (currentFilter.subQuery.relationData.criteria ===
                  Constants.FILTERLINE__RELATION_CRITERIA__LESS_THAN_VALUE &&
                  Number(currentFilter.subQuery.relationData.value) === 1)) &&
              currentFilter.criteria === Constants.FILTERLINE__CRITERIA__IN_RESULTS &&
              currentFilter.subQuery.relationData.formula === Constants.FORMULA__VALUE__COUNT
              ) {
              // we use not in results in case if user selected COUNT formula and searching EXACTLY for 0 values
                currentFilter.criteria = Constants.FILTERLINE__CRITERIA__NOT_IN_RESULTS;
              } else if (
                currentFilter.criteria === Constants.FILTERLINE__CRITERIA__NOT_IN_RESULTS
              ) {
              // in any other case if we had NOT IN RESULTS we switch back to IN RESULTS
                currentFilter.criteria = Constants.FILTERLINE__CRITERIA__IN_RESULTS;
              }
            }
          } else {
            currentFilter.criteria = criteria;
          }
          // Reset of date filter params on criteria change
          if (currentFilter.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE) {
            switch (currentFilter.criteria) {
              case Constants.FILTERLINE__CRITERIA__DATE__IN_PREVIOUS:
                if (currentFilter.filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTE ||
                currentFilter.filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR) {
                  currentFilter.dateValueStart = Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW;
                } else {
                  currentFilter.dateValueStart = Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY;
                }
                currentFilter.dateFilterType = Constants.FILTERLINE__DATE_TYPE__RELATIVE;
                this.handleOnChangeIsCompareFieldsFilter(false, id);
                break;
              case Constants.FILTERLINE__CRITERIA__DATE__IN_NEXT:
                if (currentFilter.filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTE ||
                currentFilter.filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR) {
                  currentFilter.dateValueStart = Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW;
                } else {
                  currentFilter.dateValueStart = Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY;
                }
                currentFilter.dateFilterType = Constants.FILTERLINE__DATE_TYPE__RELATIVE;
                this.handleOnChangeIsCompareFieldsFilter(false, id);
                break;
              case Constants.FILTERLINE__CRITERIA__SMALLER_THAN:
              case Constants.FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO:
              case Constants.FILTERLINE__CRITERIA__EQUALS:
              case Constants.FILTERLINE__CRITERIA__NOT_EQUAL_TO:
                if (currentFilter.filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTE ||
                currentFilter.filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR) {
                  currentFilter.dateValueStart = Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW;
                } else {
                  currentFilter.dateValueStart = Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY;
                }
                break;
              case Constants.FILTERLINE__CRITERIA__GREATER_THAN:
              case Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO:
                if (currentFilter.filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTE ||
                currentFilter.filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR) {
                  currentFilter.dateValueStart = Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW;
                } else {
                  currentFilter.dateValueStart = Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY;
                }
                break;
              case Constants.FILTERLINE__CRITERIA__NOT_BETWEEN:
              case Constants.FILTERLINE__CRITERIA__BETWEEN: {
                currentFilter.dateFilterType = Constants.FILTERLINE__DATE_TYPE__CALENDAR;
                this.handleOnChangeIsCompareFieldsFilter(false, id);

                const date = moment(new Date());
                const defaultDate = date.utc()._d;

                currentFilter.startDate = currentFilter.startDate ?
                  currentFilter.startDate :
                  defaultDate;

                currentFilter.endDate = currentFilter.endDate ?
                  currentFilter.endDate :
                  defaultDate;
                break;
              }
              case Constants.FILTERLINE__CRITERIA__DATE__TODAY:
                currentFilter.dateFilterType = Constants.FILTERLINE__DATE_TYPE__RELATIVE;
                currentFilter.dateValueStart = Constants.FILTERLINE__DATE_VALUE_START__TODAY;
                break;
              default:
            }
          }
        }
      }
    }

    const filter = filtersArray[id];

    if (!filtersUtil.isInResultsOrNotInResults(criteria) && !updateInSubQuery && filter?.subQuery?.fields?.length > 0) {
      const newSubQuery = {
        fields: [],
        filters: [],
        formulas: {
          filters: [],
          operator: Constants.FILTERLINE__OPERATOR__AND,
        },
        relations: [],
        relationData: {},
        collections: [],
        dataExtensionFields: [],
      };

      filtersArray[id].subQuery = newSubQuery;
      filtersArray[id].includesSubQuery = false;
    }

    this.setState(
      {
        filters: filtersArray,
      },
      () => {
        const { filters: f, operator } = this.state;

        handleFiltersSave({ filters: f, operator });
      },
    );
  };

  /**
   * Handles the setting of tags
   * @param {array} values - The value of the filterLine
   * @param {string} filterLineId - Id of the filterLine
   * @returns {void}
   */
  handleSetTags = (values, filterLineId) => {
    const { tags } = this.state;

    // Filter out empty strings and set tag values
    const newTags = values.filter(val => !!val);

    // set tags, filterLineId = 'temp' is for temporary use only, don't take this into account
    if (filterLineId !== 'temp') {
    // Update tags and set state
      this.setState({ tags: { ...tags, [filterLineId]: newTags } });
    }
  };

  /**
   * Finds and updates a filter
   * @param {array} filtersArray - Array of filters
   * @param {string} filterId - Id of the filter to find and update
   * @param {array} value - FilterLine's new value
   * @returns {array} - The updated filter
   */
  findAndUpdateFilter = (filtersArray, filterId, value) => {
  /**
   * Function to call recursively
   * @param {array} filtersBranch - branch with filters
   * @returns {void}
   */
    const update = (filtersBranch) => {
      filtersBranch.forEach((filter) => {
        if (filter.filters) {
          update(filter.filters);
        } else {
          if (filter.id === filterId) {
          // eslint-disable-next-line no-param-reassign
            filter.value = value;
          }
        }
      });
    };

    // Run the update
    update(filtersArray);

    // return updated filtersArray
    return filtersArray;
  };

  /**
   * Handles the addition of tags
   * @param {object} tags - An array of updated tags
   * @param {string} filterLineId - Id of the filterLine
   * @param {string} fieldType - Type of the field that's being updated
   * @returns {void}
   */
  handleTagsChanged = (tags, filterLineId, fieldType) => {
    const { filters, tags: tagsObject } = this.state;

    const outdatedTags = tagsObject[filterLineId];

    let updatedFilters;

    // If tags saved in the state are more than updated tags, then we just removed a tag
    if (outdatedTags?.length > tags?.length) {
      this.handleDelete(tags, filterLineId);

      return;
    }

    const tagsLength = tags?.length || 0;
    const outdatedTagsLength = tags?.length || 0;

    // first index of newly-added tags
    const firstIndexOfNewlyAddedTags = outdatedTagsLength - tagsLength;

    const newlyAddedTags = tags.slice(firstIndexOfNewlyAddedTags);

    // Declare regex for number and decimal
    const validDecimalRegex = /^[-+]?[0-9]+(\.[0-9]{1,})?$/m;
    const validIntegerRegex = /^[-+]?[0-9]+$/m;

    let validTags = [];

    validTags = newlyAddedTags.filter((tag) => {
    // If field type is number and tag is not a valid number, ignore it
      if (fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER && !validIntegerRegex.test(tag)) return false;

      // If field type is decimal and tag is not a valid decimal, ignore it
      if (fieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL && !validDecimalRegex.test(tag)) return false;

      // If filter has id='temp', ignore it (it's temporary id)
      if (filterLineId === 'temp') return false;

      // Tag passes validation, so don't filter it out
      return true;
    });

    // If no tags are valid, return.
    if (!validTags?.length) return;

    // Remove leading 0s to avoid duplicates (e.g 09 and 9)
    // eslint-disable-next-line no-param-reassign
    validTags = validTags.map(tag => String(Number(tag)));

    // Set state
    this.setState((prevState) => {
      const prevTags = prevState.tags[filterLineId] || [];

      // create flatted array with all tags
      const allTags = _.flattenDeep([...prevTags, ...validTags]);

      // Remove duplicates if they exist
      validTags = [...new Set(allTags)];

      // Update tags
      const updatedTags = {
        ...prevState.tags,
        [filterLineId]: [...validTags],
      };

      // Sort tags by value
      updatedTags[filterLineId].sort((a, b) => a - b);

      // Set new filter value
      const newFilterLineValue = updatedTags[filterLineId].reduce((arr, t) => {
        arr.push(t);

        return arr;
      }, []);

      // Find and update filter
      updatedFilters = this.findAndUpdateFilter(filters, filterLineId, newFilterLineValue);

      return {
        filters: updatedFilters,
        tags: updatedTags,
      };
    });
  };

  /**
   * Handles the deletion of tags
   * @param {array} updatedTags - Updated tags
   * @param {string} filterLineId - Id of the filterLine
   * @returns {void}
   */
  handleDelete = (updatedTags, filterLineId) => {
    const { tags, filters } = this.state;

    // Set new filter value
    const newFilterLineValue = updatedTags.reduce((arr, t) => {
      arr.push(t);

      return arr;
    }, []);

    // find and update filters
    const updatedFilters = this.findAndUpdateFilter(filters, filterLineId, newFilterLineValue);

    // set new tags in state
    const newTags = { ...tags, [filterLineId]: updatedTags };

    this.setState({ filters: updatedFilters, tags: newTags });
  };

  /**
   * Recursive function that searches for updated filter, and changes the value appropriately
   * @param {string} id - id filter
   * @param {string} value - value of filter
   * @param {object} filtersBranch - branch with filters
   * @param {string} name - name
   * @param {string} pickLists - available picklists for field
   * @param {boolean} onBlur - defines whether the function is executed during onBlur
   * @returns {void}
   */
  getUpdatedFilterLineValue = (id, value, filtersBranch, name, pickLists, onBlur) => {
    // Loop through filtersArray
    filtersBranch.forEach((filter, index) => {
      // If the array element contains a filters array, execute this function on this branch
      if (filter.filters) {
        this.getUpdatedFilterLineValue(id, value, filter.filters, name, pickLists, onBlur);
      } else {
        if (filter.id === id) {
          const previousValue = filter.value;

          // we need to convert local timezone to utc time if value is Date
          if (value instanceof Date && !filter.relation) {
            // takes date and converts it to utc time.
            const newDate = moment(value)
              .add(moment(value).utcOffset(), 'm')
              .utc()._d;

            if (filter.fieldType === Constants.FILTERLINE__FIELDTYPE__DATE &&
            filtersUtil.isBetweenOrNotBetween(filter.criteria)) {
              filter[name] = newDate;
            } else {
              filter.value = newDate;
            }
            // if criteria is 'between', use the name parameter to set the changed input's new value
          } else if (filtersUtil.isBetweenOrNotBetween(filter.criteria)) {
            if (typeof filter.value !== 'object') {
              filter.value = {};
            }
            filter.value[name] = value;
          } else if (filtersUtil.isInOrNotInOrOneOf(filter.criteria)) {
            const picklistFieldsObjectIds = [];

            let hasPicklist = false;

            /**
             * Split the value by comma (,) or (default separator) to an array so we can use
             * them for the in filter And removes empty values
             */
            if (value.startsWith(Constants.SEPARATOR_DEFAULT)) {
              // eslint-disable-next-line no-param-reassign
              value = value.slice(Constants.SEPARATOR_DEFAULT.length);
            } else if (value.startsWith(',')) {
              // eslint-disable-next-line no-param-reassign
              value = value.slice(1);
            }

            // Populates array with fieldObjectsIds that have picklists
            pickLists.forEach((picklist) => {
              // Include ObjectIDs of active picklists only
              if (picklist.isActive) picklistFieldsObjectIds.push(picklist.fieldObjectId);
            });

            // Checks whether the filter line contains a multi picklist or not
            picklistFieldsObjectIds.forEach((picklistFieldObjectId) => {
              if (filter.fieldObjectID === picklistFieldObjectId) hasPicklist = true;
            });

            // If multi picklist, split using default separator, otherwise using comma (,)
            filter.value = hasPicklist ?
              value.split(Constants.SEPARATOR_DEFAULT) :
              value.split(',');

            if(onBlur) {
              // trim each value in the filter when losing the focus in the input
              filter.value = [...filter.value].map(val => val.trim());
            }
          } else if (filtersUtil.isContainsOrNotContains(filter.criteria)) {
            // check if contains filter will be used with picklists
            if (filter.isPicklistOptions) {
              const valueAsArray = value.split(Constants.SEPARATOR_DEFAULT).filter(v => v !== '');

              filter.value = valueAsArray.length === 0 ? '' : valueAsArray;
            } else {
              filter.value = value;
              [index].value = value.split(this.regexForSearchingComma);
            }
          } else {
            filter.value = value;
          }

          /*
           * if field type is number and value doesn't contain only numbers
           * don't allow entering that value (eg. dot, comma, etc...)
           */
          if (filter.fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER) {
            let numberValue = [value];

            if (filtersUtil.isInOrNotInOrOneOf(filter.criteria)) {
              if (value.includes(Constants.SEPARATOR_DEFAULT)) {
                numberValue = value.split(Constants.SEPARATOR_DEFAULT);
              }
            }

            if (numberValue?.length) {
              // variable responsible for checking if the value is decimal
              let isDecimal = false;

              // check whether decimal number has been used for a field of type number
              numberValue.forEach((number) => {
                if (number !== '' && number % 1 !== 0) {
                  isDecimal = true;
                }
              });

              // do not assign a new value
              if (isDecimal) filter.value = previousValue;
            }
          }
        }
      }
    });
  };

  /**
   * Update values on filterline
   * Function can't be moved into another component. It causes state update delay
   * @param {string} id - id filter
   * @param {string} value - value of filter
   * @param {string} name - name
   * @returns {void}
   */
  handleUpdateFilterLineValue = (id, value, name) => {
    const { filters, operator } = this.state;
    const {
      handleFiltersSave,
      pickLists,
    } = this.props;

    /*
     * This is the filters array we need to find a matching element on:
     * works on the filters state unless a specific branch is passed
     */
    const filtersArray = JSON.parse(JSON.stringify(filters));

    this.getUpdatedFilterLineValue(id, value, filtersArray, name, pickLists);

    this.setState(
      {
        filters: filtersArray,
      },
      () => {
        handleFiltersSave({ filters: filtersArray, operator });
      },
    );
  };

  /**
   * Updates color of button on mouse over/out
   * Function can't be moved into another component. It causes state update delay
   * @param {object} filterBranch - branch with filters
   * @param {bool} isInResultsFormula - check if criteria in filterline is in results formula
   * @param {string} parentId - id of parent filter
   * @returns {void}
   */
  handleHideAddFiltersTogether = (filterBranch, isInResultsFormula, parentId) => {
    const { filters } = this.state;
    const { handleFiltersSave } = this.props;

    let filtersArray = filterBranch || filters;

    let parentFilter;

    if (isInResultsFormula) {
      parentFilter = this.findFilterElement(parentId, filters)?.element;

      filtersArray = parentFilter.subQuery.formulas.filters;
    }

    filtersArray.forEach((filter) => {
    // If the array element contains a filters array, execute this function on this branch
      if (filter.filters) {
        this.handleHideAddFiltersTogether(filter.filters);
      } else {
      // eslint-disable-next-line no-param-reassign
        filter.showGroupButton = false;
      }
    });

    this.setState(
      {
        filters: isInResultsFormula ? filters : filtersArray,
      },
      () => {
        const { filters: f, operator } = this.state;

        handleFiltersSave({ filters: f, operator });
      },
    );
  };

  /**
   * Handles AND-OR logics on the filter container
   * Function can't be moved into another component. It causes state update delay
   * @param {string} filterContainerId - id of a container
   * @param {string} operator - or/and
   * @param {object} filtersBranch - branch with filters
   * @param {bool} isInResultsFormula - check if criteria in filterline is in results formula
   * @param {string} parentId - filter's parent id
   * @returns {void}
   */
  handleLogicChange = (filterContainerId, operator, filtersBranch, isInResultsFormula, parentId) => {
    const { filters } = this.state;
    const { handleFiltersSave } = this.props;

    let filtersArray = filtersBranch ? filtersBranch.filters : filters;

    let parentFilter;

    if (isInResultsFormula && !filtersBranch) {
      parentFilter = this.findFilterElement(parentId, filters)?.element;

      filtersArray = parentFilter.subQuery.formulas.filters;
    }

    let foundContainer = false;

    // If there is container, operator can change on next level
    if (typeof filterContainerId !== 'undefined') {
      if (filtersBranch && filtersBranch.id === filterContainerId) {
        foundContainer = true;
        // eslint-disable-next-line no-param-reassign
        filtersBranch.operator = operator;
      } else {
        if (filtersArray) {
          for (let i = 0; i < filtersArray.length; i += 1) {
          // Is the current element a filter container?
            if (filtersArray[i].filters) {
              this.handleLogicChange(filterContainerId, operator, filtersArray[i], isInResultsFormula);
            }
          }
        }
      }
    // This indicates highest level, only change operator on highest level
    } else if (!foundContainer && !filtersBranch) {
      if (isInResultsFormula) {
        parentFilter.subQuery.formulas.operator = operator;
      } else {
        this.setState({ operator }, () => {
          const { filters: f, operator: o } = this.state;

          handleFiltersSave({ filters: f, operator: o });
        });
      }
    }

    this.setState({ filters: isInResultsFormula ? filters : filtersArray }, () => {
      const { filters: f, operator: o } = this.state;

      handleFiltersSave({ filters: f, operator: o });
    });
  };

  /**
   * Set properties of field in SubQuery
   * @param {String} collection - name of collection
   * @param {Object} deField - selected data extension field
   * @returns {Object} - object that can be used to set as a Field inside of SubQuery in a filterline
   */
  setSubQueryField = (collection, deField) => {
    const subQueryField = {
      collectionAlias: collection ? collection.toString() : collection,
      availableFieldObjectID: deField?.ObjectID && deField.ObjectID,
      field: deField?.Name?.toString() || '',
      fieldType: deField?.FieldType || '',
    };

    return subQueryField;
  };

  /**
   * When a data extension is selected through the searchable dropdown into a filter,
   * set subQuery object according to its details
   * @param {object} event - event
   * @param {string} id - filter id
   * @returns {void}
   */
  handleSetDataExtensionToFilter = async (event, id) => {
    const { filters } = this.state;
    const {
      handleFiltersSave, getDataExtensionOrDataViewFields, returnPredefinedRelationById,
    } = this.props;

    let dataExtensionCustomerKey = '';

    let collection = '';

    let dataExtensionObjectID = '';

    let predefinedRelation;

    // get the customer key and collection searching in the options array from the event
    if (Object.prototype.hasOwnProperty.call(event, 'options')) {
      for (let deIndex = 0; deIndex < event.options.length; deIndex += 1) {
        if (event.options[deIndex].value === event.value) {
          dataExtensionCustomerKey = event.options[deIndex].value;
          collection = event.options[deIndex].text;
          break;
        }
      }
    } else if (event.relation && event.relationId) {
    // get predefined relation data
      predefinedRelation = returnPredefinedRelationById(event.relationId, event);

      // for relation filter assign proper data from predefinedRelation
      dataExtensionCustomerKey = predefinedRelation?.toDECustomerKey || '';
      collection = predefinedRelation?.toDEName?.toString() || '';
      dataExtensionObjectID = predefinedRelation?.toDEObjectId || '';
    }

    const filter = this.findFilterElement(id, filters);

    let dataExtensionFields = [];

    let matchSelectedDEAndFields = false;

    // Get the details of the data extension
    const { dataExtensions, subQueryDataExtensions, handleSetSelectionState } = this.props;

    // check if DE for this filter exists in subQueryDataExtensions
    const getSelectedFilterDEInSubQueryDE = subQueryDataExtensions?.length &&
    subQueryDataExtensions.find(subQueryDE => subQueryDE.ObjectID === dataExtensionObjectID);

    for (let s = 0; s < dataExtensions.length; s += 1) {
      if (dataExtensions[s].CustomerKey === dataExtensionCustomerKey) {
      // get fields
      // eslint-disable-next-line no-await-in-loop
        dataExtensionFields = await getDataExtensionOrDataViewFields(dataExtensions[s]);
        // id should be unique
        dataExtensions[s].id = dataExtensions[s].Name + new Date().getTime();
        // add some props we need
        dataExtensions[s].fields = dataExtensionFields;
        dataExtensions[s].deAlias = collection;
        filter.element.subQuery.selectedDataExtension = dataExtensions[s];

        // the data for the filter has been defined
        matchSelectedDEAndFields = true;
      }
    }

    /*
     * if DE for this filter does not appear in subQueryDataExtensions and the data was not defined
     * (not found in selected DE)
     */
    if (!getSelectedFilterDEInSubQueryDE?.fields && !matchSelectedDEAndFields) {
      try {
      // create object with proper data to fetch this DE with fields
        const dataExtension = [{
          collection,
          alias: collection,
          collectionCustomerKey: dataExtensionCustomerKey,
          collectionObjectID: dataExtensionObjectID,
        }];

        // fetch data extension with fields
        const dataExtensionWithFields = await DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
          this.axiosCancelToken.token,
          dataExtension,
          null,
        );

        if (dataExtensionWithFields?.length) {
          const selectedDE = dataExtensionWithFields[0];

          // save this data in subQueryDataExtensions
          handleSetSelectionState({
            subQueryDataExtensions: [...subQueryDataExtensions, {
              ...selectedDE,
              deAlias: collection,
              id: selectedDE.Name + new Date().getTime(),
            }],
          });

          // get fields and fetch picklists for data extension, set selectedDataExtension
          dataExtensionFields = await getDataExtensionOrDataViewFields(selectedDE);
          filter.element.subQuery.selectedDataExtension = selectedDE;

          // the data for the filter has been defined
          matchSelectedDEAndFields = true;
        }
      } catch (error) {
        handleSetSelectionState({ error });
      }
    } else if (getSelectedFilterDEInSubQueryDE?.fields && !matchSelectedDEAndFields) {
    // if DE for this filter exists in subQueryDataExtensions, set the data without fetching it
      dataExtensionFields = getSelectedFilterDEInSubQueryDE.fields;
      filter.element.subQuery.selectedDataExtension = getSelectedFilterDEInSubQueryDE;
      matchSelectedDEAndFields = true;
    }

    filter.element.includesSubQuery = true;
    filter.element.subQuery.dataExtensionFields =
    // Keep only the fields that can be mapped
    dataExtensionFields?.filter?.(f => Util.canFieldBeMapped(filter.element.fieldType, f.FieldType)) || [];

    filter.element.subQuery.collections[0] = {
      collection,
      alias: collection,
      collectionCustomerKey: dataExtensionCustomerKey,
    };

    // for a relation filter when we add a new filter and the formula is COUNT by default
    if (filter.element?.subQuery?.relationData?.formula === Constants.FORMULA__VALUE__COUNT &&
    filter.element.relation) {
    // set the field the same as defined in predefinedRelation
      filter.element.subQuery.fields[0] = {
        collectionAlias: collection,
        field: predefinedRelation?.toFieldName?.toString() || '',
        alias: predefinedRelation?.toFieldName?.toString() || '',
        availableFieldObjectID: predefinedRelation?.toFieldObjectId || '',
        fieldType: predefinedRelation?.toFieldType || '',
      };
    } else {
    // set the first field from dataExtensionFields
      const firstFieldFromDE = filter.element?.subQuery?.dataExtensionFields?.length &&
      filter.element.subQuery.dataExtensionFields[0];

      filter.element.subQuery.fields[0] = {
        collectionAlias: collection,
        field: firstFieldFromDE?.Name?.toString() || '',
        alias: firstFieldFromDE?.Name?.toString() || '',
        availableFieldObjectID: firstFieldFromDE.ObjectID,
        fieldType: firstFieldFromDE?.FieldType || '',
      };
    }

    this.setState({ filters }, () => {
      const { filters: f, operator } = this.state;

      handleFiltersSave({ filters: f, operator });
    });
  };

  /**
   * When the data extension is removed from inside of filter, set subQuery object to its default state.
   * @param {number} id - id of filter
   * @returns {void}
   */
  handleRemoveDataExtensionFromFilter = (id) => {
    const { filters } = this.state;
    const { handleFiltersSave, handlePickListOptions } = this.props;
    const filter = this.findFilterElement(id, filters);

    if (filter && filter.element) {
    // remove ids which belong to the subquery DE from the picklist
      handlePickListOptions(
        filter.element.subQuery.dataExtensionFields,
        false,
        filter.element.subQuery.selectedDataExtension,
      );
      filter.element.subQuery.collections = [];
      filter.element.subQuery.fields = [];
      filter.element.subQuery.filters = [];
      filter.element.includesSubQuery = false;
    }

    this.setState({ filters }, () => {
      const { filters: f, operator } = this.state;

      handleFiltersSave({ filters: f, operator });
    });
  };

  /**
   * When a field is selected via dropdown, put it into subQuery object.
   * @param {string} value - value of selected field
   * @param {string} id - id of filter
   * @param {string} _parentId - filter's parent id
   * @param {bool} isInResultsFormula - check if criteria in filterline is in results formula
   * @param {object} subQuery - The subquery object
   * @returns {void}
   */
  handleChangeFilterDataExtensionField = (value, id, _parentId, isInResultsFormula, subQuery) => {
    const { filters } = this.state;
    const { handleFiltersSave } = this.props;

    let filter;

    let field;

    if (isInResultsFormula) {
      filter = this.findFilterElement(id, subQuery.formulas.filters);
      if (filter && filter.element) {
        field = subQuery.dataExtensionFields.filter(f => f.ObjectID === value);

        filter.element.fieldObjectID = field[0]?.ObjectID || undefined;
        filter.element.field = field[0]?.Name?.toString() || '';
        filter.element.fieldType = field[0]?.FieldType || '';
      }
    } else {
      filter = this.findFilterElement(id, filters);
      if (filter && filter.element) {
      // if dataExtensionFields exists
        if (filter.element?.subQuery && filter.element.subQuery?.dataExtensionFields &&
        filter.element.subQuery.dataExtensionFields?.length) {
        // find selected field in dataExtensionFields
          field = filter.element.subQuery.dataExtensionFields.filter(f => f.ObjectID === value);
        }

        // if subQuery fields exists and we are not in relation filter
        if (filter.element.subQuery?.fields && !filter.element.relation) {
          filter.element.subQuery.fields[0] = this.setSubQueryField(
            filter.element.subQuery.fields[0].collectionAlias,
            field[0],
          );
        } else if (filter.element.relation && field) {
        // for relation filter update relation Data in subQuery together with the field
          filter.element.subQuery.relationData = {
            ...filter.element.subQuery.relationData,
            field: field[0]?.Name?.toString() || '',
            fieldObjectID: field[0]?.ObjectID || undefined,
            fieldType: field[0]?.FieldType || '',
          };
        }
      }
    }

    this.setState({ filters }, () => {
      const { filters: f, operator } = this.state;

      handleFiltersSave({ filters: f, operator });
    });
  };

  /**
   * For changing the dynamic dates props (dateFilterType, dateValue, filterInterval, dateValueStart)
   * This function finds the date filter and changes that property`s value
   * @param {string} fieldProperty - property of a field
   * @param {string} filterLineId - id of filtered line
   * @param {string} value - chosen value
   * @param {object} filtersBranch - branch with filters
   * @returns {void}
   */
  handleFindDateFilter = (fieldProperty, filterLineId, value, filtersBranch) => {
    const { filters } = this.state;
    const { handleFiltersSave } = this.props;

    const filtersArray = filtersBranch || filters;

    if (this.featureDynamicDateFilterIsEnabled) {
      for (let f = 0; f < filtersArray.length; f += 1) {
        // If the array element contains a filters array, execute this function on this branch
        if (filtersArray[f].filters) {
          this.handleFindDateFilter(fieldProperty, filterLineId, value, filtersArray[f].filters);
        } else {
          if (filtersArray[f].id === filterLineId) {
            switch (fieldProperty) {
              case Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_FILTER_TYPE:
                filtersArray[f].dateFilterType = value;
                break;
              case Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE:
                filtersArray[f].dateValue = value;
                break;
              case Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__FILTER_INTERVAL:
                filtersArray[f].filterInterval = value;
                break;
              case Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE_START:
                filtersArray[f].dateValueStart = value;

                /**
                 * If you select an interval (hour or minute) then select a date value start
                 * (before/after today, today), HTML will force your choice to the first interval (i.e day)
                 * because it doesn't exist anymore. However, when HTML updates it won't notify React so we need
                 * this to ensure it's in sync.
                 */
                if ((filtersArray[f].filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR ||
                filtersArray[f].filterInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTE) && (
                  filtersArray[f].dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY ||
                  filtersArray[f].dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY ||
                  filtersArray[f].dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__TODAY
                )) {
                  filtersArray[f].filterInterval = Constants.FILTERLINE__DATE_VALUE__INTERVAL_DAYS;
                }
                break;
              default:
                break;
            }
          }
        }
      }

      this.setState({ filters: filtersArray }, () => {
        const { filters: f, operator } = this.state;

        handleFiltersSave({ filters: f, operator });
      });
    }
  };

  /**
   * This function helps to switch between relative or calendar date types
   * @param {object} e - event
   * @param {string} id - filter id
   * @returns {void}
   */
  handleOnChangeRelativeDateFilterType = (e, id) => {
    this.handleFindDateFilter(Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_FILTER_TYPE, id, e.target.value);
  };

  /**
   * This function helps to change relative date filter`s value (1,2,3,...)
   * @param {object} e - event
   * @param {string} id - filter id
   * @param {string} functionName - name of a function
   * @returns {void}
   */
  handleOnChangeRelativeDateValue = (e, id, functionName) => {
    const numberOnlyRegExp = /^[0-9]*$/;
    const dateValue = functionName ? 1 : e.target.value;

    if (dateValue > 0 && numberOnlyRegExp.test(dateValue)) {
      this.handleFindDateFilter(Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE, id, dateValue);
    } else {
      this.handleFindDateFilter(
        Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE,
        id,
        '',
      );
    }
  };

  /**
   * This function helps to change relative date filter`s interval (day, week)
   * @param {object} e - event
   * @param {string} id - filter id
   * @returns {void}
   */
  handleOnChangeRelativeDateFilterInterval = (e, id) => {
    this.handleFindDateFilter(Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__FILTER_INTERVAL, id, e.target.value);
    // Update date value start
    this.updateDateValueStartOnDateIntervalChange(e.target.value, id);
  };

  /**
   * This function helps to change relative date filter`s interval (day, week)
   * @param {object} dateInterval - event
   * @param {string} id - filter id
   * @returns {void}
   */
  updateDateValueStartOnDateIntervalChange = (dateInterval, id) => {
    const { filters } = this.state;
    const { element: filter } = this.findFilterElement(id, filters);

    // Update date value start for 'Before/ After' and 'Before/After or On'
    if (filter.criteria === Constants.FILTERLINE__CRITERIA__GREATER_THAN ||
    filter.criteria === Constants.FILTERLINE__CRITERIA__GREATER_THAN_OR_EQUAL_TO ||
    filter.criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_NEXT) {
      if (dateInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTE ||
      dateInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR) {
        this.handleFindDateFilter(
          Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE_START,
          id,
          Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW,
        );
      } else {
        this.handleFindDateFilter(
          Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE_START,
          id,
          Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY,
        );
      }
    } else if (filter.criteria === Constants.FILTERLINE__CRITERIA__SMALLER_THAN ||
    filter.criteria === Constants.FILTERLINE__CRITERIA__SMALLER_THAN_OR_EQUAL_TO ||
    filter.criteria === Constants.FILTERLINE__CRITERIA__DATE__IN_PREVIOUS) {
      if (dateInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTE ||
      dateInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR) {
        this.handleFindDateFilter(
          Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE_START,
          id,
          Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW,
        );
      } else {
        this.handleFindDateFilter(
          Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE_START,
          id,
          Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY,
        );
      }
    }

    // Update date value start for 'Equal' and 'Not Equal'
    if (filter.criteria === Constants.FILTERLINE__CRITERIA__EQUALS ||
    filter.criteria === Constants.FILTERLINE__CRITERIA__NOT_EQUAL_TO) {
      if (dateInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_MINUTE ||
      dateInterval === Constants.FILTERLINE__DATE_VALUE__INTERVAL_HOUR) {
        if (!(filter.dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW ||
        filter.dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_NOW)) {
          this.handleFindDateFilter(
            Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE_START,
            id,
            Constants.FILTERLINE__DATE_VALUE_START__AFTER_NOW,
          );
        }
      } else {
        if (!(filter.dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY ||
        filter.dateValueStart === Constants.FILTERLINE__DATE_VALUE_START__BEFORE_TODAY)) {
          this.handleFindDateFilter(
            Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE_START,
            id,
            Constants.FILTERLINE__DATE_VALUE_START__AFTER_TODAY,
          );
        }
      }
    }
  };

  /**
   * This function helps to change relative date filter`s value, depending on fieldProperty
   * @param {object} e - event
   * @param {string} id - filter id
   * @param {string} fieldProperty - property to be changed
   * @param {string} functionName - name of a function
   * @returns {void}
   */
  handleOnChangeRelativeDate = (e, id, fieldProperty, functionName) => {
    // change the relative date filter's value (1,2,3...)
    if(fieldProperty === Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE) {
      const numberOnlyRegExp = /^[0-9]*$/;
      const dateValue = functionName ? 1 : e.target.value;

      if (dateValue > 0 && numberOnlyRegExp.test(dateValue)) {
        this.handleFindDateFilter(Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE, id, dateValue);
      } else {
        this.handleFindDateFilter(
          Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE,
          id,
          '',
        );
      }
    }

    // change relative date filter`s property (interval, date type, starting day...)
    this.handleFindDateFilter(fieldProperty, id, e.target.value);

    if(fieldProperty === Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__FILTER_INTERVAL) {
      // Update date value start for filter`s interval
      this.updateDateValueStartOnDateIntervalChange(e.target.value, id);
    }
  };

  /**
   * This function hepşs to determine relative date filter`s starting day (before or after today)
   * @param {object} e - event
   * @param {string} id - filter id
   * @returns {void}
   */
  handleOnChangeRelativeDateValueStartFrom = (e, id) => {
    this.handleFindDateFilter(Constants.FILTERLINE__DYNAMIC_DATE_PROPERTY__DATE_VALUE_START, id, e.target.value);
  };

  /**
   * Checks if the fields belong to a different DE instance, when we have the same DE twice with different aliases
   * we treat them as different ones when the alias does not match
   * @param {string} collectionAlias - DE alias used to get DE
   * @param {string} deAlias - Alias of the DE we are comparing to
   * @returns {bool} To indicate if the fields belong to the different DE
   */
  areDifferentDEsBeingCompared = (collectionAlias, deAlias) => collectionAlias !== deAlias;

  /**
   * check if there are any fields to compare to
   * @param {object} field - The field that is being compared
   * @param {object} fields - available fields
   * @param {string} deAlias - Alias of the DE we are comparing to
   * @returns {object} To indicate if there's at least a field that can be compared with the given field
   */
  availableFieldToCompare = (field, fields, deAlias) => {
    let availableField;
    const { fieldObjectID, fieldType, collectionAlias } = field;

    // check if the fields belong to the same DE
    const differentDE = this.areDifferentDEsBeingCompared(collectionAlias, deAlias);

    for (let i = 0; i < fields.length; i += 1) {
      if (differentDE || (!differentDE && fieldObjectID !== fields[i].ObjectID)) {
        if (fieldType === Constants.FILTERLINE__FIELDTYPE__TEXT) {
          if (fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
          fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS ||
          fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__PHONE ||
          fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__LOCALE) {
            availableField = fields[i];
            break;
          }
        } else if (fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ||
        fieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL) {
          if (fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ||
          fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL) {
            availableField = fields[i];
            break;
          }
        } else if (fieldType === Constants.FILTERLINE__FIELDTYPE__DATE) {
          if (fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__DATE) {
            availableField = fields[i];
            break;
          }
        } else if (fieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN) {
          if (fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN) {
            availableField = fields[i];
            break;
          }
        } else if (fieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS) {
          if (fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
          fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS) {
            availableField = fields[i];
            break;
          }
        } else if (fieldType === Constants.FILTERLINE__FIELDTYPE__PHONE) {
          if (fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
          fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__PHONE) {
            availableField = fields[i];
            break;
          }
        } else if (fieldType === Constants.FILTERLINE__FIELDTYPE__LOCALE) {
          if (fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
          fields[i].FieldType === Constants.FILTERLINE__FIELDTYPE__LOCALE) {
            availableField = fields[i];
            break;
          }
        } else {
          availableField = null;
          break;
        }
      }
    }

    return availableField;
  };

  /**
   * Filters fields so that a field cannot be compared with itself
   * @param {object} field - The field that is being compared
   * @param {array} fields - An array of fields to be compared with the field above
   * @param {string} deAlias - Alias of the DE we are comparing to
   * @returns {array} The filtered fields
   */
  filterSelf = (field, fields, deAlias) => {
    const { fieldObjectID, collectionAlias } = field;

    // check if the fields belong to the same DE
    const differentDE = this.areDifferentDEsBeingCompared(collectionAlias, deAlias);

    return fields.filter(f => (differentDE || (!differentDE && f.ObjectID !== fieldObjectID)));
  };

  /**
   * Changes the filter according to if it's going to be compared or not.
   * If to be compared, sets a data extension and its first field to compare.
   * @param {boolean} value - if true - set a data extension and its first field to compare
   * @param {string} filterLineId - filter line id
   * @returns {void}
   */
  handleOnChangeIsCompareFieldsFilter = async (value, filterLineId) => {
    const { filters } = this.state;
    const {
      handleFiltersSave,
      selectedDataExtensions,
      compareSelectedDataExtensions,
      getDataExtensionOrDataViewFields,
      handleSetSelectionState,
    } = this.props;
    // Look for a clicked filter and its parent
    const filter = this.findFilterElement(filterLineId, filters);

    if (filter && filter.element) {
      if (value) {
        filter.element.isCompareFieldsFilter = true;
        if (compareSelectedDataExtensions && compareSelectedDataExtensions.length > 0) {
        // assign first element of the selectedDataExtension to subQuery`s compared DataExtension
          [filter.element.comparedDataExtension] = compareSelectedDataExtensions;
        } else {
          if (selectedDataExtensions && selectedDataExtensions.length > 0) {
          // assign first element of the selectedDataExtension
            [filter.element.comparedDataExtension] = selectedDataExtensions;
          }
        }
        try {
        // fetch its fields
          const fields = await getDataExtensionOrDataViewFields(filter.element.comparedDataExtension, true);
          // store them in the element`s property

          filter.element.comparableFields = this.filterSelf(
            filter.element,
            fields,
            filter.element.comparedDataExtension.deAlias,
          );
          filter.element.previousValue = filter.element.value;

          if (fields && fields.length > 0) {
          // check if there is field to compare to, if there is field, set that field to comparedField
            const availableField = this.availableFieldToCompare(
              filter.element,
              fields,
              filter.element.comparedDataExtension.deAlias,
            );

            filter.element.comparedField = availableField || null;
          }
        } catch (error) {
          handleSetSelectionState({ error });
        }
      } else {
        filter.element.isCompareFieldsFilter = false;
        filter.element.value = filter.element.previousValue;
      }
    }

    this.setState({
      filters,
    }, () => {
      const { filters: f, operator } = this.state;

      handleFiltersSave({ filters: f, operator });
    });
  };

  /**
   * It helps to change data extension of the comparable filter and it sets
   * its first field to compare
   * @param {string} value - DE name
   * @param {string} id - filter id
   * @returns {void}
   */
  handleOnChangeFilterComparedDataExtension = async (value, id) => {
    const { filters } = this.state;
    const {
      handleFiltersSave,
      selectedDataExtensions,
      compareSelectedDataExtensions,
      getDataExtensionOrDataViewFields,
      handleSetSelectionState,
    } = this.props;
    const filter = this.findFilterElement(id, filters);

    let comparedDataExtensions = [];

    if (filter && filter.element) {
      if (compareSelectedDataExtensions && compareSelectedDataExtensions.length > 0) {
        comparedDataExtensions = compareSelectedDataExtensions.filter(
          de => de.deAlias === value,
        );
      } else {
        if (selectedDataExtensions && selectedDataExtensions.length > 0) {
          comparedDataExtensions = selectedDataExtensions.filter(
            de => de.deAlias === value,
          );
        }
      }
      if (comparedDataExtensions && comparedDataExtensions.length > 0) {
      // find the selected data extension and assign it to element`s property
        [filter.element.comparedDataExtension] = comparedDataExtensions;
      }
      try {
      // fetch the selected data extension`s/view`s fields
        const fields = await getDataExtensionOrDataViewFields(filter.element.comparedDataExtension, true);

        if (fields && fields.length > 0) {
        // set them to the element`s property
          filter.element.comparableFields = this.filterSelf(
            filter.element,
            fields,
            filter.element.comparedDataExtension.deAlias,
          );

          // check if there is field to compare to, if there is field, set that field to comparedField
          const availableField = this.availableFieldToCompare(
            filter.element,
            fields,
            filter.element.comparedDataExtension.deAlias,
          );

          filter.element.comparedField = availableField || null;
        }
      } catch (error) {
        handleSetSelectionState({ error });
      }
    }
    this.setState({
      filters,
    }, () => {
      const { filters: f, operator } = this.state;

      handleFiltersSave({ filters: f, operator });
    });
  };

  /**
   * It sets the field to compare with main filter field
   * @param {boolean} value - false/true
   * @param {string} id - filter id
   * @returns {void}
   */
  handleOnChangeCompareFieldsFilter = (value, id) => {
    const { filters } = this.state;
    const { handleFiltersSave } = this.props;

    const filter = this.findFilterElement(id, filters);

    if (filter && filter.element) {
      const selectedField = filter.element.comparableFields.filter(
        field => field.ObjectID === value,
      );

      if (selectedField && selectedField.length > 0) {
        [filter.element.comparedField] = selectedField;
      }
    }

    this.setState({
      filters,
    }, () => {
      const { filters: f, operator } = this.state;

      handleFiltersSave({ filters: f, operator });
    });
  };

  /**
   * Set state of this object
   * @param {object} newState - A new state
   * @returns {void}
   */
  handleSetSelectedFiltersState = (newState) => {
    this.setState(newState);
  };

  /**
   * Open SubQueryModal
   * @param {string} filterLineId - id of filter line
   * @param {boolean} isSubQueryRelation - Is the filterline a relation?
   * @returns {void}
   */
  handleOpenSubQueryModal = (filterLineId, isSubQueryRelation) => {
    let subQueryModalSelectedFilters;

    let subQueryModalSelectedDataExtensions;

    // load subQuery from appropriate filterline
    const { filters } = this.state;

    // store begin state for when Cancel is clicked
    const previousSelectedFilters = JSON.parse(JSON.stringify(filters));

    // get the subQuery details from the opened filterLine
    const filter = this.findFilterElement(filterLineId, filters);

    if (filter && filter.element && filter.element.subQuery) {
      subQueryModalSelectedFilters = filter.element.subQuery.filters;

      if (filter?.element?.subQuery?.selectedDataExtension) {
        subQueryModalSelectedDataExtensions = [filter?.element?.subQuery?.selectedDataExtension];
      }
    }

    this.setState({
      subQueryModalSelectedFilters,
      showSubQueryModal: true,
      subQueryModalFilterLineId: filterLineId,
      subQueryModalSelectedDataExtensions,
      previousSelectedFilters,
      isSubQueryRelation,
    });
  };

  /**
   * Cancel changes in the SubQuery modal
   * @returns {void}
   */
  handleCancelChangesSubQueryModal = () => {
    const { previousSelectedFilters } = this.state;
    const { isFromSelection } = this.props;

    const {
      unionSelections,
      unionSelectionsIndex,
      handleSetAppState,
    } = this.props;

    if (previousSelectedFilters) {
      this.setState({
        filters: previousSelectedFilters,
      });
    }

    if (isFromSelection) {
      // Reset the filters
      unionSelections[unionSelectionsIndex].selectedFilters.filters = previousSelectedFilters;
      handleSetAppState({ unionSelections });
    }
  };

  /**
   * Change formula value [none, sum, count]
   * @param {string} id - id
   * @param {string} formulaValue - type of formula value
   * @param {boolean} newRelationFilter - determines whether a new relation filter is created
   * @param {bool} isInResultsFormula - check if criteria in filterline is in results formula
   * @param {object} subQuery - this is the subQuery object
   * @returns {void}
   */
  handleOnChangeFormulaFilterFormulaValue = (
    id,
    formulaValue,
    newRelationFilter,
    isInResultsFormula,
    subQuery,
  ) => {
    const { filters } = this.state;
    const { handleFiltersSave, returnPredefinedRelationById } = this.props;

    let isNumericFormula = false;

    let filter;

    if (isInResultsFormula) {
    // Find formula filter
      filter = this.findFilterElement(id, subQuery.formulas.filters);
    } else {
      filter = this.findFilterElement(id, filters);
    }

    let predefinedRelation;

    if (filter.element.relationId) {
      predefinedRelation = returnPredefinedRelationById(filter.element.relationId, filter.element);
    }

    if (filter && filter.element) {
      isNumericFormula = Constants.FORMULA__NUMERIC__ARRAY.includes(formulaValue);

      if (formulaValue === Constants.FORMULA__VALUE__NONE) {
        delete filter.element.formula;
        filter.element.value = filter.element.previousValue;
        filter.element.criteria = Constants.FILTERLINE__CRITERIA__EQUALS;
      } else {
      // for relation filterline
        if (filter.element.relation) {
        // set criteria in filter element -> in-result
          filter.element.criteria = Constants.FILTERLINE__CRITERIA__IN_RESULTS;

          // when we add new relation filterline
          if (newRelationFilter && Util.objectIsEmpty(filter.element.subQuery.relationData)) {
          // get DE fields and set DE in subQuery
            this.handleSetDataExtensionToFilter(filter.element, id);

            if (filter && filter.element && filter.element.subQuery && filter.element.relation &&
            predefinedRelation) {
            // set default criteria
              const defaultCriteria = filter.element.criteria === Constants.FILTERLINE__CRITERIA__IN_RESULTS ?
                Constants.FILTERLINE__RELATION_CRITERIA__AT_LEAST_VALUE :
                filter.element.criteria;

              // set initial relationData in subQuery
              filter.element.subQuery.relationData = {
                ...filter.element,
                collectionAlias: predefinedRelation?.toDEName?.toString() || '',
                field: predefinedRelation?.toFieldName?.toString() || '',
                fieldObjectID: predefinedRelation?.toFieldObjectId || '',
                fieldType: predefinedRelation?.toFieldType || '',
                formula: formulaValue,
                criteria: defaultCriteria,
                subQuery: {},
                relationId: null,
                value: '1',
              };
            }
          } else if (filter && filter.element && filter.element.subQuery) {
          // update formula for existing relation filterline
            if (filter.element.subQuery.relationData) {
            // when we change the formula from count
              if (filter.element.subQuery.relationData?.formula === Constants.FORMULA__VALUE__COUNT &&
              formulaValue !== Constants.FORMULA__VALUE__COUNT && filter.element.relation) {
              // set the first number or decimal field from dataExtensionFields in subQuery fields
                const firstFieldFromDE = filter.element.subQuery.dataExtensionFields?.length &&
                filter.element.subQuery.dataExtensionFields.find(field => field.FieldType ===
                  Constants.FILTERLINE__FIELDTYPE__NUMBER ||
                  field.FieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL);

                if (firstFieldFromDE) {
                // if the first field is found, then change it in subQuery.fields
                  this.handleChangeFilterDataExtensionField(firstFieldFromDE.ObjectID, id);
                } else {
                // if field is not found, change the field in subQuery.fields to undefined
                  this.handleChangeFilterDataExtensionField(null, id);
                }
              } else if (formulaValue === Constants.FORMULA__VALUE__COUNT &&
              filter.element.relation && predefinedRelation?.toFieldObjectId) {
              // when we change the formula to count, set a field from predefinedRelation
                this.handleChangeFilterDataExtensionField(predefinedRelation.toFieldObjectId, id);
              }

              // update relation Data in subQuery
              filter.element.subQuery.relationData = {
                ...filter.element.subQuery.relationData,
                formula: formulaValue,
              };
            }
          }
        } else {
          if (formulaValue === Constants.FORMULA__VALUE__COUNT) {
            if (subQuery.fields.length > 0) {
              this.handleChangeFilterDataExtensionField(
                subQuery.fields[0].availableFieldObjectID,
                id,
                '',
                isInResultsFormula,
                subQuery,
              );
            }
          }
          if (isNumericFormula && (filter.element.fieldType === 'Text' || filter.element.fieldType === 'Date')) {
            const field = subQuery.dataExtensionFields.find(f => f.FieldType === 'Number' || f.FieldType === 'Decimal');

            if (field) {
              this.handleChangeFilterDataExtensionField(field.ObjectID, id, '', isInResultsFormula, subQuery);
            }
          }
          filter.element.formula = formulaValue;
          filter.element.previousValue = filter.element.value;
          filter.element.value = '0';
        }
      }
    }

    this.setState({
      filters,
    }, () => {
      const { filters: f, operator } = this.state;

      handleFiltersSave({ filters: f, operator });
    });
  };

  /**
   *
   * @param {*} id
   * @param {*} value
   * @param {*} updateInSubQuery
   * @returns
   */

  handleAddFormulaFilter = (
    id,
    collectionAliasForSelectedDE,
    field,
    fieldObjectID,
    fieldType,
    formulaValue,
  ) => {
    const { filters } = this.state;

    const filter = this.findFilterElement(id, filters);
    const { handleFiltersSave, selectedDataExtensions } = this.props;

    let filtersArray = [];

    if (!filter.element.subQuery.formulas || filter.element.subQuery.formulas.length === 0) {
      filter.element.subQuery = {
        ...filter.element.subQuery,
        formulas: {
          filters: [],
          operator: 'AND',
        },
      };
    }

    filtersArray = filter.element.subQuery.formulas.filters;

    // set default criteria
    const defaultCriteria = Constants.FILTERLINE__RELATION_CRITERIA__AT_LEAST_VALUE;

    // set initial formula data
    const newFormulaFilter = {
      ...filter.element,
      field,
      fieldObjectID,
      fieldType,
      formula: formulaValue,
      criteria: defaultCriteria,
      subQuery: {},
      relationId: null,
      value: '1',
      parentId: id,
      includesSubQuery: false,
      collectionAlias: collectionAliasForSelectedDE,
      selectedActiveDE: selectedDataExtensions[0]?.deAlias,
    };

    filtersArray.push(newFormulaFilter);

    this.recreateIds(filtersArray, id, true);

    this.setState({
      filters,
    }, () => {
      const { filters: f, operator } = this.state;

      handleFiltersSave({ filters: f, operator });
    });
  };

  //
  /**
   * Change filter`s value if there is formula selected
   * @param {string} id - filter id
   * @param {string} value - filter's value
   * @param {boolean} updateInSubQuery - defines whether to update data in subQuery
   * @param {bool} isInResultsFormula - check if criteria in filterline is in results formula
   * @param {object} subQuery - this is the subQuery object
   * @returns {void}
   */
  handleOnChangeFormulaValue = (id, value = 0, updateInSubQuery, isInResultsFormula, subQuery) => {
    const { filters } = this.state;
    const { handleFiltersSave } = this.props;

    let filter;

    if (isInResultsFormula) {
    // Find formula filter
      filter = this.findFilterElement(id, subQuery.formulas.filters);
    } else {
      filter = this.findFilterElement(id, filters);
    }

    if (filter && filter.element) {
    /*
     * if field type is number and value doesn't containe only numbers
     * don't allow entering that value (eg. dot, comma, etc...)
     */
      if (filter.element.fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER &&
      !Util.containsOnlyNumbers(value) && value !== '') {
        return;
      }

      // when we want to update value in subQuery in relationData/formula
      if (updateInSubQuery && !isInResultsFormula) {
        if (filter.element?.subQuery?.relationData) {
        // update the value in relatedData
          filter.element.subQuery.relationData.value = `${value}`;
          // switch between in results and not in results statements
          if (
            filter.element.subQuery.relationData.criteria === Constants.FILTERLINE__CRITERIA__EQUALS &&
          Number(value) === 0 &&
          filter.element.criteria === Constants.FILTERLINE__CRITERIA__IN_RESULTS &&
          filter.element.subQuery.relationData.formula === Constants.FORMULA__VALUE__COUNT
          ) {
          // we use not in results in case if user selected COUNT formula and searching EXACTLY for 0 values
            filter.element.criteria = Constants.FILTERLINE__CRITERIA__NOT_IN_RESULTS;
          } else if (
            filter.element.criteria === Constants.FILTERLINE__CRITERIA__NOT_IN_RESULTS
          ) {
          // in any other case if we had NOT IN RESULTS we switch back to IN RESULTS
            filter.element.criteria = Constants.FILTERLINE__CRITERIA__IN_RESULTS;
          }
        }
      } else {
        filter.element.value = `${value}`;
      }
    }

    this.setState({
      filters,
    }, () => {
      const { filters: f, operator } = this.state;

      handleFiltersSave({ filters: f, operator });
    });
  };

  /**
   * Show/hide text
   * @returns {void}
   */
  handleShowTextBtn = () => {
    const { showText } = this.state;

    this.setState({ showText: !showText });
  };

  /**
   * Changes the filter according to if it's going to be picklist or not.
   * @param {string} filterLineId - filter line id
   * @returns {void}
   */
  handleOnChangeIsPicklistOption = async (filterLineId) => {
    const { filters } = this.state;
    const {
      handleFiltersSave,
    } = this.props;
    // Look for a clicked filter and its parent
    const filter = this.findFilterElement(filterLineId, filters);

    if (filter && filter.element) {
      if (filter.element.isPicklistOptions) {
        filter.element.isPicklistOptions = false;
      } else {
        const { pickLists } = this.props;
        const newFilterValue = [];
        const filterValue = filter.element.value.split ?
          filter.element.value.split(',') :
          filter.element.value;

        filterValue.map((f) => {
          pickLists.map((p) => {
            p.options.map((o) => {
              if (o.value === f) {
                newFilterValue.push(f);
              }

              return null;
            });

            return null;
          });

          return null;
        });
        filter.element.value = newFilterValue;
        filter.element.isPicklistOptions = true;
      }
    }

    this.setState({
      filters,
    }, () => {
      const { filters: f, operator } = this.state;

      handleFiltersSave({ filters: f, operator });
    });
  };

  /**
   * Function to close filter set modal
   * @returns {void}
   */
  handleCloseFilterSetModal = () => {
    const { hasSendable } = this.state;

    if (hasSendable) {
      this.setState({
        selectDataExtension: false,
      });
    }else {
      this.setState({
        selectDataExtension: false,
        filterSetSelectedSourceDE: '',
        selectedDataExtensionFieldId: '',
        selectDataExtensionFieldName: '',
        selectDataExtensionName: '',
      });
    }
  };

  /**
   * Handle change of source option
   * @param {Event} e - OnChange event
   * @param {Object} data - Selected option data object
   * @returns {void}
   */
  handleSourceOptionOnChange = async (e, data) => {
    const res = await data?.options?.filter(res => res.value === data?.value);

    if(res[0]?.isSendableFieldName) {
      const sendableData = await res[0]?.fields?.filter(de => de.Name === res[0]?.isSendableFieldName);

      if(sendableData) {
        this.setState({
          filterSetSelectedSourceDE: data?.value,
          filterSetSelectedSourceDEObjectID: data?.options[0]?.key,
          selectedDataExtensionFieldId: sendableData[0]?.ObjectID,
          selectDataExtensionName: data?.value,
          selectDataExtensionFieldName: sendableData[0]?.FieldType,
          selectedSubscription: res[0]?.subscription === '_SubscriberKey' ? 'SubscriberKey' : 'SubscriberID',
        });
      }
    } else {
      this.setState({
        filterSetSelectedSourceDE: data?.value,
        filterSetSelectedSourceDEObjectID: data?.options[0]?.key,
        selectedDataExtensionFieldId: res[0].fields[0].ObjectID,
        selectDataExtensionName: data?.value,
        selectDataExtensionFieldName: res[0].fields[0].FieldType,
        selectedSubscription: res[0]?.subscription === '_SubscriberKey' ? 'SubscriberKey' : 'SubscriberID',
      });
    }
  };

  /**
   * Handle change of selected data extensions fields
   * @param {*} e - OnChange event
   * @param {*} selectedDataExtensionFields - selected field
   * @param {*} selectedDataExtensionAlias - selected data extension name
   * @returns {void}
   */
  handleSelectedFieldOptionOnChange = (
    e,
    selectedDataExtensionFields,
    selectedDataExtensionAlias,
  ) => {
    const selectedDataExtensionField = selectedDataExtensionFields?.find(de => de.key === e?.target.value);

    this.setState({
      selectedDataExtensionFieldId: e?.target.value,
      selectDataExtensionFieldName: selectedDataExtensionField.label,
      selectDataExtensionName: selectedDataExtensionAlias,
    });
  };

  /**
   * Function to find and update an object based on a specific property
   * @param {*} array - array of filter sets
   * @param {*} property - filter set id
   * @param {*} value - selected filter set
   * @param {*} update - filter set to update
   * @returns {void}
   */
  findAndUpdateObject = (array, property, value, update) => {
    const index = array.findIndex(obj => obj[property] === value);

    if (index !== -1) {
      // Update the object
      array[index] = { ...array[index], ...update };
    }
  };

  /**
   * Function to remove fields from object
   * @param {*} array - array of filter sets
   * @param {*} fieldName - filter set variable
   * @param {*} selectedFilterSetId - selected filter set id
   * @returns {void}
   */
  removeFieldFromObjects = (array, fieldName, selectedFilterSetId) => {
    return array.map((obj) => {
      if (obj._id === selectedFilterSetId) {
        const { [fieldName]: removedField, ...rest } = obj;

        return rest;
      }

      return obj;
    });
  };

  /**
   * Function to add customer key to data views object
   * @param {*} arr - array of data views
   * @returns {void}
   */
  addCustomerKeyToObjects = (arr) => {
    return arr.map((obj, index) => {
      // Add ID property to each object
      obj.CustomerKey = obj.Name + index + 1;

      return obj;
    });
  };

  /**
   * Function to check if data extensions have loaded
   * @returns {void}
   */
  waitForDataExtensionsToLoad = () => {
    return new Promise((resolve) => {
      const checkDataExtensions = () => {
        const {
          dataExtensions,
          selectedDataExtensions,
        } = this.props;

        if (dataExtensions.length === selectedDataExtensions.length + 1) {
          // Wait for a short period before checking again (adjust the delay if needed)
          setTimeout(checkDataExtensions, 2000);
        } else {
          // Both dataExtensions and selectedDataExtensions have been loaded
          resolve();
        }
      };

      checkDataExtensions();
    });
  };

  /**
   * Append filters to the behavioral filter set and adds filter set to filter line
   * @returns {void}
   */
  handleFilterSetModalSave = async () => {
    // Create behavioral filter
    const {
      selectedDataExtensionFieldId,
      selectDataExtensionFieldName,
      selectDataExtensionName,
      selectedFilterSetId,
      selectedSubscription,
      dataViewName,
      behavioralFilterSetCriteria,
      filterSetSelectedSourceDEObjectID,
    } = this.state;

    const {
      filterSets,
      revertBehavioralFilterSet,
      selectedDataExtensions,
    } = this.props;

    this.setState({ loadingDataExtensions: true });

    await this.waitForDataExtensionsToLoad();

    const dataViewFields = await DataViewsAPI.getDataViewFields(dataViewName, this.axiosCancelToken.token);

    const dataViewFieldsFinalObject = await this.addCustomerKeyToObjects(dataViewFields);

    // Create new behavioral filter set object
    const newFilters = {
      filters: [{
        fieldObjectID: selectedDataExtensionFieldId ? selectedDataExtensionFieldId.toString() : null,
        field: selectDataExtensionFieldName ? selectDataExtensionFieldName.toString() : null,
        collectionAlias: selectDataExtensionName,
        deId: filterSetSelectedSourceDEObjectID,
        hideCollectionAlias: false,
        fieldType: 'Text',
        criteria: behavioralFilterSetCriteria,
        value: '',
        includesSubQuery: true,
        subQuery: {
          fields: [{
            availableFieldObjectID: selectedSubscription,
            collectionAlias: dataViewName,
            field: selectedSubscription,
            fieldType: selectedSubscription === 'SubscriberID' ? 'Number' : 'Text',
          }],
          filters: {
            filters: [{
              fieldObjectID: 'EventDate',
              field: 'EventDate',
              collectionAlias: dataViewName,
              deId: dataViewName,
              hideCollectionAlias: true,
              fieldType: 'Date',
              criteria: 'in-previous',
              includesSubQuery: false,
              subQuery: {
                fields: [],
                filters: [],
                formulas: {
                  filters: [],
                  operator: 'AND',
                },
                relations: [],
                collections: [],
                dataExtensionFields: [],
              },
              isCompareFieldsFilter: false,
              isPicklistOptions: true,
              comparableFields: [],
              relation: false,
              relationId: '',
              dateFilterType: 'relative',
              dateValueStart: 'Before Today',
              filterInterval: 'day',
              dateValue: 1,
              id: '0',
            }],
            operator: 'AND',
          },
          formulas: {
            filters: [],
            operator: Constants.FILTERLINE__OPERATOR__AND,
          },
          relations: [],
          collections: [{
            alias: dataViewName,
            collection: dataViewName,
            collectionCustomerKey: dataViewName,
          }],
          dataExtensionFields: dataViewFieldsFinalObject, // TODO: get from open DE
          selectedDataExtension: {
            CustomerKey: dataViewName,
            Name: dataViewName,
            deAlias: dataViewName,
            dragged: false,
            fields: dataViewFieldsFinalObject,
            id: dataViewName + Date.now(),
          },
        },
        isCompareFieldsFilter: false,
        isPicklistOptions: true,
        comparableFields: [],
        relation: false,
        relationId: '',
        id: 1,
        previousValue: '',

      }],
      operator: 'AND',
    };

    // update behavioral filter set props
    this.findAndUpdateObject(
      filterSets,
      '_id',
      selectedFilterSetId,
      { dataExtensionObjectId: filterSetSelectedSourceDEObjectID },
    );

    this.findAndUpdateObject(
      filterSets,
      '_id',
      selectedFilterSetId,
      { filters: newFilters },
    );
    // Call AddFilterSetFilters
    this.addFilterSetFilters(selectedFilterSetId);
    this.handleCloseFilterSetModal();

    this.findAndUpdateObject(
      filterSets,
      '_id',
      selectedFilterSetId,
      { dataExtensionObjectId: '' },
    );
    // Revert the state of behavioral filter set
    const modifiedArray = this.removeFieldFromObjects(filterSets, 'filters', selectedFilterSetId);

    revertBehavioralFilterSet(modifiedArray);

    this.setState({
      loadingDataExtensions: false,
      selectDataExtension: false,
      filterSetSelectedSourceDE: '',
      selectedDataExtensionFieldId: '',
      selectDataExtensionFieldName: '',
      selectDataExtensionName: '',
      selectedFilterSetId: '',
      selectedSubscription: 'SubscriberKey',
      dataViewName: '',
      behavioralFilterSetCriteria: '',
      hasSendable: true,
    });

    this.checkSendableDataExtension(selectedDataExtensions);
  };

  /**
   * Handles change of subscription
   * @param {*} subscriber - selected subscriber
   * @returns {void}
   */
  handleSelectedSubscription = (subscriber) => {
    this.setState({ selectedSubscription: subscriber });
  };

  render() {
    const {
      selectedDataExtensions,
      compareSelectedDataExtensions,
      handleSetSelectionState,
      isSubQuery,
      handleFiltersSave,
      selectedFilters,
      getDataExtensionOrDataViewFields,
      handleFeatureMissing,
      manageSubscriberRelationship,
      DEBorderMouseOver,
      filterBorderMouseOver,
      showInResultsOption,
      pickLists,
      handlePickListOptions,
      dataExtensions,
      loadingSubQueryFields,
      handleRemoveFilterLine,
      subQueryModalFilterLineId: subQueryFilterLineId,
      filterType,
      isSubQueryRelation: isSubQueryParentRelation,
      returnPredefinedRelationById,
      applyTimezoneSettingsToAllDateFields,
      handleSetTimezoneToAllDateFields,
      timezoneSettingsForAllDateFields,
      showFilterSets,
      showEssentialsUpgradeModal,
    } = this.props;
    const {
      filters,
      operator,
      showSubQueryModal,
      subQueryModalFilterLineId,
      subQueryModalSelectedFilters,
      subQueryModalSelectedDataExtensions,
      previousSelectedFilters,
      showText,
      isSubQueryRelation,
      tags,
      showTimezoneModal,
      convertTimezone,
      convertToTimezone,
      convertFromTimezone,
      collapseFormulas,
      selectDataExtension,
      filterSetSelectedSourceDE,
      selectedDataExtensionFieldId,
      selectedSubscription,
      loadingDataExtensions,
    } = this.state;

    Util.updateFiltersFieldName(selectedDataExtensions, selectedFilters);
    const filterText = filtersUtil.getFilterTextForFilters(filters, operator, showInResultsOption, pickLists);

    return (
    <>
      <div className="selected-filters">
        <h4 className="section-title"> Filters </h4>
        {' '}
        <div id="filters">
          <FilterContainer
            handleSetDataExtensionToFilter={this.handleSetDataExtensionToFilter}
            dataExtensions={dataExtensions}
            showInResultsOption={showInResultsOption}
            addNewFilterline={this.addNewFilterline}
            handleUpdateFilterLineValue={this.handleUpdateFilterLineValue}
            handleRemoveFilterLine={handleRemoveFilterLine}
            filterType={filterType}
            subQueryModalFilterLineId={subQueryFilterLineId || subQueryModalFilterLineId}
            selectedDataExtensions={selectedDataExtensions}
            compareSelectedDataExtensions={compareSelectedDataExtensions}
            handleUpdateFilterLineCriteria={this.handleUpdateFilterLineCriteria}
            handleUpdateFormulaFilterLineCriteria={this.handleUpdateFormulaFilterLineCriteria}
            handleLogicChange={this.handleLogicChange}
            handleHoverFilterMerging={this.handleHoverFilterMerging}
            handleHideAddFiltersTogether={this.handleHideAddFiltersTogether}
            dropFieldOnFilters={this.dropFieldOnFilters}
            filters={filters}
            handleMergeFilterLinesCriteria={this.handleMergeFilterLinesCriteria}
            handleMergeFilterLines={this.handleMergeFilterLines}
            operator={operator}
            handleSetSelectionState={handleSetSelectionState}
            isSubQuery={isSubQuery}
            handleOpenSubQueryModal={this.handleOpenSubQueryModal}
            handleRemoveDataExtensionFromFilter={this.handleRemoveDataExtensionFromFilter}
            handleChangeFilterDataExtensionField={this.handleChangeFilterDataExtensionField}
            handleOnChangeRelativeDate={this.handleOnChangeRelativeDate}
            handleOnChangeRelativeDateFilterType={this.handleOnChangeRelativeDateFilterType}
            handleOnChangeRelativeDateValue={this.handleOnChangeRelativeDateValue}
            handleOnChangeRelativeDateFilterInterval={this.handleOnChangeRelativeDateFilterInterval}
            handleOnChangeRelativeDateValueStartFrom={this.handleOnChangeRelativeDateValueStartFrom}
            handleOnChangeIsCompareFieldsFilter={this.handleOnChangeIsCompareFieldsFilter}
            handleOnChangeFilterComparedDataExtension={this.handleOnChangeFilterComparedDataExtension}
            handleOnChangeCompareFieldsFilter={this.handleOnChangeCompareFieldsFilter}
            handleFeatureMissing={handleFeatureMissing}
            handleOnChangeFormulaFilterFormulaValue={this.handleOnChangeFormulaFilterFormulaValue}
            handleAddFormulaFilter={this.handleAddFormulaFilter}
            handleOnChangeFormulaValue={this.handleOnChangeFormulaValue}
            DEBorderMouseOver={DEBorderMouseOver}
            filterBorderMouseOver={filterBorderMouseOver}
            pickLists={pickLists}
            handleOnChangeIsPicklistOption={this.handleOnChangeIsPicklistOption}
            loadingSubQueryFields={loadingSubQueryFields}
            handleFiltersSave={handleFiltersSave}
            subQueryFilters={filters}
            isSubQueryParentRelation={isSubQueryParentRelation}
            returnPredefinedRelationById={returnPredefinedRelationById}
            tags={tags}
            handleTagsChanged={this.handleTagsChanged}
            handleSetTags={this.handleSetTags}
            updateTags={this.updateTags}
            handleOpenTimezoneModal={this.handleOpenTimezoneModal}
            collapseFormulas={collapseFormulas}
            handleCollapseAllFormulas={this.handleCollapseAllFormulas}
            showEssentialsUpgradeModal={showEssentialsUpgradeModal}
          />
        </div>
        {/* Filter text show more/less */}
        <p style={{ wordWrap: 'break-word', lineHeight: '1.25rem' }}>
          {filterText.length > 320 && showText === false ?
            `${Util.abbreviate(filterText, 320)}` :
            filterText}
          <span
            id="show-more-less-text"
            className={filterText.length > 320 ? 'show-more-less-text' : 'show-more-less-text-none'}
            onClick={() => this.handleShowTextBtn()}
          >
            {showText === false ? 'Show More' : 'Show Less'}
          </span>
        </p>
      </div>

      {!isSubQuery && showSubQueryModal ?
        (
          <SubQueryModal
            hideCollectionAlias
            showInResultsOption={showInResultsOption}
            show={showSubQueryModal}
            handleSetSelectedFiltersState={this.handleSetSelectedFiltersState}
            handleCancelChangesSubQueryModal={this.handleCancelChangesSubQueryModal}
            handleSetSelectionState={handleSetSelectionState}
            compareSelectedDataExtensions={selectedDataExtensions}
            selectedDataExtensions={subQueryModalSelectedDataExtensions}
            handleFiltersSave={handleFiltersSave}
            selectedFilters={selectedFilters}
            previousSelectedFilters={previousSelectedFilters}
            subQueryModalFilterLineId={subQueryModalFilterLineId}
            subQueryModalSelectedFilters={subQueryModalSelectedFilters}
            getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
            handleFeatureMissing={handleFeatureMissing}
            manageSubscriberRelationship={manageSubscriberRelationship}
            DEBorderMouseOver={DEBorderMouseOver}
            filterBorderMouseOver={filterBorderMouseOver}
            pickLists={pickLists}
            handlePickListOptions={handlePickListOptions}
            handleRemoveFilterLine={handleRemoveFilterLine}
            filterType={filterType}
            isSubQueryRelation={isSubQueryRelation}
            applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
            timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
            handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
            showEssentialsUpgradeModal={showEssentialsUpgradeModal}
          />
        ) :
        null}
      {
        showTimezoneModal && (
          <TimezoneModal
            convertTimezone={convertTimezone}
            convertToTimezone={convertToTimezone}
            convertFromTimezone={convertFromTimezone}
            showFilterSets={showFilterSets}
            handleConvertTimezone={this.handleConvertTimezone}
            handleFormElementChanged={this.handleFormElementChanged}
            handleSaveTimezoneSettings={this.handleSaveTimezoneSettings}
            handleCloseTimezoneModal={this.handleCloseTimezoneModal}
            applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
            handleChangeApplyTimezoneToAllDateFields={this.handleChangeApplyTimezoneToAllDateFields}
          />
        )
      }
      {selectDataExtension && (
        <FilterSetsModal
        handleCloseFilterSetModal={this.handleCloseFilterSetModal}
        selectedDataExtensions={selectedDataExtensions}
        filterSetSelectedSourceValue={filterSetSelectedSourceDE}
        handleSourceOptionOnChange={this.handleSourceOptionOnChange}
        selectedDataExtensionField={selectedDataExtensionFieldId}
        handleSelectedFieldOptionOnChange={this.handleSelectedFieldOptionOnChange}
        handleFilterSetModalSave={this.handleFilterSetModalSave}
        selectedSubscription={selectedSubscription}
        handleSelectedSubscription={this.handleSelectedSubscription}
        loadingDataExtensions={loadingDataExtensions}
        />
      )}
    </>
    );
  }
}

SelectedFilters.propTypes = {
  /**
   * prop passed to the FilterLine component and its used to
   * check where its needed to allow the user to select the options
   * 'In Results' and 'Not In Results'
   */
  showInResultsOption: PropTypes.bool.isRequired,
  /**
   * It determines if the collection alias should be hidden after the name in the filter line
   */
  hideCollectionAlias: PropTypes.bool.isRequired,
  /**
   * It keeps the data extensions after they are retrieved from SFMC
   * if dataViews feature is enabled, it will also contain dataViews as well
   */
  dataExtensions: PropTypes.instanceOf(Array),
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It keeps the all selected data extensions if the subquery modal is opened
   */
  compareSelectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It keeps the selected filters for a Selection
   * selectedFilters are stored as filters in database
   * It will be passed from Selection.js
   */
  selectedFilters: PropTypes.instanceOf(Object),
  /**
   * It helps to save the selected filters for the selection
   * it will be passed from Selection.js
   */
  handleFiltersSave: PropTypes.func.isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It determines if the selected field for a filter is a subquery or not
   */
  isSubQuery: PropTypes.bool,
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func.isRequired,
  /**
   * It helps to throw a warning message if a feature is disabled and used in the Selection
   * It will be passed from Selection.js
   */
  handleFeatureMissing: PropTypes.func,
  /**
   * It helps to manage subscriber relationship while creating a data extension
   * It will be passed from Selection.js
   */
  manageSubscriberRelationship: PropTypes.func,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   * it will be passed from Selection.js
   */
  handlePickListOptions: PropTypes.func.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * Stores the loading state of filterlines with subQueries
   */
  loadingSubQueryFields: PropTypes.instanceOf(Object),
  /**
   * It Removes a given filterLine
   * It is passed from Selection.js
   */
  handleRemoveFilterLine: PropTypes.func,
  /**
   * It keeps the filterline IDs of a subquery`s filters
   */
  subQueryModalFilterLineId: PropTypes.string,
  /**
   * It keeps the states of a Selection`s if appendDataExtension feature is enabled
   */
  unionSelections: PropTypes.instanceOf(Array),
  /**
   * This prop keeps the unionSelectionsIndex of Union Selection
   * This prop will be passed from App.js component if the appendDataExtension feature is enabled
   */
  unionSelectionsIndex: PropTypes.number,
  /**
   * it sets the App component`s state
   * This prop will be passed from App.js component through Selection.js
   */
  handleSetAppState: PropTypes.func,
  /**
   * Indicates the type/location of a filter
   */
  filterType: PropTypes.string,
  /**
   * Updates the subquery property of a filterline
   */
  handleUpdateSubQuery: PropTypes.func,
  /**
   * Indicates whether the parent of the subQuery is a relation
   */
  isSubQueryRelation: PropTypes.bool,
  /**
   * An array containing data extensions used in InResults and relations filters
   */
  subQueryDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It returns predefined relation object for relation filter
   * it is passed from Selection.js
   */
  returnPredefinedRelationById: PropTypes.func,
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * Handles the setting of timezone settings to all date filters
   */
  handleSetTimezoneToAllDateFields: PropTypes.func,
  /**
   * An object containing timezone details
   */
  timezoneSettingsForAllDateFields: PropTypes.instanceOf(Object),
  /**
   * Indicates whether Filters component is the main filters component
   */
  isRegularFilter: PropTypes.bool.isRequired,
  /**
   * An array containing filter sets
   */
  filterSets: PropTypes.instanceOf(Array),
  /**
   * Define whether filter sets should be shown or not
   */
  showFilterSets: PropTypes.bool,
  /**
   * Define whether this component is used from selection or not
   */
  isFromSelection: PropTypes.bool,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func,
  /**
   * Revert behavioral filterSets
   */
  revertBehavioralFilterSet: PropTypes.func,
};

export default SelectedFilters;

import '@DESelectMC/deselect-component-library/dist/cjs/index.css';
import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { NavBar } from '@DESelectMC/deselect-component-library';

import Select from '../../../shared/Select/Select';
import Constants from '../../../../constants/constants';
import Features from '../../../../features';
import Button from '../../../shared/Button/Button';
import Input from '../../../shared/Input/Input';
import Util from '../../../../util';
import UpgradeBadge from '../../../shared/UpgradeBadge/UpgradeBadge';
import Tooltip from '../../../shared/Tooltip/Tooltip';
import { featureAdvertExists } from '../../../shared/Feature/featureUtil';
import GuidanceTip from '../../../shared/GuidanceTip/GuidanceTip';

const hasReadOnlyAccess = Util.userInfo()?.hasReadOnlyAccess;
const orgInfo = Util.readObjectFromCookies('org');
const user = Util.readObjectFromCookies(process.env.REACT_APP_SEGMENT__SESSION_USER_KEY);

/**
 * Get the search criteria constant label from a search criteria
 * @param {string} searchCriteria - Is the search criteria name
 * @returns {string} The search criteria constant label corresponding to searchCriteria
 */
const searchCriteriaLabel = (searchCriteria) => {
  if (searchCriteria === Constants.SELECTION__SEARCH_CRITERIA__NAME) {
    return Constants.SELECTION__SEARCH_CRITERIA__NAME__LABEL;
  }
  if (searchCriteria === Constants.SELECTION__SEARCH_CRITERIA__CREATED_BY_ID) {
    return Constants.SELECTION__SEARCH_CRITERIA__CREATED_BY_ID__LABEL;
  }
  if (searchCriteria === Constants.SELECTION__SEARCH_CRITERIA__UPDATED_BY_ID) {
    return Constants.SELECTION__SEARCH_CRITERIA__UPDATED_BY_ID__LABEL;
  }

  return '';
};

const NavbarHeader = ({
  handleSearch,
  handleNavigator,
  handleChangeSearchCriteria,
  searchCriteria,
  selections,
  showSelectionsStats,
  filterSelectList,
  searchField,
  searchCriteriaValue,
  searchBarLonger,
  handleSetOverviewState,
  overviewSection,
  handleSetAppState,
  refreshSelections,
  handleOpenCalendarView,
}) => {
  // use Reacts useRef to store the debounced function across renders
  const debouncedSearch = React.useRef(
    /**
     * Returns a function, that, as long as it continues to be invoked, will not
     * be triggered. The function will be called after it stops being called for
     * 500 milliseconds.
     * @returns {void}
     */
    debounce(async () => {
      try {
        // fetch selections
        await refreshSelections();

        // stop loading selections in overview table
        handleSetOverviewState({ loadingSelectionsList: false });
      } catch (error) {
        handleSetOverviewState({ error });
      }
    }, 500),
  ).current;

  /**
   * Handles changing value in the search input
   * @param {object} e - JS Event
   * @param {string} criteria - selected criteria
   * @returns {void}
   */
  const handleChange = async (e, criteria) => {
    // start loading selections in overview table
    handleSetOverviewState({ loadingSelectionsList: true });
    const isSelectValue = criteria === Constants.SELECTION__SEARCH_CRITERIA__CREATED_BY_ID ||
      criteria === Constants.SELECTION__SEARCH_CRITERIA__UPDATED_BY_ID;

    handleSearch(e, isSelectValue);
    debouncedSearch();
  };

  React.useEffect(() => {
    return () => {
      // cancel the debounced function
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // waterfall selection feature flag
  const featureWaterfallSelectionIsEnabled = Features.isFeatureEnabled(Constants.FEATURE__WATERFALL_SELECTIONS);

  // schedule selection feature flag
  const featureScheduleSelectionIsEnabled = Features.isFeatureEnabled(Constants.FEATURE__SCHEDULE_SELECTIONS);

  // classNames for Selections button
  const classNameForSelectionsButton = classNames(
    'selections-section-button',
    // eslint-disable-next-line quote-props
    { 'selected': overviewSection === Constants.OVERVIEW__SECTION__SELECTIONS },
  );

  // classNames for Waterfall Selections button
  const classNameForWaterfallButton = classNames(
    'waterfall-section-button',
    {
      selected: overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS,
      'unavailable-feature': !featureWaterfallSelectionIsEnabled,
    },
  );

  // classNames for Waterfall Selections button
  const classNameForScheduledSelectionsButton = classNames(
    'scheduled-section-button',
    {
      'unavailable-feature': !featureScheduleSelectionIsEnabled,
    },
  );

  // classNames for new selection button label
  const newSelectionButtonLabel = overviewSection === Constants.OVERVIEW__SECTION__SELECTIONS ?
    'New Selection' :
    'New Waterfall Selection';

  /**
   * Opens New Selection depending on the selected section
   * @returns {void}
   */
  const handleOpenNewSelection = async () => {
    if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS) {
      handleNavigator(Constants.NAVIGATION__WATERFALL_SELECTION);
    } else {
      // Check for limits for essentials edition
      if (orgInfo?.edition === Constants.ORGANISATION__EDITION__ESSENTIALS) {
        if (selections?.length >= Constants.ESSENTIALS_EDITION_MAX_SELECTIONS_COUNT) {
          await Util.handleUpgradeWarning(Constants.ESSENTIALS_EDITION_MAX_SELECTIONS_LIMIT_LABEL);

          return;
        }
      }
      handleNavigator(Constants.NAVIGATION__SELECTION);
    }
  };

  /**
   * Handles switching between sections in Overview
   * @param {string} section - selected section
   * @returns {void}
   */
  const handleChangeOverviewSection = (section) => {
    // change the section
    handleSetAppState({ overviewSection: section });

    // reset folderId and search values
    handleSetOverviewState({
      filterFolderId: null,
      searchCriteria: Constants.SELECTION__SEARCH_CRITERIA__NAME,
      searchCriteriaValue: '',
      searchField: '',
    });
  };

  /**
   * Get navigation bar options
   * @returns {void}
   */
  const getNavBarOptions = () => {
    const engageStatus = user?.engageInstallationStatus?.organisationExists;
    const searchStatus = user?.searchInstallationStatus?.organisationExists;
    const stackNumber = orgInfo?.marketingCloudStackNumber;

    let search = Constants.DESELECT_SEARCH_STAGING_APP_NAME;

    let engage = Constants.DESELECT_ENGAGE_STAGING_APP_NAME;

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      search = Constants.DESELECT_SEARCH_PRODUCTION_APP_NAME;
      engage = Constants.DESELECT_ENGAGE_PRODUCTION_APP_NAME;
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'release') {
      search = Constants.DESELECT_SEARCH_RELEASE_APP_NAME;
      engage = Constants.DESELECT_ENGAGE_RELEASE_APP_NAME;
    }

    let engageLink = Constants.DESELECT_ENGAGE_APP_EXCHANGE_URL;

    let searchLink = Constants.DESELECT_SEARCH_APP_EXCHANGE_URL;

    if (engageStatus) {
      engageLink = `https://mc.${stackNumber}.exacttarget.com/cloud/#app/${engage}`;
    }

    if (searchStatus) {
      // eslint-disable-next-line max-len
      searchLink = `https://mc.${stackNumber}.exacttarget.com/cloud/#app/${search}`;
    }

    const options = [
      {
        id: 'Segment',
        name: 'Segment',
        url: '',
      },
      {
        id: 'Engage',
        name: 'Engage',
        url: engageLink,
      },
      {
        id: 'Search',
        name: 'Search',
        url: searchLink,
      },
    ];

    return options;
  };

  const optionsForSearchCriteria = [
    {
      value: Constants.SELECTION__SEARCH_CRITERIA__NAME,
      label: Constants.SELECTION__SEARCH_CRITERIA__NAME__LABEL,
    },
    {
      value: Constants.SELECTION__SEARCH_CRITERIA__CREATED_BY_ID,
      label: Constants.SELECTION__SEARCH_CRITERIA__CREATED_BY_ID__LABEL,
    },
    {
      value: Constants.SELECTION__SEARCH_CRITERIA__UPDATED_BY_ID,
      label: Constants.SELECTION__SEARCH_CRITERIA__UPDATED_BY_ID__LABEL,
    },
  ];

  /**
   * Opens Calendar View or Feature Advert Modal if the feature is not enabled
   * @returns {void}
   */
  const handleOpenScheduleCalendarView = () => {
    if(featureScheduleSelectionIsEnabled) {
      handleOpenCalendarView();
    }else{
      handleSetOverviewState({
        featureAdvertModal: {
          show: true,
          feature: Constants.FEATURE__SCHEDULE_SELECTIONS,
        },
      });
    }
  };

  /**
   * Opens Waterfall Selections or Feature Advert Modal if the feature is not enabled
   * @returns {void}
   */
  const handleOpenWaterfallSelections = () => {
    if(featureWaterfallSelectionIsEnabled) {
      handleChangeOverviewSection(Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS);
    }else{
      handleSetOverviewState({
        featureAdvertModal: {
          show: true,
          feature: Constants.FEATURE__WATERFALL_SELECTIONS,
        },
      });
    }
  };

  return (
    <div className="overview_wrapper" id="overview-header">
      <div className="overview-title-section-wrapper">
        <div className="overview_wrapper_title">
          <svg className="slds-button__icon" aria-hidden="true">
            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#home" />
          </svg>
        </div>
        <div className="navbar-css">
        <NavBar
          options={getNavBarOptions()}
          selected="Segment"
        />
        </div>

        <div className="overview-sections">
          <Button
            onClick={() => handleChangeOverviewSection(Constants.OVERVIEW__SECTION__SELECTIONS)}
            id="selections-section"
            className={classNameForSelectionsButton}
            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
          >
            Selections
          </Button>

          <Button
            onClick={handleOpenWaterfallSelections}
            id="waterfallSelections-section"
            className={classNameForWaterfallButton}
            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
          >
            <span className="overview-item-content">Waterfall Selections</span>
            {
              !featureWaterfallSelectionIsEnabled &&
              (featureAdvertExists(Constants.FEATURE__WATERFALL_SELECTIONS) ?
              <UpgradeBadge /> :
              <Tooltip
                nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
              />)
            }
          </Button>
        </div>
      </div>

      {showSelectionsStats ?
        (<div className="selection-count-limits">
        <h5>
          Active Selections:
          {' '}
          {selections?.length}
          /
          {Constants.ESSENTIALS_EDITION_MAX_SELECTIONS_COUNT}
        </h5>
         </div>) :
        null}
      <div className="overview_wrapper_middleContainer">
        {/* Dropdown for criteria */}
        <div
          className={`slds-form-element dropdown-for-criteria ${searchBarLonger ? 'longerinput' : ''}`}
          onFocus={() => handleSetOverviewState({ searchBarLonger: true })}
          onBlur={() => handleSetOverviewState({ searchBarLonger: false })}
        >
          <div className="slds-form-element__control">
            <Select
              id="dropdown-search"
              onChange={e => handleChangeSearchCriteria(e.target.value)}
              value={searchCriteria}
              options={optionsForSearchCriteria}
            />
          </div>
        </div>

        {searchCriteria === Constants.SELECTION__SEARCH_CRITERIA__UPDATED_BY_ID ?
          (
            <div className="slds-form-element">
              <div className="slds-form-element__control">
                <Select
                  id="updatedBy-search"
                  onChange={e => handleChange(e, searchCriteria)}
                  value={searchCriteriaValue}
                  options={[{ value: '', label: 'All' }]}
                  selectList={filterSelectList(selections, searchCriteria)}
                />
              </div>
            </div>
          ) :
          null}

        {searchCriteria === Constants.SELECTION__SEARCH_CRITERIA__CREATED_BY_ID ?
          (
            <div className="slds-form-element">
              <div className="slds-form-element__control">
                <Select
                  id="createBy-search"
                  onChange={e => handleChange(e, searchCriteria)}
                  value={searchCriteriaValue}
                  options={[{ value: '', label: 'All' }]}
                  selectList={filterSelectList(selections, searchCriteria)}
                />
              </div>
            </div>
          ) :
          null}

        {searchCriteria === Constants.SELECTION__SEARCH_CRITERIA__NAME ?
          (
            <div className="slds-form-element">
              <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_left">
                <svg
                  className="slds-icon slds-input__icon slds-input__icon_left slds-icon-text-default"
                  aria-hidden="true"
                >
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#search" />
                </svg>
                <Input
                  id="selection-search"
                  placeholder={`Search by ${searchCriteriaLabel(searchCriteria)}`}
                  className="name-search"
                  onChange={e => handleChange(e, searchCriteria)}
                  value={searchField}
                />
                <div className="guidance-parent-search-selection">
                    <GuidanceTip tipId="selection-search-tip" />
                </div>
              </div>
            </div>
          ) :
          null}

      </div>
      <div className="overview_wrapper_button">
        <Button
            onClick={handleOpenScheduleCalendarView}
            id="calendar-view-for-schedules"
            className={classNameForScheduledSelectionsButton}
            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
          >
            <span
              className="slds-icon_container slds-icon-standard-account overview-item-content"
              title="View selection schedules">
                  <svg
                    className="slds-icon schedule"
                    aria-hidden="true"
                    id="schedule-icon">
                    <use xlinkHref="assets/icons/utility-sprite/svg/symbols.svg#event" />
                  </svg>
                  <span className="slds-assistive-text">Schedule Calendar</span>
            </span>
            {
              !featureScheduleSelectionIsEnabled &&
              (featureAdvertExists(Constants.FEATURE__SCHEDULE_SELECTIONS) ?
              <UpgradeBadge /> :
              <Tooltip
                nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
              />)
            }
        </Button>
        <Button
          onClick={handleOpenNewSelection}
          id={overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS ?
            'new-waterfall-selection' :
            'new-selection'}
          buttonLook={Constants.BUTTON__TYPE__BRAND}
          disabled={hasReadOnlyAccess}
        >
          {newSelectionButtonLabel}
        </Button>
        <div className="guidance-parent-new-selection">
          <GuidanceTip tipId="new-selection-tip" toolTipPosition="right" />
        </div>
      </div>
    </div>
  );
};

NavbarHeader.propTypes = {
  /**
   * It helps to search for selections on the Overview page
   */
  handleSearch: PropTypes.func.isRequired,
  /**
   * handleNavigator prop is passed from App.js and it helps to navigate between Overview and Selection
   */
  handleNavigator: PropTypes.func.isRequired,
  /**
   * handleChangeCriteria is passed from Overview.js and it helps to change the search criteria for selections
   * on Overview page
   */
  handleChangeSearchCriteria: PropTypes.func.isRequired,
  /**
   * search criteria prop keeps the value of the property to search in the selections on Overview Page
   */
  searchCriteria: PropTypes.string.isRequired,
  /**
   * List of all selections
   */
  selections: PropTypes.instanceOf(Array),
  /**
   * Function to create a list of possible options for select, out of selection list
   */
  filterSelectList: PropTypes.func.isRequired,
  /**
   * Input of the search
   */
  searchField: PropTypes.string.isRequired,
  /**
   * Chosen option from possible options in select
   */
  searchCriteriaValue: PropTypes.string.isRequired,
  /**
   * Boolean making search input longer/shorter
   */
  searchBarLonger: PropTypes.bool.isRequired,
  /**
   * Function setting state of overview.js
   */
  handleSetOverviewState: PropTypes.func.isRequired,
  /**
   * Defines which section in Overview is selected - selections or waterfall
   */
  overviewSection: PropTypes.string.isRequired,
  /**
   * handleSetAppState prop is passed from App.js
   * and it helps to set state of app
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * Refresh and set the selections state on the front page
   */
  refreshSelections: PropTypes.func.isRequired,
  /**
   * Handles the opening of calendar view on the overview page
   */
  handleOpenCalendarView: PropTypes.func,
  /**
   * Boolean than helps determine if the selection stats should be shown or not
   */
  showSelectionsStats: PropTypes.bool,
};

export default NavbarHeader;

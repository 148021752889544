import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert2';
import { toast } from 'react-toastify';

import Features from '../../../../features';
import DataAction from '../DataAction/DataAction';
import SortLimit from '../SortLimit/SortLimit';
import Constants from '../../../../constants/constants';
import './styles.scss';
import Util from '../../../../util';
import Button from '../../../shared/Button/Button';
import Spinner from '../../../shared/Spinner/Spinner';
import Alert from '../../../shared/Alert/Alert';
import filtersUtil from '../../../../utils/filters/filtersUtil';
import timeUtil from '../../../../utils/time/timeUtil';
import SwalUtil from '../../../../utils/swal/swalUtil';

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
const SelectedTargetFields = ({
  targetDataExtensionFields,
  targetDataExtensionCustomerKey,
  matchedFields,
  relations,
  selectedDataExtensions,
  handleSetSelectionState,
  newTargetDataExtensionFields,
  editNewAutoTargetDE,
  newTargetDataExtension,
  subscriberFieldNames,
  handleAddFieldToTargetDE,
  dropToTargetDataExtensionField,
  editTargetDataExtension,
  prevTargetDEFields,
  dataAction,
  showDataActionModal,
  filterBorderMouseOver,
  targetDataExtensions,
  sortLimit,
  showSortLimitModal,
  prioDeduplication,
  usePrioDeduplication,
  targetDataExtension,
  handleChangeSendableAndSubscriberField = () => {},
}) => {
  const [targetDEFieldsBeforeEdits, setTargetDEFieldsBeforeEdits] = useState([]);
  const featureAutoCreateTargetDEIsEnabled = Features.isFeatureEnabled(
    Constants.FEATURE__AUTO_CREATE_TARGET_DATA_EXTENSION,
  );

  // feature for encrypted fields
  const featureEncryptedFieldsIsEnabled = Features.isFeatureEnabled(
    Constants.FEATURE__ENCRYPTED_FIELDS,
  );

  /**
   * Function which helps to open data action modal
   * @returns {void}
   */
  const handleOpenDataActionModal = () => {
    handleSetSelectionState({ showDataActionModal: true });
  };

  /**
   * Function for deleting option for dropdown
   * @param {object[]} newDeFields - New DE fields
   * @returns {void}
   */
  const deleteSubscriberOption = (newDeFields) => {
    let text = false;

    let number = false;

    if (newDeFields.length) {
      // Go through fields and check if there is any field with text, number of email field type
      newDeFields.forEach((field) => {
        if (
          field.FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
          field.FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS
        ) {
          text = true;
        }
        if (field.FieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER) {
          number = true;
        }
      });
      /*
       * if there is not field with field type text then delete Subscriber Key as a option from dropdown
       * if there is not field with field type number then delete Subscriber Id as a option from dropdown
       * if there is not field with field type email then delete Email Address as a option from dropdown
       */
      if (!text) {
        const index = subscriberFieldNames.indexOf(
          Constants.SUBSCRIBER_FIELD__SUBSCRIBER_KEY,
        );

        if (index > -1) {
          subscriberFieldNames.splice(index, 1);
        }
      }
      if (!number) {
        const index = subscriberFieldNames.indexOf(
          Constants.SUBSCRIBER_FIELD__SUBSCRIBER_ID,
        );

        if (index > -1) {
          subscriberFieldNames.splice(index, 1);
        }
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      subscriberFieldNames = [];
    }
    handleSetSelectionState({ subscriberFieldNames });
  };

  /**
   * Determines if the field is Sendable or not
   *  @param {string} fieldName - target DE field name
   *  @param {boolean} editMode - defines whether we are in editing TargetDE mode
   *  @returns {boolean} If field is sendable return true
   */
  const isSendableField = (fieldName, editMode) => {
    /*
     * Find field in target DE which is sendable, if we are in edit mode - check newTargetDataExtension,
     * otherwise, get data from all target data extensions
     */
    const sendableTargetDEFieldName = editMode ?
      (newTargetDataExtension?.relationship?.sendableDataExtensionField?.name?.toString()) :
      targetDataExtensions.find(
        de => de.CustomerKey === targetDataExtensionCustomerKey && de.IsSendable,
      )?.SendableDataExtensionField?.Name?.toString();

    if (sendableTargetDEFieldName === fieldName?.toString()) {
      // If targetDE field name matches the sendable field name, return true
      return true;
    }

    return false;
  };

  /**
   * when user clicks the x icon to remove a mapped field
   * @param {string} fieldObjectID - id of removed DE
   * @param {number} index - index of removed DE
   * @param {boolean} isTargetDEField - Are we deleting a target field ?
   * @returns {void}
   */
  const removeTargetDataExtensionField = async (fieldObjectID, index, isTargetDEField) => {
    let message;

    let title;

    const confirmationMessage = 'Are you sure you want to delete this field?';

    // target data extension fields depending on the mode
    const targetDEFields = editTargetDataExtension ? targetDataExtensionFields : newTargetDataExtensionFields;

    // get the name of the removed field
    const getRemovedFieldName = targetDEFields.find(field => field.ObjectID === fieldObjectID)?.Name;

    // get information whether this field is used in subscriber relationship
    const isRemovedFieldSendable = isSendableField(getRemovedFieldName, true);

    // when the sendable field is removed
    if(isRemovedFieldSendable && newTargetDataExtension?.relationship?.isSendable &&
        isTargetDEField && editTargetDataExtension) {
      // eslint-disable-next-line max-len
      message = 'This field is used in the subscriber relationship, therefore cannot be deleted. Select another Sendable Field before deleting this one.';

      title = 'Delete Sendable Field';

      await SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title,
        message,
      });

      return;
    }
    message = isTargetDEField ?
      confirmationMessage + ' All its data will be lost.' :
      'This target field will be removed from matched Target Data Extension Fields.';

    title = `Delete Field${isTargetDEField ? '' : ' Mappings'}`;

    const result = await SwalUtil.fire({
      title,
      message,
      options: {
        showCancelButton: true,
      },
    });

    if (result.value) {
      let removedFields = [];
      const removeNewTargetDEField = [];

      /**
       * If there is index value then it means we're trying to remove a newTargetDeField
       * so find it with given index and push the the other ones in to array
       */

      if (index > -1) {
        // If we are in Edit Target DE fields mode remove field from targetDataExtensionFields
        if (targetDEFields?.length) {
          targetDEFields.forEach((field, fieldIndex) => {
            if (index !== fieldIndex) {
              removeNewTargetDEField.push(field);
            }
          });
        }
      }

      // remove the field from the matchedFields
      removedFields = matchedFields.filter(
        field => field.targetDataExtensionFieldObjectID !== fieldObjectID,
      );

      if (isRemovedFieldSendable && !newTargetDataExtension?.relationship?.isSendable) {
        if (removeNewTargetDEField && removeNewTargetDEField.length > 0) {
          targetDEFields.forEach((field) => {
            if (field.ObjectID === fieldObjectID &&
              newTargetDataExtension.relationship.sendableDataExtensionField.name &&
              field.Name.toString() === newTargetDataExtension.relationship.sendableDataExtensionField.name.toString()
            ) {
              for (let i = 0; i < removeNewTargetDEField.length; i += 1) {
                if (removeNewTargetDEField[i].FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
                  removeNewTargetDEField[i].FieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ||
                  removeNewTargetDEField[i].FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS) {
                  handleChangeSendableAndSubscriberField(removeNewTargetDEField[i]);
                }
              }
            }
          });
        }
      }

      // Delete option for dropdown
      deleteSubscriberOption(removeNewTargetDEField);

      if (editTargetDataExtension) {
        handleSetSelectionState({ targetDataExtensionFields: removeNewTargetDEField });
      } else if (editNewAutoTargetDE) {
        handleSetSelectionState({ newTargetDataExtensionFields: removeNewTargetDEField });
      }

      handleSetSelectionState({
        matchedFields: removedFields,
      });
    }
  };
  /**
   * This function shows us about field`s information when user clicked on the pencil icon
   * @param {string} objectID - id of a clicked field
   * @returns {void}
   */
  const showInfoAboutField = async (objectID) => {
    let field = [];

    let primaryKeyProperty = false;

    let disableEncryptedFieldProperty = false;

    let isRequiredProperty = true;

    let maxFieldLength;

    /**
     * If we are not in ACDE mode (then we are in edit target de mode)
     * Then get field from target data extension
     */
    if (editTargetDataExtension) {
      field = targetDataExtensionFields.filter(
        f => f.ObjectID === objectID,
      );
      // Get field from target de fields before we entered edit target de mode
      const prevField = prevTargetDEFields.filter(
        f => f.ObjectID === objectID,
      );

      if (prevField && prevField.length > 0) {
        // Check if field has primary key
        if (prevField[0].IsPrimaryKey) {
          primaryKeyProperty = true;
        }

        // For existing fields disable the ability to change StorageType
        disableEncryptedFieldProperty = true;

        // Check if field is not required
        if (!prevField[0].IsRequired) {
          isRequiredProperty = false;
        }

        // Get field max length
        maxFieldLength = prevField[0].MaxLength;
      } else {
        // If we are in edit mode for new created field, allow to change StorageType to Encrypted
        disableEncryptedFieldProperty = false;
      }
    } else {
      field = newTargetDataExtensionFields.filter(
        f => f.ObjectID === objectID,
      );
    }

    let fieldIsSendable = false;
    /**
     * Check if field which we want to edit is Sendable
     * If so set fieldIsSendable to true so later in the code we can update sendable field name
     */

    if (newTargetDataExtension.relationship.sendableDataExtensionField &&
      newTargetDataExtension.relationship.sendableDataExtensionField.name &&
      newTargetDataExtension.relationship.sendableDataExtensionField.name === field[0].Name.toString()) {
      fieldIsSendable = true;
    }
    /**
     * Added new field property so when you first time open
     * field info it will be undefined and then initialize it to
     * the !field[0].IsRequired
     */
    if (typeof field[0].IsNullable === 'undefined') {
      field[0].IsNullable = !field[0].IsRequired;
    }

    /**
     * This function converts boolean value to Yes or No
     * @param {boolean} value - True/false
     * @returns {string|null} 'Yes' or 'No'
     */
    const convertBooleanToYesNoString = (value) => {
      switch (value) {
        case true:
          return 'Yes';
        case false:
          return 'No';
        default:
          return null;
      }
    };
    /**
     * This function helps to parse values into html elements
     * @param {string} name - Name
     * @param {string} value - Input value
     * @param {string} abbreviatedValue - Abbreviated input value
     * @param {string} disable - Disable
     * @returns {string} HTML for the fields
     */
    const parseFieldInfoIntoHtmlElements = (name, value, abbreviatedValue, disable) => {
      /**
       * Get the scale of the value
       * @returns {string} The string correspond to the scale
       */
      const getScale = () => {
        if (value?.scale) return `${value.length}.${value.scale}`;
        if (typeof value === 'boolean') return convertBooleanToYesNoString(value);
        if (value === Constants.FIELD_STORAGE_TYPE__ENCRYPTED) { return 'Yes'; }
        if (value === Constants.FIELD_STORAGE_TYPE__PLAIN) { return 'No'; }

        return value;
      };

      /**
       * Get the HTML for the field
       * @returns {string} HTML for the field
       */
      const getFieldHTML = () => {
        /**
         * Returns HTML for the input component
         * @param {boolean} disable - indicates if input should be disabled
         * @param {string} id - input id
         * @param {string|number} value - input value
         * @param {string} className - additional class name for the input
         * @param {string} type - input type
         * @param {string} max - max property for the input
         * @returns {string} HTML for input component
         */
        const htmlInput = (disable, id, value, className, type, max) => (
          `<div class="slds-form-element">
          <label class="slds-form-element__label" for="text-input-id-1">
          <div class="slds-form-element__control">
            <input
              ${disable ? 'disabled' : ''}
              type="${type}"
              id="${id}"
              min="1"
              max="${max || '2550'}"
              required=""
              class="slds-input ${className}"
              value="${value}"
            />
          </div>
        </div>`
        );

        /**
         * Returns HTML for the checkbox component
         * @param {boolean} disable - indicates if input should be disabled
         * @param {string} id - checkbox id
         * @param {boolean} isChecked - indicates if input should be checked
         * @param {string} label - label for the checkbox
         * @returns {string} HTML for checkbox component
         */
        const htmlCheckbox = (disable, id, isChecked, label) => (
          `<div class="slds-form-element">
          <div class="slds-form-element__control">
            <div class="slds-checkbox">
              <input
                ${disable ? 'disabled' : ''}
                type="checkbox"
                name="options"
                id="${id}"
                ${isChecked ? 'checked' : ''}
              />
              <label class="slds-checkbox__label" for="${label}">
                <span class="slds-checkbox_faux"></span>
              </label>
            </div>
          </div>
        </div>`
        );

        switch (name) {
          case Constants.FIELD_INFO_LABEL__NAME:
            return htmlInput(
              null,
              Constants.FIELD_INFO_INPUT_ID__FIELD_NAME,
              value,
              null,
              'text',
            );
          case Constants.FIELD_INFO_LABEL__LENGTH:
            return htmlInput(
              disable,
              Constants.FIELD_INFO_INPUT_ID__MAX_LENGTH,
              value,
              'slds-input_counter',
              'number',
            );
          case Constants.FIELD_INFO_LABEL__PRIMARY_KEY:
            return htmlCheckbox(
              disable,
              Constants.FIELD_INFO_INPUT_ID__PRIMARY_KEY,
              value,
              Constants.FIELD_INFO_INPUT_ID__PRIMARY_KEY,
            );
          case Constants.FIELD_INFO_LABEL__ENCRYPTED_FIELD:
            return htmlCheckbox(
              disable,
              Constants.FIELD_INFO_INPUT_ID__ENCRYPTED,
              value === Constants.FIELD_STORAGE_TYPE__ENCRYPTED,
              Constants.FIELD_INFO_INPUT_ID__ENCRYPTED,
            );
          case Constants.FIELD_INFO_LABEL__NULLABLE:
            return htmlCheckbox(
              disable,
              Constants.FIELD_INFO_INPUT_ID__NULLABLE,
              value,
              Constants.FIELD_INFO_INPUT_ID__NULLABLE,
            );
          case Constants.FIELD_INFO_LABEL__DEFAULT_DATE_VALUE:
            return (
              `<input
                id="${Constants.FIELD_INFO_INPUT_ID__DEFAULT_DATE_VALUE}"
                type="date"
                class="input-date-looks-like-LDS"
                max=${new Date().toLocaleDateString()}
                value=${value}
              />`
            );
          case Constants.FIELD_INFO_LABEL__LENGTH_SCALE:
            return (
              htmlInput(
                disable,
                Constants.FIELD_INFO_INPUT_ID__MAX_LENGTH,
                value.length,
                'slds-input_counter',
                'number',
                '4000',
              ) +
              htmlInput(
                disable,
                Constants.FIELD_INFO_INPUT_ID__SCALE,
                value.scale,
                'slds-input_counter',
                'number',
                '4000',
              )
            );
          case Constants.FIELD_INFO_LABEL__DEFAULT_NUMBER_VALUE:
            return htmlInput(
              null,
              Constants.FIELD_INFO_INPUT_ID__DEFAULT_NUMBER_VALUE,
              value,
              'slds-input_counter',
              'number',
              '4000',
            );
          case Constants.FIELD_INFO_LABEL__DEFAULT_TEXT_VALUE:
            return htmlInput(
              null,
              Constants.FIELD_INFO_INPUT_ID__DEFAULT_TEXT_VALUE,
              value,
              null,
              'text',
            );
          case Constants.FIELD_INFO_LABEL__DEFAULT_BOOLEAN_VALUE:
            return (
              `<div class="slds-form-element">
                <div class="slds-form-element__control">
                  <div class="slds-select_container">
                  <select class="slds-select" id="${Constants.FIELD_INFO_INPUT_ID__DEFAULT_BOOLEAN_VALUE}" >
                    <option value="" ${value === '' ? 'selected' : null}>none</option>
                    <option value="true"  ${value === 'true' ? 'selected' : null}>true</option>
                    <option value="false" ${value === 'false' ? 'selected' : null}>false</option>
                  </select>
                  </div>
                </div>
              </div>`
            );
          default:
            return `${abbreviatedValue || value}`;
        }
      };

      return (
        `<div class="field-info-row">
          <div class="field-info-row-left" title="${name}">
            ${name === Constants.FIELD_INFO_LABEL__DEFAULT_DATE_VALUE ||
              name === Constants.FIELD_INFO_LABEL__DEFAULT_NUMBER_VALUE ||
              name === Constants.FIELD_INFO_LABEL__DEFAULT_TEXT_VALUE ||
              name === Constants.FIELD_INFO_LABEL__DEFAULT_BOOLEAN_VALUE ?
          'Default Value:' :
          `${name}:`}
          </div>
          <div
            class="field-info-row-right"
            title="${getScale()}"
          >` +
            getFieldHTML() +
                      `
          </div>
        </div>`
      );
    };

    // This variable is in html format so we can parse inputs into swal
    let message = parseFieldInfoIntoHtmlElements(
      Constants.FIELD_INFO_LABEL__NAME,
      field[0].Name.toString(),
      Util.abbreviate(field[0].Name.toString(), '16'),
    );

    message += parseFieldInfoIntoHtmlElements(Constants.FIELD_INFO_LABEL__TYPE, field[0].FieldType);

    const isExistingField = !!targetDEFieldsBeforeEdits?.find(de => de.ObjectID === field[0].ObjectID);

    const isFieldLengthEditable = () => {
      if (field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT) {
        return true;
      } if (field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL && !isExistingField) {
        return true;
      }

      return false;
    };

    if (field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL) {
      message += parseFieldInfoIntoHtmlElements(
        Constants.FIELD_INFO_LABEL__LENGTH_SCALE,
        { length: field[0].MaxLength, scale: field[0].Scale || '' },
        null,
        // If we are not in ACDE mode disable input field, enable it if it's a new field
        !isFieldLengthEditable(),
      );
    } else if (field[0].MaxLength || (field[0].FieldType !== Constants.FILTERLINE__FIELDTYPE__NUMBER &&
      field[0].FieldType !== Constants.FILTERLINE__FIELDTYPE__DATE &&
      field[0].FieldType !== Constants.FILTERLINE__FIELDTYPE__BOOLEAN)) {
      message += parseFieldInfoIntoHtmlElements(
        Constants.FIELD_INFO_LABEL__LENGTH,
        field[0].MaxLength || '',
        null,
        // If we are not in ACDE mode and field type is something else then Text disable input field
        !isFieldLengthEditable(),
      );
    }

    message += parseFieldInfoIntoHtmlElements(
      Constants.FIELD_INFO_LABEL__PRIMARY_KEY,
      typeof field[0].IsPrimaryKey === 'undefined' ? false : field[0].IsPrimaryKey,
      null,
      // If field already has defined primary key and nullable property disable checkboxes for those properties
      !!(!editNewAutoTargetDE && (!isRequiredProperty || primaryKeyProperty)),
    );

    // show only if encrypted fields feature is enabled
    if (featureEncryptedFieldsIsEnabled) {
      message += parseFieldInfoIntoHtmlElements(
        Constants.FIELD_INFO_LABEL__ENCRYPTED_FIELD,
        field[0].StorageType,
        null,
        // If we are not in ACDE mode and encryptedFieldProperty is disabled
        !!(!editNewAutoTargetDE && isExistingField && disableEncryptedFieldProperty),
      );
    }

    message += parseFieldInfoIntoHtmlElements(
      Constants.FIELD_INFO_LABEL__NULLABLE,
      field[0].IsNullable,
      null,
      // If field already has defined primary key and nullable property disable checkboxes for those properties
      !!(!editNewAutoTargetDE && (!isRequiredProperty || primaryKeyProperty)),
    );

    // If selectedField is date
    if (field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__DATE) {
      let dateValue;
      // Check if the field has Default Value assigned before

      if (field[0].DefaultValue) {
        // Format the date for html`s date input (yyyy-mm-dd)
        dateValue = timeUtil.formatDateForHtmlDateInput(field[0].DefaultValue);
      }
      message += parseFieldInfoIntoHtmlElements(
        Constants.FIELD_INFO_LABEL__DEFAULT_DATE_VALUE,
        dateValue || '',
      );
      // If selectedField is number or decimal (we can use input type number for both number and decimal field type)
    } else if (filtersUtil.isNumberOrDecimal(field[0].FieldType)) {
      message += parseFieldInfoIntoHtmlElements(
        Constants.FIELD_INFO_LABEL__DEFAULT_NUMBER_VALUE,
        field[0].DefaultValue || '',
      );
      // If selectedField is text
    } else if (field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT) {
      message += parseFieldInfoIntoHtmlElements(
        Constants.FIELD_INFO_LABEL__DEFAULT_TEXT_VALUE,
        field[0].DefaultValue || '',
      );
      // If selectedField is boolean
    } else if (
      field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__BOOLEAN
    ) {
      message += parseFieldInfoIntoHtmlElements(
        Constants.FIELD_INFO_LABEL__DEFAULT_BOOLEAN_VALUE,
        field[0].DefaultValue || '',
      );
    }

    /**
     * Returns the value of a tag with a `Constants.FIELD_INFO_INPUT_ID__DEFAULT_X_VALUE` or '' if it doesn't exist.
     * @returns {string} The default value
     */
    const getDefaultValue = () => {
      const idToCheck = [
        Constants.FIELD_INFO_INPUT_ID__DEFAULT_DATE_VALUE,
        Constants.FIELD_INFO_INPUT_ID__DEFAULT_NUMBER_VALUE,
        Constants.FIELD_INFO_INPUT_ID__DEFAULT_TEXT_VALUE,
        Constants.FIELD_INFO_INPUT_ID__DEFAULT_BOOLEAN_VALUE,
      ];

      const element = idToCheck.find(id => document.getElementById(id));

      return element ? document.getElementById(element).value : '';
    };

    // In this section we're getting the values from swal inputs
    let formValues = await SwalUtil.fire({
      title: 'Field Information',
      message,
      options: {
        preConfirm: () => ({
          maxLength: document.getElementById(Constants.FIELD_INFO_INPUT_ID__MAX_LENGTH) ?
            document.getElementById(Constants.FIELD_INFO_INPUT_ID__MAX_LENGTH).value :
            '',
          fieldName: document.getElementById(Constants.FIELD_INFO_INPUT_ID__FIELD_NAME).value,
          isPrimaryKey: document.getElementById(Constants.FIELD_INFO_INPUT_ID__PRIMARY_KEY).checked,
          StorageType: document.getElementById(Constants.FIELD_INFO_INPUT_ID__ENCRYPTED)?.checked ?
            Constants.FIELD_STORAGE_TYPE__ENCRYPTED :
            Constants.FIELD_STORAGE_TYPE__PLAIN,
          isNullable: document.getElementById(Constants.FIELD_INFO_INPUT_ID__NULLABLE).checked,
          scale: document.getElementById(Constants.FIELD_INFO_INPUT_ID__SCALE) ?
            document.getElementById(Constants.FIELD_INFO_INPUT_ID__SCALE).value :
            0,
          defaultValue: getDefaultValue(),
        }),
        showCancelButton: true,
      },
    });
    /**
     * It is assigned again like this, to get the values from preConfirm function
     * Assignment has been updated like the statement below
     */

    formValues = formValues.value;

    // For showing the warnings about values that user entered
    const fireSwal = async text => SwalUtil.fire({
      type: Constants.SWAL__TYPE__ERROR,
      title: 'Error',
      message: text,
    });

    // The conditions to check if length and scale are in the correct formats
    if (formValues) {
      /**
       * If are not in ACDE mode (then we are in edit target de mode) check if we decreased field length
       * If so, throw warning message
       */
      if (maxFieldLength > formValues.maxLength && !editNewAutoTargetDE) {
        await fireSwal(
          `The length of an existing Text field cannot be decreased. It will be set to ${field[0].MaxLength}.`,
        );
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      if (Number.parseInt(formValues.maxLength) < 0) {
        await fireSwal(`You can't assign a value less than 0 to length.
        ${field[0].MaxLength ? `It will be set to ${field[0].MaxLength}.` : ''}`);
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      if (
        formValues.maxLength.includes('.') ||
        formValues.maxLength.includes(',')
      ) {
        await fireSwal(`You can't assign decimal values to length.
        ${field[0].MaxLength ? `It will be set to ${field[0].MaxLength}.` : ''}`);
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      if (
        field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER &&
        (formValues.defaultValue.includes(',') ||
        formValues.defaultValue.includes('.'))
      ) {
        await fireSwal('You can\'t assign a decimal value to Default Value. This field is a Number.');
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      if (
        field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT &&
        !formValues.maxLength
      ) {
        await fireSwal('Length property is required for Field of type Text.');
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      /*
       * do not allow the length of the email field to be larger
       * than 254 (according to SFMC, length of the email field type cannot be longer than 254)
       */
      if (
        field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS &&
        Number.parseInt(formValues.maxLength) > 254
      ) {
        await fireSwal(`You can't assign a value greater than 254 to length. It will be set to ${field[0].MaxLength}.`);
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      // check if the entered length is greater than 4000. If so, throw a swal message
      if (Number.parseInt(formValues.maxLength) > 4000) {
        await fireSwal(`You can't assign a value greater than 4000 to length.
        ${field[0].MaxLength ? `It will be set to ${field[0].MaxLength}.` : ''}`);
        showInfoAboutField(field[0].ObjectID);

        return;
      }

      if (field[0].Scale > 0) {
        if (field[0].Scale && !formValues.scale) {
          await fireSwal(`You can't assign empty value to scale. It will be set to ${field[0].Scale}.`);
          showInfoAboutField(field[0].ObjectID);

          return;
        }
        if (Number.parseInt(formValues.scale) < 1) {
          await fireSwal(`You can't assign a value less than 1 to scale. It will be set to ${field[0].Scale}.`);
          showInfoAboutField(field[0].ObjectID);

          return;
        }
        if (Number.isNaN(Number.parseInt(formValues.scale))) {
          await fireSwal(`You can't assign empty value to length. It will be set to ${field[0].Scale}.`);
          showInfoAboutField(field[0].ObjectID);

          return;
        }
        if (Number.parseInt(formValues.scale) > Number.parseInt(field[0].MaxLength)) {
          await fireSwal('You can\'t assign greater value than length to scale.');
          showInfoAboutField(field[0].ObjectID);

          return;
        }
        if (formValues.scale.includes('.') || formValues.scale.includes(',')) {
          await fireSwal(`You cant' assign decimal values to scale. It will be set to ${field[0].Scale}.`);
          showInfoAboutField(field[0].ObjectID);

          return;
        }
        // check if the entered scale is greater than 4000. If so, throw a swal message
        if (Number.parseInt(formValues.scale) > 4000) {
          await fireSwal(`You can't assign a value greater than 4000 to scale.
          ${field[0].Scale ? `It will be set to ${field[0].Scale}.` : ''}`);
          showInfoAboutField(field[0].ObjectID);

          return;
        }

        // eslint-disable-next-line require-atomic-updates
        field[0].Scale = Number.parseInt(formValues.scale);
      }

      // Check if formValues.fieldName exists
      if (formValues.fieldName !== undefined) {
        // Get field name without spaces on the beginning
        formValues.fieldName = formValues.fieldName.trimLeft();
      }

      // Check if there is a fieldName value given for the field
      if (formValues.fieldName) {
        let duplicatedField;

        // If we are in ACDE mode
        if (editNewAutoTargetDE) {
          // Check if that field name exists
          duplicatedField = Util.getDuplicateField(
            newTargetDataExtensionFields,
            formValues.fieldName,
            field[0].Name,
          );
        } else {
          // Check if that field name exists in target data extension fields
          duplicatedField = Util.getDuplicateField(
            targetDataExtensionFields,
            formValues.fieldName,
            field[0].Name,
          );
        }

        // If it exists then fire a swal
        if (duplicatedField?.length) {
          await fireSwal(`A field has been found with this name.
          It will be set to ${field[0].Name.toString()}.`);
          showInfoAboutField(field[0].ObjectID);

          return;
        }
        // If given name starts with an underscore, warn user about invalid field name.
        if (Util.startsWithUnderScore(formValues.fieldName)) {
          await fireSwal(Constants.WARNING__FIELD_INVALID__STARTS_WITH_UNDERSCORE);
          showInfoAboutField(field[0].ObjectID);
        }
        // If given name contains illegal characters, warn user about invalid field name.
        if (Util.containsIllegalCharacters(formValues.fieldName)) {
          const invalidCharacters = Util.containsIllegalCharacters(formValues.fieldName);

          await fireSwal(
            // eslint-disable-next-line max-len
            `Target Data Extension Field name <b>${formValues.fieldName}</b> cannot contain <b>${invalidCharacters}</b>.It will be set to ${field[0].Name.toString()}.`,
          );
          showInfoAboutField(field[0].ObjectID);

          return;
        }
        // If given name contains only numbers, warn user about invalid field name.
        if (Util.containsOnlyNumbers(formValues.fieldName)) {
          await fireSwal(
            // eslint-disable-next-line max-len
            `Field name <b>${formValues.fieldName}</b> cannot consist entirely of numbers. It will be set to ${field[0].Name.toString()}.`,
          );
          showInfoAboutField(field[0].ObjectID);
        }

        // check if the field with the same name exists from the beginning and has not been removed
        const existingFieldWithTheSameName = prevTargetDEFields.find(prevField => (
          targetDataExtensionFields.find(tdeField => prevField.Name.toString().toLowerCase().trim() ===
          formValues.fieldName.toString().toLowerCase().trim() &&
          prevField.ObjectID === tdeField.ObjectID)
        ));

        // check if the name of the new added field exists in SendableDataExtensionField
        const isAddedFieldNameInSendableDataExtensionField = targetDataExtension?.SendableDataExtensionField
          ?.Name?.toString()?.toLowerCase()?.trim() === formValues.fieldName?.toString()?.toLowerCase()?.trim() &&
          !existingFieldWithTheSameName;

        // If the name of the new added field exists in SendableDataExtensionField, throw swal message
        if (isAddedFieldNameInSendableDataExtensionField && !editNewAutoTargetDE && editTargetDataExtension) {
          await fireSwal(
            // eslint-disable-next-line max-len
            'The field with this name has been removed and added again. This name is used in the subscriber relationship. Please rename the field or click Update Target Data Extension first, then go back to editing and add field with this name again.',
          );
          showInfoAboutField(field[0].ObjectID);

          return;
        }

      /**
       * If there is no fieldName value given, then fire a swal
       * because in any case fields have their own default names!
       */
      } else {
        await fireSwal(`A field name cannot be empty.
        It will be set to ${field[0].Name.toString()}.`);
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      // If a date value is selected for defaultValue then assign it to the field
      if (
        formValues.defaultValue &&
        field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__DATE
      ) {
        // eslint-disable-next-line require-atomic-updates
        field[0].DefaultValue = new Date(
          formValues.defaultValue,
        ).toLocaleDateString(timeUtil.getUserLocale());
        // If a value is selected for defaultValue then assign it to the field
      } else if (
        formValues.defaultValue &&
        field[0].FieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL
      ) {
        /**
         * If field is Decimal and if user input value eg. "23" it will be set to the 23,0000
         * Note: Length of number after decimal separator represents scale defined for that field
         */
        // eslint-disable-next-line require-atomic-updates
        field[0].DefaultValue = Number(formValues.defaultValue).toFixed(
          formValues.scale,
        );
        // If a value is selected for defaultValue then assign it to the field
      } else if (formValues.defaultValue) {
        // eslint-disable-next-line require-atomic-updates
        field[0].DefaultValue = formValues.defaultValue;
      } else {
        // Else assign an empty value
        // eslint-disable-next-line require-atomic-updates
        field[0].DefaultValue = '';
      }
      /**
       * If field`s name is not equal to given fieldName and is not invalid,
       * Then assign to the field and change matchedFields`s targetDataExtensionsFieldName and
       * targetDataExtensionFieldObjectID to keep them matched
       */
      if (field[0].Name.toString() !== formValues.fieldName && !Util.startsWithUnderScore(formValues.fieldName) &&
        !Util.containsOnlyNumbers(formValues.fieldName.toString())) {
        const matchedField = matchedFields.filter(mf => mf.targetDataExtensionFieldObjectID === field[0].ObjectID);

        // eslint-disable-next-line require-atomic-updates
        field[0].Name = formValues.fieldName.toString();

        // If we are not in Edit Target DE mode then don't add field name to the field ObjectID
        if (editNewAutoTargetDE) {
          // eslint-disable-next-line require-atomic-updates
          field[0].ObjectID = field[0].ObjectID + '+' + formValues.fieldName;
        }
        // Check if there is matched field
        if (matchedField && matchedField.length > 0) {
          matchedField[0].targetDataExtensionFieldName = field[0].Name.toString();
          matchedField[0].targetDataExtensionFieldObjectID = field[0].ObjectID;
        }
      }

      // if the length for a field marked as Primary Key is not set, throw a message
      if (formValues.isPrimaryKey && formValues.maxLength === '' &&
      (field[0].FieldType !== Constants.FILTERLINE__FIELDTYPE__NUMBER &&
        field[0].FieldType !== Constants.FILTERLINE__FIELDTYPE__DATE &&
        field[0].FieldType !== Constants.FILTERLINE__FIELDTYPE__BOOLEAN)) {
        await fireSwal(`You can't assign empty value to length of a field marked as Primary Key.
        ${field[0].MaxLength ? `It will be set to ${field[0].MaxLength}.` : ''}`);
        showInfoAboutField(field[0].ObjectID);

        return;
      }
      if (formValues.isPrimaryKey && formValues.maxLength === '0') {
        await fireSwal(`You can't assign a value less than 1 to length of a field marked as Primary Key.
        ${field[0].MaxLength ? `It will be set to ${field[0].MaxLength}.` : ''}`);
        showInfoAboutField(field[0].ObjectID);

        return;
      }

      // eslint-disable-next-line require-atomic-updates
      field[0].MaxLength = formValues.maxLength;

      /* eslint-disable require-atomic-updates */
      field[0].IsPrimaryKey = formValues.isPrimaryKey;

      // If primary key is true set IsNullable to false otherwise set it to the check box value
      field[0].IsNullable = field[0].IsPrimaryKey ?
        false :
        formValues.isNullable;

      // set Storage Type and isRequired property
      field[0].StorageType = formValues.StorageType;
      field[0].IsRequired = !field[0].IsNullable;

      /* eslint-enable require-atomic-updates */
      // If the field which we want to edit is Sendable update sendable field
      if (fieldIsSendable) {
        handleChangeSendableAndSubscriberField(field[0]);
      }

      handleSetSelectionState({
        newTargetDataExtensionFields,
        matchedFields,
      });
    }
  };

  /**
   * Automatically map fields if they have the same unique name and field type
   * @param {string} result - the option selected by the user in the swal
   * @returns {void}
   */
  const mapFields = async (result) => {
    const overWrite = result === Constants.AUTOMAP__OVERWRITE;

    /**
     * Create key for the fieldsMap
     * @param {*} fieldName - Name of the field
     * @param {*} fieldType - Type of the field
     * @return {string} Field name combined with field type with a '-' in-between
     */
    const createKey = (fieldName, fieldType) => `${fieldName}-${fieldType}`;

    // Create map with fields in selectedDataExtensions
    const fieldsMap = new Map();
    const tracker = [];

    selectedDataExtensions.forEach(de => de.fields.forEach((field) => {
      const fieldName = field.Name.toString().toLowerCase();
      const fieldType = field.FieldType;
      const key = createKey(fieldName, fieldType);
      const mapValue = fieldsMap.get(key) || [];

      if (mapValue instanceof Array) {
        mapValue.push(field);
        fieldsMap.set(key, mapValue[0]);
      } else {
        tracker.push(key);
      }
    }));

    tracker.forEach((tr) => {
      fieldsMap.delete(tr);
    });

    // Loop through all targetDataExtension fields to see if there is a match with a field from selectedDataExtensions
    targetDataExtensionFields.forEach((field) => {
      const fieldName = field.Name.toString().toLowerCase();
      const fieldType = field.FieldType;
      const key = createKey(fieldName, fieldType);
      const availableField = fieldsMap.get(key);

      /**
       * if we have some fields mapped then check if current target DE field can be matched
       * with a field from selectedDataExtension and overwrite option is picked
       */
      if (matchedFields && matchedFields.length > 0 && availableField && overWrite) {
        /**
         * if that is the case then remove currently mapped target de field with
         * selectedDataExtension field and update matchedFields
         */
        // eslint-disable-next-line no-param-reassign
        matchedFields = matchedFields.filter(mf => mf.targetDataExtensionFieldObjectID !== field.ObjectID);
        handleSetSelectionState({ matchedFields });
      }

      if (availableField) {
        // if a field is available then create variables used in matchedFields
        const targetDataExtensionFieldCustomerKey = field.CustomerKey;
        const targetDataExtensionFieldObjectID = field.ObjectID;
        const availableFieldFieldType = availableField.FieldType;
        const availableFieldName = availableField.Name.toString();
        const targetDataExtensionFieldName = field.Name.toString();
        const availableFieldDataExtensionCustomerKey = availableField.DataExtension.CustomerKey.toString();
        const availableFieldObjectID = availableField.ObjectID;
        const availableFieldIsRequired = `${availableField.IsRequired}`;
        const availableFieldMaxLength = availableField.MaxLength || Util.getMaxLength(availableField.FieldType);

        // pass these parameters to a function and assign to the matched fields
        // eslint-disable-next-line no-param-reassign
        matchedFields = dropToTargetDataExtensionField(
          availableFieldFieldType,
          targetDataExtensionFieldCustomerKey,
          availableFieldName,
          /**
           * This parameter is null because we cannot see data extension alias in fields
           * so we are passing null and then in this function we are handling data extension alias
           */
          null,
          targetDataExtensionFieldName,
          availableFieldDataExtensionCustomerKey,
          availableFieldObjectID,
          targetDataExtensionFieldObjectID,
          false,
          availableFieldIsRequired,
          availableFieldMaxLength,
        );
      }
    });

    /**
     * If matchedFields returns null it means it doesn't find any matched field.
     * In this case, assign it an empty array
     */
    if (matchedFields) {
      handleSetSelectionState({ matchedFields });
    } else {
      handleSetSelectionState({ matchedFields: [] });
    }
  };

  /**
   * Opens an automap modal and executes the automap function
   * @returns {void}
   */
  const autoMap = async () => {
    let matchedFieldsBeforeAutomap;

    // if the toast about automap exists, close it
    if (toast.isActive(Constants.NOTIFICATION__TOAST_ID__AUTOMAP)) {
      toast.dismiss(Constants.NOTIFICATION__TOAST_ID__AUTOMAP);
    }

    // show a swal with the options to choose
    SwalUtil.fire({
      title: 'Automap',
      // eslint-disable-next-line max-len
      message: `This will automatically map unique available fields with your target data extension.
      Do you want to overwrite existing mappings?`,
      options: {
        input: 'radio',
        inputValue: Constants.AUTOMAP__OVERWRITE,
        inputOptions: {
          overwrite: 'Overwrite',
          doNotOverwrite: 'Do not overwrite',
        },
        showCancelButton: true,
        showConfirmButton: true,
        showLoaderOnConfirm: true,
        didOpen() {
          // if all fields are matched, disable do not overwrite radio button
          if (matchedFields.length === targetDataExtensionFields.length) {
            document.getElementsByClassName('swal2-label')[1].style.color = '#b5b5b5';
            document.getElementsByName('swal2-radio')[1].disabled = true;
          }
        },
        customClass: {
          confirmButton: 'targetDE-automap-confirm',
          loader: 'mapping-loader',
          input: 'input-class',
          cancelButton: 'targetDE-automap-cancel',
        },
        // it is a loading spinner with the button
        loaderHtml: `
        <div class="map-btn-container">
          <button disabled id="map-button" class="slds-button slds-button_neutral" type="button"> Mapping...
            <div class="preview-loader-container automap-spinner">
              <div
                role="status"
                class="slds-spinner slds-spinner_x-small"
              >
                <div class="slds-spinner__dot-a" />
                <div class="slds-spinner__dot-b" />
              </div>
            </div>
          </button>
        </div>`,
        preConfirm: result => new Promise((resolve) => {
          if (result === Constants.AUTOMAP__DO_NOT_OVERWRITE) {
            // define how many fields are matched before automap
            matchedFieldsBeforeAutomap = matchedFields.length;
          }

          // setTimeout to show loader on confirm button (for better visual perception)
          setTimeout(() => {
            // trigger mapFields function
            swal.disableButtons();
            mapFields(result);
            resolve();
          }, 200);
        }),
      },
    }).then((result) => {
      // if the user clicks on the confirm button
      if (result.value) {
        let automapNotification;

        // see how many fields have been matched without overwriting
        const notOverwrittenFields = matchedFields.length - matchedFieldsBeforeAutomap;

        // depending on the option, display a message in the toast
        if ((result.value === Constants.AUTOMAP__OVERWRITE && matchedFields.length === 1) ||
        (result.value === Constants.AUTOMAP__DO_NOT_OVERWRITE && matchedFields.length && notOverwrittenFields === 1)) {
          automapNotification = 'Automap is complete. 1 field is matched.';
        } else if (result.value === Constants.AUTOMAP__OVERWRITE && matchedFields.length) {
          automapNotification = `Automap is complete. ${matchedFields.length} fields are matched.`;
        } else if (result.value === Constants.AUTOMAP__DO_NOT_OVERWRITE && matchedFields.length &&
          notOverwrittenFields) {
          automapNotification = `Automap is complete. ${notOverwrittenFields} fields are matched.`;
        } else if (!matchedFields.length || !notOverwrittenFields) {
          automapNotification = 'Automap is complete. No matching fields were found.';
        }

        // the toast will appear
        toast.success(
          <div className="slds-notify slds-notify_toast slds-theme_success">
            <span className="slds-assistive-text">success</span>
            <span
              className="slds-icon_container slds-icon-utility-success slds-m-right_small slds-no-flex slds-align-top"
              title="Description of icon when needed"
            >
              <svg className="slds-icon slds-icon_small" aria-hidden="true">
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#success" />
              </svg>
            </span>
            <span>
              {automapNotification}
            </span>
          </div>,
          {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-mapped',
            toastId: Constants.NOTIFICATION__TOAST_ID__AUTOMAP,
            hideProgressBar: true,
            autoClose: 4000,
            containerId: Constants.NOTIFICATION__CONTAINER_ID__TARGET_DEFINITION,
            draggable: false,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
          },
        );
      }
    });
  };

  /**
   * Delete all matched fields
   * @returns {void}
   */
  const deleteAllMatchedFields = async () => {
    const result = await SwalUtil.fire({
      title: 'Delete Mappings',
      message: 'Are you sure you want to delete all field mappings? You won\'t be able to revert this!',
      options: {
        showCancelButton: true,
        buttonsStyling: false,
      },
    });

    // If a user confirms the deletion
    if (result.value) {
      handleSetSelectionState({ matchedFields: [] });
    }
  };

  /**
   * Check if required field is mapped or not
   * @property {string} ObjectID => target DE field ObjectID
   * @param {string} ObjectID - target DE field ObjectID
   * @returns {array} The ObjectID of the fields which are not mapped
   */
  const isRequiredFieldMapped = (ObjectID) => {
    // Keeps ObjectID of the fields which are not mapped
    const notMappedFields = [];
    // Helps to determine either ObjectID is mapped or not

    let check = false;
    // Loop through matched fields and check if there is a field with ObjectID

    matchedFields.forEach((matchedField) => {
      if (ObjectID === matchedField.targetDataExtensionFieldObjectID) {
        // If field with ObjectID is in matched fields set check to true
        check = true;
      }
    });
    // If field is not inside matched fields then push ObjectID of that field in notMappedFields
    if (!check) {
      notMappedFields.push(ObjectID);
    }

    return notMappedFields;
  };

  /**
   * Looks if there is only one mapped required field for cross join relation
   * and return true if that is the case
   * @param {Object} targetDataExtensionField - target DE field to show warning from
   * @returns {Boolean} - Returns true or false depending the warning should be shown or not
   */
  const isShowingCrossJoinWarningRequired = (targetDataExtensionField) => {
    if (targetDataExtensionField.IsRequired === Constants.FIELD__IS_REQUIRED &&
      relations?.some(relation => relation.joinType === Constants.RELATIONTYPE__CROSS) &&
      matchedFields?.filter(
        matchedField => String(matchedField.availableFieldIsRequired) ===
        String(Constants.FIELD__IS_REQUIRED),
      )?.length === 1 &&
      !isRequiredFieldMapped(targetDataExtensionField.ObjectID).length) {
      return true;
    }

    return false;
  };

  /**
   * Handle onClick event on the cross join warning icon
   * @returns {void}
   */
  const handleClickCrossJoinWarningIcon = async () => {
    await SwalUtil.fire({
      title: 'Only one primary key mapped for Cross Join',
      message: `You only have one primary key mapped for Cross Join.
       This may result in unexpected results due to primary key constraints.
       Please remove the primary key or add a primary key from the second Data Extension in the Cross Join.`,
      options: {
        showConfirmButton: true,
        confirmButtonText: 'OK',
      },
    });
  };

  /**
   * Indicates whether a field is existing or is newly added
   * We need this so we can show correct swal message when removing a field
   * @param {string} targetDEFieldObjectID - targetDEFieldObjectId
   * @returns {boolean} If field already exists in target DE fields
   */
  const isExistingField = (targetDEFieldObjectID) => {
    let existingField = false;

    prevTargetDEFields.forEach((field) => {
      if (targetDEFieldObjectID === field.ObjectID) {
        existingField = true;
      }
    });

    return existingField;
  };

  /**
   * Returns true if all icons are rendered in edit TargetDE field mode
   * @param {object} targetDataExtensionField - object with target DE field
   * @returns {boolean} true/false depending on whether the conditions are met
   */
  const editedFieldWithAllIcons = (targetDataExtensionField) => {
    if (targetDataExtensionField.IsPrimaryKey &&
    targetDataExtensionField.StorageType === Constants.FIELD_STORAGE_TYPE__ENCRYPTED &&
    featureEncryptedFieldsIsEnabled) return true;

    return false;
  };

  /**
   * Returns true if availableFieldDataExtensionCustomerKey property in the field exists
   * @param {object} field - field object
   * @returns {boolean} true if availableFieldDataExtensionCustomerKey exists, false otherwise
   */
  const isAvailableFieldDECustomerKeyExists = field => field.availableFieldDataExtensionCustomerKey &&
       field.availableFieldDataExtensionCustomerKey !== 'null';

  /**
   * Function that handle onDragEnter for target collection row
   * @param {object} e - JS Event
   * @returns {void}
   */
  const onDragEnterForTargetCollectionRow = (e) => {
    if (!editTargetDataExtension && e.target.firstChild &&
           e.target.firstChild.className === 'target-collection-dropzone') {
      // eslint-disable-next-line no-param-reassign
      e.target.firstChild.style.pointerEvents = '';
    }
  };

  /**
   * Function that handle onDragEnter or onDragLeave for target collection dropzone
   * @param {object} e - JS Event
   * @param {boolean} isOnDragEnter - indicates if onDragEnter event is triggered
   * @returns {void}
   */
  const onDragEnterOrLeaveForTargetCollectionDropzone = (e, isOnDragEnter) => {
    const targetCollectionDropzoneWithFirstChild = e?.target?.className === 'target-collection-dropzone' &&
         e.target?.firstChild?.firstChild !== null;

    const isEmptyForDropzone = e?.target?.firstChild?.className === 'empty-for-dropzone';

    // Make border solid on dragEnter and dashed on dragLeave
    const firstChildStyleBorder = isOnDragEnter ? 'var(--solidBorder)' : 'var(--dashedBorder)';

    if(targetCollectionDropzoneWithFirstChild && isOnDragEnter) {
      e.target.style.pointerEvents = 'none';
    }

    if(!editTargetDataExtension && (isOnDragEnter || targetCollectionDropzoneWithFirstChild && !isOnDragEnter)) {
      // Enable pointer events if drag out of mapped field
      e.target.style.pointerEvents = '';
    }

    if (!editTargetDataExtension && !isOnDragEnter) {
      e.target.style.border = '';
    }

    // Make border solid or dashed
    if (isEmptyForDropzone && (isOnDragEnter || !editTargetDataExtension && !isOnDragEnter)) {
      e.target.firstChild.style.border = firstChildStyleBorder;
    }
  };

  /**
   * Function that handle onDrop for target collection dropzone
   * @param {object} e - JS Event
   * @param {object} targetDataExtensionField - target data extension field object
   * @returns {void}
   */
  const onDropForTargetCollectionDropzone = (e, targetDataExtensionField) => {
    if (!editTargetDataExtension) {
      if (e?.target?.firstChild?.className === 'empty-for-dropzone') {
        e.target.firstChild.style.border = '';

        return handleAddFieldToTargetDE(
          e,
          targetDataExtensionField.CustomerKey,
          targetDataExtensionField.Name.toString(),
          targetDataExtensionField.FieldType,
          targetDataExtensionField.ObjectID,
          null,
          targetDataExtensionField.IsRequired,
          false,
          false,
          false,
        );
      }

      if (e.target) { e.target.style.border = ''; }
    }

    return false;
  };

  /**
   * Renders a DE Field
   * @returns {object} The HTML for the DE fields
   */
  const renderDEField = () => {
    if (targetDataExtensionFields &&
      targetDataExtensionFields.length > 0 &&
      newTargetDataExtensionFields.length < 1 &&
      !editNewAutoTargetDE) {
      return (
        targetDataExtensionFields.map((targetDataExtensionField, index) => (
          <div
            key={targetDataExtensionField.Name.toString()}
            className="target-collection-row"
            onDragEnter={onDragEnterForTargetCollectionRow}
          >
            <div
              className={`target-collection-dropzone${editTargetDataExtension ? ' pointer-event-none' : ''}`}
              onDragEnter={e => onDragEnterOrLeaveForTargetCollectionDropzone(e, true)}
              onDragLeave={onDragEnterOrLeaveForTargetCollectionDropzone}
              onDragOver={e => e.preventDefault()}
              onDrop={e => onDropForTargetCollectionDropzone(e, targetDataExtensionField)}
            >
              <div
                className="empty-for-dropzone"
                style={{
                  pointerEvents: filterBorderMouseOver ? 'none' : '',
                  border: filterBorderMouseOver && !editTargetDataExtension ?
                    'var(--dashedBorder)' :
                    'var(--transparentBorder)',
                }}
              >
                {matchedFields.map(field => field.targetDataExtensionFieldObjectID ===
                    targetDataExtensionField.ObjectID ?
                  (
                    <div
                      key={field.availableFieldObjectID}
                      className="drag-field available-field"
                    >
                      <div className="drag-field_wrapper">
                        <div
                          className="field-label"
                          title={
                            field.availableFieldName +
                              ' ' +
                              (isAvailableFieldDECustomerKeyExists(field) ?
                                field.availableFieldDataExtensionAlias :
                                `${field?.globalCustomValueId?.length ? 'Shared ' : ''}Custom Value`)
                          }
                        >
                          {`${Util.abbreviate(field.availableFieldName, 30)} (${
                            isAvailableFieldDECustomerKeyExists(field) ?
                              Util.abbreviate(field.availableFieldDataExtensionAlias, 40) :
                              `${field?.globalCustomValueId?.length ? 'Shared ' : ''}Custom Value`
                          })`}
                        </div>
                        {editTargetDataExtension ?
                          null :
                          (
                            <div>
                              <Button
                                className="remove-field-target-de"
                                noButtonClass
                                onClick={() => removeTargetDataExtensionField(
                                  field.targetDataExtensionFieldObjectID,
                                )}
                              >
                                <i className="far fa-times-circle remove-filter" />
                              </Button>
                            </div>
                          )}
                      </div>
                    </div>
                  ) :
                  null)}
              </div>
            </div>
            <div
              className={'target-collection-field' +
                ((prevTargetDEFields && prevTargetDEFields.length === 0) ||
                  (isExistingField(targetDataExtensionField.ObjectID) &&
                    prevTargetDEFields &&
                    prevTargetDEFields.length > 0) ?
                  '' :
                  ' newly-added-field')}
              data-field-type="a"
              title={targetDataExtensionField.Name.toString()}
            >
              {editTargetDataExtension ?
                (
                  <div className={`span-field-info-button ${editedFieldWithAllIcons(targetDataExtensionField) &&
                  'with-2-icons'}`}
                  >
                    <span
                      id="edit-info-icon"
                      // eslint-disable-next-line max-len
                      className={'slds-icon_container slds-icon-utility-announcement slds-current-color remove-filter edit-info-icon' + (isSendableField(targetDataExtensionField.Name.toString()) ? ' disable-div' : '')}
                      onClick={() => showInfoAboutField(targetDataExtensionField.ObjectID)}
                      title="Edit"
                    >
                      <svg
                        className="slds-icon slds-icon_x-small dropEdit"
                        aria-hidden="true"
                      >
                        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#edit" />
                      </svg>
                    </span>
                    {targetDataExtensionField.IsPrimaryKey ?
                      <i className="fas fa-key primary-key-icon" title={Constants.FIELD_INFO_LABEL__PRIMARY_KEY} /> :
                      null}
                    {targetDataExtensionField.StorageType === Constants.FIELD_STORAGE_TYPE__ENCRYPTED &&
                    featureEncryptedFieldsIsEnabled ?
                      (
                        <i
                          className="fas fa-lock encrypted-lock-icon"
                          title={Constants.FIELD_INFO_LABEL__ENCRYPTED_FIELD}
                        />
                      ) :
                      null}
                  </div>
                ) :
                null}

              {Util.abbreviate(targetDataExtensionField.Name.toString(), '35')}
              {matchedFields.map(field => field.availableFieldIsRequired ===
                  Constants.FIELD__IS_NOT_REQUIRED &&
                  targetDataExtensionField.IsRequired ===
                  Constants.FIELD__IS_REQUIRED &&
                  field.targetDataExtensionFieldObjectID ===
                  targetDataExtensionField.ObjectID ?
                (
                  <i
                    key={`selectedDEField_${new Date().getTime()}`}
                    title={Constants.WARNING__FIELD_REQUIRED}
                    className="fas fa-exclamation-triangle link status-warning target-definition-icon"
                    onClick={e => handleAddFieldToTargetDE(
                      e,
                      targetDataExtensionField.CustomerKey,
                      targetDataExtensionField.Name.toString(),
                      targetDataExtensionField.FieldType,
                      targetDataExtensionField.ObjectID,
                      null,
                      targetDataExtensionField.IsRequired,
                      true,
                      false,
                      false,
                    )}
                  />
                ) :
                null)}
              {matchedFields.map(
                field => field.availableFieldMaxLength !== 'undefined' &&
                    targetDataExtensionField.MaxLength < field.availableFieldMaxLength &&
                    field.targetDataExtensionFieldObjectID === targetDataExtensionField.ObjectID ?
                  (
                    <i
                      key={`selectedTF_${new Date().getTime()}`}
                      title={Constants.WARNING__FIELD_LENGTH}
                      className="fas fa-exclamation-triangle link status-warning target-definition-icon"
                      onClick={e => handleAddFieldToTargetDE(
                        e,
                        targetDataExtensionField.CustomerKey,
                        targetDataExtensionField.Name.toString(),
                        targetDataExtensionField.FieldType,
                        targetDataExtensionField.ObjectID,
                        null,
                        targetDataExtensionField.IsRequired,
                        false,
                        false,
                        true,
                      )}
                    />
                  ) :
                  null,
              )}
              {targetDataExtensionField.IsRequired === Constants.FIELD__IS_REQUIRED ?
                isRequiredFieldMapped(targetDataExtensionField.ObjectID)
                  .map(id => id === targetDataExtensionField.ObjectID ?
                    (
                      <i
                        key={id}
                        title={Constants.SWAL_TITLE__FIELD_REQUIRED}
                        className="fas fa-exclamation-triangle link status-error target-definition-icon"
                        onClick={e => handleAddFieldToTargetDE(
                          e,
                          targetDataExtensionField.CustomerKey,
                          targetDataExtensionField.Name.toString(),
                          targetDataExtensionField.FieldType,
                          targetDataExtensionField.ObjectID,
                          null,
                          targetDataExtensionField.IsRequired,
                          false,
                          true,
                        )}
                      />
                    ) :
                    null) :
                null}

              {isShowingCrossJoinWarningRequired(targetDataExtensionField) ?
                (<i
                  title={Constants.WARNING__USE_OF_REQUIRED_FIELD_WITH_CROSS_JOIN}
                  className="fas fa-exclamation-triangle link status-warning target-definition-icon"
                  onClick={() => handleClickCrossJoinWarningIcon()}
                />) :
                null}

            </div>
            {editTargetDataExtension ?
              (
                <div>
                  <Button
                    buttonIcon
                    className="delete remove-field-target-de"
                    title="delete"
                    id="remove-new-target-de"
                    ariaDescribedBy="delete"
                    ariaDisabled="true"
                    onClick={async () => removeTargetDataExtensionField(
                      targetDataExtensionField.ObjectID,
                      index,
                      true,
                    )}
                  >
                    <svg
                      className="slds-button__icon slds-icon_small trash"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#recycle_bin_empty" />
                    </svg>
                  </Button>
                </div>
              ) :
              null}
          </div>
        ))
      );
    }

    if (!targetDataExtensionFields?.length &&
      targetDataExtensionCustomerKey) {
      return (
        <div className="demo-only demo--inverse" style={{ height: '6rem' }}>
          <Spinner size={Constants.SPINNER__SIZE__MEDIUM} />
        </div>
      );
    }

    if (editNewAutoTargetDE &&
      newTargetDataExtensionFields &&
      featureAutoCreateTargetDEIsEnabled &&
      newTargetDataExtensionFields.length &&
      newTargetDataExtensionFields.length > 0) {
      return (
        newTargetDataExtensionFields.map((newTargetDeField, index) => (
          <div key={newTargetDeField.ObjectID}>
            <div className="target-collection-row">
              <div
                className="target-collection-dropzone"
              >
                {matchedFields.map(
                  field => field.targetDataExtensionFieldObjectID === newTargetDeField.ObjectID ?
                    (
                      <div
                        key={field.availableFieldName}
                        className="drag-field available-field card"
                        title={
                          field.availableFieldName +
                          ' ' +
                          (isAvailableFieldDECustomerKeyExists(field) ?
                            field.availableFieldDataExtensionAlias :
                            'Custom Values')
                        }
                      >
                        <div>
                          <div className="field-label">
                            {`${Util.abbreviate(field.availableFieldName, 20)} (${
                              isAvailableFieldDECustomerKeyExists(field) ?
                                Util.abbreviate(field.availableFieldDataExtensionAlias, 30) :
                                'Custom Values'
                            })`}
                          </div>
                        </div>
                      </div>
                    ) :
                    null,
                )}
              </div>
              <div
                className="target-collection-field"
                data-field-type="a"
                title={newTargetDeField.Name.toString()}
              >
                <div className={`span-field-info-button ${editedFieldWithAllIcons(newTargetDeField) &&
                  'with-2-icons'}`}
                >
                  <span
                    id="edit-info-icon"
                    // eslint-disable-next-line max-len
                    className="slds-icon_container slds-icon-utility-announcement slds-current-color remove-filter edit-info-icon"
                    onClick={() => showInfoAboutField(newTargetDeField.ObjectID)}
                    title="Edit"
                  >
                    <svg
                      className="slds-icon slds-icon_x-small dropEdit"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#edit" />
                    </svg>
                  </span>
                  {newTargetDeField.IsPrimaryKey ?
                    <i className="fas fa-key primary-key-icon" title="Primary Key" /> :
                    null}
                  {newTargetDeField.StorageType === Constants.FIELD_STORAGE_TYPE__ENCRYPTED &&
                  featureEncryptedFieldsIsEnabled ?
                    (
                      <i
                        className="fas fa-lock encrypted-lock-icon"
                        title={Constants.FIELD_INFO_LABEL__ENCRYPTED_FIELD}
                      />
                    ) :
                    null}
                </div>
                <span className="span-target-de-field-name">
                  {Util.abbreviate(newTargetDeField.Name.toString(), '35')}
                </span>
                <div>
                  <Button
                    iconButton
                    className="delete remove-field-target-de"
                    title="delete"
                    id="remove-new-target-de"
                    ariaDescribedBy="delete"
                    ariaDisabled="true"
                    onClick={async () => removeTargetDataExtensionField(
                      newTargetDeField.ObjectID,
                      index,
                      true,
                    )}
                  >
                    <svg
                      className="slds-button__icon slds-icon_small trash"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#recycle_bin_empty" />
                    </svg>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))
      );
    }

    return null;
  };

  /**
   * Returns sort limit select part message
   * @returns {string} message based on sort limit select type
   */
  const renderSortLimitSelectMessage = () => {
    // define a message variable
    const message = 'Selecting';

    // if type is number return type value and message accordingly
    if (sortLimit?.select?.type === Constants.SORT_LIMIT__SELECT_TYPE__NUMBER) {
      return `${message} ${sortLimit?.select?.value} records`;
    }

    // if type is percentage return type value and message accordingly
    if (sortLimit?.select?.type === Constants.SORT_LIMIT__SELECT_TYPE__PERCENTAGE) {
      return `${message} ${sortLimit?.select?.value}% of records`;
    }

    // otherwise return all record message
    return `${message} all records`;
  };

  const checkForMissingFields = async () => {
    // Variable that shows whether sort & limit should be reset
    let resetSortAndLimit = false;

    // Variable that shows whether prioDedup should be reset
    let resetPrioDeduplication = false;

    // State that should be reset
    let stateToReset = {};

    // Make sure we are not on edit mode
    if (!editTargetDataExtension) {
      // Check if sort & limit is active, and is using a field
      if (sortLimit?.enabled && sortLimit?.orderBy?.type === Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD) {
        // find the selected targeted field name
        const field = targetDataExtensionFields.find(x => x.ObjectID === sortLimit?.orderBy?.fieldObjectID)?.Name;

        // In case field is not found reset state and show swal
        if (!field) {
          resetSortAndLimit = true;

          stateToReset = {
            sortLimit: {
              enabled: false,
              select: {
                type: Constants.SORT_LIMIT__SELECT_TYPE__NUMBER,
                value: 0,
              },
              orderBy: {
                type: Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD,
                fieldObjectID: '',
                sort: '',
              },
            },
          };
        }
      }

      // Check if prioDedup is active
      if (usePrioDeduplication) {
        // find the selected deduplication field name
        const field = targetDataExtensionFields.find(x => x.ObjectID === prioDeduplication
          .deduplicationFieldObjectID)?.Name;

        // find the selected criteria field name
        const criteriaField = targetDataExtensionFields.find(x => x.ObjectID === prioDeduplication
          .criteriaFieldObjectID)?.Name;

        // In case a field is not found reset state and show swal
        if ((prioDeduplication.criteriaFieldObjectID && !criteriaField) || !field) {
          resetPrioDeduplication = true;

          stateToReset = {
            ...stateToReset,
            prioDeduplication: {
              multipleSortingOptionLines: [],
              mode: Constants.PRIO_DEDUP__MODE__BASIC__VALUE,
              type: Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE,
              deduplicationFieldObjectID: '',
              criteriaFieldObjectID: '',
              priorities: [],
              sortOrder: Constants.PRIO_DEDUP__SORT_ORDER__ASC,
            },
            usePrioDeduplication: false,
            advancedDedupFilterSaveIndex: null,
            advancedDeduplicationRules: [],
            previousAdvancedDeduplicationRules: [],
          };
        }
      }

      // Reset state
      await handleSetSelectionState({
        ...stateToReset,
      });

      // Check if swal should be shown for Sort&Limit
      if (resetSortAndLimit) {
        // Fire swal message
        await SwalUtil.fire({
          title: 'Warning',
          message: 'The field used in Sort & Limit is deleted, therefore Sort & Limit will be disabled.',
          options: {
            showConfirmButton: true,
            confirmButtonText: 'OK',
          },
        });
      }

      // Check if swal should be shown for prioDedup
      if (resetPrioDeduplication) {
        // Fire swal message
        await SwalUtil.fire({
          title: 'Warning',
          message: 'A field used in Prio Deduplication is deleted, therefore Prio Deduplication will be disabled.',
          options: {
            showConfirmButton: true,
            confirmButtonText: 'OK',
          },
        });
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { checkForMissingFields(); }, [targetDataExtensionFields, editTargetDataExtension]);

  useEffect(() => {
    setTargetDEFieldsBeforeEdits(targetDataExtensionFields);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editTargetDataExtension]);

  /**
   * Returns sort limit order part message
   * @returns {string} message based on sort limit order type
   */
  const renderSortLimitOrderMessage = () => {
    // define a message variable
    const message = ', sorted';

    // if type is set by field and order is set return message accordingly
    if (sortLimit?.orderBy?.type === Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD) {
      // find the selected targeted field name
      const field = targetDataExtensionFields.find(x => x.ObjectID === sortLimit?.orderBy?.fieldObjectID)?.Name;

      // eslint-disable-next-line max-len
      return `${message} by ${field}
        ${sortLimit.orderBy.sort === Constants.SORT_LIMIT__ORDER_BY_SORT__ASCENDING ? 'ascending' : 'descending'}.`;
    }

    // otherwise return random order message
    return `${message} at random.`;
  };

  /**
   * Returns deduplication multiple sorting options message
   * @param {array} list - list containing all sorting options fields
   * @returns {string} message of multiple sorting options
   */
  const renderDeduplicationMultipleSortingOptionLines = (list) => {
    // declare variable message
    let message = '';

    // if list contains any items then iterate through each item
    if (list.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of list) {
        // find the selected deduplication field name
        const field = targetDataExtensionFields.find(x => x.ObjectID === item.criteriaFieldObjectID)?.Name;

        // set the message with field name and sorting order
        message = `${message} ${field?.toString()} ${item?.sortOrder === Constants.PRIO_DEDUP__SORT_ORDER__ASC ?
          'ascending,' :
          'descending,'}`;
      }
    }

    // return complete message
    message = `${message.substring(0, message.length - 1)}.`;

    return message;
  };

  /**
   * Returns deduplication message
   * @returns {string} message based on deduplication type
   */
  const renderDeduplicationMessage = () => {
    // define a message variable
    const message = 'Deduplicating on';

    // find the selected deduplication field name
    const field = targetDataExtensionFields.find(x => x.ObjectID === prioDeduplication
      .deduplicationFieldObjectID)?.Name;

    // find the selected criteria field name
    const criteriaField = targetDataExtensionFields.find(x => x.ObjectID === prioDeduplication
      .criteriaFieldObjectID)?.Name;

    // if mode is basic return message with field names
    if (prioDeduplication?.mode === Constants.PRIO_DEDUP__MODE__BASIC__VALUE) {
      // if sorting all values selected then show all options message
      if (prioDeduplication?.type === Constants.PRIO_DEDUP__SORTING_PRIO__SORT_ALL_VALUES__VALUE) {
        // return message with sorting options and order
        // eslint-disable-next-line max-len
        return `${message} ${field}, based on ${criteriaField} ${prioDeduplication?.sortOrder === Constants.PRIO_DEDUP__SORT_ORDER__ASC ? 'ascending,' : 'descending,'}${renderDeduplicationMultipleSortingOptionLines(prioDeduplication?.multipleSortingOptionLines)}`;
      }

      // otherwise return message with criteria field name
      return `${message} ${field}, based on ${criteriaField}.`;
    }

    // if it is advanced mode, return message accordingly
    return `${message} ${field}, based on advanced rules.`;
  };

  /**
   * Returns HTML object with title for data action alert
   * @returns {object} HTML object
   */
  const renderDataActionAlertTitle = () => (
    <>
      <b>Overwrite Data Action Selected.</b>
      {' '}
      This action will overwrite
      all of the current data in the Target Data Extension.
      <a
        href="#!"
        className="change-data-action"
        title="Change Data Action"
        role="button"
        onClick={handleOpenDataActionModal}
      >
        Change Data Action
      </a>
    </>
  );

  return (
    <>
      <div className="selectedTF-info">
        {!editNewAutoTargetDE && !editTargetDataExtension ?
          (
            <div className="box-STF-icons">
                <Button
                  buttonIconBorderFilled
                  id="delete-matched-fields"
                  onClick={deleteAllMatchedFields}
                  title="Delete mappings"
                >
                  <svg className="slds-button__icon" aria-hidden="true">
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#recycle_bin_empty" />
                  </svg>
                </Button>
                <Button
                  buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                  className="automap"
                  onClick={() => autoMap()}
                >
                  Automap
                </Button>

                {showDataActionModal && (
                  <DataAction
                    show={showDataActionModal}
                    dataAction={dataAction}
                    targetDataExtensionFields={targetDataExtensionFields}
                    handleSetSelectionState={handleSetSelectionState}
                  />
                )}

                {showSortLimitModal && (
                  <SortLimit
                    show={showSortLimitModal}
                    sortLimit={sortLimit}
                    targetDataExtensionFields={targetDataExtensionFields}
                    handleSetSelectionState={handleSetSelectionState}
                  />
                )}

            </div>
          ) :
          null}
      </div>
      {dataAction === Constants.DATA_ACTION__OVERWRITE && !editNewAutoTargetDE && !editTargetDataExtension ?
        (
          <Alert
            className="warning-data-action"
            type={Constants.ALERT__TYPE__WARNING}
            id="overwrite-warning"
            title={renderDataActionAlertTitle()}
          />
        ) :
        null}
      {(usePrioDeduplication && !editNewAutoTargetDE && !editTargetDataExtension &&
        targetDataExtensionFields?.length) ?
        (
          <Alert
            type={Constants.ALERT__TYPE__SUCCESS}
            className="indication-message"
            id="deduplication-message"
            withoutIcon
            title={`Prio Deduplication enabled: ${renderDeduplicationMessage()}`}
          />
        ) :
        null}
      {(sortLimit?.enabled && !editNewAutoTargetDE && !editTargetDataExtension &&
        targetDataExtensionFields?.length) ?
        (
          <Alert
            type={Constants.ALERT__TYPE__SUCCESS}
            className="indication-message"
            id="sort-limit-message"
            withoutIcon
            title={`Sort & Limit enabled: ${renderSortLimitSelectMessage()}${renderSortLimitOrderMessage()}`}
          />
        ) :
        null}
      <div className="selectedTF-field_names">
        <div id="selected-target-fields-header1">
          <b>Field</b>
        </div>
        <div id="selected-target-fields-header2">
          <b>Target Data Extension Field</b>
        </div>
      </div>

      <div id="selected-fields" className="selectedTF-fields">
        {/* <div className="row target-collection-row"></div> things will be rendered here */}
        {renderDEField()}
        {(editNewAutoTargetDE && featureAutoCreateTargetDEIsEnabled) || editTargetDataExtension ?
          (
            <div
              className="target-de-fields-dropzone filter-drop filterline-dropzone"
              onDragOver={e => e.preventDefault()}
              onDrop={handleAddFieldToTargetDE}
              /* eslint-disable no-param-reassign */
              onDragEnter={(e) => { e.target.style.border = 'var(--solidBorder)'; }}
              onDragLeave={(e) => { e.target.style.border = 'var(--dashedBorder)'; }}
              /* eslint-enable no-param-reassign */
              style={{
                border: filterBorderMouseOver ?
                  'var(--dashedBorder)' :
                  'var(--transparentBorder)',
              }}
            >
              Drop fields here to
              {' '}
              {editTargetDataExtension ?
                'add new fields to Target Data Extension' :
                'automatically create a new target data extension'}
            </div>
          ) :
          null}
      </div>
      <br />
    </>
  );
};

SelectedTargetFields.propTypes = {
  /**
   * It keeps the value of AC/DE creation status
   * It will be passed from Selection.js
   */
  editNewAutoTargetDE: PropTypes.bool.isRequired,
  /**
   * It keeps the fields which user drag and drop during the process of creating a new DE
   */
  newTargetDataExtensionFields: PropTypes.instanceOf(Array),
  /**
   * It keeps the fields of an existing target data extension
   * It will be passed from Selection.js
   */
  targetDataExtensionFields: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps the Customer Key of a selected existing target data extension
   * It will be passed from Selection.js
   */
  targetDataExtensionCustomerKey: PropTypes.string,
  /**
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js
   */
  matchedFields: PropTypes.instanceOf(Array).isRequired,
  /**
   * Array of relations of the Selection
   */
  relations: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array).isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It keeps the info about a New Auto Create Data Extension
   */
  newTargetDataExtension: PropTypes.instanceOf(Object).isRequired,
  /**
   * It keeps subscriber field names
   */
  subscriberFieldNames: PropTypes.instanceOf(Array),
  /**
   * It helps to add a new field to a DE during the process of creating a new DE
   */
  handleAddFieldToTargetDE: PropTypes.func,
  /**
   * It helps to match a field between available fields and target DE fields
   */
  dropToTargetDataExtensionField: PropTypes.func,
  /**
   * boolean state from Selection for going in or out the edit target de mode
   */
  editTargetDataExtension: PropTypes.bool.isRequired,
  /**
   * keeps target de fields before entering the edit target de mode
   * It will be passed from Selection.js
   */
  prevTargetDEFields: PropTypes.instanceOf(Array),
  /**
   * It keeps data action state (overwrite, append, update)
   */
  dataAction: PropTypes.string.isRequired,
  /**
   * It keeps the boolean state which is used to open or close data action modal
   * It will be passed from Selection.js
   */
  showDataActionModal: PropTypes.bool.isRequired,
  /**
   * Keeps he target data extensions after they are retrieved from SFMC
   */
  targetDataExtensions: PropTypes.instanceOf(Array),
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * It keeps data about sort and limit
   */
  sortLimit: PropTypes.instanceOf(Object).isRequired,
  /**
   * It keeps the boolean state which is used to open or close sort and limit modal
   * It will be passed from Selection.js
   */
  showSortLimitModal: PropTypes.bool.isRequired,
  /**
   * It keeps if the prio deduplication settings will be applied
   */
  prioDeduplication: PropTypes.instanceOf(Object).isRequired,
  /**
   * It keeps if the prio deduplication settings will be applied
   */
  usePrioDeduplication: PropTypes.bool.isRequired,
  /**
   * Handles the changing of sendable and subscriber field names
   */
  handleChangeSendableAndSubscriberField: PropTypes.func,
  /**
   * It keeps data for selected target data extension
   */
  targetDataExtension: PropTypes.instanceOf(Object).isRequired,

};

export default SelectedTargetFields;

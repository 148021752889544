import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import './styles.scss';

import NewAutoCreatedTargetDE from '../../../Selection/TargetDefinition/NewAutoCreatedTargetDE/NewAutoCreatedTargetDE';
import RadioButton from '../../../shared/RadioButton/RadioButton';
import DataExtensionsAPI from '../../../../api/data-extensions';
import Util from '../../../../util';
import Constants from '../../../../constants/constants';
import organisationsAPI from '../../../../api/organisations';
import Features from '../../../../features';
import ModalTemplate from '../../../shared/ModalTemplate/ModalTemplate';
import Spinner from '../../../shared/Spinner/Spinner';
import ToggleButton from '../../../shared/ToogleButton/ToggleButton';
import Select from '../../../shared/Select/Select';
import Input from '../../../shared/Input/Input';
import Alert from '../../../shared/Alert/Alert';
import SwalUtil from '../../../../utils/swal/swalUtil';
import UpgradeBadge from '../../../shared/UpgradeBadge/UpgradeBadge';
import Tooltip from '../../../shared/Tooltip/Tooltip';
import { featureAdvertExists } from '../../../shared/Feature/featureUtil';

class CopySelectionModal extends Component {
  isMounting = false;

  constructor(props) {
    super(props);
    this.state = {
      copySelection: { ...props.selection },
      selectCopyTargetDE: props.selection?.isTemplate,
      loadingData: false,
      nameDEError: false,
      nameSelectionError: false,
      copyTargetDE: {},
      targetDataExtensions: [],
      loadingFoldersForCopy: false,
      copyTo: Constants.COPY_TO__CURRENT_BU,
      matchOn: Constants.COPY_MATCHING_ON__CUSTOMER_KEY,
      businessUnits: [],
      selectedBusinessUnitId: null,
      selectedBusinessUnitName: '',
      copyingSelection: false,
    };

    this.featureCopyToOtherBUs = Features.isFeatureEnabled(Constants.FEATURE__COPY_TO_OTHER_BUS);
    this.refBtn = React.createRef();
  }

  /**
   * Fetch data while loading modal
   * @returns {void}
   */
  async componentDidMount() {
    // Before mounting we create 'CancelToken' for further cancellation of memory leak
    this.axiosCancelToken = axios.CancelToken.source();
    this.isMounting = true;

    const { copyTargetDE, copySelection } = this.state;
    const { selection, isWaterfall, findDuplicateSelection } = this.props;

    // start loading data when component is mounted
    if (this.isMounting) {
      this.setState({ loadingData: true });
      try {
        // set default name for selection
        const defaultNameForCopy = `${selection.name} copy`;
        const defaultSelectionName = await this.createDefaultName(defaultNameForCopy, 1, findDuplicateSelection);

        this.setState({ copySelection: { ...copySelection, name: defaultSelectionName } });

        // do not fetch target data extensions for waterfall selection
        if (isWaterfall) {
          this.setState({ loadingData: false });

          return;
        }

        // get all data extensions and set new state of targetDataExtensions when component is mounted
        const targetDataExtensions = await DataExtensionsAPI.getDataExtensions(
          this.axiosCancelToken.token,
          Constants.DATAEXTENSION__FILTER_MODE__TARGET,
        );

        this.setState({ targetDataExtensions });

        if (selection.targetCollectionCustomerKey) {
          // find Target Data Extension in Copy Selection
          const targetDataExtension = targetDataExtensions.find(tde => tde.CustomerKey ===
            selection.targetCollectionCustomerKey);

          if (targetDataExtension) {
            // set default name for targetDE
            const defaultNameForTargetCopy = `${targetDataExtension.Name} copy`;
            const defaultTargetDEName = await this.createDefaultName(
              defaultNameForTargetCopy,
              1,
              this.findDuplicateTargetDEName,
            );

            // if targetDE exists and component is mounted, set it as copyTargetDE, with default Name and folderId
            this.setState({
              copyTargetDE: {
                ...copyTargetDE,
                ...targetDataExtension,
                Name: defaultTargetDEName,
                folderId: targetDataExtension.CategoryID,
              },
            });
          } else {
            // set copyTargetDE as an empty object
            this.setState({ copyTargetDE: {}, selectCopyTargetDE: false });
          }
        } else {
          this.setState({ selectCopyTargetDE: false });
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          // in case of error, display the message
          this.showErrorMessage('Error', error);
        }
      }
    }

    if (this.featureCopyToOtherBUs && !isWaterfall) {
      // If copy to other BUs and the BUs have not been fetched before
      try {
        this.axiosCancelToken = axios.CancelToken.source();
        const user = Util.readObjectFromCookies(process.env.REACT_APP_SEGMENT__SESSION_USER_KEY);
        const { loggedInBusinessUnitId } = user || {};

        const accessibleBusinessUnits = await organisationsAPI.getUsersBUs(this.axiosCancelToken.token);
        const businessUnits = accessibleBusinessUnits
          .filter(businessUnit => businessUnit.businessUnitId !== loggedInBusinessUnitId);

        this.setState({
          businessUnits,
          selectedBusinessUnitId: businessUnits[0]?.businessUnitId,
          selectedBusinessUnitName: businessUnits[0]?.name,
        });
      } catch (error) {
        this.showErrorMessage('Error', error);
      }
    }

    // finish loading data and show the content of the modal
    this.setState({ loadingData: false });
  }

  /**
   * Avoid memory leak by canceling all subscriptions and asynchronous tasks
   * @returns {void}
   */
  componentWillUnmount() {
    this.axiosCancelToken.cancel();
    this.isMounting = false;
  }

  /**
   * The function that assigns a default name for the copy
   * @param {string} name - name for which creates the name of the copy
   * @param {number} i - the index of the copy
   * @param {Promise} findDuplicateObj - Promise object containing a duplicate name
   * @returns {Promise<string|function>} defaultName or function that runs createDefaultName again
   */
  createDefaultName = async (name, i, findDuplicateObj) => {
    let index = i;

    // define default name for the copy and check if there is an object with duplicate name
    const defaultName = `${name} ${index}`;
    const duplicateName = await findDuplicateObj(defaultName);

    /**
     * if it doesn't find an object with duplicate name, return the default name,
     * in another case perform the function again increasing the index by 1
     */
    if (!duplicateName) {
      return defaultName;
    }

    index += 1;

    return this.createDefaultName(name, index, findDuplicateObj);
  };

  /**
   * The function that finds the duplicate name of Target Data Extension
   * @param {string} targetDEName - name of Target Data Extension
   * @returns {Promise<object>} - Target Data Extension
   */
  findDuplicateTargetDEName = async (targetDEName) => {
    const { targetDataExtensions } = this.state;

    return targetDataExtensions.find(
      de => targetDEName.toString().toLowerCase() === de.Name.toString().toLowerCase(),
    );
  };

  /**
   * Change the selection name
   * @param {object} e - event
   * @returns {void}
   */
  handleSelectionNameChange = (e) => {
    const { copySelection } = this.state;

    this.setState({ nameSelectionError: false });

    // set a new name for the selection
    this.setState({
      copySelection: {
        ...copySelection,
        [e.target.name]: e.target.value,
      },
    });
  };

  /**
   * Saves changes - copy Selection and copy TargetDE
   * @returns {void}
   */
  // eslint-disable-next-line consistent-return
  handleOnSubmit = async () => {
    const {
      copySelection,
      selectCopyTargetDE,
      copyTargetDE,
      copyTo,
      matchOn,
      selectedBusinessUnitId,
      selectedBusinessUnitName,
    } = this.state;
    const { handleCopySelection, isWaterfall, findDuplicateSelection } = this.props;

    // start copying selection
    this.setState({ copyingSelection: true });

    // check if the name of this selection already exists
    const duplicateSelection = await findDuplicateSelection(copySelection.name);

    // eslint-disable-next-line no-negated-condition
    if (!copySelection.name || !copySelection.name.trim()) {
      // if the selection name is not entered throw a swal message
      this.showErrorMessage('Missing Name', 'Please enter a name for Selection.');

      // eslint-disable-next-line no-negated-condition
    } else if ((!copyTargetDE?.Name || !copyTargetDE?.Name?.trim()) && selectCopyTargetDE) {
      // if the copy TargetDE is select and targetDE name is not entered throw a swal message
      this.showErrorMessage('Missing Name', 'Please enter a name for Target Data Extension.');
    } else if (duplicateSelection && copyTo === Constants.COPY_TO__CURRENT_BU) {
      // if the name exists, display an alert
      this.setState({ nameSelectionError: true, copyingSelection: false });

      return false;
    } else if (copyTargetDE?.Name && !Util.nameValidation(copyTargetDE?.Name) && selectCopyTargetDE) {
      // check if New Target DE name is valid
      this.setState({ copyingSelection: false });

      return false;
    } else {
      if (isWaterfall) {
      // create a copy of waterfall selection
        handleCopySelection({
          selectionId: copySelection._id,
          copySelectionName: copySelection.name,
        });
      } else {
        // create a copy of selection
        handleCopySelection(
          {
            selectionId: copySelection._id,
            copySelectionName: copySelection.name,
            isCopyTargetDE: selectCopyTargetDE,
            copyTargetDEName: copyTargetDE.Name,
            copyTargetDEFolderId: copyTargetDE.folderId,
            businessUnitId: copyTo !== Constants.COPY_TO__CURRENT_BU && selectedBusinessUnitId,
            matchOn,
            businessUnitName: copyTo !== Constants.COPY_TO__CURRENT_BU && selectedBusinessUnitName,
          },
        );
      }
    }
  };

  /**
   * This function throws swal if an error occurs
   * @param {String} errorTitle - title of the swal fire message
   * @param {string} errorMessage - text of the swal fire message
   * @returns {void}
   */
  showErrorMessage = (errorTitle, errorMessage) => SwalUtil.fire({
    type: Constants.SWAL__TYPE__ERROR,
    title: errorTitle,
    message: errorMessage,
    options: {
      customClass: {
        popup: 'popup-targetDE',
      },
    },
  }).then((result) => {
    if (result.value && this.refBtn?.current) {
      // remove all assigned classNames
      setTimeout(() => {
        this.refBtn.current.className = '';
      }, 100);

      this.setState({ copyingSelection: false });
    }
  });

  /**
   * Function for setting the copySelectionModal state
   * @param {object} newState - currently passed object
   * @returns {void}
   */
  handleSetNewCopyState = (newState) => {
    this.setState(newState);
  };

  /**
   * Event handler for when form elements' values are changed
   * @param {object} e - onChange event object
   * @returns {void}
   */
  handleFormElementChanged = async (e) => {
    const { copyTargetDE } = this.state;
    const { value } = e.target;

    if (value === Constants.COPY_TO__OTHER_BU) {
      this.setState({
        copyTo: value,
        selectCopyTargetDE: !!Object.keys(copyTargetDE).length,
        nameSelectionError: false,
      });
    } else {
      this.setState(prevState => ({
        copyTo: value,
        copyTargetDE: {
          ...prevState.copyTargetDE,
          folderId: prevState.copyTargetDE.CategoryID,
        },
      }));
    }
  };

  /**
   * Event handler for when form Match On values change
   * @param {object} e - onChange event object
   * @returns {void}
   */
  handleMatchOnChange = async (e) => {
    const { value } = e.target;

    this.setState({
      matchOn: value,
    });
  };

  /**
   * Event handler for when business units' dropdown value is changed
   * @param {object} e - onChange event object
   * @returns {void}
   */
  handleBusinessUnitChanged = (e) => {
    const { businessUnits } = this.state;

    // find the BU with the selected businessUnitId and get the name
    const businessUnitName = businessUnits?.find(bu => bu?.businessUnitId?.toString() ===
      e.target.value.toString())?.name;

    this.setState({ selectedBusinessUnitId: e.target.value, selectedBusinessUnitName: businessUnitName });
  };

  render() {
    const {
      copySelection,
      selectCopyTargetDE,
      loadingData,
      nameDEError,
      nameSelectionError,
      targetDataExtensions,
      copyTargetDE,
      loadingFoldersForCopy,
      copyTo,
      matchOn,
      businessUnits,
      selectedBusinessUnitId,
      copyingSelection,
    } = this.state;

    const {
      selection, handleCloseCopySelectionModal, hideCopySelectionModal,
      isWaterfall, showEssentialsUpgradeModal,
    } = this.props;

    // check if the copyTo is set to current BU or other BU
    const copyToCurrentBU = copyTo === Constants.COPY_TO__CURRENT_BU;
    const copyToOtherBU = copyTo === Constants.COPY_TO__OTHER_BU;

    // check if matchOn is set to Customer Key or Name
    const matchOnCustomerKey = matchOn === Constants.COPY_MATCHING_ON__CUSTOMER_KEY;
    const matchOnName = matchOn === Constants.COPY_MATCHING_ON__NAME;

    return (
      (selection && !hideCopySelectionModal) ?
        (
          <ModalTemplate
            id="copy-selection-modal-dialog"
            containerClassName={isWaterfall ? 'waterfall' : ''}
            headerId="copy-selection-header"
            headerTitle={isWaterfall ? 'Copy Waterfall Selection' : 'Copy Selection'}
            handleCancel={handleCloseCopySelectionModal}
            cancelButtonId="copySelection-cancel"
            handleSave={this.handleOnSubmit}
            saveButtonDisabled={(!copyToOtherBU && (nameDEError || nameSelectionError)) ||
            loadingData || loadingFoldersForCopy ||
            (copyToOtherBU && !businessUnits.length) || copyingSelection}
            saveButtonTitle="Copy"
            saveButtonId="copySelection-save"
            loadingSaveButton={copyingSelection}
            loadingSaveButtonTitle="Copying..."
          >
            {/*  eslint-disable-next-line no-negated-condition */}
            {!loadingData ?
              (
                <>
                  <div className="selection-copy-name-wrapper">
                    <span className="copy-selection-name-label">Name</span>
                    <Input
                      name="name"
                      id={`field-name-text${copySelection._id}`}
                      min="1"
                      max="2550"
                      value={copySelection.name ? copySelection.name : ''}
                      className="copy-selection-modal field-name"
                      onChange={this.handleSelectionNameChange}
                    />
                  </div>
                  <div className="modal-container">
                    <Alert
                      className={nameSelectionError ? 'alert-visible' : 'alert-hidden'}
                      title="Selection with this name already exists."
                      id="nameCopyError-1"
                    />
                    { !isWaterfall &&
                          (
                            <div className="copy-to-container">
                              <fieldset className="slds-form-element">
                                <legend className="slds-form-element__legend slds-form-element__label">
                                  Copy to:
                                </legend>
                                <div className="slds-form-element__control">
                                  <RadioButton
                                    id={Constants.COPY_TO__CURRENT_BU}
                                    name={Constants.COPY_TO__CURRENT_BU}
                                    label="This Business Unit"
                                    value={Constants.COPY_TO__CURRENT_BU}
                                    onChange={this.handleFormElementChanged}
                                    checked={copyToCurrentBU}
                                    forwardRef={this.refBtn}
                                    disabled={loadingFoldersForCopy}
                                  />
                                    <div
                                    onClick={this.featureCopyToOtherBUs ?
                                      null :
                                      () => showEssentialsUpgradeModal(Constants.FEATURE__COPY_TO_OTHER_BUS)}>
                                  <RadioButton
                                    id={Constants.COPY_TO__OTHER_BU}
                                    name={Constants.COPY_TO__OTHER_BU}
                                      containerClassName={this.featureCopyToOtherBUs ? '' : 'disabled-overlay'}
                                    label="Other Business Unit"
                                    value={Constants.COPY_TO__OTHER_BU}
                                    onChange={this.handleFormElementChanged}
                                    checked={copyToOtherBU}
                                    disabled={!businessUnits.length || loadingFoldersForCopy}
                                    forwardRef={this.refBtn}
                                  />
                                  {
                                    !this.featureCopyToOtherBUs &&
                                      (featureAdvertExists(Constants.FEATURE__COPY_TO_OTHER_BUS) ?
                                      <UpgradeBadge /> :
                                      <Tooltip
                                        nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                                        type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
                                      />)
                                  }
                                    </div>
                                </div>
                              </fieldset>
                              { copyToOtherBU ?
                                (
                                  <Select
                                    containerClassName="business-units-dropdown-container"
                                    id="user-business-units"
                                    disabled={!businessUnits.length}
                                    onChange={this.handleBusinessUnitChanged}
                                    value={selectedBusinessUnitId || businessUnits[0]}
                                    noOptionsLabel="No business units found"
                                    options={businessUnits.map(businessUnit => ({
                                      value: businessUnit.businessUnitId,
                                      label: businessUnit.name ?
                                        `${businessUnit.name} -
                                          ${businessUnit.businessUnitId}` :
                                        businessUnit.businessUnitId,
                                    }))}
                                  />
                                ) :
                                null}
                                {copyToOtherBU && (
                                  <div className="copy-to-bu__warnings">
                                    Please, note the following restrictions on copying to another Business Unit:
                                    <ul>
                                      <li>
                                        Requires access to the target Business Unit
                                        (if it&apos;s not on the list you can&apos;t access it)
                                      </li>
                                      <li>
                                        Both business units must include Data Extensions
                                        with the same Customer Keys / Names and fields
                                      </li>
                                      <li>
                                        Any mappings made directly with Shared Custom Values will be removed
                                      </li>
                                    </ul>
                                  </div>
                                )}
                        {copyToOtherBU && (
                        <fieldset className="slds-form-element">
                          <legend className="slds-form-element__legend slds-form-element__label">
                            Match Data Extensions on:
                          </legend>
                          <div className="slds-form-element__control">
                            <RadioButton
                              id={Constants.COPY_MATCHING_ON__CUSTOMER_KEY}
                              name={Constants.COPY_MATCHING_ON__CUSTOMER_KEY}
                              label="Customer Key"
                              value={Constants.COPY_MATCHING_ON__CUSTOMER_KEY}
                              onChange={this.handleMatchOnChange}
                              checked={matchOnCustomerKey}
                              forwardRef={this.refBtn}
                              disabled={loadingFoldersForCopy}
                            />
                            <div>
                              <RadioButton
                                id={Constants.COPY_MATCHING_ON__NAME}
                                name={Constants.COPY_MATCHING_ON__NAME}
                                label="Name"
                                value={Constants.COPY_MATCHING_ON__NAME}
                                onChange={this.handleMatchOnChange}
                                checked={matchOnName}
                                disabled={!businessUnits.length || loadingFoldersForCopy}
                                forwardRef={this.refBtn}
                              />
                            </div>
                          </div>
                        </fieldset>)}
                            </div>
                          )}

                        {copyTargetDE.CustomerKey && !isWaterfall ?
                          (
                        <>
                          <div className="selection-modal target-DE">
                            <span
                              className="slds-form-element__label slds-m-bottom_none checkbox-label"
                            >
                              Copy Target Data Extension
                            </span>
                            <ToggleButton
                              id="targetDE-copy-checkbox"
                              name="select-copy-selection-toggle"
                              onChange={() => {
                                this.setState({
                                  selectCopyTargetDE: !selectCopyTargetDE,
                                });
                              }}
                              checked={selectCopyTargetDE}
                              disabled={copyToOtherBU}
                            />
                          </div>
                          <div
                            className="location-wrapper-section"
                            style={{ color: !selectCopyTargetDE && '#dddbda' }}
                          >
                            <NewAutoCreatedTargetDE
                              isCopySelectionModal
                              isChangeLocation
                              newTargetDataExtension={copyTargetDE}
                              selectCopyTargetDE={selectCopyTargetDE}
                              handleSetNewCopyState={this.handleSetNewCopyState}
                              targetDataExtensions={targetDataExtensions}
                              loadingFoldersForCopy={loadingFoldersForCopy}
                              copyToOtherBU={copyToOtherBU}
                              selectedBusinessUnit={selectedBusinessUnitId}
                              showErrorMessage={this.showErrorMessage}
                              refBtn={this.refBtn}
                            />
                          </div>
                        </>
                          ) :
                          null }

                    {!isWaterfall && !copyTargetDE.CustomerKey ?
                      (
                        <>
                          <div className="selection-modal target-DE">

                            <span
                              className="slds-form-element__label
                                  slds-m-bottom_none checkbox-label disabled-text"
                            >
                              Copy Target Data Extension
                            </span>
                            <ToggleButton
                              id="targetDE-copy-checkbox"
                              disabled
                              checkboxOffTitle="Disabled"
                              checked={false}
                              name="select-copy-selection-toggle"
                            />
                          </div>
                          <p className="no-target-DE-text">
                            There is no target data extension in this selection.
                            The selection will be copied without a target data extension.
                          </p>
                        </>
                      ) :
                      null}
                  </div>
                </>
              ) :
              (
                <div className="demo-only demo--inverse" style={{ height: '6rem' }}>
                  <Spinner size={Constants.SPINNER__SIZE__MEDIUM} />
                </div>
              )}
          </ModalTemplate>
        ) :
        (
          null
        )
    );
  }
}
CopySelectionModal.propTypes = {
  /**
   * Object with a selected Selection
   */
  selection: PropTypes.oneOfType([PropTypes.object]).isRequired,
  /**
   * It copies the Selection
   */
  handleCopySelection: PropTypes.func.isRequired,
  /**
   * It closes the CopySelectionModal
   */
  handleCloseCopySelectionModal: PropTypes.func.isRequired,
  /**
   * Defines whether the modal is hidden while waiting for the request to be executed
   */
  hideCopySelectionModal: PropTypes.bool.isRequired,
  /**
   * Defines whether the Waterfall Selections section is selected in the Overview
   */
  isWaterfall: PropTypes.bool,
  /**
   * Finds duplicate Selection by its name
   */
  findDuplicateSelection: PropTypes.func,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func.isRequired,
};

export default CopySelectionModal;

/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import _ from 'lodash';

import SelectionsAPI from '../../../api/selections';
import { setErrors } from './errorActions';
import {
  LOADING_SELECTIONS, REMOVE_SELECTED_SELECTION,
  SET_ALL_SELECTIONS,
  SET_SELECTION_CHAIN,
  SET_SELECTED_SELECTIONS, SET_SELECTED_SELECTION_DATA,
  SET_SELECTIONS_FOR_RUN_CHAIN,
  START_LOADING_SELECTION_ID, STOP_LOADING_SELECTION_ID,
  SET_SELECTIONS_WITH_MISSING_TARGET_DE,
  SET_SELECTIONS_WITH_NO_MATCHED_FIELD,
  SET_SELECTIONS_WITHOUT_MAPPED_REQUIRED_FIELDS,
  REMOVE_SELECTED_SELECTIONS_BY_SELECTION_ID,
  SET_SCHEDULED_RUN_STATE,
  SET_WATERFALL_SCHEDULES,
  SET_WATERFALL_SCHEDULE,
} from '../types';
// eslint-disable-next-line import/no-cycle
import {
  getTargetDataExtension, removeTargetDataExtensions, startLoadingTargetDEObjectID,
}
  from './targetDataExtensionActions';
import waterfallSelectionUtil from '../../../utils/waterfallSelection/waterfallSelectionUtil';
import Util from '../../../util';
import SwalUtil from '../../../utils/swal/swalUtil';
import Constants from '../../../constants/constants';
// eslint-disable-next-line import/no-cycle
import { setPageItems } from './globalActions';

/**
 * Function that sets all selections loading status
 * @param {boolean} isLoading - true/false depending of loading status
 * @returns {void}
 */
export const loadingSelections = isLoading => (dispatch) => {
  dispatch({
    type: LOADING_SELECTIONS,
    payload: isLoading,
  });
};

/**
 * Function that sets selections with missing targetDE
 * @param {array} selectionsIds - array with selectionsId
 * @returns {void}
 */
export const setSelectionsWithMissingTargetDE = selectionsIds => (dispatch) => {
  dispatch({
    type: SET_SELECTIONS_WITH_MISSING_TARGET_DE,
    payload: selectionsIds,
  });
};

/**
 * Function that sets selections that have no mapped fields with all required fields in target data extension
 * @param {array} selectionsIds - array with selectionsId
 * @returns {void}
 */
export const setSelectionsWithoutMappedRequiredFields = selectionsIds => (dispatch) => {
  dispatch({
    type: SET_SELECTIONS_WITHOUT_MAPPED_REQUIRED_FIELDS,
    payload: selectionsIds,
  });
};

/**
 * Function that sets selections with no matched field
 * @param {array} selectionsIds - array with selectionsId
 * @returns {void}
 */
export const setSelectionsWithNoMatchedField = selectionsIds => (dispatch) => {
  dispatch({
    type: SET_SELECTIONS_WITH_NO_MATCHED_FIELD,
    payload: selectionsIds,
  });
};

/**
 * Function that gets and sets all existing selections
 * @param {object} cancelToken - The cancel token from Axios
 * @param {string} folderId - selected folder Id
 * @param {number} pageIdx - pagination index
 * @param {string} searchValue - value from the search input
 * @param {object} query - query for an api call
 * @returns {void}
 */
export const setAllSelections = (cancelToken, folderId, pageIdx, searchValue, query) => async (dispatch) => {
  try {
    let apiQuery = query;

    const folderID = folderId || folderId === null || folderId === '' ? folderId : null;

    if (!apiQuery) {
      // build query for an api call
      apiQuery = Util.queryForSelections({
        page: pageIdx || 1,
        folderId: folderID,
        value: searchValue || '',
        criteria: Constants.SELECTION__SEARCH_CRITERIA__NAME,
        sortBy: Constants.SELECTION__SEARCH_CRITERIA__UPDATED_AT,
        sortOrder: -1,
        limit: null,
        hasEnabledSchedule: null,
      });
    }

    // get all existing selections
    const { selections, totalPages } = await SelectionsAPI.getSelections(cancelToken, apiQuery);

    // set all selections in the redux store
    dispatch({
      type: SET_ALL_SELECTIONS,
      payload: selections,
    });

    // set the number of pagination buttons
    dispatch(setPageItems(totalPages));

    // stop loading selections
    dispatch(loadingSelections(false));
  } catch (err) {
    dispatch(loadingSelections(false));
    if (!axios.isCancel(err)) dispatch(setErrors(err));
  }
};

/**
 * Function that sets selected selections
 * @param {array} selectedSelections - array with selected selections
 * @returns {void}
 */
export const setSelectedSelections = selectedSelections => (dispatch) => {
  dispatch({
    type: SET_SELECTED_SELECTIONS,
    payload: selectedSelections,
  });
};

/**
 * function that reset scheduledRun to default values
 * @returns {void}
 */
export const resetScheduledRun = () => {
  return (dispatch) => {
    const defaultScheduledRun = {
      enabled: false,
      mode: Constants.SCHEDULE_SELECTION__MODE__ONCE,
      runOnce: {
        dateValue: null,
        timeValue: null,
      },
      runRepeat: {
        repeatMode: null,
        minutesValue: null,
        hoursValue: null,
        daysOfWeek: [],
        daysValue: null,
        nextScheduledRunDate: null,
        monthsValue: null,
      },
      timezone: null,
      UTCOffset: null,
    };

    // Reset scheduledRun
    dispatch({
      type: SET_SCHEDULED_RUN_STATE,
      payload: defaultScheduledRun,
    });
  };
};

/**
 * Function that removes selection from selectedSelection array
 * @param {object} removedSelection - selection to be removed
 * @param {array} selectionsUsingTheSameTargetDE - array with selected selections which use the same TargetDE
 * as the selection to be removed
 * @param {boolean} targetDEIsUsedInChain - defines whether targetDE is used in the chain
 * @returns {void}
 */
export const removeSelectedSelection = (
  removedSelection,
  selectionsUsingTheSameTargetDE,
  targetDEIsUsedInChain,
) => async (dispatch) => {
  // Swal alert will appear to confirm or cancel deletion
  await SwalUtil.fire({
    title: 'Remove Selection',
    message: 'Are you sure you want to remove this selection?',
    options: {
      showCancelButton: true,
      confirmButtonText: 'Remove',
    },
  }).then((result) => {
    // If remove button is clicked then remove it
    if (result.value) {
      /*
       * if only one selection use the same TargetDE as removed one,
       * then remove it from the array with targetDataExtensions
       */
      if (selectionsUsingTheSameTargetDE?.length < 2 && !targetDEIsUsedInChain) {
        dispatch(removeTargetDataExtensions(removedSelection.targetCollectionObjectID));
      }

      dispatch({
        type: REMOVE_SELECTED_SELECTION,
        payload: removedSelection,
      });
    }
  });
};

/**
 * Function that adds array of loading selections IDs to state if it starts loading
 * @param {array} selectionIds -  array with IDs of loading selections
 * @returns {void}
 */
export const startLoadingSelectionId = selectionIds => (dispatch) => {
  dispatch({
    type: START_LOADING_SELECTION_ID,
    payload: selectionIds,
  });
};

/**
 * Function that removes IDs of selections from an array if it stops loading
 * @param {array} selectionIds - array with IDs of loading selections
 * @returns {void}
 */
export const stopLoadingSelectionId = selectionIds => (dispatch) => {
  dispatch({
    type: STOP_LOADING_SELECTION_ID,
    payload: selectionIds,
  });
};

/**
 * Function that saves only necessary data for selected selections
 * @param {array} selections - array with selections data
 * @returns {void}
 */
export const setSelectedSelectionData = selections => (dispatch) => {
  // set selected selection data in the redux store
  dispatch({
    type: SET_SELECTED_SELECTION_DATA,
    payload: selections,
  });
};

/**
 * Function that handles showing notification and removing selections when targetDE used in the selection does not exist
 * @param {array} missingObjectIDs - array with object IDs for targetDEs that don't exist
 * @param {array} selectedSelections - array with selected selections
 * @param {boolean} withoutSwal - indicates that the swal with the message is not to be shown
 * @returns {void}
 */
export const handleMissingTargetDataExtension = (
  missingObjectIDs,
  selectedSelections,
  withoutSwal,
) => async (dispatch) => {
  // get selected selections that use this targetDE
  const selectionsToRemove = [];

  missingObjectIDs.forEach((objectID) => {
    // for each missing Object ID get array with selections using it as targetCollectionObjectID
    const selectionsForObjectID = selectedSelections.filter(
      selection => selection.targetCollectionObjectID ===
      objectID,
    );

    // push into array
    selectionsToRemove.push(selectionsForObjectID);
  });

  // name of removed selections - without duplicate in flatten array
  const nameOfRemovedSelections = _.uniq(_.flatten(selectionsToRemove).map(selection => ' ' + selection.name));

  /*
   * define array with selectionIds (unique, not duplicated values) to remove and
   * dispatch action to set it in redux state
   */
  const selectionIdsToRemove = _.uniq(_.flatten(selectionsToRemove).map(selection => selection.selectionId));

  dispatch(setSelectionsWithMissingTargetDE(selectionIdsToRemove));

  if (!withoutSwal) {
    // show message that this targetDE does not exists for selected selections
    await SwalUtil.fire({
      message: `Target Data Extension used in selected selections:
      <span class="bold_swal">${nameOfRemovedSelections.map(name => name)} </span>
      is not available anymore and it will be removed.`,
      type: Constants.SWAL__TYPE__ERROR,
      options: {
        confirmButtonText: 'OK',
        allowOutsideClick: false,
      },
    }).then((result) => {
      if (result.value) {
      // remove selected selections
        dispatch({
          type: REMOVE_SELECTED_SELECTIONS_BY_SELECTION_ID,
          payload: selectionIdsToRemove,
        });

        // stop loading this selection from selectionIds
        dispatch(stopLoadingSelectionId(selectionIdsToRemove));
      }
    });
  }

  return selectionIdsToRemove;
};

/**
 * Function that handles showing notification and removing selections that do not have at least one field matched
 * with target data extension
 * @param {array} selectionWithoutMatchedField - array with selections without matched field
 * @param {array} selectionsIdsWithMissingTargetDE - array with selection IDs for which targetDE was not found
 * @param {boolean} withoutSwal - indicates whether swal message is to be shown
 * @param {array} targetDEObjectIDsToRemove - array with object IDs of targetDE to be removed
 * @returns {void}
 */
export const handleMissingMatchedField = (
  selectionWithoutMatchedField,
  selectionsIdsWithMissingTargetDE,
  withoutSwal,
  targetDEObjectIDsToRemove,
) => async (dispatch) => {
  // filter selections without existing fields
  const existingSelectionsWithoutMatchedField = selectionWithoutMatchedField.filter((selection) => {
    // get already removed selections, for which targetDE was not found
    const removedSelection = selectionsIdsWithMissingTargetDE.find(id => id === selection._id);

    // once a selection has been removed, don't return it
    if (!removedSelection) { return selection; }

    return null;
  });

  if (existingSelectionsWithoutMatchedField?.length) {
    // create array with selection IDs and selection (unique, without duplicates)
    const selectionIdsToRemove = _.uniq(existingSelectionsWithoutMatchedField.map(selection => selection._id));
    const selectionsWithoutMatchedFieldWithoutDuplicates = Util
      .removeDuplicatesFromArray(existingSelectionsWithoutMatchedField);

    if (!withoutSwal) {
      // show swal message for all selections without matched field
      await SwalUtil.fire({
        message: `Selected selection:
        <span class="bold_swal">${selectionsWithoutMatchedFieldWithoutDuplicates.map(selection => ' ' +
        selection.name)} </span>
        does not have at least one matched field with target data extension and it will be removed.`,
        type: Constants.SWAL__TYPE__ERROR,
        options: {
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        },
      }).then((result) => {
        if (result.value) {
          // remove selected selections
          dispatch({
            type: REMOVE_SELECTED_SELECTIONS_BY_SELECTION_ID,
            payload: selectionIdsToRemove,
          });

          if (targetDEObjectIDsToRemove?.length) {
            // remove Target Data Extension
            targetDEObjectIDsToRemove.forEach((objectID) => {
              dispatch(removeTargetDataExtensions(objectID));
            });
          }
        }
      });
    }

    // save selection id in redux state
    dispatch(setSelectionsWithNoMatchedField(selectionIdsToRemove));
  }
};

/**
 * Function that handles showing notification and removing selections that do not have all required fields mapped
 * with target data extension
 * @param {array} selectedSelections - array with selected selections
 * @param {array} targetDataExtensions - array with target data extensions
 * @param {array} runStatusForSelectionChain - array with selections for run chain
 * @param {boolean} withoutSwal - indicates that the swal with the message is not to be shown
 * @returns {object} Swal object with message
 */
export const handleUnmappedRequiredFields = (
  selectedSelections,
  targetDataExtensions,
  runStatusForSelectionChain,
  withoutSwal,
) => async (dispatch) => {
  // check if all require fields are matched in targetDE
  const selectionWithoutMatchedRequiredField = selectedSelections.reduce((array, selection) => {
    const { matchedFields } = selection;

    // get targetDE used in selection
    const targetDE = targetDataExtensions.find(tde => tde.ObjectID === selection.targetCollectionObjectID);

    // get all required fields from targetDE
    const requiredFields = targetDE?.fields.filter(field => field.IsRequired);

    if (requiredFields?.length && matchedFields?.length) {
      // find required field that is not mapped by ObjectID
      const requiredUnmappedField = requiredFields.find((field) => {
        const getMatchedFieldByObjectID = matchedFields.find(matched => matched.targetDataExtensionFieldObjectID ===
              field.ObjectID);

        if (!getMatchedFieldByObjectID) {
          // check by field name
          const getMatchedFieldByName = matchedFields.find(matched => matched?.field?.toString() ===
            field?.Name?.toString());

          // if field does not exists, then return it
          if (!getMatchedFieldByName) {
            return field;
          }

          return null;
        }

        return null;
      });

      // if required unmapped field is found, add it to an array
      if (requiredUnmappedField) { return [...array, selection]; }

      return array;
    }

    return array;
  }, []);

  if (selectionWithoutMatchedRequiredField?.length) {
    // get targetDE ObjectIDs to be removed
    const targetDEObjectIDsToRemoved = waterfallSelectionUtil.targetDataExtensionsToRemoved(
      selectionWithoutMatchedRequiredField,
      selectedSelections,
      runStatusForSelectionChain,
    );

    // create array with selection IDs and selection (unique, without duplicates)
    const selectionsId = _.uniq(selectionWithoutMatchedRequiredField.map(selection => selection.selectionId));
    const selectionWithoutMatchedRequiredFieldWithoutDuplicates = Util
      .removeDuplicatesFromArray(selectionWithoutMatchedRequiredField);

    if (!withoutSwal) {
      //  show message that this selection does not have all required fields mapped
      await SwalUtil.fire({
        // eslint-disable-next-line max-len
        message: `Selection: <span class="bold_swal">${selectionWithoutMatchedRequiredFieldWithoutDuplicates.map(selection => ' ' + selection.name)} </span> does not have all required fields mapped in target data extension and it will be removed.`,
        type: Constants.SWAL__TYPE__ERROR,
        options: {
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        },
      }).then((result) => {
        if (result.value) {
          // remove selected selection by selectionId
          dispatch({
            type: REMOVE_SELECTED_SELECTIONS_BY_SELECTION_ID,
            payload: selectionsId,
          });

          if (targetDEObjectIDsToRemoved?.length) {
            // loop through the array and remove targetDE
            targetDEObjectIDsToRemoved.forEach((targetDE) => {
              dispatch(removeTargetDataExtensions(targetDE));
            });
          }
        }
      });
    }

    // set selections without mapped required fields
    dispatch(setSelectionsWithoutMappedRequiredFields(selectionsId));
  }
};

/**
 * Function that fetch single selection by Id
 * @param {string} selectionId - selection Id
 * @param {object} cancelToken - The cancel token from Axios
 * @returns {void}
 */
// eslint-disable-next-line consistent-return
export const getSelectionById = (selectionId, cancelToken) => async (dispatch) => {
  try {
    // get selection by Id
    const selection = await SelectionsAPI.getSelection(
      selectionId,
      cancelToken,
    );

    // check if at least 1 field is matched with targetDE
    let unionWithNoMatchedField;

    if (selection?.unionSelections?.length) {
      unionWithNoMatchedField = selection.unionSelections.find((union) => {
        if (!union?.fields?.length) return union;

        return null;
      });
    }

    // stop loading selection
    dispatch(stopLoadingSelectionId([selectionId]));

    if (unionWithNoMatchedField) {
      return { selectionWithoutMatchedField: selection };
    }

    // set proper and required data in selected selections state
    dispatch(setSelectedSelectionData([selection]));

    // Get the selections schedules from the backend
    // eslint-disable-next-line no-use-before-define
    await dispatch(getSelectionSchedules(selectionId, cancelToken));

    return { selection };
  } catch (err) {
    dispatch(stopLoadingSelectionId([selectionId]));
    if (!axios.isCancel(err)) dispatch(setErrors(err));
  }
};

/**
 * Function that fetch selections by its Id
 * @param {array} selectionIds - array with selection ids
 * @param {object} cancelToken - The cancel token from Axios
 * @param {boolean} onlyReturnData - defines whether the data should only be returned without dispatch an action
 * to change selections state
 * @returns {object} object with selections
 */
// eslint-disable-next-line consistent-return
export const getMultipleSelectionsById = (selectionIds, cancelToken, onlyReturnData) => async (dispatch) => {
  try {
    // get selection by Id
    const selections = await SelectionsAPI.getMultipleSelections(
      selectionIds,
      cancelToken,
    );

    let selectionWithoutMatchedField = [];

    if (selections?.length) {
      // check if at least 1 field is matched with targetDE
      selectionWithoutMatchedField = selections.filter((selection) => {
        if (selection?.unionSelections?.length) {
          // take the matched fields from union selection
          const unionWithoutMatchedField = selection.unionSelections.find(union => !union?.fields?.length);

          // return if there are no fields
          if (unionWithoutMatchedField) { return selection; }
        }

        return null;
      });
    }

    if (!onlyReturnData) {
      // set proper and required data in selected selections state
      dispatch(setSelectedSelectionData(selections));
    }

    if (selectionWithoutMatchedField?.length) {
      // return selections without matched field if found
      return { selectionWithoutMatchedField, selections };
    }

    return { selections };
  } catch (err) {
    dispatch(stopLoadingSelectionId(selectionIds));
    if (!axios.isCancel(err)) dispatch(setErrors(err));
  }
};

/**
 * Get selections' waterfall schedules by their IDs
 * @param {array} targetDEObjectIDs - array with TDEs Object IDs
 * @param {object} cancelToken - Axios token
 * @returns {object} object with fetched selections' schedules
 */
export const getSelectionsSchedules = (targetDEObjectIDs, cancelToken) => async (dispatch) => {
  try {
    // get selection by Id
    const selectionsSchedules = await SelectionsAPI.getSelectionsSchedules(
      targetDEObjectIDs,
      cancelToken,
    );

    return dispatch({
      type: SET_WATERFALL_SCHEDULES,
      payload: selectionsSchedules,
    });
  } catch (err) {
    if (!axios.isCancel(err)) dispatch(setErrors(err));
  }
};

/**
 * Get a selection's waterfall schedules
 * @param {string} selectionId - id of the selection
 * @param {object} cancelToken - Axios token
 * @returns {object} object with fetched selection's schedules
 */
export const getSelectionSchedules = (selectionId, cancelToken) => async (dispatch) => {
  try {
    // get selection by Id
    const selectionWaterfallSchedules = await SelectionsAPI.getSelectionWaterfallSchedules(
      selectionId,
      cancelToken,
    );

    dispatch({
      type: SET_WATERFALL_SCHEDULE,
      payload: { selectionId, selectionWaterfallSchedules },
    });
  } catch (err) {
    if (!axios.isCancel(err)) dispatch(setErrors(err));
  }
};

/**
 * Function that sets selections for run chain
 * @param {array} selectionsToChain - array with selections data for chain
 * @returns {void}
 */
export const setSelectionsForRunChain = selectionsToChain => (dispatch) => {
  dispatch({
    type: SET_SELECTIONS_FOR_RUN_CHAIN,
    payload: {
      selectionsToChain,
    },
  });
};

/**
 * Function that sets selection chain in redux state
 * @param {array|null} selectionChain - array with selection chain (if exists)
 * @returns {void}
 */
export const setSelectionChain = selectionChain => (dispatch) => {
  dispatch({
    type: SET_SELECTION_CHAIN,
    payload: {
      selectionChain,
    },
  });
};

/**
 * Function that changes the order in selected selections and saves new data in state
 * @param {object} source - source from which dragging begins
 * @param {object} destination - the direction to which the element is dragged
 * @param {array} selectedSelections - array with selected selections
 * @returns {void}
 */
export const reorderSelectedSelections = (source, destination, selectedSelections) => (dispatch) => {
  // get array with reordered selections
  const reorderedSelections = waterfallSelectionUtil.reorder(selectedSelections, source.index, destination.index);

  // save new array in redux state
  dispatch(setSelectedSelections(reorderedSelections));
};

/**
 * Function that gets and sets the data after dropping the selection in Selection Container
 * @param {object} source - source from which dragging begins
 * @param {object} destination - the direction to which the element is dragged
 * @param {object} inputData - an object with data taken from the current state
 * @returns {void}
 */
export const setDataAfterDroppingSelection = (source, destination, inputData) => async (dispatch) => {
  // destructuring input data
  const {
    selectedSelections,
    loadingSelectionIds,
    axiosCancelToken,
    targetDataExtensions,
    loadingTargetDEObjectIDs,
    runStatusForSelectionChain,
    allSelections,
  } = inputData;

  // create copy of selected selections
  const selections = [...selectedSelections];

  // get dragged item data
  const draggedItem = allSelections[source.index];

  // check if this selection exists in selected selections
  const draggedItemInSelectedSelection = selectedSelections.find(selection => (
    selection.selectionId === draggedItem._id));

  // if this selection has been chosen once
  if (draggedItemInSelectedSelection) {
    // show the message stating that this selection cannot be selected a second time
    await SwalUtil.fire({
      message: 'This selection has already been chosen. Please select a different selection.',
      type: Constants.SWAL__TYPE__ERROR,
      options: {
        confirmButtonText: 'OK',
        allowOutsideClick: false,
      },
    });

    return;
  }

  // copy selections on drop
  const copiedSelections = waterfallSelectionUtil.copy(allSelections, selections, source, destination);

  // dispatch action to set selectedSelections in redux state
  dispatch(setSelectedSelections(copiedSelections));

  if (selections[destination.index]) {
    // dragged selection property
    const selection = selections[destination.index];

    // defines variables for fetching selection and targetDE
    let findObjectIDInTargetDataExtensions;

    let findObjectIDInLoadingArray;

    let findSelectionIdInLoadingArray;

    let findSelectionIdInSelectedSelections;

    let selectionPromise;

    let targetDEPromise;

    // if selection is defined
    if (selection) {
      if (selections?.length) {
        // check if selection has been fetched
        findSelectionIdInSelectedSelections = selections.find(select => select.selectionId ===
          selection.selectionId && select.selectedDEObjectIDs);
      }

      if (loadingSelectionIds?.length) {
        // check if this selection is loading
        findSelectionIdInLoadingArray = loadingSelectionIds.find(id => id === selection.selectionId);
      }

      // fetch selection data if it's not fetched and it's not loading
      if (!findSelectionIdInLoadingArray && !findSelectionIdInSelectedSelections) {
        dispatch(startLoadingSelectionId([selection.selectionId]));
        selectionPromise = new Promise((resolve) => {
          resolve(dispatch(getSelectionById(selection.selectionId, axiosCancelToken.token)));
        });
      } else if (!findSelectionIdInLoadingArray && findSelectionIdInSelectedSelections) {
        // if this selection has already been fetched, set the appropriate properties from the retrieved data
        dispatch(setSelectedSelectionData([selection]));
      }
    }

    if (selection?.targetCollectionObjectID) {
      if (targetDataExtensions?.length) {
        // check if targetDE has been fetched
        findObjectIDInTargetDataExtensions = targetDataExtensions.find(
          targetDE => targetDE.ObjectID === selection.targetCollectionObjectID,
        );
      }

      if (loadingTargetDEObjectIDs?.length) {
        // check if this targetDE is loading
        findObjectIDInLoadingArray = loadingTargetDEObjectIDs.find(ck => ck ===
                selection.targetCollectionObjectID);
      }

      // if targetDE is not fetched and it's not loading
      if (!findObjectIDInTargetDataExtensions && !findObjectIDInLoadingArray) {
        // dispatch actions to set loading indicator and fetch Target Data Extension
        dispatch(startLoadingTargetDEObjectID([selection.targetCollectionObjectID]));
        targetDEPromise = new Promise((resolve) => {
          resolve(dispatch(getTargetDataExtension(selection, axiosCancelToken.token)));
        });
      }
    }

    // get fetched data if all the promises get resolved
    const fetchedData = await Promise.allSettled([selectionPromise, targetDEPromise]);

    if (fetchedData?.length) {
      let removedSelection = false;
      // if one of target DE does not exists or there is no matched field with targetDE

      if (fetchedData[1]?.value?.targetDataExtension?.length === 0) {
        removedSelection = true;
      }

      // if there is a selection which does not have a matched field
      if (fetchedData[0]?.value?.selectionWithoutMatchedField && !removedSelection) {
        const { selectionWithoutMatchedField } = fetchedData[0].value;

        // get selection objects from selections chain in run detail
        const selectionsInRunChain = waterfallSelectionUtil.getSelectionsData(
          runStatusForSelectionChain,
          allSelections,
        );

        // check if targetDE is used in other selected selections - selected and in run chain
        const selectionsUsingTheSameTargetDE = selectedSelections.filter(
          select => select.targetCollectionObjectID ===
                selectionWithoutMatchedField.targetCollectionObjectID,
        );

        // check if targetDE is used in other selections in run chain
        const removedSelectionInRunChain = selectionsInRunChain?.length ?
          selectionsInRunChain.find(selectionObj => selectionObj.targetCollectionObjectID ===
                  selectionWithoutMatchedField.targetCollectionObjectID) :
          null;

        // show message that this selection does not have at least 1 matched field
        await SwalUtil.fire({
          // eslint-disable-next-line max-len
          message: 'Selection does not have at least one matched field with target data extension. Please choose a different selection.',
          type: Constants.SWAL__TYPE__ERROR,
          options: {
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          },
        }).then((res) => {
          if (res.value) {
            // remove selected selection by selectionId
            dispatch({
              type: REMOVE_SELECTED_SELECTIONS_BY_SELECTION_ID,
              payload: [selectionWithoutMatchedField._id],
            });

            // remove targetDE if it's used only for this selection
            if (selectionsUsingTheSameTargetDE?.length < 2 && !removedSelectionInRunChain) {
              dispatch(removeTargetDataExtensions(selectionWithoutMatchedField.targetCollectionObjectID));
            }
          }
        });
      }
    }
  }
};

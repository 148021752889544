import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Swal from 'sweetalert2';
import classNames from 'classnames';

import './styles.scss';

import DeedeeAIAPI from '../../../api/deedeeAI';
import AdminHeader from '../../shared/AdminHeader/AdminHeader';
import Util from '../../../util';
import SelectScopeController from '../../DeedeeAI/SelectScopeController/SelectScopeController';
import ScopedDataExtensions from '../../DeedeeAI/ScopedDataExtensions/ScopedDataExtensions';
import Button from '../../shared/Button/Button';
import Constants from '../../../constants/constants';
import Alert from '../../shared/Alert/Alert';

/**
 * DeedeeAIExtensions component.
 *
 * @returns {JSX.Element} DeedeeAIExtensions component.
 */
function DeedeeAIExtensions({ handleSetAppState }) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [scopeDEs, setScopeDEs] = useState([]);
  const [scopeDataExtensionsToAdd, setScopeDataExtensionsToAdd] = useState([]);
  const [initialScopeDEs, setInitialScopeDEs] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const loggedInUser = Util.userInfo();

  const axiosCancelToken = axios.CancelToken.source();

  /**
   * Add or remove a data extension from the selected scope.
   *
   * @param {Object} de - The data extension to add or remove.
   * @returns {void}
   */
  const handleAddScopeDE = (de) => {
    if (scopeDEs.length >= Constants.SCOPE_DES_LIMIT) {
      Swal.fire({
        icon: 'warning',
        title: 'Scope Limit Reached',
        html: `<p class="width_swal scope-de-width_swal">
        You cannot add more than ${Constants.SCOPE_DES_LIMIT} scope data extensions.</p>`,
        showCancelButton: false,
        confirmButtonText: 'OK',
        buttonsStyling: false,
        footer: '<div></div>',
        allowOutsideClick: false,
      });

      return;
    }
    if (scopeDataExtensionsToAdd.some(ext => ext.ObjectID === de.ObjectID)) {
      setScopeDataExtensionsToAdd(prevDEs => prevDEs.filter(ext => ext.ObjectID !== de.ObjectID));
    } else {
      setScopeDataExtensionsToAdd(prevDEs => [...prevDEs, de]);
    }
    setHasChanges(true);
  };

  /**
   * Edit an existing data extension.
   *
   * @param {string} deObjectID - The identifier for the data extension.
   * @param {string} alias - The new alias for the data extension.
   * @param {string} description - The new description for the data extension.
   * @returns {void}
   */
  const handleEditDE = (deObjectID, alias, description) => {
    setScopeDEs(prevDEs => prevDEs.map(de => de.deObjectID === deObjectID ? { ...de, alias, description } : de));
    setHasChanges(true);
  };

  /**
   * Delete an existing data extension.
   *
   * @param {string} deObjectID - The identifier for the data extension.
   * @returns {void}
   */
  const handleDeleteDE = (deObjectID) => {
    setScopeDEs(prevDEs => prevDEs.filter(de => de.deObjectID !== deObjectID));
    setHasChanges(true);
  };

  /**
   * Add a new data extension.
   *
   * @param {Object} newDE - The new data extension to add.
   * @returns {void}
   */
  const handleAddDE = (newDE) => {
    if (!scopeDEs.some(de => de.deObjectID === newDE.deObjectID)) {
      setScopeDEs(prevScopeDEs => [...prevScopeDEs, newDE]);
      setHasChanges(true);
    }
  };

  /**
   * Save selected scope data extensions.
   * Add unique data extensions from scopeDataExtensionsToAdd to scopeDEs.
   * @returns {void}
   */
  const handleSaveSelectedScopeDEs = () => {
    const uniqueScopeDEsToAdd = scopeDataExtensionsToAdd
      .filter(
        deToAdd => !scopeDEs.some(de => de.deObjectID === deToAdd.ObjectID),
      )
      .map(deToAdd => ({
        businessUnitId: loggedInUser.loggedInBusinessUnitId,
        deObjectID: deToAdd.ObjectID,
        customerKey: deToAdd.CustomerKey,
        name: deToAdd.Name,
        alias: deToAdd.Name,
        description: '',
        orgId: loggedInUser.orgId,
      }));

    const spaceRemaining = Constants.SCOPE_DES_LIMIT - scopeDEs.length;
    const desToAdd = uniqueScopeDEsToAdd.slice(0, spaceRemaining);
    const remainingDEs = uniqueScopeDEsToAdd.slice(spaceRemaining);

    if (remainingDEs.length > 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Scope Limit Reached',
        html: `<p class="width_swal scope-de-width_swal">
        You cannot add more than ${Constants.SCOPE_DES_LIMIT} scope data extensions.
        ${remainingDEs.length} of your selected data extensions were not added.</p>`,
        showCancelButton: false,
        confirmButtonText: 'OK',
        buttonsStyling: false,
        footer: '<div></div>',
        allowOutsideClick: false,
      });
    }

    setScopeDEs(prevScopeDEs => [...prevScopeDEs, ...desToAdd]);
    setScopeDataExtensionsToAdd(remainingDEs); // Keep the extensions that weren't added because of the limit
    setHasChanges(true);
  };

  /**
   * Show a Swal message.
   *
   * @param {string} type - Type of Swal (e.g., 'error', 'success').
   * @param {string} title - Title of Swal.
   * @param {string} message - Message of Swal.
   * @returns {Promise} A promise resolved when Swal is closed.
   */
  const showSwal = (type, title, message) => {
    return Swal.fire({
      icon: type,
      title: `<div class="${classNames({
        'error-title': type === Constants.SWAL__TYPE__ERROR,
      })}">${title}</div>`,
      html: `<p class="width_swal scope-de-width_swal">${message}</p>`,
      showCancelButton: true,
      confirmButtonText: 'Save',
      cancelButtonText: 'Cancel',
      buttonsStyling: false,
      footer: '<div></div>',
      allowOutsideClick: false,
    });
  };

  /**
   * Update scoped data extensions.
   * New scoped data extensions are added and existing scoped data extensions are updated.
   * @returns {void}
   */
  const handleUpdateScopeDEs = async () => {
    const missingFields = [];

    scopeDEs.forEach((de, index) => {
      if (!de.alias) {
        missingFields.push({ index, field: 'Alias', name: de.name });
      }
      if (!de.description) {
        missingFields.push({ index, field: 'Content', name: de.name });
      }
    });

    if (missingFields.length > 0) {
      let errorMessage =
        'The following fields require to be filled:<ul class="scope-missing-fields-li"><br/>';

      missingFields.forEach((missingField) => {
        errorMessage += `<li>
        - <strong>${missingField.name}</strong> is missing <i>${missingField.field}</i></li>`;
      });

      errorMessage += '</ul>';

      Swal.fire({
        icon: 'error',
        type: Constants.SWAL__TYPE__WARNING,
        title: 'Missing Fields',
        html: `<div class="scope-width_swal scope-missing-fields-width_swal">
        ${errorMessage}</div>`,
        showCancelButton: false,
        confirmButtonText: 'OK',
        buttonsStyling: false,
        footer: '<div></div>',
        allowOutsideClick: false,
      });

      return;
    }

    if (
      !Array.isArray(scopeDEs) ||
      scopeDEs.length > Constants.SCOPE_DES_LIMIT
    ) {
      Swal.fire({
        type: Constants.SWAL__TYPE__WARNING,
        title: 'Scope Limit Reached',
        html: `<p class="width_swal scope-de-width_swal">
        You cannot add more than ${Constants.SCOPE_DES_LIMIT} scope data extensions.</p>`,
        showCancelButton: false,
        confirmButtonText: 'OK',
        buttonsStyling: false,
        footer: '<div></div>',
        allowOutsideClick: false,
      });

      return;
    }

    try {
      setIsLoading(true);

      const addedDEs = scopeDEs.filter(
        de => !initialScopeDEs.some(
          initialDE => initialDE.deObjectID === de.deObjectID,
        ),
      );

      const deletedDEs = initialScopeDEs.filter(
        initialDE => !scopeDEs.some(de => de.deObjectID === initialDE.deObjectID),
      );

      const swalMessage = `
      <div class="swal-changes">
        ${
  addedDEs.length > 0 ?
    '<div class="swal-changes__title">Add to Deedee AI scope:</div>' :
    ''
}
        <ul class="swal-changes__list">${addedDEs
    .map(de => `<li>${de.name}</li>`)
    .join('')}</ul>
        <br />
        ${
  deletedDEs.length > 0 ?
    '<div class="swal-changes__title">Remove from Deedee AI scope:</div>' :
    ''
}
        <ul class="swal-changes__list">${deletedDEs
    .map(de => `<li>${de.name}</li>`)
    .join('')}</ul>
      </div>
    `;

      const swalResult = await showSwal(
        Constants.SWAL__TYPE__WARNING,
        'Save Changes',
        addedDEs.length || deletedDEs.length ?
          swalMessage :
          'Are you sure you want to save these changes?',
      );

      if (swalResult.isConfirmed) {
        // Perform the save operation
        const response = await DeedeeAIAPI.updateScopeDataExtensions(
          scopeDEs,
          axiosCancelToken.token,
        );

        handleSetAppState(prevState => ({
          ...prevState,
          deedeeAIScopeDEs: response.data,
        }));

        setScopeDEs(prevDEs => [...prevDEs]);
        setInitialScopeDEs(scopeDEs);
        setScopeDataExtensionsToAdd([]);
        setHasChanges(false);
        setShowAlert(true);

        // Hide the alert after 5 seconds
        setTimeout(() => {
          setShowAlert(false);
        }, 5000);
        setIsLoading(false);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
        Swal.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Error',
          text: error,
        });
        setScopeDEs(initialScopeDEs);
      }
    } finally {
      setIsLoading(false);
      // setIsSaving(false);
    }
  };

  /**
   * Handle the cancel button click.
   * Checks if there are any unsaved changes and displays a confirmation dialog.
   * If confirmed, reset the state and discard the changes.
   * @returns {void}
   */
  const handleCancel = () => {
    if (hasChanges) {
      Swal.fire({
        type: Constants.SWAL__TYPE__WARNING,
        title: 'Unsaved Changes',
        html: '<p class="width_swal">You have unsaved changes. Are you sure you want to cancel?</p>',
        showCancelButton: true,
        confirmButtonText: 'Discard Changes',
        cancelButtonText: 'Cancel',
        buttonsStyling: false,
        footer: '<div></div>',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          // Reset the state and discard the changes
          setScopeDEs(initialScopeDEs);
          setScopeDataExtensionsToAdd([]);
          setHasChanges(false);
        }
      });
    }
  };

  /**
   * Fetches the scope data extensions from the Deedee AI API.
   */
  useEffect(() => {
    const fetchDeedeeAIScopeDataExtensions = async () => {
      try {
        const response = await DeedeeAIAPI.getScopeDataExtensions(
          axiosCancelToken.token,
        );

        setScopeDEs(response.data);
        setInitialScopeDEs(response.data);
      } catch (err) {
        setError(err);
      }
    };

    fetchDeedeeAIScopeDataExtensions();

    // Cleanup function for canceling the axios request
    return () => {
      axiosCancelToken.cancel('Component unmounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <p>
Error:
{' '}
{error.message}
           </p>;
  }

  return (
    <div className="deedee-ai-extensions-container">
      <AdminHeader
        title="DeedeeAI Data Extensions"
        iconLink="/assets/icons/standard-sprite/svg/symbols.svg#user"
        iconTitle="Audit Logs Menu"
      />
      <div className="deedee-ai-data-extensions">
        {showAlert && (
          <Alert
            type={Constants.ALERT__TYPE__SUCCESS}
            id="preview-data-action-info"
            className="preview-data-action-info"
            title="Successfully updated the scope data extensions."
          />
        )}
        <div>
          <SelectScopeController
            axiosCancelToken={axiosCancelToken}
            handleAddScopeDE={handleAddScopeDE}
            scopeDataExtensionsToAdd={scopeDataExtensionsToAdd}
            handleSaveSelectedScopeDEs={handleSaveSelectedScopeDEs}
            scopeDEs={scopeDEs}
            isSavingChanges={isLoading}
          />
          <ScopedDataExtensions
            handleEditDE={handleEditDE}
            handleDeleteDE={handleDeleteDE}
            handleAddDE={handleAddDE}
            scopeDEs={scopeDEs}
          />
        </div>
      </div>
      <div className="deedee-ai-data-extensions-actions">
        <Button
          title={isLoading ? 'Saving...' : 'Save Changes'}
          buttonLook={Constants.BUTTON__TYPE__BRAND}
          onClick={handleUpdateScopeDEs}
          disabled={!hasChanges || isLoading}
          className="save-btn"
        >
          {isLoading ? 'Saving...' : 'Save Changes'}
        </Button>
        {hasChanges && (
          <Button
            title="Cancel"
            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
            onClick={handleCancel}
            className="cancel-btn"
          >
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
}

DeedeeAIExtensions.propTypes = {
  /**
   * handleSetAppState prop is passed from App.js (it is used to be passed to PicklistAE)
   */
  handleSetAppState: PropTypes.func.isRequired,
};

export default DeedeeAIExtensions;

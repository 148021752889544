import React, { Component, useMemo } from 'react';
import PropTypes from 'prop-types';
import './App.css';
import './App.scss';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import Overview from './containers/overview/Overview';
import Selection from './containers/selection/Selection';
import AdminPanel from './containers/adminPanel/AdminPanel';
import RightBar from './components/Rightbar/Rightbar';
import Constants from './constants/constants';
import NetworkErrorModal from './NetworkErrorModal';
import SelectionFoldersApi from './api/selectionsFolders';
import NotificationsAPI from './api/notifications';
import WaterfallSelection from './containers/waterfallSelection/WaterfallSelection';
import store from './redux/store/store';
import theme from './theme/theme';
import socketUtil from './utils/socketIO/socketUtil';
import WarningAlert from './components/shared/WarningAlert/WarningAlert';
import Util from './util';
import UsersAPI from './api/users';
import GuidanceTipsAPI from './api/guidance-tips';
import { GuidanceTipsContext } from './utils/contexts/guidanceTipsContext';
import Zendesk, { ZendeskAPI } from './ZendeskConfig';
import DeedeeAIAPI from './api/deedeeAI';
import Features from './features';

const justLoggedIn = Cookies.get('justLoggedIn');
const latePayment = Cookies.get('latePayment');
const hasReadOnlyAccess = Util.userInfo()?.hasReadOnlyAccess;
// setup cache headers

axios.defaults.headers = {
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  Pragma: 'no-cache',
  Expires: 0,
};

const GuidanceTipsContextWrapper = ({ preferences, guidanceTips, children }) => {
  const contextValue = useMemo(() => ({ preferences, guidanceTips }), [preferences, guidanceTips]);

  return (
    <GuidanceTipsContext.Provider value={contextValue}>
      {children}
    </GuidanceTipsContext.Provider>
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisconnected: false,
      openAidown: false,
      navigator: Constants.NAVIGATION__OVERVIEW,
      globalNavigator: Constants.NAVIGATION__OVERVIEW,
      folderSettings: {
        closed: [],
        open: [],
      },
      selectedSelectionFolderId: null,
      selectedSelectionPaginationIndex: 1,
      selectedWaterfallSelectionFolderId: null,
      selectedWaterfallSelectionPaginationIndex: 1,
      currentSelectionName: '',
      addSelectionToWFSelectedSelections: null,
      backToWaterFall: null,
      preferences: {},
      guidanceTips: [],
      deedeeAIScopeDEs: [],
      deedeeAIRequestFeedback: {
        status: false,
        openAIDescription: '',
        openAISQLQuery: '',
        processingLog: [],
        selectionId: null,
        userPrompt: '',
        feedback: null,
        statusMessage: '',
        errorMessage: '',
      },
      showDeedeeAIFeedbackModal: false,
    };
    this.clearState = {
      unionSelections: [],
      unionSelectionsIndex: 0,
      showErrorModal: false,
      folderId: '',
      folders: [],
      justLoggedIn: false,
      notifications: [],
      error: null,
      eventId: null,
      isSelectionMounted: false,
      overviewSection: Constants.OVERVIEW__SECTION__SELECTIONS,
      loadingFolders: true,
      currentSelectionName: '',
    };

    this.state = {
      ...this.state,
      ...this.clearState,
    };

    this.axiosCancelToken = axios.CancelToken.source();
    socketUtil.socketConnection();
  }

  async componentDidMount() {
    // get the values from theme and create key-value pair for each property
    const values = Object.values(theme);

    const keyValuePairProperties = values.reduce((array, value) => {
      return [...array, ...Object.entries(value)];
    }, []);

    // create variable for each property used in the App
    keyValuePairProperties.forEach(([key, value]) => {
      const cssKey = `--${key}`;
      const cssValue = value;

      document.body.style.setProperty(cssKey, cssValue);
    });

    // Handle connection status
    this.handleConnectionChange();
    /*
     * window.online and window.offline event listener
     * Take proper actions based on the network status
     */
    window.addEventListener(Constants.NETWORK_ONLINE, this.handleConnectionChange);
    window.addEventListener(Constants.NETWORK_OFFLINE, this.handleConnectionChange);

    // Get all folders
    try {
      const folders = await SelectionFoldersApi.getSelectionFolders(this.axiosCancelToken.token);
      const preferences = await this.getUserPreferences();
      const guidanceTips = await this.getGuidanceTips();
      const deedeeAIScopeDEs = await this.getDeedeeAIScopeDataExtensions();

      this.setState({ preferences: preferences || {}, guidanceTips, deedeeAIScopeDEs });

      this.setState({ folders: folders.data });
    } catch (err) {
      this.setState({ error: err });
    }

    // Checks if the user loaded the app for the first time after login
    if (justLoggedIn && justLoggedIn.toString() === 'true') {
      // Saves this state so the app knows the user logged in and we can set it back to false
      this.setState({ justLoggedIn: true });
      // Set the cookie back to false
      Cookies.set('justLoggedIn', false);
      await this.getNotifications();
    }
  }

  /**
   * Updates isDisconnected state based on network status
   * @returns {void} Will setState for isDisconnected
   */
  handleConnectionChange = () => {
    this.setState({ isDisconnected: !navigator.onLine });
  };

  /**
   * General function for setting the app state
   * @param {object} newState - currently passed object
   * @param {function} cb - callback function
   * @returns {void}
   */
  handleSetAppState = (newState, cb) => {
    const { isSelectionMounted } = this.state;

    if (isSelectionMounted || !newState.origin) {
      this.setState(newState, cb);
    }
  };

  /**
   * General function to push new elements into app states. Ex. newly created tabs
   * @param {string} stateName - state's name
   * @param {object} elementToAdd - element to be added
   * @param {function} cb - callback function
   * @returns {void}
   */
  handleAddElementToAppArrayState = (stateName, elementToAdd, cb) => {
    this.setState(prevState => ({ [stateName]: [...prevState[stateName], elementToAdd] }), cb);
  };

  /**
   * Updates user's current position in navigation
   * @param {string} value - navigator status
   * @param {boolean} resetAction - action that defines whether any user location vars should be reset
   * @returns {void} Will setState for navigator and globalNavigator
   */
  handleNavigator = (value, resetAction) => {
    const { overviewSection } = this.state;

    const defaultDeedeeAIRequestPayload = {
      showDeedeeAIFeedbackModal: false,
      deedeeAIRequestFeedback: {
        status: false,
        openAIDescription: '',
        openAISQLQuery: '',
        processingLog: [],
        selectionId: null,
        userPrompt: '',
        feedback: null,
        statusMessage: '',
        errorMessage: '',
      },
    };

    const newState = {
      navigator: value,
      globalNavigator: value,
      ...defaultDeedeeAIRequestPayload,
    };

    switch (resetAction) {
      case Constants.FULL_RESET:
        this.setState({
          ...newState,
          selectedSelectionFolderId: null,
          selectedWaterfallSelectionFolderId: null,
          selectedSelectionPaginationIndex: 1,
          selectedWaterfallSelectionPaginationIndex: 1,
        });

        break;

      case Constants.PAGE_RESET:
        if (overviewSection === Constants.OVERVIEW__SECTION__SELECTIONS) {
          this.setState({ ...newState, selectedSelectionPaginationIndex: 1 });
        } else {
          this.setState({ ...newState, selectedWaterfallSelectionPaginationIndex: 1 });
        }

        break;

      default:
        this.setState({ ...newState });

        break;
    }
  };
  /**
   * Handles toast open when open ai is down
   * @param {boolean} status - true or false
   * @returns {void}
   */

  handleOpenAidown = (status) => {
    this.setState({ openAidown: status });
  };

  /**
   * Updates folder id that user in on the overview screen
   * @param {string} folderId - Id of the folder
   * @returns {void} Will setState for selectedWaterfallSelectionFolderId or selectedSelectionFolderId
   */
  handleFilterFolderId = (folderId) => {
    const { overviewSection } = this.state;

    if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS) {
      this.setState({
        selectedWaterfallSelectionFolderId: folderId,
        selectedWaterfallSelectionPaginationIndex: 1,
      });
    } else {
      this.setState({
        selectedSelectionFolderId: folderId,
        selectedSelectionPaginationIndex: 1,
      });
    }
  };

  /**
   * Updates page index that the user is in on the overview screen
   * @param {number} index - Page index
   * @returns {void} Will setState for selectedWaterfallSelectionPaginationIndex or selectedSelectionPaginationIndex
   */
  handlePaginationIndex = (index) => {
    const { overviewSection } = this.state;

    if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS) {
      this.setState({
        selectedWaterfallSelectionPaginationIndex: index,
      });
    } else {
      this.setState({
        selectedSelectionPaginationIndex: index,
      });
    }
  };

  /**
   * Validates the folder id that the user is in, and returns it
   * @returns {string} The folder id the user is currently in
   */
  getFilterFolderId = () => {
    const {
      overviewSection,
      selectedWaterfallSelectionFolderId,
      selectedSelectionFolderId,
      folders,
      loadingFolders,
    } = this.state;

    // Get filter folder id based on the overview section we are currently in.
    let filterFolderId = overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS ?
      selectedWaterfallSelectionFolderId :
      selectedSelectionFolderId;

    /**
     * When filterFolderId is null we want to show the default folder (All Selections)
     * When filterFolderId is empty string we want to show Uncategorized Selections folder.
     * When filterFolderId is 'archivedSelectionFolderId' we want to show Archived Selections folder.
     * In all other cases search in fetched folders to make sure filterFolderId is not deleted.
     */
    if (
      filterFolderId &&
      filterFolderId !== '' &&
      filterFolderId !== 'archivedSelectionFolderId' &&
      (!loadingFolders || folders.length)
    ) {
      // In case filter folder is deleted, show default folder.
      filterFolderId = (folders.find(folder => folder._id === filterFolderId))?._id || null;
    }

    return filterFolderId;
  };

  /**
   * Validates the page index that the user is in, and returns it
   * @returns {number} The page index the user is currently in
   */
  getPaginationIndex = () => {
    const {
      overviewSection,
      selectedSelectionPaginationIndex,
      selectedWaterfallSelectionPaginationIndex,
    } = this.state;

    const paginationIndex = overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS ?
      selectedWaterfallSelectionPaginationIndex :
      selectedSelectionPaginationIndex;

    return paginationIndex;
  };

  /**
   * Updates user's current position in navigation, can a sub screen (like selection > target definition)
   * @param {string} value - Navigator status
   * @returns {void} Will setState for globalNavigator
   */
  handleGlobalNavigator = (value) => {
    this.setState({
      globalNavigator: value,
    });
  };

  /**
   * Triggers inside of componentWillUnmount function in Selection
   * @returns {void}
   */
  handleClearCurrentSelection = () => {
    const { navigator } = this.state;

    /*
     * Clear every other property except the currentSelectionId
     * if we're not navigating away from selection
     */

    if (navigator === Constants.NAVIGATION__SELECTION ||
      navigator === Constants.NAVIGATION__WATERFALL_SELECTION) {
      this.setState(this.clearState);
    } else {
      this.setState({ ...this.clearState, currentSelectionId: '' });
    }
  };

  /**
   * Update user's preferences in the DB
   * @param {Object} preferences - user preferences object
   * @returns {void}
   */
  updateUserPreferences = async (preferences) => {
    try {
      const updatedPreferences = await UsersAPI.updatePreferences(
        preferences,
        this.axiosCancelToken.token,
      );

      this.setState({ preferences: updatedPreferences || {} });
    } catch (err) {
      this.setState({ error: err });
    }
  };

  /**
   * Get user's preferences in the DB
   * @returns {Object} - preferences object
   */
  getUserPreferences = async () => {
    try {
      const { data } = await UsersAPI.getPreferences(
        this.axiosCancelToken.token,
      );

      return data;
    } catch (err) {
      this.setState({ error: err });
    }
  };

  /**
   * Get Guidance Tips from the DB
   * @returns {Promise<Array>} - Guidance Tips List
   */
  getGuidanceTips = async () => {
    try {
      const data = await GuidanceTipsAPI.getGuidanceTips(
        this.axiosCancelToken.token,
      );

      return data;
    } catch (err) {
      this.setState({ error: err });
    }
  };

  /**
   * Fetches the scope data extensions from the Deedee AI API
   * @returns {Promise<Array>} - The scope data extensions
   */
  getDeedeeAIScopeDataExtensions = async () => {
    try {
      const response = await DeedeeAIAPI.getScopeDataExtensions(
        this.axiosCancelToken.token,
      );

      return response.data;
    } catch (err) {
      this.setState({ error: err });
    }
  };

  /**
   * Get all notifications
   * @returns {void} Fetch all notifications and setState notifications
   */
  getNotifications = async () => {
    try {
      const notifications = await NotificationsAPI.getNotifications(
        this.axiosCancelToken.token,
      );

      this.setState({ notifications: notifications.data });
    } catch (err) {
      this.setState({ error: err });
    }
  };

  /**
   * Refresh folders list
   * @returns {void} Will fetch all selections folders and setState folders
   */
  refreshFolders = async () => {
    const { overviewSection } = this.state;

    try {
      this.setState({ loadingFolders: true });

      // depending on the selected section in overview, get folders with the appropriate type
      const isWaterfall = overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS;
      const folders = await SelectionFoldersApi.getSelectionFolders(this.axiosCancelToken.token, isWaterfall);

      this.setState({ folders: folders.data, loadingFolders: false });
    } catch (err) {
      this.setState({ error: err, loadingFolders: false });
    }
  };

  /**
   * Connect parent+children. Make it out of the received json
   * @param {array} folders - current folders
   * @param {string} parentId - id of the parent folder
   * @returns {array} out - connected parent + children
   */
  getNestedSelectionFolders = (folders, parentId) => {
    if (folders === undefined) return [];

    return folders
      .filter(folder => folder.parentFolderId === parentId)
      .map((folder) => {
        const children = this.getNestedSelectionFolders(folders, folder._id);
        // eslint-disable-next-line no-param-reassign

        if (children.length) folder.children = children;

        return folder;
      });
  };

  /**
   * Handle loading Zendesk chat box in the app
   * @returns {void} - Calls the Zendesk API
   */
  handleZendeskLoaded = () => {
    ZendeskAPI('webWidget:on', 'open');
  };

  // eslint-disable-next-line
  UNSAFE_componentWillUnmount() {
    window.removeEventListener(Constants.NETWORK_ONLINE, this.handleConnectionChange);
    window.removeEventListener(Constants.NETWORK_OFFLINE, this.handleConnectionChange);
    socketUtil.socketDisconnect();
  }

  render() {
    const {
      globalNavigator,
      navigator,
      currentSelectionId,
      folderId,
      unionSelections,
      unionSelectionsIndex,
      isDisconnected,
      folders,
      justLoggedIn: hasJustLoggedIn,
      notifications,
      isSelectionMounted,
      overviewSection,
      loadingFolders,
      error,
      folderSettings,
      currentSelectionName,
      backToWaterFall,
      addSelectionToWFSelectedSelections,
      selections,
      preferences,
      guidanceTips,
      deedeeAIScopeDEs,
      deedeeAIRequestFeedback,
      showDeedeeAIFeedbackModal,
      openAidown,
    } = this.state;

    const zendeskChatbot =
      Util.isDESelectFreeUser() || Features.isFeatureEnabled(Constants.FEATURE__ZENDESK_CHATBOT);

    // throw an error
    if (error) {
      throw error;
    }

    /**
     * Check if the browser is 'online' and has the Internet access
     * If the browser is 'offline'; display a modal with a proper message
     */
    if (isDisconnected) {
      return (
        <NetworkErrorModal error={false} />
      );
    }

    /**
     * if there is no popup
     * render overview - navigator:overview
     * render selectionCriteria - navigator:selectionCriteria
     * render preview - navigator:preview
     * render adminPanel - navigator:adminPanel
     */
    return (
      <GuidanceTipsContextWrapper preferences={preferences} guidanceTips={guidanceTips}>
        {
          zendeskChatbot &&
          <Zendesk defer onLoaded={this.handleZendeskLoaded} />
        }

        <ToastContainer enableMultiContainer limit={1} containerId={Constants.NOTIFICATION__CONTAINER_ID__RUNNING} />

        {(navigator === Constants.NAVIGATION__OVERVIEW) && (

          <div>
            {hasReadOnlyAccess && <WarningAlert text={Constants.WARNING_TEXT__USER_ONLY_ACCESS} />}

            <RightBar
              handleNavigator={this.handleNavigator}
              updateUserPreferences={this.updateUserPreferences}
              userPreferences={preferences}
              navigator={globalNavigator}
              handleSetAppState={this.handleSetAppState}
              deedeeAIScopeDEs={deedeeAIScopeDEs}
              deedeeAIRequestFeedback={deedeeAIRequestFeedback}
              showDeedeeAIFeedbackModal={showDeedeeAIFeedbackModal}
              handleOpenAidown={this.handleOpenAidown}
            />
            <Overview
              handleNavigator={this.handleNavigator}
              handleOpenAidown={this.handleOpenAidown}
              handleSetAppState={this.handleSetAppState}
              refreshFolders={this.refreshFolders}
              folders={folders}
              folderId={folderId}
              getNestedSelectionFolders={this.getNestedSelectionFolders}
              justLoggedIn={hasJustLoggedIn}
              notifications={notifications || []}
              latePayment={latePayment}
              overviewSection={overviewSection}
              loadingFolders={loadingFolders}
              folderSettings={folderSettings}
              filterFolderId={this.getFilterFolderId()}
              handleFilterFolderId={this.handleFilterFolderId}
              paginationIndex={this.getPaginationIndex()}
              handlePaginationIndex={this.handlePaginationIndex}
              openAidown={openAidown}
            />
          </div>
        )}
        {(navigator === Constants.NAVIGATION__SELECTION) && (
          <div>
            {hasReadOnlyAccess && <WarningAlert text={Constants.WARNING_TEXT__USER_ONLY_ACCESS} />}

            <RightBar
              handleNavigator={this.handleNavigator}
              updateUserPreferences={this.updateUserPreferences}
              userPreferences={preferences}
              navigator={globalNavigator}
              handleSetAppState={this.handleSetAppState}
              deedeeAIScopeDEs={deedeeAIScopeDEs}
              deedeeAIRequestFeedback={deedeeAIRequestFeedback}
              showDeedeeAIFeedbackModal={showDeedeeAIFeedbackModal}
              handleOpenAidown={this.handleOpenAidown}
            />
            <Selection
              handleGlobalNavigator={this.handleGlobalNavigator}
              handleNavigator={this.handleNavigator}
              currentSelectionId={currentSelectionId}
              handleClearCurrentSelection={this.handleClearCurrentSelection}
              unionSelections={unionSelections}
              unionSelectionsIndex={unionSelectionsIndex}
              handleSetAppState={this.handleSetAppState}
              handleAddElementToAppArrayState={this.handleAddElementToAppArrayState}
              folderId={folderId}
              paginationIndex={this.getPaginationIndex()}
              folders={folders}
              isSelectionMounted={isSelectionMounted}
              currentSelectionName={currentSelectionName}
              backToWaterFall={backToWaterFall}
              deedeeAIRequestFeedback={deedeeAIRequestFeedback}
              showDeedeeAIFeedbackModal={showDeedeeAIFeedbackModal}
            />
          </div>
        )}
        {(navigator === Constants.NAVIGATION__ADMIN_PANEL) && (
          <div>
            <RightBar
              handleNavigator={this.handleNavigator}
              updateUserPreferences={this.updateUserPreferences}
              userPreferences={preferences}
              navigator={globalNavigator}
              handleSetAppState={this.handleSetAppState}
              deedeeAIScopeDEs={deedeeAIScopeDEs}
              showDeedeeAIFeedbackModal={showDeedeeAIFeedbackModal}
              handleOpenAidown={this.handleOpenAidown}
            />
            <AdminPanel
              handleNavigator={this.handleNavigator}
              handleSetAppState={this.handleSetAppState}
            />
          </div>
        )}
        {(navigator === Constants.NAVIGATION__WATERFALL_SELECTION) && (

          <div>
            {hasReadOnlyAccess && <WarningAlert text={Constants.WARNING_TEXT__USER_ONLY_ACCESS} />}

            <RightBar
              handleNavigator={this.handleNavigator}
              userPreferences={preferences}
              updateUserPreferences={this.updateUserPreferences}
              navigator={globalNavigator}
              handleSetAppState={this.handleSetAppState}
              deedeeAIScopeDEs={deedeeAIScopeDEs}
              deedeeAIRequestFeedback={deedeeAIRequestFeedback}
              showDeedeeAIFeedbackModal={showDeedeeAIFeedbackModal}
              handleOpenAidown={this.handleOpenAidown}
            />
            <Provider store={store}>
              <WaterfallSelection
                handleNavigator={this.handleNavigator}
                getNestedSelectionFolders={this.getNestedSelectionFolders}
                folderId={folderId || ''}
                paginationIndex={this.getPaginationIndex()}
                currentSelectionId={currentSelectionId || ''}
                handleSetAppState={this.handleSetAppState}
                waterfallFolders={folders}
                folderSettings={folderSettings}
                currentSelectionName={currentSelectionName}
                addSelectionToWFSelectedSelections={addSelectionToWFSelectedSelections}
                waterfallSelections={selections}
              />
            </Provider>
          </div>
        )}
      </GuidanceTipsContextWrapper>
    );
  }
}

GuidanceTipsContextWrapper.propTypes = {
  /**
   * User preferences object
   */
  preferences: PropTypes.object.isRequired,
  /**
   * Guidance tips array
   */
  guidanceTips: PropTypes.array.isRequired,
  /**
   * Children nodes for this component
   */
  children: PropTypes.node.isRequired,
};

export default App;
